import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';

import { Title } from '@app/views/AddOffer/components/AddOfferForm/MainInformation/Title';
import { TravelCategory } from '@app/views/AddOffer/components/AddOfferForm/MainInformation/TravelCategory';
import { Description } from '@app/views/AddOffer/components/AddOfferForm/MainInformation/Description';
import { LocationWidget } from '@app/widgets/Location';
import { Offer } from '@app/api/gql/generated-types';

import { Images } from '../Images';

interface MainInformationProps {
  isShowTitle?: boolean;
  isEdit?: boolean;
}

export const MainInformation: FC<MainInformationProps> = ({
  isShowTitle = true,
  isEdit = false,
}) => {
  const { t } = useTranslation(['forms']);

  const { requestId, isPublic } = useWatch<Offer>(['requestId', 'isPublic']);

  const isShowActivities = useMemo(() => {
    if (!!requestId && !isPublic) {
      return false;
    }
    return true;
  }, [isPublic, requestId]);

  return (
    <Box w="100%">
      {isShowTitle && (
        <Text
          color="black.100"
          fontSize={{ base: 24, md: '28px' }}
          fontWeight="700"
          my={{ base: 2, md: 4 }}
          textAlign="center">
          {isEdit ? t('EditOffer') : t('CreateNewOffer')}
        </Text>
      )}
      <Box mb="20px">
        <Images />
      </Box>

      <Title />
      <Description />
      <Box mb={5}>
        <LocationWidget label={t('location')} />
      </Box>
      {isShowActivities && (
        <Flex flexDirection={{ md: 'row', base: 'column' }} gap={4}>
          <TravelCategory />
        </Flex>
      )}
    </Box>
  );
};
