import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIcon } from '@app/icons/search-icon.svg';

interface SearchBarProps {
  onSetSearch: (val: string) => void;
}

export const SearchBar: FC<SearchBarProps> = ({ onSetSearch }) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation('forms');

  const onKeyDown = (e) => {
    if (!value) {
      return;
    }
    if (e.key === 'Enter') {
      onSetSearch(value);
    }
  };
  return (
    <InputGroup
      backgroundColor="grey.100"
      borderRadius={10}
      gap={2.5}
      mt={5}
      px={2.5}
      py={2}
      variant="unstyled">
      <InputLeftAddon>
        <SearchIcon />
      </InputLeftAddon>
      <Input
        autoFocus
        fontSize={14}
        placeholder={t('faq.search.placeholder')}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </InputGroup>
  );
};
