import { HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderStatusDescriptionProps {
  textTransKey?: string;
  emoji?: string;
}

export const OrderStatusDescription: FC<OrderStatusDescriptionProps> = ({
  textTransKey = 'orderStatusDescription.pending',
  emoji = 'ℹ️',
}) => {
  const { t } = useTranslation('tables');
  return (
    <HStack
      alignItems="flex-start"
      backgroundColor="grey.150"
      mt={2}
      px={5}
      py={2}
      w="full">
      <Text fontSize={12}>{emoji}</Text>
      <Text color="grey.250" fontSize={12}>
        {t(textTransKey)}
      </Text>
    </HStack>
  );
};
