import { Flex, Box, Icon, Text } from '@chakra-ui/react';
import React, { FC, MouseEvent } from 'react';

import { DateFormatter } from '@app/components/DateFormatter';
import { MdDoneAll } from '@app/widgets/react-icons';
import { MessageFragment } from '@app/api/schemas/message.mongo.generated';

import { useContextMenuActions } from '../../hooks/contextMenuActions';

import { MessageImage } from './MessageImage';
import { MessageReferenceItem } from './MessageReferenceItem';

interface MessageItemProps {
  message: MessageFragment;
  isOwnerId?: boolean;
  isWidget?: boolean;
  onContextMenu: (
    event: MouseEvent<HTMLDivElement>,
    message: MessageFragment,
    isOwnerId: boolean,
  ) => void;
}

export const MessageItem: FC<MessageItemProps> = ({
  message,
  isOwnerId,
  isWidget = false,
  onContextMenu,
}) => {
  const isAttachBorderRadius = isOwnerId ? '0 0 0 20px' : '0 0 20px 0';
  const isDefaultBorderRadius = isOwnerId
    ? '20px 20px 0 20px'
    : '20px 20px 20px 0';

  const { setMessage } = useContextMenuActions();

  const onReply = () => {
    setMessage(message);
  };

  const hasReference =
    !!message?.reference?.offerId ||
    !!message?.reference?.requestId ||
    !!message?.reference?.orderId;

  return (
    <Flex
      key={message?._id}
      justify={isOwnerId ? 'flex-end' : 'flex-start'}
      padding="5px 0px"
      w="100%"
      onContextMenu={(e) => onContextMenu(e, message, isOwnerId)}>
      <Box maxW={isWidget ? '245px' : '420px'} minW="150px">
        {hasReference && (
          <Box
            _hover={{
              opacity: 0.7,
            }}
            backgroundColor="grey.100"
            borderRadius="20px 20px 0 0 "
            cursor="pointer"
            p={isWidget ? 2 : 4}
            role="group"
            transition="all 0.2s linear">
            <MessageReferenceItem
              isWidget={isWidget}
              item={message.reference}
            />
          </Box>
        )}
        {!!message?.attachments?.length && (
          <MessageImage
            attachment={message?.attachments[0]}
            hasTopPlate={!!hasReference}
            isOwnerId={isOwnerId}
            isTextBelow={!!message.content}
            onReply={onReply}
          />
        )}

        {!!message?.content && (
          <>
            <Flex
              background={isOwnerId ? 'primary.green' : 'grey.300'}
              borderRadius={
                !!message?.attachments?.length || hasReference
                  ? isAttachBorderRadius
                  : isDefaultBorderRadius
              }
              flexDirection="column"
              gap={isWidget ? '2px' : '8px'}
              p={isWidget ? '3' : '4'}>
              {message?.replyTo && (
                <Text
                  borderColor="white"
                  borderLeft="1px"
                  color="white"
                  dangerouslySetInnerHTML={{
                    __html: message?.replyTo?.content,
                  }}
                  noOfLines={1}
                  pl="6px"
                  whiteSpace="pre-line"
                />
              )}
              <Text
                color="white"
                dangerouslySetInnerHTML={{
                  __html: message.content,
                }}
                fontSize={isWidget ? '12px' : '14px'}
                whiteSpace="pre-line"
              />
              <Flex justifyContent="flex-end">
                <Icon
                  as={MdDoneAll}
                  color="white"
                  display={message?.seen ? 'flex' : 'none'}
                  h="18px"
                  me="8px"
                  w="18px"
                />
              </Flex>
            </Flex>
            <Text
              color="grey.200"
              fontSize={12}
              fontWeight="500"
              mt="8px"
              textAlign={isOwnerId ? 'right' : 'left'}>
              <DateFormatter date={new Date(message.createdAt)} format="p" />
            </Text>
          </>
        )}
      </Box>
    </Flex>
  );
};
