import { VStack } from '@chakra-ui/react';
import React, { FC, useContext } from 'react';

import { SidebarLinks } from '@app/components/Sidebar/components/Links';
import { SidebarContext } from '@app/contexts/SidebarContext';

import { HeaderMenu } from './HeaderMenu';
import { BalanceBlock } from './BalanceBlock';
import UserMenu from './UserMenu';

interface SidebarContentProps {
  isHover?: boolean;
}

const SidebarContent: FC<SidebarContentProps> = ({ isHover = false }) => {
  const { toggleSidebar } = useContext(SidebarContext);
  return (
    <VStack
      borderRadius="30px"
      h="full"
      justifyContent="space-between"
      opacity={isHover ? 0.5 : 1}
      transition="opacity 0.15s linear">
      <VStack
        alignItems={toggleSidebar ? 'center' : 'flex-start'}
        gap="30px"
        pt="25px"
        w="full">
        <HeaderMenu />
        <SidebarLinks />
      </VStack>

      <VStack
        alignItems={!toggleSidebar ? 'center' : 'flex-start'}
        gap="22px"
        pb="20px"
        w="full">
        <BalanceBlock />
        <UserMenu />
      </VStack>
    </VStack>
  );
};

export default SidebarContent;
