import { Box, Flex, HStack, Link, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface AuthFooterProps {
  color?: string;
}

export const AuthFooter: FC<AuthFooterProps> = ({ color = 'white' }) => {
  const { t, i18n } = useTranslation(['footer']);

  return (
    <Box as="footer" px={[0, 0, 0, 8]} width="100%" zIndex={5}>
      <Flex
        flexDirection={{ base: 'column', '2xl': 'row' }}
        gap="15px"
        justifyContent="space-between">
        <Text color={color} fontSize="20px" fontWeight={700}>
          MIRA.travel
        </Text>
        <HStack spacing="40px">
          <Text
            as={Link}
            color={color}
            href="https://mira.travel/support"
            target="_blank"
            variant="mainWhiteLink">
            {t('support')}
          </Text>
          <Text
            as={Link}
            color={color}
            href={`https://mira.travel/docs/${i18n.resolvedLanguage}/agent_offer_MIRA.travel.pdf`}
            target="_blank"
            variant="mainWhiteLink">
            {t('privacyPolicy')}
          </Text>
          <Text
            as={Link}
            color={color}
            href={`https://mira.travel/docs/${i18n.resolvedLanguage}/user_agreement_MIRA.travel.pdf`}
            target="_blank"
            variant="mainWhiteLink">
            {t('terms')}
          </Text>
        </HStack>
      </Flex>
    </Box>
  );
};
