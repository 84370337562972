import { Flex, useColorModeValue, Text } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityFragment } from '@app/api/gql/generated-types';
import { getLang } from '@app/components/FormatNumber/utils';

interface TagProps {
  title?: string;
  image?: string;
  isGrey?: boolean;
  details?: ActivityFragment['details'];
}

export const Tag: FC<TagProps> = ({ details, isGrey }) => {
  const { i18n } = useTranslation();
  const lang = getLang(i18n.resolvedLanguage);
  const title = useMemo(
    () => details?.find((detail) => detail.lang === lang)?.title,
    [details, lang],
  );
  const bg = isGrey ? 'mainGray.200' : 'green.300';
  const txtColor = useColorModeValue('mainGray.100', 'mainGray.100');
  return (
    <Flex
      bg={bg}
      borderRadius="10px"
      color={txtColor}
      columnGap="8px"
      fontSize={14}
      fontWeight={500}
      lineHeight="24px"
      padding="5px 10px"
      width="fit-content">
      <Text color={txtColor} noOfLines={1}>
        {title}
      </Text>
    </Flex>
  );
};

export const CategoryTag: FC<TagProps> = ({ details }) => {
  const { i18n } = useTranslation();
  const lang = getLang(i18n.resolvedLanguage);
  const title = useMemo(
    () => details?.find((detail) => detail.lang === lang)?.title,
    [details, lang],
  );

  const bg = useColorModeValue('grey.250', 'grey.250');
  const txtColor = useColorModeValue('grey.100', 'grey.100');

  return (
    <Flex
      bg={bg}
      borderRadius="10px"
      color={txtColor}
      columnGap="8px"
      fontSize={14}
      fontWeight={500}
      lineHeight="24px"
      padding="5px 10px"
      width="fit-content">
      <Text color={txtColor} noOfLines={1}>
        {title}
      </Text>
    </Flex>
  );
};
