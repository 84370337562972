import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';
import { FormControl } from '@chakra-ui/react';

import { Business } from '@app/api/gql/generated-types';
import Label from '@app/components/FormElements/Label';
import { ErrorMessage } from '@app/components/FormElements/ErrorMessage';
import { useCountriesQuery } from '@app/api/schemas/country.amazon.generated';

import { SelectField } from '../SelectField';

interface SelectCountryProps {
  field: ControllerRenderProps<Business, 'country'>;
  label?: string;
  variant?: string;
}

export const SelectCountry: FC<SelectCountryProps> = ({
  field,
  label,
  variant = null,
}) => {
  const { data: { countries } = {} } = useCountriesQuery({
    variables: {
      pagination: {
        limit: 300,
      },
    },
    context: {
      clientName: 'amazon',
    },
  });

  const { t, i18n } = useTranslation('forms');
  const {
    formState: { errors, isSubmitted },
  } = useFormContext();

  return (
    <FormControl
      isInvalid={isSubmitted && !!errors.country}
      pb="25px"
      position="relative">
      {!!label && (
        <Label color="grey.300" fontSize={14}>
          {label}
        </Label>
      )}
      <SelectField
        isSearchable
        error={!!errors.country?.message}
        getOptionLabel={(country) =>
          country.translations?.[i18n?.language] ?? country.name
        }
        getOptionValue={(country) => country._id}
        options={countries?.items}
        placeholder={t('countryPlaceholder')}
        variant={variant}
        {...field}
      />
      <ErrorMessage
        bottom="6px"
        color="red.600"
        fontSize="12px"
        fontWeight={400}
        position="absolute">
        {errors.country?.message as string}
      </ErrorMessage>
    </FormControl>
  );
};
