import { Box, HStack, IconButton, Image, Text } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Account } from '@app/api/gql/generated-types';
import { ReactComponent as ReplayIcon } from '@app/icons/replay-icon.svg';
import { ReactComponent as CloseIcon } from '@app/icons/close-icon.svg';
import useCurrentAccount from '@app/hooks/useCurrentAccount';

import { useContextMenu } from '../../hooks/useContextMenu';
import { useContextMenuActions } from '../../hooks/contextMenuActions';

interface MessageReplayProps {
  participants: Account[];
  isWidget?: boolean;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const MessageReplay: FC<MessageReplayProps> = ({
  participants,
  isWidget = false,
}) => {
  const { account } = useCurrentAccount();
  const { state } = useContextMenu();
  const { setInitState } = useContextMenuActions();
  const { t } = useTranslation(['modalsAndMenus']);

  const companion = participants?.[0];

  const hadAttachment = state?.selectedMessage?.attachments?.length;
  const name = useMemo(() => {
    if (state?.selectedMessage?.ownerId?._id === account?._id) {
      return '';
    }

    return (
      companion?.fullName ?? `${companion?.firstName} ${companion?.lastName}`
    );
  }, [
    account?._id,
    companion?.firstName,
    companion?.fullName,
    companion?.lastName,
    state?.selectedMessage?.ownerId?._id,
  ]);

  return (
    <HStack
      alignItems="center"
      bg={isWidget ? 'transparent' : 'grey.150'}
      borderRadius="10px 10px 0px 0px"
      gap={3}
      h="56px"
      p={isWidget ? '10px 0' : '10px 15px'}
      w="100%">
      <Box color="grey.300">
        <ReplayIcon />
      </Box>
      {!!hadAttachment && (
        <Image
          height="35"
          objectFit="contain"
          src={`${imageServer}/${state?.selectedMessage?.attachments?.[0]?.url}?width=282&format=webp`}
          width="35px"
        />
      )}
      <Box
        borderLeft={hadAttachment ? '0' : '1px'}
        borderLeftColor="grey.300"
        flex={1}
        h="36px"
        lineHeight="20px"
        mr={6}
        px={hadAttachment ? '0' : '10px'}>
        <Text color="primary.green" fontSize={isWidget ? '14px' : '16px'}>
          {t('replyTo')} {name}
        </Text>
        <Text
          color="grey.300"
          fontSize={isWidget ? '12px' : '14px'}
          lineHeight="16px"
          noOfLines={1}>
          {state?.selectedMessage?.content ?? '...'}
        </Text>
      </Box>

      <IconButton
        alignItems="center"
        aria-label="Close"
        color="grey.300"
        cursor="pointer"
        position="absolute"
        right={3}
        size="xs"
        top={4}
        variant="icon"
        onClick={setInitState}>
        <CloseIcon width="20px" />
      </IconButton>
    </HStack>
  );
};
