import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
} from '@chakra-ui/react';
import { addDays } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateFormatter } from '@app/components/DateFormatter';
import {
  OfferAddition,
  OfferPriceEstimateType,
  OfferType,
} from '@app/api/gql/generated-types';
import { PriceField } from '@app/components/PriceField';

interface OfferPriceAccordionItemProps {
  item: OfferAddition;
  type: OfferType;
  estimateType?: OfferPriceEstimateType;
}

export const OfferPriceAccordionItem: FC<OfferPriceAccordionItemProps> = ({
  item,
  type,
  estimateType,
}) => {
  const endDate = useMemo(
    () =>
      !!item?.startDate
        ? addDays(new Date(item?.startDate), Number(item?.days))
        : null,
    [item?.days, item?.startDate],
  );
  const { t } = useTranslation('tables');
  const priceLabel = useMemo(() => {
    if (type) {
      return t('price') + ' ' + t(`offerType.${type}`, { ns: 'forms' });
    }
    return t('price');
  }, [type]);
  if (!item?.startDate) {
    return null;
  }

  return (
    <AccordionItem
      backgroundColor="gray.100"
      border="0 none"
      borderRadius={5}
      my={2}
      px={2}
      py={0.5}>
      <AccordionButton
        _hover={{
          backgroundColor: 'transparent',
        }}
        px="0"
        py={1}>
        <HStack flex={1} justifyContent="space-between">
          {!!item.startDate && (
            <Text
              color="grey.300"
              fontSize={14}
              fontWeight={400}
              lineHeight="20px">
              📅 <DateFormatter date={new Date(item.startDate)} /> -{' '}
              {!!endDate && <DateFormatter date={endDate} />}
            </Text>
          )}
          <AccordionIcon color="grey.300" h="24px" w="24px" />
        </HStack>
      </AccordionButton>
      {!!item?.days && (
        <AccordionPanel fontSize={14} lineHeight="20px" px={0} py={1}>
          🌜 {t('night', { count: Number(item.days) })}
        </AccordionPanel>
      )}
      {!!item?.adults && (
        <AccordionPanel fontSize={14} lineHeight="20px" px={0} py={1}>
          👥 {t('adult', { count: Number(item.adults) })}
          {!!item?.children && (
            <>; {t('child', { count: Number(item.children) })}</>
          )}
        </AccordionPanel>
      )}
      {!!item?.price && (
        <AccordionPanel fontSize={14} lineHeight="20px" px={0} py={1}>
          💰 <PriceField type={estimateType} value={item?.price} /> {priceLabel}
        </AccordionPanel>
      )}
    </AccordionItem>
  );
};
