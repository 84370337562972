export const hasUppercaseChar = (value: string): boolean => {
  const regex = /[A-Z]/;
  return regex.test(value);
};

// it should check for one lowercase character in the string
export const hasLowercaseChar = (value: string): boolean => {
  const regex = /[a-z]/;
  return regex.test(value);
};

// it should check for one digit in the string
export const hasDigitChar = (value: string): boolean => {
  const regex = /[0-9]/;
  return regex.test(value);
};

export const hasSpecialChar = (value: string): boolean => {
  const regex = /[\$&+,:;=?@#|\'<>.^*()%!-]/;
  return regex.test(value);
};
