import { Amplify, type ResourcesConfig } from 'aws-amplify';
import { defaultStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    signUpVerificationMethod: 'code',
    loginWith: {
      oauth: {
        scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        responseType: 'code',
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        redirectSignIn: [window.location.origin],
        redirectSignOut: [window.location.origin],
      },
    },
  },
};

Amplify.configure({
  Auth: authConfig,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
