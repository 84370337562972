import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CharacteristicFragment = { __typename?: 'TravelerCharacteristic', _id: any | null, parent: { __typename?: 'TravelerCharacteristic', _id: any | null } | null, details: Array<{ __typename?: 'TravelerCharacteristicsDetail', lang: string | null, title: string | null } | null> | null };

export type FindTravelerCharacteristicsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindTravelerCharacteristicsQuery = { __typename?: 'Query', findTravelerCharacteristics: { __typename?: 'FindTravelerCharacteristicsPayload', total: number | null, items: Array<{ __typename?: 'TravelerCharacteristic', _id: any | null, parent: { __typename?: 'TravelerCharacteristic', _id: any | null } | null, details: Array<{ __typename?: 'TravelerCharacteristicsDetail', lang: string | null, title: string | null } | null> | null } | null> | null } | null };

export const CharacteristicFragmentDoc = gql`
    fragment Characteristic on TravelerCharacteristic {
  _id
  parent {
    _id
  }
  details {
    lang
    title
  }
}
    `;
export const FindTravelerCharacteristicsDocument = gql`
    query FindTravelerCharacteristics {
  findTravelerCharacteristics {
    items {
      ...Characteristic
    }
    total
  }
}
    ${CharacteristicFragmentDoc}`;

/**
 * __useFindTravelerCharacteristicsQuery__
 *
 * To run a query within a React component, call `useFindTravelerCharacteristicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTravelerCharacteristicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTravelerCharacteristicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindTravelerCharacteristicsQuery(baseOptions?: Apollo.QueryHookOptions<FindTravelerCharacteristicsQuery, FindTravelerCharacteristicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTravelerCharacteristicsQuery, FindTravelerCharacteristicsQueryVariables>(FindTravelerCharacteristicsDocument, options);
      }
export function useFindTravelerCharacteristicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTravelerCharacteristicsQuery, FindTravelerCharacteristicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTravelerCharacteristicsQuery, FindTravelerCharacteristicsQueryVariables>(FindTravelerCharacteristicsDocument, options);
        }
export function useFindTravelerCharacteristicsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindTravelerCharacteristicsQuery, FindTravelerCharacteristicsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindTravelerCharacteristicsQuery, FindTravelerCharacteristicsQueryVariables>(FindTravelerCharacteristicsDocument, options);
        }
export type FindTravelerCharacteristicsQueryHookResult = ReturnType<typeof useFindTravelerCharacteristicsQuery>;
export type FindTravelerCharacteristicsLazyQueryHookResult = ReturnType<typeof useFindTravelerCharacteristicsLazyQuery>;
export type FindTravelerCharacteristicsSuspenseQueryHookResult = ReturnType<typeof useFindTravelerCharacteristicsSuspenseQuery>;
export type FindTravelerCharacteristicsQueryResult = Apollo.QueryResult<FindTravelerCharacteristicsQuery, FindTravelerCharacteristicsQueryVariables>;