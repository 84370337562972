import { HStack, Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';

export const GlobalSkeleton = () => {
  return (
    <VStack gap={4} m={6}>
      <Skeleton height="10vh" w="full" />
      <HStack gap={4} w="full">
        <Skeleton flex="2" height="80vh" />
        <Skeleton flex="8" height="80vh" />
      </HStack>
    </VStack>
  );
};
