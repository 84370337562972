import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getLang } from '@app/components/FormatNumber/utils';

import {
  ActivityFragment,
  useFindActivitiesQuery,
} from '../schemas/activity.mongo.generated';

export const useActivitiesData = () => {
  const { i18n } = useTranslation();
  const { data: { findActivities: activities } = {}, loading: isLoading } =
    useFindActivitiesQuery();
  const lang = getLang(i18n.resolvedLanguage);
  const items = useMemo(() => {
    const parents = activities?.items?.filter((item) => !item.parent) ?? [];
    const options =
      parents?.map((item) => ({
        label: item?.details?.find((detail) => detail.lang === lang)?.title,
        options: activities?.items?.filter(
          (child) => child?.parent?._id === item?._id,
        ),
      })) ?? [];

    return options;
  }, [activities?.items, lang]);

  const getTitle = (details: ActivityFragment['details']) => {
    return details?.find((detail) => detail.lang === lang)?.title;
  };

  return {
    items,
    isLoading,
    getTitle,
  };
};
