import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { signInWithRedirect } from 'aws-amplify/auth';

import { HSeparator } from '@app/components/Separator';
import { useErrorNotification } from '@app/hooks/useErrorNotification';
import { ReactComponent as GoogleIcon } from '@app/icons/google-icon.svg';
import { unsplash } from '@app/api/unsplash';

import SignUpForm from '../SignUpForm';

export const Hero = ({ onClickMore }) => {
  const { t, i18n } = useTranslation(['forms', 'addOffer']);
  const errorNotify = useErrorNotification();
  const handleSignInGoogle = async () => {
    try {
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      errorNotify(error);
    }
  };

  const [photos, setPhotos] = useState<{ urls: { regular: string } }[]>(null);

  const getPhotos = useCallback(async () => {
    try {
      const { response } = await unsplash.photos.getRandom({
        query: 'travel nature',
        count: 1,
      });
      if (response?.length) {
        setPhotos(response);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const photo = photos?.[0] ?? '';

  useEffect(() => {
    void getPhotos();
  }, []);

  return (
    <Box
      background={
        photo?.urls?.regular
          ? `url(${photo?.urls?.regular}) no-repeat`
          : 'grey.300'
      }
      backgroundSize="cover"
      flex={1}
      position="relative"
      py={20}>
      <Box h="full" position="relative" zIndex={5}>
        <HStack
          alignItems="center"
          flexDirection={{ base: 'column', lg: 'row' }}
          h="full"
          px={{ base: 4, lg: 10 }}>
          <Box flex={1} mb={{ base: 10, lg: 0 }}>
            <Heading
              color="white"
              fontSize={{ base: '34px', lg: '64px' }}
              fontWeight={600}
              lineHeight={{ base: '38px', lg: '68px' }}
              mb={4}>
              Туристичний маркетплейс для сучасних агентів
            </Heading>
            <Text color="white" fontSize={{ base: 14, lg: 20 }} maxW="500px">
              Отримай доступ до тисяч туристів і збільш свої продажі за
              допомогою інноваційних рішень
            </Text>
            <Button maxW="200px" mt={6} variant="primary" onClick={onClickMore}>
              Дізнатись більше
            </Button>
          </Box>
          <HStack flex={1} justifyContent="center">
            <Box
              backgroundColor="rgba(0, 0, 0, 0.4)"
              borderRadius={4}
              flex={1}
              maxW="420px"
              px={6}
              py={6}>
              <Heading color="white" fontSize="34px" fontWeight={600}>
                Реєструйся
              </Heading>
              <Text color="whiteAlpha.800" mb={8}>
                і починай заробляти разом з нами
              </Text>
              <SignUpForm />
              <Flex alignItems="center" my="25px" w="full">
                <HSeparator backgroundColor="whiteAlpha.600" />
                <Text color="white" mx="20px" textAlign="center">
                  {t('or')}
                </Text>
                <HSeparator backgroundColor="whiteAlpha.600" />
              </Flex>
              <HStack justifyContent="center" w="full">
                <Button
                  backgroundColor="#23252C"
                  variant="gray"
                  onClick={handleSignInGoogle}>
                  <HStack>
                    <Text color="white">{t('loginWith')}</Text>
                    <GoogleIcon />
                  </HStack>
                </Button>
              </HStack>
              <Text color="white" fontSize={12} mt="25px">
                <Trans
                  components={{
                    terms: (
                      <Text
                        as={Link}
                        color="white"
                        fontSize={12}
                        href={`https://mira.travel/docs/${i18n.resolvedLanguage}/user_agreement_MIRA.travel.pdf`}
                        target="_blank"
                        textDecoration="underline"
                      />
                    ),
                    privacy: (
                      <Text
                        as={Link}
                        color="white"
                        fontSize={12}
                        href={`https://mira.travel/docs/${i18n.resolvedLanguage}/agent_offer_MIRA.travel.pdf`}
                        target="_blank"
                        textDecoration="underline"
                      />
                    ),
                  }}
                  i18nKey="bySigninUp"
                  ns="forms"
                />
              </Text>
            </Box>
          </HStack>
        </HStack>
      </Box>
      <Box
        backgroundColor="rgba(0, 0, 0, 0.4)"
        h="full"
        position="absolute"
        top={0}
        w="full"
        zIndex={2}
      />
    </Box>
  );
};
