import { Box, Image, Text, VStack } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

import NoDataIcon from '@app/icons/no-offers-icon.svg';

interface EmptyListProps {
  image?: string;
  imageMargin?: string;
  title?: string | ReactNode;
  description?: string | ReactNode;
  maxDescWidth?: string;
  goButton?: ReactNode | undefined;
}

export const EmptyList: FC<EmptyListProps> = ({
  image = NoDataIcon,
  imageMargin = '25px 0 15px 0',
  title = 'No data',
  description = 'There is no data to show you right now',
  maxDescWidth = '100%',
  goButton,
  ...props
}) => {
  return (
    <VStack
      alignItems="center"
      h="calc(100dvh - 200px)"
      justifyContent="center"
      minH="100%"
      w="100%"
      {...props}>
      <VStack
        alignItems="center"
        flex={1}
        flexDirection="column"
        justifyContent="center"
        spacing={0}
        textAlign="center"
        w="100%">
        <Image alt="No Data Icon" margin={imageMargin} src={image} />
        <Text color="black.100" fontSize="24px">
          {title}
        </Text>
        <Text
          color="grey.250"
          fontSize="16px"
          fontWeight="400"
          mt="5px"
          w={maxDescWidth}>
          {description}
        </Text>
        {goButton && <Box my="15px">{goButton}</Box>}
      </VStack>
    </VStack>
  );
};
