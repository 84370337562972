import { useState } from 'react';

import { Offer, UpdateOfferInputStatus } from '@app/api/gql/generated-types';
import {
  FindOffersDocument,
  useUpdateOfferMutation,
} from '@app/api/schemas/offer.mongo.generated';
import { FindRequestDocument } from '@app/api/schemas/request.mongo.generated';

export const useOfferStatus = () => {
  const [offer, setOffer] = useState<Offer>();
  const [mutate] = useUpdateOfferMutation({
    refetchQueries: [FindRequestDocument, FindOffersDocument],
  });

  const hideOffer = async () => {
    try {
      await mutate({
        variables: {
          input: {
            _id: offer?._id,
            isPublic: false,
          },
        },
        optimisticResponse: {
          updateOffer: {
            __typename: 'Offer',
            _id: offer._id,
            isPublic: false,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const showOffer = async () => {
    try {
      await mutate({
        variables: {
          input: {
            _id: offer?._id,
            isPublic: true,
          },
        },
        optimisticResponse: {
          updateOffer: {
            __typename: 'Offer',
            _id: offer._id,
            isPublic: true,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const changeStatus = async (status: UpdateOfferInputStatus) => {
    try {
      await mutate({
        variables: {
          input: {
            _id: offer?._id,
            status,
          },
        },
        optimisticResponse: {
          updateOffer: {
            __typename: 'Offer',
            _id: offer._id,
            status,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    offer,
    setOffer,
    hideOffer,
    showOffer,
    changeStatus,
  };
};
