import { useCallback } from 'react';

import { MessageReference } from '@app/api/gql/generated-types';
import {
  useGetChatLazyQuery,
  useGetChatStatusLazyQuery,
} from '@app/api/schemas/chat.mongo.generated';
import { db } from '@app/db';
import { useChatWidgetContext } from '@app/providers/ChatWidgetProvider';

export const useGoChat = () => {
  const { setActiveChat, activeChat } = useChatWidgetContext();
  const [getChatStatus] = useGetChatStatusLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [getChat] = useGetChatLazyQuery();
  const openChat = useCallback(
    async (accountId: string, reference: MessageReference = null) => {
      try {
        const { data: { getChatStatus: chatStatus } = {} } =
          await getChatStatus({
            variables: {
              input: {
                accountId,
              },
            },
          });

        if (!chatStatus?.isNew) {
          const {
            data: { getChat: chat },
          } = await getChat({
            variables: {
              input: {
                chatId: chatStatus?.chatId,
              },
            },
          });
          db.chats.add(chat);
          setActiveChat({
            ...chat,
            state: { isNew: chatStatus.isNew, reference },
          });
          return;
        }
        setActiveChat({
          state: {
            isNew: chatStatus.isNew,
            reference,
            otherAccountId: accountId,
          },
        });
      } catch (error) {
        setActiveChat({
          state: { isNew: true, reference, otherAccountId: accountId },
        });
      }
    },
    [getChatStatus],
  );

  const updateActiveChat = useCallback(
    (reference: MessageReference = null) => {
      setActiveChat({
        ...activeChat,
        state: {
          ...activeChat.state,
          reference,
        },
      });
    },
    [activeChat],
  );

  return {
    openChat,
    updateActiveChat,
  };
};
