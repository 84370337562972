import React, { useCallback, useMemo } from 'react';
import enGB from 'date-fns/locale/en-GB';
import uk from 'date-fns/locale/uk';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import formatRelative from 'date-fns/formatRelative';
import { useTranslation } from 'react-i18next';

import {
  Notification,
  NotificationEntityType,
} from '@app/api/gql/generated-types-amazon';
import {
  NotificationFragmentDoc,
  useNotificationsQuery,
  useUpdateNotificationMutation,
} from '@app/api/schemas/notification.amazon.generated';
import { useApolloLoadingStatus } from '@app/hooks/useApolloLoadingStatus';
import { createAmazonPaginationVariables } from '@app/api/apollo-pagination';

import { NotificationSkeleton } from './NotificationSkeleton';

const entityLink = {
  [NotificationEntityType.CHAT]: '/chat/',
  [NotificationEntityType.OFFER]: '/offers/',
  [NotificationEntityType.REQUEST]: '/requests/',
  [NotificationEntityType.ORDER]: '/orders/',
  [NotificationEntityType.MODAL]: '?modal=',
};

const locale = {
  ...enGB,
  ...uk,
};

const now = new Date();
const sort = 'createdAt__desc';

export const NotificationsContent = () => {
  const { t } = useTranslation('tables');
  const {
    data: { notifications: items } = {},
    networkStatus,
    fetchMore,
  } = useNotificationsQuery({
    variables: {
      pagination: {
        limit: 20,
        sort,
      },
    },
    context: {
      clientName: 'amazon',
    },
  });

  const [update] = useUpdateNotificationMutation({
    context: {
      clientName: 'amazon',
    },
  });

  const { isLoadingData, isFetchingNextPage } =
    useApolloLoadingStatus(networkStatus);

  const notifications = useMemo(() => items?.items ?? [], [items?.items]);

  const handleSetRead = useCallback(async (item: Notification) => {
    try {
      await update({
        variables: {
          id: item?._id,
        },
        update(cache, _result, { variables }) {
          cache.updateFragment<Notification>(
            {
              id: `Notification:${variables?.id}`,
              fragment: NotificationFragmentDoc,
              fragmentName: 'Notification',
            },
            (data) => {
              return {
                ...data,
                isRead: true,
              };
            },
          );
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);
  const fetchNextPage = async () => {
    try {
      if (items?.hasNext && !isLoadingData) {
        await fetchMore(createAmazonPaginationVariables(items, 20, sort));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {notifications?.map((item) => (
        <Box
          key={item?._id}
          _hover={{
            backgroundColor: !item?.isRead ? 'transparent' : 'gray.100',
          }}
          as={Link}
          backgroundColor={!item?.isRead ? 'green.200' : 'transparent'}
          borderRadius={3}
          cursor="pointer"
          my={0.5}
          p={2}
          to={`${entityLink[item?.entityType]}${item?.entityId}`}
          transition="all 0.2s"
          onClick={() => handleSetRead(item)}>
          <Text fontWeight={item?.isRead ? 400 : 600}>{item?.title}</Text>
          <Text
            color="mainGray.300"
            noOfLines={3}
            opacity={item?.isRead ? 0.8 : 1}>
            {item?.text}
          </Text>
          <HStack mt={1}>
            <Text color="primary.green" fontSize={12} fontWeight={700}>
              {formatRelative(new Date(item?.createdAt), now, {
                locale,
              })}
            </Text>
          </HStack>
        </Box>
      ))}
      {isFetchingNextPage && <NotificationSkeleton />}
      {items?.hasNext && !isLoadingData && (
        <Button
          disabled={isFetchingNextPage}
          h="36px"
          isLoading={isFetchingNextPage}
          loadingText={t('loadMore')}
          mt={2}
          size="sm"
          variant="outline"
          onClick={fetchNextPage}>
          {t('loadMore')}
        </Button>
      )}
    </>
  );
};
