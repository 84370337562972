import { HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

import { ReactComponent as ChildrenIcon } from '@app/icons/child-icon-active.svg';
import { TextColumnTag } from '@app/views/Requests/components/TextColumnTag';

interface AdultKidsCellProps {
  adults: number;
  children: number;
}

export const AdultKidsCell: FC<AdultKidsCellProps> = ({ adults, children }) => {
  return (
    <HStack spacing={1}>
      <HStack color="mainGray.300" spacing={1}>
        <Text fontSize={14}>🧑‍🦳</Text>
        <TextColumnTag value={adults} />
      </HStack>
      {children > 0 && (
        <HStack color="mainGray.300" spacing={1}>
          <Text color="gray.500">+</Text>
          <Text fontSize={14}>👶</Text>
          <TextColumnTag value={children} />
        </HStack>
      )}
    </HStack>
  );
};
