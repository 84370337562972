import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
export type CurrencyFragment = { __typename?: 'Currency', _id: any, code: string | null, name: string | null, symbol: string | null };

export const CurrencyFragmentDoc = gql`
    fragment Currency on Currency {
  _id
  code
  name
  symbol
}
    `;