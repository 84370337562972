import { useEffect } from 'react';

import { useSignOut } from '@app/hooks/useSignOut';

export const Logout = (): null => {
  const { signOut } = useSignOut();
  useEffect((): void => {
    void signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
