import React, { FC, ReactNode } from 'react';
import { FormErrorMessage } from '@chakra-ui/react';
import { FormErrorMessageProps } from '@chakra-ui/form-control/dist/form-error';

interface ErrorMessageProps extends FormErrorMessageProps {
  children: ReactNode;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ children, ...props }) => {
  return (
    <FormErrorMessage
      color="red.600"
      fontSize="12px"
      fontWeight={400}
      {...props}>
      {children}
    </FormErrorMessage>
  );
};
