import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatPreviewFragment } from '@app/api/schemas/chat.mongo.generated';
import { useAccountQuery } from '@app/api/schemas/account.amazon.generated';

import { useContextMenuActions } from '../../hooks/contextMenuActions';

import { Footer } from './Footer';
import { Header } from './Header';
import { Messages } from './Messages';

interface MessengerProps {
  chat: ChatPreviewFragment;
  isWidget?: boolean;
  state?: unknown;
  onCloseWidget?: () => void;
}

export const Messenger: FC<MessengerProps> = ({
  chat,
  isWidget = false,
  state,
  onCloseWidget,
}) => {
  const { t } = useTranslation(['profile']);

  const { data: { account: guestAccount } = {} } = useAccountQuery({
    variables: {
      accountId: state?.otherAccountId,
    },
    skip: !state?.otherAccountId,
    context: {
      clientName: 'amazon',
    },
  });

  const { setShowReference } = useContextMenuActions();

  const companion = useMemo(
    () => (state?.isNew ? guestAccount : chat?.participantsExcludingSelf?.[0]),
    [chat?.participantsExcludingSelf, guestAccount, state?.isNew],
  );

  const title = useMemo(() => {
    if (chat?.title) {
      return chat.title;
    }
    if (companion?.fullName) {
      return `${t('chatWith')} ${companion?.fullName}`;
    }

    if (companion?.firstName || companion?.lastName) {
      return `${t('chatWith')} ${companion?.firstName} ${companion?.lastName}`;
    }

    return t('chat');
  }, [
    chat?.title,
    companion?.fullName,
    companion?.firstName,
    companion?.lastName,
    t,
  ]);

  useEffect(() => {
    if (state?.reference) {
      setShowReference(true, state.reference);
    } else {
      setShowReference(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.reference]);

  return (
    <>
      <Footer
        id={chat?._id}
        isWidget={isWidget}
        otherAccountId={state?.otherAccountId}
        participants={chat?.participantsExcludingSelf}
      />
      <Messages
        key={chat?._id}
        chatId={chat?._id}
        isWidget={isWidget}
        unreadCount={chat?.unreadCount}
      />
      <Header
        companion={companion}
        id={chat?._id}
        isWidget={isWidget}
        title={title}
        onCloseWidget={onCloseWidget}
      />
    </>
  );
};
