import { Box, Link, Text } from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { unsplash } from '@app/api/unsplash';

import { AuthFooter } from './Footer';
const utmSource = '?utm_source=mira.travel&utm_medium=referral';

interface PhotoBarProps {
  isShowFooter?: boolean;
}

export const PhotoBar: FC<PhotoBarProps> = ({ isShowFooter = true }) => {
  const [photos, setPhotos] = useState<{ urls: { regular: string } }[]>(null);

  const getPhotos = useCallback(async () => {
    try {
      const { response } = await unsplash.photos.getRandom({
        query: 'travel nature',
        count: 1,
      });
      if (response?.length) {
        setPhotos(response);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const photo = photos?.[0] ?? '';

  useEffect(() => {
    void getPhotos();
  }, []);
  return (
    <Box h="full" overflow="hidden" position="relative" zIndex={2}>
      <Box
        background={
          photo?.urls?.regular
            ? `url(${photo?.urls?.regular}) no-repeat`
            : 'grey.300'
        }
        backgroundSize="cover"
        h="full"
        position="absolute"
        w="full"
      />
      {isShowFooter && (
        <>
          <Box
            background="linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)"
            bottom={0}
            h="200px"
            left={0}
            position="absolute"
            width="full"
          />
          <Box bottom={0} left={0} p={4} position="absolute" w="full">
            <AuthFooter />
            {!!photo?.id && (
              <Text color="white" fontSize={10} opacity={0.8} px={8} py={2}>
                Photo by{' '}
                <Text
                  as={Link}
                  color="white"
                  fontSize={10}
                  href={`https://unsplash.com/@${photo?.user?.username}${utmSource}`}
                  textDecoration="underline">
                  {photo?.user?.first_name} {photo?.user?.last_name}
                </Text>{' '}
                on{' '}
                <Text
                  as={Link}
                  color="white"
                  fontSize={10}
                  href={`https://unsplash.com/${utmSource}`}
                  textDecoration="underline">
                  Unsplash
                </Text>
              </Text>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
