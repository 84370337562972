import { useContext } from 'react';

import { NotificationContext } from '@app/providers/NotificationProvider';

export const useWatchData = () => {
  const { requests, orders, chats } = useContext(NotificationContext);
  return {
    requests,
    orders,
    chats,
  };
};
