import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  UseDisclosureProps,
  VStack,
} from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

import {
  CreateOfferInput,
  OfferType,
  OrderStatus,
  UpdateOfferInput,
} from '@app/api/gql/generated-types';
import { ReactComponent as CloseIcon } from '@app/icons/close-icon.svg';
import { useSendMessageMutation } from '@app/api/schemas/message.mongo.generated';
import { useGetOfferQuery } from '@app/api/schemas/offer.mongo.generated';
import useCurrentAccount from '@app/hooks/useCurrentAccount';
import { useUpdateOrderMutation } from '@app/api/schemas/order.mongo.generated';

import { useOfferFormSubmit } from '../../hooks/useOfferFormSubmit';
import { MainInformation } from '../AddOfferForm/MainInformation/MainInformation';
import { OfferDetails } from '../AddOfferForm/OfferDetails';
import { OfferPriceSegment } from '../AddOfferForm/OfferPrice/OfferPriceSegment';

import { OfferActionButtons } from './OfferActionButtons';
import { OfferIsPublic } from './OfferIsPublic';
import { OfferPreviewMobile } from './OfferPreviewMobile';

interface AddOfferModalProps extends UseDisclosureProps {
  requestId?: string;
  chatId?: string;
  offerId?: string;
  orderId?: string;
}

export const AddOfferModal: FC<AddOfferModalProps> = ({
  isOpen,
  onClose,
  requestId,
  chatId,
  offerId,
  orderId,
}) => {
  const { account } = useCurrentAccount();
  const [mutate, { loading: isLoadingUpdateOrder }] = useUpdateOrderMutation();
  const [sendMessage, { loading }] = useSendMessageMutation();
  const { data: { getOffer: offer } = {} } = useGetOfferQuery({
    variables: {
      input: {
        id: offerId,
      },
    },
    skip: !offerId,
  });

  const offerValues = useMemo(
    () => ({
      ...(offer && {
        ...offer,
        ...(offer?.requestId && { requestId: offer.requestId?._id }),
        ...(!!offer?.photo?.length && {
          photo: offer.photo?.map((item) => omit(item, ['__typename'])),
        }),
        startDate: new Date(offer?.startDate),
        price: {
          value: Number(offer?.price?.value) / 100,
          currency: !isNil(offer?.price?.currency)
            ? offer?.price?.currency
            : account?.settings?.currency,
        },
      }),
    }),
    [account?.settings?.currency, offer],
  );

  const {
    form,
    onSubmit,
    onUpdatePrice,
    isLoading,
    isDraftRef,
    isPriceEditRef,
  } = useOfferFormSubmit({
    hasRedirect: false,
    values: offerValues ?? null,
  });
  const { t } = useTranslation('forms');

  const handleSendMessage = useCallback(
    async (id: string) => {
      await sendMessage({
        variables: {
          input: {
            chatId,
            content: '',
            reference: {
              offerId: id,
            },
          },
        },
        updateQueries: {
          FindMessages: (previousData, { mutationResult }) => {
            const message = mutationResult.data.sendMessage;
            return {
              ...previousData,
              findMessages: {
                ...previousData.findMessages,
                items: [message, ...previousData.findMessages.items],
              },
            };
          },
        },
      });
    },
    [chatId],
  );

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, []);

  const handleSend = async (data: CreateOfferInput) => {
    try {
      const id = await onSubmit(data);
      if (!isDraftRef.current && !!chatId) {
        await handleSendMessage(id);
      }

      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitOfferPrice = async (data: UpdateOfferInput) => {
    try {
      await onUpdatePrice(data);
      await mutate({
        variables: {
          input: {
            _id: orderId,
            status: OrderStatus.PENDING_TRAVELER,
          },
        },
      });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (isDraft: boolean) => {
    isDraftRef.current = Boolean(isDraft);
    await form.handleSubmit(handleSend)();
  };
  const handleEditPrice = async () => {
    isPriceEditRef.current = true;
    await form.handleSubmit(handleSubmitOfferPrice)();
  };

  useEffect(() => {
    if (requestId) {
      form.setValue('requestId', requestId);
      form.setValue('isPublic', !requestId);
      form.setValue('type', OfferType.TOUR);
    }
  }, [requestId]);

  useEffect(() => {
    form.setValue('isPublic', false);
  }, []);

  return (
    <Modal
      closeOnEsc={false}
      isOpen={isOpen}
      scrollBehavior="inside"
      onClose={handleClose}
      closeOnOverlayClick={false}
      // initialFocusRef={finalRef}
      isCentered={true}>
      <ModalOverlay backdropFilter="blur(4px)" bg="rgba(0, 0, 0, 0.6)" />

      <ModalContent
        boxShadow="0 0 0 transparent"
        h="full"
        maxW="full"
        overflow="hidden"
        width={{ base: 'calc(100% - 20px)', md: 'full' }}>
        <FormProvider {...form}>
          <HStack justifyContent="center" overflow="hidden">
            <Box
              background="#FDFEFE"
              borderRadius={6}
              boxShadow="0px 8px 44px rgba(66, 66, 66, 0.16) !important"
              h="full"
              maxW={{ base: '450px', lg: '520px', '2xl': '600px' }}
              position="relative"
              w="full">
              <IconButton
                aria-label="Close"
                position="absolute"
                right={4}
                size="sm"
                top={1}
                variant="icon"
                zIndex={5}
                onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Box
                h="full"
                mx={2}
                overflowY="auto"
                pb={4}
                pt={6}
                px={{ base: 1, '2xl': 6 }}>
                {!orderId && (
                  <Flex
                    flexDirection={'column'}
                    gap={0}
                    mb={{ base: 2, md: 4 }}
                    mx={{ base: 0, md: 4 }}>
                    <Text fontSize={18} fontWeight={600} mb={4}>
                      {t('CreateNewOffer')}
                    </Text>
                    <Box flex={5}>
                      <MainInformation isShowTitle={false} />
                    </Box>

                    <OfferDetails isHideMultiplePrices={!!requestId} />
                    <OfferIsPublic />
                    <OfferActionButtons
                      isEdit={!!offerId}
                      isLoading={isLoading || loading}
                      onSubmit={handleSubmit}
                    />
                  </Flex>
                )}
                {!!orderId && (
                  <VStack mb={{ base: 2, md: 4 }} mx={{ base: 0, md: 4 }}>
                    <Text fontSize={20} fontWeight={600} mb={2}>
                      {t('updateDetailsTitle')}
                    </Text>
                    <Text
                      color="grey.300"
                      fontSize={14}
                      mb={2}
                      textAlign="left"
                      w="full">
                      {t('updateDetailsDescription')}
                    </Text>
                    <OfferPriceSegment
                      isFirst
                      isHidden={false}
                      type={OfferType.TOUR}
                    />

                    <HStack justifyContent="flex-end" mt={4} spacing={4}>
                      <Button
                        fontSize={16}
                        isDisabled={
                          isLoading || loading || isLoadingUpdateOrder
                        }
                        isLoading={isLoading || loading || isLoadingUpdateOrder}
                        px={10}
                        variant="primary"
                        w="auto"
                        onClick={handleEditPrice}>
                        {t('sendUpdate')}
                      </Button>
                    </HStack>
                  </VStack>
                )}
              </Box>
            </Box>
            {!orderId && <OfferPreviewMobile />}
          </HStack>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
