import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { DropzoneOptions, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Dropzone } from '@app/components/CustomDropzone/Dropzone';
import { DropzoneContent } from '@app/components/CustomDropzone/DropzoneContent';
import { useErrorNotification } from '@app/hooks/useErrorNotification';
import { OfferFileFragment } from '@app/api/schemas/photo.mongo.generated';

import Popup from '../Popup';
import { SearchImagesModal } from '../SearchImages';

interface AddOfferDropZoneProps {
  setFiles: (f: File[]) => void;
  files?: File[] | OfferFileFragment[];
  allowedFileExtensionsText: string;
  subTitle?: string;
  title: string;
  showFileNames?: boolean;
  extraDropzoneOptions?: DropzoneOptions;
  rewriteFiles?: boolean;
  isSearchable?: boolean;
  errors?: string;
}

export const CustomDropZone: React.FC<AddOfferDropZoneProps> = ({
  setFiles,
  files,
  allowedFileExtensionsText,
  title,
  subTitle,
  showFileNames,
  isSearchable = false,
  extraDropzoneOptions,
  rewriteFiles = false,
  errors = null,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('validations');
  const errorNotification = useErrorNotification();

  const onDrop = (uploaded: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 0) {
      const rejectedFilesString = rejectedFiles
        ?.map((file) => file.file.name)
        ?.join();
      errorNotification({
        message: `${rejectedFilesString}. ${t('uploadFile')}`,
      });
    }
    if (rewriteFiles) {
      setFiles(uploaded);
      return;
    }
    const oldFiles = files?.length ? files : [];
    setFiles([...oldFiles, ...uploaded]);
  };
  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    ...extraDropzoneOptions,
  };

  const onAddFile = (photo) => {
    setFiles([...(files ?? []), ...(photo ?? [])]);
    onClose();
  };

  const onRemove = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  return (
    <>
      <Dropzone
        content={
          <DropzoneContent
            allowedFileExtensionsText={allowedFileExtensionsText}
            files={showFileNames ? files : []}
            isSearchable={isSearchable}
            subTitle={subTitle}
            title={title}
            onRemove={onRemove}
            onSearchOpen={onOpen}
            onSort={setFiles}
          />
        }
        dropzoneOptions={dropzoneOptions}
        formError={errors}
      />
      {isOpen && (
        <Popup
          hideCloseIcon
          closeOnEsc={true}
          isOpen={isOpen}
          maxW={{ '2xl': '780px', base: '500px' }}
          scrollBehavior="inside"
          onClose={onClose}>
          <SearchImagesModal onAddFile={onAddFile} onClose={onClose} />
        </Popup>
      )}
    </>
  );
};
