import { Box, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import React, { FC, useCallback, MouseEvent } from 'react';

import { ChatHeader } from '@app/views/Chat/components/chat/ChatHeader';
import { ReactComponent as ClearIcon } from '@app/icons/close-icon.svg';
import { ChatPreviewFragment } from '@app/api/schemas/chat.mongo.generated';

interface ChatContactBubbleProps {
  onOpen: (chat: ChatPreviewFragment) => void;
  onClose: (id: string) => void;
  chat: ChatPreviewFragment;
  isActive: boolean;
}

export const ChatContactBubble: FC<ChatContactBubbleProps> = ({
  onOpen,
  onClose,
  chat,
  isActive,
}) => {
  const { onOpen: onHover, onClose: onUnhover, isOpen } = useDisclosure();

  const handleRemove = useCallback(
    (e: MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onClose(chat?._id);
    },
    [chat?._id],
  );

  return (
    <Tooltip
      hasArrow
      isDisabled={isActive}
      label={chat?.lastMessage?.content}
      placement="auto"
      variant="ligth">
      <Box
        cursor="pointer"
        position="relative"
        px={1}
        userSelect="none"
        onClick={() => onOpen(chat)}
        onMouseEnter={onHover}
        onMouseLeave={onUnhover}>
        <ChatHeader isWidget isActive={isActive} item={chat} />
        {isOpen && (
          <IconButton
            _hover={{
              color: 'primary.green',
            }}
            aria-label="Close chat"
            background="white.0"
            borderRadius="50%"
            h={5}
            icon={<ClearIcon height="14px" width="14px" />}
            minW={5}
            position="absolute"
            right="1px"
            size="xs"
            top="1px"
            variant="icon"
            w={5}
            onClick={handleRemove}
          />
        )}
      </Box>
    </Tooltip>
  );
};
