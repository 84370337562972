import React, { FC } from 'react';
import InputMask from 'react-input-mask';
import { useController, useFormContext } from 'react-hook-form';
import { FormControl, Input, InputProps } from '@chakra-ui/react';

import Label from '@app/components/FormElements/Label';
import { ErrorMessage } from '@app/components/FormElements/ErrorMessage';

interface PhoneNumberInputProp extends InputProps {
  name: string;
  mask: string;
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
}

export const PhoneNumberInput: FC<PhoneNumberInputProp> = ({
  mask,
  isRequired = false,
  name,
  label,
  placeholder,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { ref, onBlur, value, onChange },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
    defaultValue: '',
  });
  return (
    <FormControl isInvalid={isSubmitted && !!errors[name]} position="relative">
      {label && (
        <Label color="grey.300" fontSize={14} isRequired={isRequired}>
          {label}
        </Label>
      )}
      <Input
        as={InputMask}
        inputRef={ref}
        isRequired={true}
        mask={mask}
        maskChar="_"
        maskPlaceholder=""
        ms={{ base: '0px', md: '0px' }}
        placeholder={placeholder}
        type={'text'}
        value={value}
        variant="auth"
        onBlur={onBlur}
        onChange={onChange}
        {...props}
      />
      <ErrorMessage
        bottom="6px"
        color="red.600"
        fontSize="12px"
        fontWeight={400}
        position="absolute">
        {errors[name]?.message as string}
      </ErrorMessage>
    </FormControl>
  );
};
