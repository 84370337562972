import { Box } from '@chakra-ui/react';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

const options: DropzoneOptions = {
  accept: {
    'image/jpeg': [],
    'image/jpg': [],
    'image/png': [],
  },
  maxSize: 1024 * 1024 * 5,
};

export const ProfileAvatarDropzone = (props: {
  content: JSX.Element | string;
  dropzoneOptions: DropzoneOptions;
}) => {
  const { content, dropzoneOptions, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    ...dropzoneOptions,
  });
  return (
    <Box
      {...getRootProps({ className: 'dropzone' })}
      {...rest}
      cursor="pointer">
      <input {...getInputProps()} />

      {content}
    </Box>
  );
};
