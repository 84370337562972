import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const variants = {
  addOffer: () => ({
    container: {
      boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
      borderRadius: '30px',
      p: '30px',
      w: '100%',
    },
  }),
  small: () => ({
    container: {
      boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
      borderRadius: '20px',
      p: '20px',
      w: '100%',
      h: '100%',
    },
  }),
};

const baseStyle = definePartsStyle({
  container: {
    p: '20px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    borderRadius: '20px',
    minWidth: '0px',
    wordWrap: 'break-word',
    bg: mode('#ffffff', 'navy.800'),
    backgroundClip: 'border-box',
  },
});
// export variants in the component theme
export const cardTheme = defineMultiStyleConfig({ variants, baseStyle });

export const CardComponent = {
  components: {
    Card: cardTheme,
  },
};
