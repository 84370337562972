import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

import { FormControl, FormControlProps } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Popper from '@popperjs/core';

import { CustomDatepickerHeader } from '@app/components/Datepicker/DatepickerHeader';
import { ErrorMessage } from '@app/components/FormElements/ErrorMessage';
import { formatDate } from '@app/utils/formatDate';
import { useLocale } from '@app/hooks/useLocale';

import Label from '../FormElements/Label';

import { DatepickerContainer } from './DatepickerContainer';
import { DatepickerRangeInput } from './DatepickerRangeInput';

interface DatePickerRangeProps<T> {
  name: string;
  placeholder?: string;
  formControlProps?: FormControlProps;
  label?: string;
  optionType?: T;
  placement?: Popper.Placement;
}

export const DatePickerRange = <T,>({
  name,
  placeholder = 'Select date range',
  formControlProps,
  label,
  placement = 'auto',
}: DatePickerRangeProps<T>) => {
  const { setValue, getValues } = useFormContext();
  const locale = useLocale();
  const {
    formState: { errors, isValid },
  } = useFormContext<T>();
  const { t } = useTranslation(['forms']);
  const value = getValues(name);

  const currentDate = useMemo(
    () =>
      value?.from || value?.to
        ? new Date(value?.from || value?.to)
        : new Date(),
    [value],
  );

  const isDateInvalid = useMemo(
    () => !!errors[name] && !isValid,
    [errors, isValid, name],
  );

  const [currentMonth, setCurrentMonth] = useState<Date>(currentDate);

  const dayClassName = (date: Date) => {
    if (date.getMonth() !== currentMonth.getMonth()) {
      return 'datepicker-day-wrapper datepicker-day-wrapper__outside-month';
    }
    return 'datepicker-day-wrapper';
  };

  const onChangeDate = (date: [Date, Date]) => {
    const [start, end] = date;
    setValue(`${name}.from`, formatDate(start, 'MM/dd/yyyy'));
    setValue(`${name}.to`, formatDate(end, 'MM/dd/yyyy'));
  };

  useEffect(() => {
    setCurrentMonth(currentDate);
  }, [currentDate]);

  return (
    <FormControl
      flex={2}
      isInvalid={isDateInvalid}
      w={['100%', 'auto']}
      {...formControlProps}>
      <Label color="grey.300" fontSize={14}>
        {label ? label : t('date')}
      </Label>
      <ReactDatePicker
        selectsRange
        calendarContainer={DatepickerContainer}
        calendarStartDay={1}
        customInput={<DatepickerRangeInput date={value} />}
        dayClassName={dayClassName}
        endDate={value?.to ? new Date(value?.to) : undefined}
        locale={locale}
        placeholderText={placeholder}
        popperPlacement={placement}
        renderCustomHeader={(props) => <CustomDatepickerHeader {...props} />}
        selected={
          value?.from || value?.to
            ? new Date(value?.from ?? value?.to)
            : undefined
        }
        shouldCloseOnSelect={false}
        startDate={value?.from ? new Date(value?.from) : undefined}
        onChange={onChangeDate}
        onMonthChange={(date) => setCurrentMonth(date)}
      />
      <ErrorMessage>{errors.startDate?.message}</ErrorMessage>
    </FormControl>
  );
};
