import { NetworkStatus } from '@apollo/client';

export const useApolloLoadingStatus = (networkStatus: NetworkStatus) => {
  return {
    isLoading: networkStatus === NetworkStatus.loading,
    isLoadingData: networkStatus === NetworkStatus.setVariables,
    isFetchingNextPage: networkStatus === NetworkStatus.fetchMore,
    isRefetching: networkStatus === NetworkStatus.refetch,
    isReady: networkStatus === NetworkStatus.ready,
  };
};
