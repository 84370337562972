import React, { forwardRef, useState } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';

import { ReactComponent as EyeOff } from '@app/icons/eye-off-icon.svg';
import { ReactComponent as EyeOn } from '@app/icons/eye-on-icon.svg';

export const InputPassword = forwardRef((props: InputProps, ref) => {
  const [show, setShow] = useState(false);
  return (
    <InputGroup size="md">
      <Input
        isRequired={true}
        placeholder="***********"
        size="lg"
        type={show ? 'text' : 'password'}
        variant="auth"
        {...props}
        ref={ref}
      />
      <InputRightElement alignItems="center" display="flex" mt="4px">
        <Box
          _hover={{ cursor: 'pointer' }}
          color="mainGray.100"
          onClick={() => setShow((val) => !val)}>
          {show ? <EyeOff /> : <EyeOn />}
        </Box>
      </InputRightElement>
    </InputGroup>
  );
});
InputPassword.displayName = 'InputPassword';
