import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { AuthUser, FetchUserAttributesOutput } from 'aws-amplify/auth';

import { Account, Business } from '@app/api/gql/generated-types-amazon';

interface AccountContextProps {
  account: Account;
  user: AuthUser;
  business: Business;
  userAttributes: FetchUserAttributesOutput;
}

interface CurrentAccountProviderProps extends PropsWithChildren {
  account: Account;
  user: AuthUser;
  userAttributes: FetchUserAttributesOutput;
}

export const CurrentAccountContext = createContext<AccountContextProps>(null);

export const useCurrentAccountContext = () => {
  const context = useContext(CurrentAccountContext);

  if (!context) {
    throw new Error('useCurrentAccountContext must be used within a CurrentAccountProvider');
  }

  return context;
};

const CurrentAccountProvider: FC<CurrentAccountProviderProps> = ({
  account,
  user,
  userAttributes,
  children,
}) => {
  return (
    <CurrentAccountContext.Provider
      value={{
        account,
        user,
        userAttributes,
        business: account?.business,
      }}>
      {children}
    </CurrentAccountContext.Provider>
  );
};

export default CurrentAccountProvider;
