import { HStack } from '@chakra-ui/react';
import React from 'react';

import { SidebarBrand } from '@app/components/Sidebar/components/Brand';

// import { Notifications } from './Notifications';

export const HeaderMenu = () => {
  return (
    <HStack alignItems="flex-start" justifyContent="space-between" w="full">
      <SidebarBrand />
      {/* <Notifications /> */}
    </HStack>
  );
};
