import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { unsplash } from '@app/api/unsplash';
import { ReactComponent as SearchIcon } from '@app/icons/search-icon.svg';
import { ReactComponent as CloseIcon } from 'icons/close-icon.svg';
import { ReactComponent as Selected } from 'icons/selected-image-icon.svg';
import { ReactComponent as NoImages } from 'icons/no-images-icon.svg';

interface SearchImagesModalProps {
  onAddFile: (photo: unknown[]) => void;
  onClose: () => void;
}

export const SearchImagesModal: FC<SearchImagesModalProps> = ({
  onAddFile,
  onClose,
}) => {
  const { t } = useTranslation('forms');
  const [term, setTerm] = useState<string>();
  const [photos, setPhotos] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const getPhotos = useCallback(async () => {
    try {
      const { response } = await unsplash.photos.getRandom({
        query: 'travel',
        count: 20,
      });
      if (response?.length) {
        setPhotos(response);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const querySearch = useCallback(
    async (nextPage = 1) => {
      const { response } = await unsplash.search.getPhotos({
        query: term,
        perPage: 30,
        page: nextPage,
      });
      return response;
    },
    [term],
  );

  const searchUnsplash = useCallback(async () => {
    if (!term) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await querySearch();
      setPhotos(response?.results ?? []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [term]);

  const handleAddPhoto = useCallback(
    (photo) => {
      const selectedArray = [...selected];
      const existsIndex = selectedArray?.findIndex(
        (item) => item.id === photo.id,
      );

      if (existsIndex >= 0) {
        selectedArray.splice(existsIndex, 1);
        setSelected(selectedArray);
        return;
      }

      setSelected((items) => [photo, ...items]);
    },
    [selected],
  );

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      void searchUnsplash();
    }
  };

  const handleLoadMore = useCallback(async () => {
    setPage((i) => i + 1);
    try {
      const response = await querySearch(page + 1);
      setPhotos((items) => [...items, ...(response?.results ?? [])]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [term, page]);

  const isSelected = (item) =>
    selected?.findIndex((i) => i.id === item.id) >= 0;

  useEffect(() => {
    void getPhotos();
  }, []);

  return (
    <>
      <VStack
        backgroundImage={photos?.[0]?.urls?.regular}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        borderTopLeftRadius={25}
        borderTopRightRadius={25}
        justifyContent="flex-start"
        position="sticky"
        top={0}>
        <VStack
          background="blackAlpha.600"
          borderTopLeftRadius={25}
          borderTopRightRadius={25}
          justifyContent="center"
          p={5}
          spacing="18px"
          w="full">
          <IconButton
            _focus={{
              bg: 'black.145',
            }}
            _hover={{
              bg: 'black.145',
            }}
            alignItems="center"
            aria-label="Close"
            color="white"
            cursor="pointer"
            position="absolute"
            right={4}
            size="xs"
            top={4}
            variant="icon"
            onClick={onClose}>
            <CloseIcon width="20px" />
          </IconButton>
          <InputGroup
            justifyContent="center"
            maxW="420px"
            mb={!!selected?.length ? 0 : '24px'}
            mt="24px"
            transition="all 0.25s">
            <Input
              borderRadius={10}
              placeholder={t('searchPhotoPlaceholder')}
              value={term}
              variant="main"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={onKeyDown}
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                size="sm"
                variant="icon"
                onClick={searchUnsplash}>
                <SearchIcon width="20px" />
              </IconButton>
            </InputRightElement>
          </InputGroup>
          {!!selected?.length && (
            <HStack justifyItems="center">
              <Button variant="primary" onClick={() => onAddFile(selected)}>
                {t('addPhotoButton', { count: selected?.length })}
              </Button>
            </HStack>
          )}
        </VStack>
      </VStack>
      {!!photos?.length && (
        <Box mx={4} overflowY="auto" py={4}>
          <Box my={3}>
            <Box
              mx="auto"
              px={3}
              sx={{ columnCount: [1, 2, 3], columnGap: '12px', rowGap: '12px' }}
              userSelect="none">
              {photos?.map((photo, index) => (
                <Box
                  key={`${photo.id}-${index}`}
                  display="inline-block"
                  mb={2}
                  position="relative"
                  role="group"
                  transition="all 0.15s"
                  onClick={() => handleAddPhoto(photo)}>
                  <Image
                    _groupHover={{
                      opacity: 0.9,
                    }}
                    fallback={
                      <Skeleton h="200px" maxW="320px" minW="230px" w="full" />
                    }
                    src={photo?.urls?.small}
                    w="full"
                  />
                  {isSelected(photo) && (
                    <>
                      <Box
                        backgroundColor="primary.green"
                        h="full"
                        opacity={0.75}
                        position="absolute"
                        top={0}
                        w="full"
                        zIndex={2}></Box>
                      <Box position="absolute" right={1} top={1} zIndex={3}>
                        <Selected height={20} width={20} />
                      </Box>
                    </>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
          {!!term && (
            <HStack justifyContent="center" my={2}>
              <Button
                isDisabled={isLoading}
                isLoading={isLoading}
                variant="link"
                onClick={handleLoadMore}>
                {t('loadMore', { ns: 'tables' })}
              </Button>
            </HStack>
          )}
        </Box>
      )}
      {!isLoading && !photos?.length && (
        <VStack justifyContent="center" minH="330px" spacing={4}>
          <NoImages />
          <Box>
            <Text fontSize={20} fontWeight={600} textAlign="center">
              {t('searchImages.noResults.title')}
            </Text>
            <Text color="grey.250" fontSize={16} textAlign="center">
              {t('searchImages.noResults.description')}
            </Text>
          </Box>
        </VStack>
      )}
    </>
  );
};
