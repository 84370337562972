window.Buffer = window.Buffer || require('buffer').Buffer;

import S3 from 'aws-s3';

import useCurrentAccount from '@app/hooks/useCurrentAccount';

const S3_BUCKET = process.env.REACT_APP_AMAZON_S3_BUCKET;
const REGION = process.env.REACT_APP_AMAZON_S3_REGION;
const KEY = process.env.REACT_APP_AMAZON_S3_KEY;
const ACCESS_KEY = process.env.REACT_APP_AMAZON_S3_ACCESS_KEY;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: KEY,
  secretAccessKey: ACCESS_KEY,
};

export const useS3 = () => {
  const { user } = useCurrentAccount();
  const offersClient = new S3({ ...config, dirName: `offers/${user?.userId}` });
  const accountClient = new S3({
    ...config,
    dirName: `account/${user?.userId}`,
  });
  const docsClient = new S3({
    ...config,
    dirName: `docs/${user?.userId}`,
  });
  return {
    offersClient,
    accountClient,
    docsClient,
  };
};
