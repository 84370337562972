import { mode } from '@chakra-ui/theme-tools';

export const TextStyles = {
  components: {
    Text: {
      baseStyle: {
        color: 'navy.700',
        fontSize: '14px',
        fontWeight: 500,
      },

      variants: {
        main: (props: any) => ({
          color: 'navy.700',
          fontSize: '14px',
          cursor: 'pointer',
          fontWeight: 500,
        }),
        smallButton: {
          fontSize: '12px',
          cursor: 'pointer',
          color: 'grey.300',
          transition: 'all 0.15s linear',
          whiteSpace: 'nowrap',
          _hover: {
            opacity: 0.8,
          },
        },
        mainWhiteLink: {
          color: 'white',
          fontSize: '14px',
          cursor: 'pointer',
          _hover: {
            opacity: 0.7,
          },
          fontWeight: 400,
        },
        newMain: () => ({
          color: 'grey.300',
          fontSize: '16px',
          cursor: 'pointer',
          fontWeight: 500,
        }),
        secondaryGray: () => ({
          color: 'secondaryGray.600',
          fontSize: '16px',
          cursor: 'pointer',
          fontWeight: 400,
        }),
        header: () => ({
          fontSize: '24px',
          fontWeight: 700,
          color: 'brand.300',
        }),
        secondaryDark: () => ({
          color: 'black.100',
          fontSize: '16px',
          cursor: 'pointer',
          fontWeight: 600,
        }),

        input: (props: any) => ({
          bg: mode('gray.100', 'navy.800')(props),
          borderRadius: 10,
          w: '100%',
          minH: '34px',
          color: mode('black.100', 'white')(props),

          fontSize: 'sm',
          fontWeight: 400,
          p: '10px',
          pl: '16px',
          _placeholder: { color: 'secondaryGray.500' },
          _active: {
            borderColor: 'primary.green',
          },
          _focus: {
            borderColor: 'primary.green',
          },
          _invalid: {
            borderColor: 'red.600',
          },
        }),
      },
    },
  },
};
