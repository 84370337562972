import {
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { ReactComponent as Icon } from '@app/icons/ic_right.svg';

const items = [
  {
    title: 'Широка аудиторія туристів в одному застосунку',
    description: 'Доступ до туристів із різних куточків світу',
  },
  {
    title: 'Зручна платформа для продажу турів',
    description: 'Публікуйте пропозиції та керуйте бронюваннями',
  },
  {
    title: 'Швидка обробка запитів',
    description: 'Миттєво отримуйте запити на підбір туру від усіх туристів',
  },
  {
    title: 'Надійні та зручні платежі',
    description: 'Співпраця з довіреними фінансовими партнерами',
  },
  {
    title: 'Низька комісія',
    description: 'Вигідні умови для роботи з клієнтами',
  },
  {
    title: 'Онлайн чат з туристами',
    description: 'Консультація на будь-якому етапі бронювання',
  },
];

export const Benefits = ({ onRegister, onMoreInfo }) => {
  return (
    <Box px={{ base: 4, lg: 10 }} py={10}>
      <Heading
        color="mainGray.500"
        fontSize={{ base: '28px', lg: '54px' }}
        fontWeight={600}
        lineHeight={{ base: '30px', lg: '58px' }}
        mb={10}
        textAlign="center">
        Наші переваги
      </Heading>

      <Grid
        gap={6}
        templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}>
        {items?.map((item, index) => (
          <VStack
            key={index}
            _hover={{
              opacity: 0.9,
            }}
            alignItems="flex-start"
            backgroundColor="rgba(36, 38, 43, 0.26)"
            cursor="pointer"
            flex={1}
            justifyContent="space-between"
            minW="300px"
            px={8}
            py={6}
            spacing={4}
            transition="all 0.2s"
            onClick={onMoreInfo}>
            <Box>
              <Text
                color="#23252C"
                fontSize={{ base: 20, lg: 28 }}
                fontWeight={600}
                lineHeight={{ base: '26px', lg: '36px' }}
                mb={4}>
                {item.title}
              </Text>
              <Text color="#23252C">{item.description}</Text>
            </Box>
            <Button variant="link" w="full" onClick={onMoreInfo}>
              <HStack justifyContent="space-between" w="full">
                <Text color="primary.green">Дізнатись більше</Text>
                <Icon />
              </HStack>
            </Button>
          </VStack>
        ))}
      </Grid>
      <HStack justifyContent="center" mt={14}>
        <Button maxW="300px" size="xl" variant="primary" onClick={onRegister}>
          Зареєструватись
        </Button>
      </HStack>
    </Box>
  );
};
