import { Text, TextProps } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

interface TextColumnTagType extends TextProps {
  value: string | number | ReactNode;
  isActive?: boolean;
}
export const TextColumnTag: FC<TextColumnTagType> = ({
  value,
  isActive = false,
  ...props
}) => {
  const color = isActive ? 'black' : 'grey.300';
  return (
    <Text
      color={color}
      fontSize={16}
      fontWeight={600}
      lineHeight="24px"
      noOfLines={1}
      {...props}>
      {value}
    </Text>
  );
};
