import { UseDisclosureProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal } from '@app/components/ConfirmationModal';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';

interface ActionModalProps extends UseDisclosureProps {
  offer: OfferFragment;
  type: 'Achive' | 'ShowHide';
  onSubmit: () => Promise<void>;
}

export const ActionModal: FC<ActionModalProps> = ({
  offer,
  type,
  onSubmit,
  ...confirm
}) => {
  const { t } = useTranslation(['tables']);

  const showHideDesc = [
    offer?.isPublic
      ? t('hideOffer', { ns: 'modalsAndMenus' })
      : t('showOffer', { ns: 'modalsAndMenus' }),
    offer?.isPublic
      ? t('confirmHideOfferDesc', { ns: 'modalsAndMenus' })
      : t('confirmShowOfferDesc', { ns: 'modalsAndMenus' }),
    offer?.isPublic
      ? t('confirmHideOfferSubDesc', { ns: 'modalsAndMenus' })
      : t('confirmShowOfferSubDesc', { ns: 'modalsAndMenus' }),
  ];

  const achiveDesc = [
    t('archiveOffer', { ns: 'modalsAndMenus' }),
    t('confirmArchiveDesc', { ns: 'modalsAndMenus' }),
    t('confirmArchiveSubDesc', { ns: 'modalsAndMenus' }),
  ];
  return (
    <ConfirmationModal
      {...confirm}
      confirmBtnText={t('confirmBtnText', { ns: 'modalsAndMenus' })}
      description={type === 'ShowHide' ? showHideDesc : achiveDesc}
      photo={offer?.photo?.[0]}
      status={offer?.status}
      title={offer?.title}
      width="505px"
      onSubmit={onSubmit}
    />
  );
};
