import { Box, HStack, Text, useToast } from '@chakra-ui/react';
import React from 'react';

import { ReactComponent as Icon } from '@app/icons/error-outline.svg';

export const useSuccessNotification = () => {
  const toast = useToast();
  return (message: string) =>
    toast({
      title: message,
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      render: ({ title, description, onClose }) => (
        <HStack
          backgroundColor="#dcebeb"
          borderRadius={10}
          m={1}
          maxW="300px"
          p="15px 25px"
          spacing={3}
          onClick={onClose}>
          <Box color="primary.green">
            <Icon />
          </Box>
          <Box>
            <Text color="primary.green" fontSize={12}>
              {title}
            </Text>
            <Text color="primary.green" fontSize={12}>
              {description}
            </Text>
          </Box>
        </HStack>
      ),
    });
};
