import { Text, TextProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';

interface MarkDownViewProps extends TextProps {
  text: string;
}

const View = styled(Text)`
  ul,
  ol {
    margin-left: 15px;
    marign-top: 10px;
    li {
    }
  }
`;

export const MarkDownView: FC<MarkDownViewProps> = ({ text, ...props }) => {
  return (
    <View {...props}>
      <div dangerouslySetInnerHTML={{ __html: `${text}` }} />
    </View>
  );
};
