import { Box, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

interface ResetFilterButtonProps {
  onClick: () => void;
}

export const ResetFilterButton: FC<ResetFilterButtonProps> = ({ onClick }) => {
  const { t } = useTranslation('tables');
  return (
    <Box alignSelf="flex-end" mb="13px">
      <Text _hover={{ opacity: 0.8 }} cursor="pointer" onClick={onClick}>
        <CloseIcon
          color={'grey.300'}
          h={{ base: '10px', xl: '7px' }}
          marginRight={'8px'}
          w={{ base: '10px', xl: '7px' }}
        />
        <Text
          as="span"
          display={{ base: 'none', xl: 'inline' }}
          variant="smallButton">
          {t('resetAll')}
        </Text>
      </Text>
    </Box>
  );
};
