import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

import {
  hasDigitChar,
  hasLowercaseChar,
  hasSpecialChar,
  hasUppercaseChar,
} from '@app/utils/validation';

export const useValidationSchema = () => {
  const { t } = useTranslation('validations');
  const validationSchema = object().shape({
    email: string()
      .label('Email')
      .email(t('emailValid'))
      .required(t('emailRequired'))
      .test('email', t('emailValid'), (value) => validator.isEmail(value)),

    password: string()
      .label('Password')
      .test(
        'is-length-enough',
        'Must be at least 8 characters long',
        (value) => value?.length >= 8,
      )
      .test(
        'is-contains-uppercase',
        'Must contain at least one uppercase letter',
        (value) => hasUppercaseChar(value),
      )
      .test(
        'is-contains-lowercase',
        'Must contain at least one lowercase letter',
        (value) => hasLowercaseChar(value),
      )
      .test('is-contains-digits', 'Must contain at least one digit', (value) =>
        hasDigitChar(value),
      )
      .test(
        'is-contains-special-char',
        'Must contain at least one special character',
        (value) => hasSpecialChar(value),
      )
      .required(t('passwordRequired')),
  });

  return yupResolver(validationSchema);
};
