import { Location } from 'react-router';

import { ActivityFragment } from './schemas/activity.mongo.generated';
import { PaymentProcessorName } from './gql/generated-types-amazon';

export enum Role {
  CLIENT = 'client',
  MANAGER = 'manager',
  ADMIN = 'admin',
}

export enum NotificationType {
  REQUEST = 'REQUEST',
  ORDER = 'ORDER',
}

export interface NotificationItem {
  description: string;
  createdAt: Date;
  id: string;
  title: string;
  type: NotificationType;
}

export enum OfferTabs {
  OfferPreview = 'offer',
  RequestDetails = 'request',
  OrderPreview = 'order',
}
export enum RequestTabs {
  RequestPreview = 'request',
  MyOffers = 'myOffers',
}

export interface ChatLocation<State> extends Omit<Location, 'state'> {
  state: State;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly ActivityFragment[];
}

export enum OnboradingType {
  FOP = 'itn',
  LLC = 'edpnou',
}

export interface OnboardingFormValues {
  companyType: OnboradingType;
  paymentProcessorName: PaymentProcessorName;
  partner_data: {
    company_id: string;
    result_url: string;
    email: string;
    identifier_value: string;
  };
  redirect_data: {
    url: string;
    delay?: number;
    button_text?: string;
  };
}

export enum OnboradingStatus {
  NO_CONNECT = 'no_connect',
  IN_PROGRESS = 'in_progress',
  CONNECTED = 'connected',
}
