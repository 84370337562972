import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomDropZone } from '@app/components/CustomDropzone/CustomDropzone';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';

export const Images = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<OfferFragment>();

  const { t } = useTranslation(['addOffer']);

  const photoError = errors?.photo?.message;

  return (
    <Controller
      control={control}
      name="photo"
      render={({ field: { value, onChange } }) => (
        <CustomDropZone
          isSearchable
          allowedFileExtensionsText={t('dropSubTitle')}
          errors={photoError}
          files={value}
          setFiles={onChange}
          showFileNames={true}
          title={t('dropTitle')}
        />
      )}
    />
  );
};
