import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

import { ChatPreviewFragment } from '@app/api/schemas/chat.mongo.generated';

interface ChatWidgetProps {
  activeChat: ChatPreviewFragment;
  setActiveChat: (chat: ChatPreviewFragment) => void;
}

export const ChatWidgetContext = createContext<ChatWidgetProps>(null);

export const useChatWidgetContext = () => {
  const context = useContext(ChatWidgetContext);

  return context;
};

const ChatWidgetProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeChat, setActiveChat] = useState<ChatPreviewFragment>();

  return (
    <ChatWidgetContext.Provider
      value={{
        activeChat,
        setActiveChat,
      }}>
      {children}
    </ChatWidgetContext.Provider>
  );
};

export default ChatWidgetProvider;
