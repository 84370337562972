import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

export const useLoginValidationSchema = () => {
  const { t } = useTranslation(['validations']);
  return object().shape({
    username: string()
      .email(t('emailValid'))
      .required(t('emailRequired'))
      .test('email', t('emailValid'), (value) => validator.isEmail(value)),
    password: string().required(t('passwordRequired')),
  });
};
