import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivityFragment = { __typename?: 'Activity', _id: any | null, color: string | null, parent: { __typename?: 'Activity', _id: any | null } | null, details: Array<{ __typename?: 'ActivitiesDetail', lang: string | null, title: string | null } | null> | null };

export type FindActivitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindActivitiesQuery = { __typename?: 'Query', findActivities: { __typename?: 'FindActivitiesPayload', total: number | null, items: Array<{ __typename?: 'Activity', _id: any | null, color: string | null, parent: { __typename?: 'Activity', _id: any | null } | null, details: Array<{ __typename?: 'ActivitiesDetail', lang: string | null, title: string | null } | null> | null } | null> | null } | null };

export const ActivityFragmentDoc = gql`
    fragment Activity on Activity {
  _id
  color
  parent {
    _id
  }
  details {
    lang
    title
  }
}
    `;
export const FindActivitiesDocument = gql`
    query FindActivities {
  findActivities {
    items {
      ...Activity
    }
    total
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useFindActivitiesQuery__
 *
 * To run a query within a React component, call `useFindActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<FindActivitiesQuery, FindActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindActivitiesQuery, FindActivitiesQueryVariables>(FindActivitiesDocument, options);
      }
export function useFindActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindActivitiesQuery, FindActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindActivitiesQuery, FindActivitiesQueryVariables>(FindActivitiesDocument, options);
        }
export function useFindActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindActivitiesQuery, FindActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindActivitiesQuery, FindActivitiesQueryVariables>(FindActivitiesDocument, options);
        }
export type FindActivitiesQueryHookResult = ReturnType<typeof useFindActivitiesQuery>;
export type FindActivitiesLazyQueryHookResult = ReturnType<typeof useFindActivitiesLazyQuery>;
export type FindActivitiesSuspenseQueryHookResult = ReturnType<typeof useFindActivitiesSuspenseQuery>;
export type FindActivitiesQueryResult = Apollo.QueryResult<FindActivitiesQuery, FindActivitiesQueryVariables>;