import React, { ReactNode, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalProps,
  ModalOverlayProps,
  ModalCloseButton,
} from '@chakra-ui/react';

interface UploadPreviewModalProps extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: ReactNode;
  width?: string;
  height?: string;
  size?: string;
  closeOnOverlayClick?: boolean;
  maxW?: string;
  scrollBehavior?: 'inside' | 'outside';
  modalOverplayProps?: ModalOverlayProps;
  blockScrollOnMount?: boolean;
  hideCloseIcon?: boolean;
}

export const UploadPreviewModal: React.FC<UploadPreviewModalProps> = ({
  children,
  title,
  isOpen,
  onClose,
  closeOnOverlayClick,
  maxW,
  width,
  height,
  scrollBehavior = 'outside',
  modalOverplayProps,
  blockScrollOnMount = false,
  isCentered = true,
  hideCloseIcon = false,
}) => {
  const finalRef = useRef();

  return (
    <Modal
      blockScrollOnMount={blockScrollOnMount}
      closeOnEsc={closeOnOverlayClick}
      closeOnOverlayClick={closeOnOverlayClick}
      initialFocusRef={finalRef}
      isCentered={isCentered}
      isOpen={isOpen}
      scrollBehavior={scrollBehavior}
      onClose={onClose}>
      <ModalOverlay
        backdropFilter="blur(2px)"
        bg="rgba(255, 255, 255, 0.8)"
        {...modalOverplayProps}
      />
      <ModalContent
        background="#FDFEFE"
        borderRadius="30px"
        boxShadow="0px 8px 44px rgba(66, 66, 66, 0.16) !important"
        height={height}
        maxW={maxW}
        width={{ base: 'calc(100% - 20px)', md: width }}>
        {hideCloseIcon ? null : (
          <ModalCloseButton
            _focus={{ outline: 'none' }}
            borderRadius="full"
            color="osloGray"
            data-cy="close-btn"
            right="19px"
            size="md"
            top="19px"
            zIndex="3"
          />
        )}
        <ModalHeader
          color="black.100"
          fontSize="24px"
          fontWeight="normal"
          lineHeight="20px"
          px="48px"
          py="28px"
          textAlign="left"
          width="100%">
          {title}
        </ModalHeader>
        {children}
      </ModalContent>
    </Modal>
  );
};
