import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import capitalize from 'lodash/capitalize';

import { StatusCount } from '@app/api/gql/generated-types';
import { FieldWrapper } from '@app/components/fields/FieldWrapper';
import { MultiSelectField } from '@app/components/MultiSelectField';
import { statusOrderColor } from '@app/hooks/useStatusColor';

import { orderStatusList } from '../../utils/orderStatusList';

interface SelectStatusProps {
  label?: string;
  statusCounts: StatusCount[];
}

export const SelectStatus: FC<SelectStatusProps> = ({
  label = 'status',
  statusCounts = [],
}) => {
  const count = (item) =>
    statusCounts?.find((status) => status._id === item)?.count ?? 0;

  const options = orderStatusList.map((item) => ({
    label: item,
    value: item,
    statusColor: statusOrderColor(item),
    count: count(item),
  }));

  const { t } = useTranslation(['forms']);
  const { control } = useFormContext();

  return (
    <FieldWrapper label={t(`${label}`)} mb="0">
      <Controller
        control={control}
        name="status"
        render={({ field }) => {
          return (
            <MultiSelectField
              isMulti
              closeMenuOnSelect={false}
              getOptionLabel={(item) =>
                capitalize(
                  t(`orderStatus.${item.label}`, {
                    ns: 'tables',
                  }),
                )
              }
              hideSelectedOptions={false}
              isClearable={false}
              isSearchable={false}
              itemsLength={1}
              options={options}
              placeholder={t('select')}
              {...field}
            />
          );
        }}
      />
    </FieldWrapper>
  );
};
