import React from 'react';
import { Flex } from '@chakra-ui/react';

const HSeparator = (props) => {
  return (
    <Flex bg="rgba(135, 140, 189, 0.3)" h="1px" w="100%" {...props}></Flex>
  );
};

const VSeparator = (props) => {
  return <Flex bg="rgba(135, 140, 189, 0.3)" w="1px" {...props}></Flex>;
};

export { HSeparator, VSeparator };
