export interface SignUpFormProps {
  email: string;
  password: string;
  agreeWithPrivacyPolicy: boolean;
}

export const initialValues: SignUpFormProps = {
  email: '',
  password: '',
  agreeWithPrivacyPolicy: false,
};
