import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { getLanguageLocale } from '@app/utils/languagesMap';

export const useLocale = () => {
  const { i18n } = useTranslation();
  const locale = useMemo(
    () => getLanguageLocale(i18n.resolvedLanguage),
    [i18n.resolvedLanguage],
  );
  return locale;
};
