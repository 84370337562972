import {
  Box,
  CloseButton,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React, { useMemo } from 'react';
import { DropzoneOptions, FileRejection } from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fetchAuthSession, updateUserAttributes } from 'aws-amplify/auth';

import { CreateManagerAccountInput } from '@app/api/gql/generated-types';
import { ProfileAvatarDropzone } from '@app/components/CustomDropzone/ProfileAvatarDropzone';
import { PhoneNumberInput } from '@app/components/PhoneNumberInput';
import { FormField } from '@app/components/formInputs/FormField';
import useCurrentAccount from '@app/hooks/useCurrentAccount';
import { useErrorNotification } from '@app/hooks/useErrorNotification';
import { useUploadFiles } from '@app/hooks/useUploadFiles';
import { ReactComponent as UploadIcon } from '@app/icons/upload-icon.svg';
import { preparingForSendingPhoneNumber } from '@app/utils/formatPhone';
import { Submit } from '@app/views/Login/components/LoginForm/Submit';
import {
  ProfileInformation,
  initialValues,
} from '@app/views/ProfileInformationPage/initialValues';
import { useValidationSchema } from '@app/views/ProfileInformationPage/useValidationSchema';
import { ProfileSteps } from '@app/components/ProfileSteps';
import {
  WhoAmIDocument,
  useUpdateAccountMutation,
} from '@app/api/schemas/account.amazon.generated';
import { UserRoles } from '@app/api/gql/generated-types-amazon';
import { amplitude } from '@app/amplitude';

export const ProfileInformationPage = () => {
  const { userAttributes } = useCurrentAccount();
  const resolver = useValidationSchema();
  const { uploadToAccount, isLoading: isLoadingFile } = useUploadFiles();
  const [update, { loading: isLoadingUpdate }] = useUpdateAccountMutation({
    context: {
      clientName: 'amazon',
    },
    refetchQueries: [WhoAmIDocument],
  });
  const loading = isLoadingUpdate || isLoadingFile;

  const errorNotification = useErrorNotification();
  const { t } = useTranslation('forms');

  const defaultValues = useMemo(() => {
    return {
      ...initialValues,
      firstName: userAttributes?.name,
      lastName: userAttributes?.family_name,
    };
  }, [userAttributes?.family_name, userAttributes?.name]);

  const onSubmit = async (values: CreateManagerAccountInput) => {
    try {
      let avatar = null;
      if (!isEmpty(values?.avatar)) {
        const file = values?.avatar[0];
        avatar = await uploadToAccount(file);
      }
      await updateUserAttributes({
        userAttributes: {
          'custom:role': 'manager',
          'custom:roles': `[${UserRoles.CLIENT}, ${UserRoles.MANAGER}]`,
          phone_number: preparingForSendingPhoneNumber(values.phone),
        },
      });
      await update({
        variables: {
          input: {
            phone: preparingForSendingPhoneNumber(values.phone),
            firstName: values.firstName,
            lastName: values.lastName,
            ...(avatar && { avatar: omit(avatar, ['status']) }),
            roles: [UserRoles.CLIENT, UserRoles.MANAGER],
            role: 'manager',
          },
        },
      });
      amplitude.logEvent('Onboarding Step 1', { platform: 'web' });
      await fetchAuthSession({ forceRefresh: true });
    } catch (error) {
      console.error(error);
      errorNotification(error);
    }
  };
  const form = useForm<ProfileInformation>({
    defaultValues,
    resolver,
    mode: 'onBlur',
  });

  const onDrop = (uploadedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 0) {
      const rejectedFilesString = rejectedFiles
        ?.map((file) => file.file.name)
        ?.join();
      errorNotification({
        message: `${rejectedFilesString}. ${t('uploadFile')}`,
      });
    }
    form.setValue('avatar', uploadedFiles);
  };

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    maxFiles: 1,
    maxSize: 1024 * 1024 * 5,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
  };

  const [avatar] = form.watch(['avatar']);

  return (
    <>
      <Box marginBottom={20} minW="full">
        <ProfileSteps index={0} />
      </Box>
      <FormProvider {...form}>
        <Box w="full">
          <VStack gap="25px">
            <Box w="full">
              <FormField
                isRequired={true}
                mb="25px"
                name="firstName"
                placeholder={t('firstName')}
                size="lg"
                variant="auth"
              />
              <FormField
                isRequired={true}
                mb="25px"
                name="lastName"
                placeholder={t('lastName')}
                size="lg"
                variant="auth"
              />
              <PhoneNumberInput
                height="45px"
                mask="+99 (999) 999-99-99"
                mb="25px"
                name="phone"
                placeholder={t('phoneNumber')}
                size="lg"
                variant="auth"
              />
              <VStack>
                <Box
                  backgroundColor="secondaryGray.300"
                  borderRadius={15}
                  height={20}
                  mb="40px"
                  mt="15px"
                  position="relative"
                  w={20}>
                  <ProfileAvatarDropzone
                    content={
                      !!avatar?.length ? (
                        <Image
                          borderRadius={15}
                          height="80px"
                          objectFit="cover"
                          src={
                            Array.isArray(avatar) && avatar?.length
                              ? URL.createObjectURL(avatar?.[0])
                              : avatar
                          }
                          w="80px"
                          zIndex={2}
                        />
                      ) : (
                        <HStack
                          alignItems="center"
                          h="80px"
                          justifyContent="center"
                          w="80px">
                          <UploadIcon />
                        </HStack>
                      )
                    }
                    dropzoneOptions={dropzoneOptions}
                  />
                  {!!avatar?.length && (
                    <CloseButton
                      borderRadius="50px"
                      position="absolute"
                      right="-25px"
                      top="-10px"
                      onClick={() => form.setValue('avatar', [])}
                    />
                  )}
                </Box>
                <Text color="black" fontSize="14px" fontWeight={600}>
                  {t('profileLogoTitle')}
                </Text>
                <Text color="grey.200" fontSize={12} textAlign="center">
                  {t('logoAllowedExtensions')}
                </Text>
              </VStack>
              <Box mb={6} mt="15px">
                <Submit
                  loading={loading}
                  title={t('nextStep')}
                  onSubmit={onSubmit}
                />
              </Box>
            </Box>
          </VStack>
        </Box>
      </FormProvider>
    </>
  );
};
