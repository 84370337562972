import { Table } from 'dexie';

import { ChatPreviewFragment } from '@app/api/schemas/chat.mongo.generated';

export type ChatsTable = {
  chats: Table<ChatPreviewFragment>;
};

export const messengerSchema = {
  chats:
    '_id, lastMessage, participantsExcludingSelf, title, unreadCount, updatedAt',
};
