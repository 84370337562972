import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import { WysiwygEditor } from '@app/components/formInputs/WysiwygEditor';

export const Description = () => {
  const { t } = useTranslation(['forms', 'addOffer']);

  return (
    <Box mb="20px">
      <WysiwygEditor
        label={t('description')}
        name={`description`}
        placeholder={t('descPlaceholder')}
      />
    </Box>
  );
};
