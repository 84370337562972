import { Flex, Box, Text } from '@chakra-ui/react';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const options: DropzoneOptions = {
  accept: {
    'image/jpeg': [],
    'image/jpg': [],
    'image/png': [],
  },
  maxSize: 1024 * 1024 * 5,
};

export const Dropzone = (props: {
  content: JSX.Element | string;
  dropzoneOptions: DropzoneOptions;
  formError?: string | null;
}) => {
  const { content, dropzoneOptions, formError, ...rest } = props;
  const { t } = useTranslation('validations');
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    ...options,
    ...dropzoneOptions,
  });
  return (
    <Flex
      align="center"
      bg="gray.100"
      border={formError ? '1px solid' : 'none'}
      borderColor={formError ? 'red.600' : 'none'}
      borderRadius="16px"
      cursor="pointer"
      h="100%"
      justify="center"
      maxW="100%"
      position="relative"
      w="100%"
      {...getRootProps({ className: 'dropzone' })}
      {...rest}>
      <input {...getInputProps()} />
      {content}
      <Flex
        alignItems="center"
        bottom="-22px"
        display="flex"
        gap="10px"
        justifyContent="flex-start"
        position="absolute"
        w="100%">
        <>
          {formError && (
            <Box color="red.600" fontSize="12px" fontWeight={400} w="auto">
              {t('dropZoneInvalid')}
            </Box>
          )}
          {fileRejections?.map(({ file, errors }) => (
            <Box key={file?.size} w="auto">
              {errors.map((e) => (
                <Text
                  key={e.code}
                  color="red.600"
                  fontSize="12px"
                  fontWeight={400}>
                  {e.message}
                </Text>
              ))}
            </Box>
          ))}
        </>
      </Flex>
    </Flex>
  );
};
