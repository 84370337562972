import {
  Box,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';

import { ReactComponent as Icon } from '@app/icons/notification-bell.svg';

import { BadgeCount } from './BadgeCount';
import { NotificationsContent } from './NotificationsContent';

export const NotificationWidget = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const updateCounters = useCallback(() => {
    // client.cache.updateQuery<NotificationsQuery>(
    //   {
    //     query: NotificationsDocument,
    //   },
    //   (data) => {
    //     return {
    //       ...data,
    //       notifications: {
    //         ...(data?.notifications ?? {}),
    //         unreadCount: (data?.notifications?.unreadCount || 1) - 1,
    //       },
    //     };
    //   },
    // );
  }, []);

  return (
    <Box>
      <Popover
        isLazy
        isOpen={isOpen}
        placement="bottom-end"
        onClose={onClose}
        onOpen={onOpen}>
        <PopoverTrigger>
          <Box position="relative">
            <IconButton
              _hover={{
                backgroundColor: 'transparent',
              }}
              aria-label="Notifications"
              backgroundColor="gray.100"
              icon={<Icon height={30} width={30} />}
              variant="icon"
            />
            <BadgeCount />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          backgroundColor="white"
          border="0 none"
          boxShadow="0px 8px 24px rgba(66, 66, 66, 0.16)"
          maxH="80dvh"
          overflowY="auto"
          px={2}
          py={2}>
          {isOpen && <NotificationsContent />}
        </PopoverContent>
      </Popover>
    </Box>
  );
};
