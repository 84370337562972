import { StylesConfig } from 'react-select';

export const customStyles = (
  error?: string,
  variant?: string,
): StylesConfig<{ value: string; label: string }, false> => {
  const isAuthVariant = variant === 'auth';
  return {
    control: (base, state) => {
      let border = 'none';
      // = state.isFocused
      //   ? '1px solid var(--chakra-colors-brand-100)  !important'
      //   : '1px solid var(--chakra-colors-secondaryGray-100) !important';

      if (error) {
        border = '1px solid var(--chakra-colors-red-600) !important';
      }
      const backgroundColor = (() => {
        if (isAuthVariant) {
          return 'var(--chakra-colors-mainGray-200)';
        }
        if (state.isDisabled) {
          return 'var(--chakra-colors-gray-100)';
        }
        return 'var(--chakra-colors-gray-100)';
      })();
      return {
        ...base,
        opacity: state.isDisabled ? 0.5 : 1,
        cursor: 'pointer',
        backgroundColor,
        borderRadius: '10px',
        boxShadow: '0px 0px 0px transparent',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1px 10px 1px 12px',
        fontSize: '14px',
        color: state.isDisabled
          ? 'var(--chakra-colors-secondaryGray-500)'
          : 'var(--chakra-colors-brand-900)',
        minHeight: isAuthVariant ? '45px' : '40px',
        border,

        // '&:hover': {
        //   border: '1px solid var(--chakra-colors-brand-100) !important',
        // },
      };
    },
    option: (provided, state) => {
      let color = 'var(--chakra-colors-blue-800)';

      if (state.isSelected || (state.isFocused && !state.isDisabled)) {
        color = 'var(--chakra-colors-primary-green)';
      } else if (state.isDisabled || (state.isSelected && state.isDisabled)) {
        color = 'var(--chakra-colors-secondaryGray-500)';
      }

      return {
        ...provided,
        fontSize: '14px',
        cursor: 'pointer',
        background: 'transparent',
        color,

        padding: '8px 10px',
        borderRadius: '20px',
        '&:hover': {
          cursor: state.isDisabled ? 'not-allowed' : 'pointer',

          backgroundColor: 'transparent',
          color: !state.isDisabled
            ? 'var(--chakra-colors-primary-green)'
            : null,
        },
      };
    },
    placeholder: (base) => ({
      ...base,
      whiteSpace: 'nowrap',
      fontWeight: isAuthVariant ? 600 : 400,
      color: isAuthVariant
        ? 'var(--chakra-colors-mainGray-100)'
        : 'var(--chakra-colors-secondaryGray-500)',
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '16px',
      marginTop: '5px',
      overflow: 'hidden',
      padding: '10px 10px',
    }),
    menuList: (base) => ({
      ...base,
      padding: '0',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base, state) => {
      const readOnly = !!state.selectProps?.readOnly || state.isDisabled;

      return {
        ...base,
        color: readOnly
          ? 'var(--chakra-colors-grey-200)'
          : 'var(--chakra-colors-mainGray-300)',
        transition: 'all .3s ease',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        padding: '8px 5px',
        svg: {
          width: '20px',
          height: '20px',
          color: readOnly
            ? 'var(--chakra-colors-grey-200)'
            : 'var(--chakra-colors-mainGray-300)',
        },
      };
    },
    noOptionsMessage: (base) => ({
      ...base,
      padding: '0',
      fontSize: '14px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0px !important',
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: 'var(--chakra-colors-brand-900)',
      borderRadius: '16px',
      marginRight: '6px',
      padding: '0px 4px',
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: 'white',
      fontSize: '14px',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: 'white',
      background: 'transparent',
      ':hover': {
        background: 'transparent',
        color: 'white',
      },
    }),
    singleValue: (base, state) => {
      return {
        ...base,
        fontWeight: isAuthVariant ? 500 : 400,
        color: state.isDisabled
          ? 'var(--chakra-colors-secondaryGray-500)'
          : 'var(--chakra-colors-brand-900)',
      };
    },
  };
};

export const rightAddonStyles = (): StylesConfig<
  { value: string; label: string },
  false
> => ({
  control: (base, state) => {
    // = state.isFocused
    //   ? '1px solid var(--chakra-colors-brand-100)  !important'
    //   : '1px solid var(--chakra-colors-secondaryGray-100) !important';

    return {
      ...base,
      cursor: 'pointer',
      backgroundColor: state.isDisabled
        ? '#F3F6F9'
        : 'var(--chakra-colors-gray-100)',
      borderRadius: '0 10px 10px 0',
      boxShadow: '0px 0px 0px transparent',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1px 2px 1px 8px',
      border: '0 none',
      borderLeft: '1px solid var(--chakra-colors-secondaryGray-100)',
      fontSize: '14px',
      color: state.isDisabled
        ? 'var(--chakra-colors-secondaryGray-500)'
        : 'var(--chakra-colors-brand-900)',
      minHeight: '38px',

      '&:hover': {
        borderColor: 'var(--chakra-colors-secondaryGray-100)',
      },
    };
  },
  option: (provided, state) => {
    let color = 'var(--chakra-colors-blue-800)';

    if (state.isSelected || (state.isFocused && !state.isDisabled)) {
      color = 'var(--chakra-colors-primary-green)';
    } else if (state.isDisabled || (state.isSelected && state.isDisabled)) {
      color = 'var(--chakra-colors-secondaryGray-500)';
    }

    return {
      ...provided,
      fontSize: '14px',
      cursor: 'pointer',
      background: 'transparent',
      color,
      padding: '8px 10px',
      borderRadius: '20px',
      '&:hover': {
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',

        backgroundColor: 'transparent',
        color: !state.isDisabled ? 'var(--chakra-colors-primary-green)' : null,
      },
    };
  },
  placeholder: (base) => ({
    ...base,
    whiteSpace: 'nowrap',
    color: 'var(--chakra-colors-secondaryGray-500)',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '16px',
    marginTop: '5px',
    overflow: 'hidden',
    padding: '10px 10px',
  }),
  menuList: (base) => ({
    ...base,
    padding: '0',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base, state) => {
    const readOnly = !!state.selectProps?.readOnly || state.isDisabled;

    return {
      ...base,
      color: readOnly
        ? 'var(--chakra-colors-grey-200)'
        : 'var(--chakra-colors-mainGray-300)',
      transition: 'all .3s ease',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      padding: '8px 5px',
      svg: {
        width: '20px',
        height: '20px',
        color: readOnly
          ? 'var(--chakra-colors-grey-200)'
          : 'var(--chakra-colors-mainGray-300)',
      },
    };
  },
  noOptionsMessage: (base) => ({
    ...base,
    padding: '0',
    fontSize: '14px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0px !important',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: 'var(--chakra-colors-brand-900)',
    borderRadius: '16px',
    marginRight: '6px',
    padding: '0px 4px',
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'white',
    fontSize: '14px',
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: 'white',
    background: 'transparent',
    ':hover': {
      background: 'transparent',
      color: 'white',
    },
  }),
  singleValue: (base, state) => {
    return {
      ...base,
      color: state.isDisabled
        ? 'var(--chakra-colors-secondaryGray-500)'
        : 'var(--chakra-colors-brand-900)',
    };
  },
});
