import { OfferFileFragment } from '@app/api/schemas/photo.mongo.generated';
import PdfIcon from '@app/icons/pdf-file-svgrepo-com.svg';

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const getFile = (file: File | OfferFileFragment | string) => {
  if (file?.type === 'application/pdf') {
    return PdfIcon;
  }
  if (!!file?.key) {
    return `${imageServer}/${file.key}?width=800&format=webp`;
  }
  if (!!file?.urls) {
    return file?.urls?.regular;
  }
  if (file) {
    return URL.createObjectURL(file);
  }
  return null;
};
