import React, { forwardRef } from 'react';
import { InputGroup, InputRightElement, Text } from '@chakra-ui/react';

import './datepicker.css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from '@app/icons/calendar-icon.svg';
import { formatDate } from '@app/utils/formatDate';
import { useLocale } from '@app/hooks/useLocale';

export const DatepickerRangeInput = forwardRef<
  HTMLDivElement,
  {
    onClick?: () => void;
    date: { from: Date; to: Date };
  }
>(({ onClick, date }, ref) => {
  const { t } = useTranslation('addOffer');
  const locale = useLocale();
  const valueStart =
    !!date?.from && formatDate(new Date(date?.from), 'P', { locale });
  const valueEnd = date?.to && formatDate(new Date(date?.to), 'P', { locale });

  return (
    <InputGroup
      ref={ref}
      cursor="pointer"
      minW="150px"
      userSelect="none"
      variant="unstyled"
      w={{ base: '100%' }}
      onClick={() => onClick?.()}>
      <Text
        color={valueStart || valueEnd ? 'black.100' : 'secondaryGray.500'}
        height="auto"
        variant="input">
        {valueStart ? valueStart : t('from')} &#8594;{' '}
        {valueEnd ? valueEnd : t('to')}
      </Text>
      <InputRightElement
        alignItems="center"
        display="flex"
        height="100%"
        mr="5px">
        <CalendarIcon />
      </InputRightElement>
    </InputGroup>
  );
});

DatepickerRangeInput.displayName = 'DatepickerRangeInput';
