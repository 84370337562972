import { HStack } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';

export const EmojiIcon: FC<PropsWithChildren> = ({ children }) => {
  return (
    <HStack alignItems="center" h="25px" justifyContent="center" w="25px">
      {children}
    </HStack>
  );
};
