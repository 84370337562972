import { use } from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import resources from './namespaces';

export const lngs = {
  en: { nativeName: 'en' },
  uk: { nativeName: 'ua' },
};

const init = async () => {
  await use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
      defaultNS: 'offersAndRequests',
      fallbackNS: 'offersAndRequests',
      fallbackLng: 'en',
      detection: {
        order: ['localStorage', 'navigator'],
      },
      debug: false,
      ns: [
        'businessSettings',
        'forms',
        'mainSettings',
        'workSettings',
        'profileSettings',
        'offersAndRequests',
        'tables',
        'modalsAndMenus',
        'addOffer',
        'offerDetails',
        'tabs',
      ],
      resources,
    });
};

void init();
