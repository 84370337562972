export const linkStyles = {
  components: {
    Link: {
      baseStyle: {
        textDecoration: 'none',
        boxShadow: 'none',
        _focus: {
          boxShadow: 'none',
        },
        _active: {
          boxShadow: 'none',
        },
        _hover: {
          textDecoration: 'none',
          border: 'none',
        },
      },

      variants: {
        main: (props: any) => ({
          color: 'primary.green',
          textDecoration: 'underline',
          _over: {
            textDecoration: 'none',
          },
        }),
      },
    },
  },
};
