import { FormControl, HStack, Radio, RadioGroup, Text } from '@chakra-ui/react';
import React from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Label from '@app/components/FormElements/Label';
import { Offer, OfferPriceEstimateType } from '@app/api/gql/generated-types';

export const OfferPriceEstimate = () => {
  const { t } = useTranslation(['forms']);
  const { requestId } = useWatch<Offer>(['requestId']);
  const { control, setValue } = useFormContext<Offer>();
  const { field: { value } = {} } = useController<Offer>({
    name: 'priceEstimateType',
    control,
  });
  const handleChange = (val: OfferPriceEstimateType) => {
    setValue('priceEstimateType', val);
  };
  return (
    <FormControl>
      <Label color="grey.300" fontSize={14} ms={0}>
        {t('estimateLabel')}
      </Label>
      <RadioGroup
        isDisabled={!!requestId}
        mb="15px"
        value={value}
        variant="primary"
        onChange={handleChange}>
        <HStack spacing={5}>
          <Radio value={OfferPriceEstimateType.FINAL}>
            <HStack>
              <Text color="grey.250">{t('offerPriceEsimateFinal')}</Text>
            </HStack>
          </Radio>
          <Radio value={OfferPriceEstimateType.ESTIMATE}>
            <HStack>
              <Text color="grey.250">{t('offerPriceEsimateEstimate')}</Text>
            </HStack>
          </Radio>
        </HStack>
      </RadioGroup>
    </FormControl>
  );
};
