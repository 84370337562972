import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import React, { FC, PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface BlockHideWrapperProps extends PropsWithChildren {
  height?: number;
}

export const BlockHideWrapper: FC<BlockHideWrapperProps> = ({
  height = 80,
  children,
  ...props
}) => {
  const { t } = useTranslation(['tables']);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const ref = useRef();
  const { height: boxHeight } = useSize(ref) ?? { height: null };

  const isHidden = boxHeight >= height;

  return (
    <Box {...props}>
      <Box
        maxH={isHidden && !isOpen ? `${height}px` : 'full'}
        overflow="hidden">
        <Box ref={ref}>{children}</Box>
      </Box>
      {isHidden && (
        <HStack justifyContent="flex-end">
          <Box
            _hover={{
              opacity: 0.8,
            }}
            autoFocus={false}
            color="grey.250"
            cursor={'pointer'}
            fontSize={12}
            mt={1}
            px={0}
            onClick={() => (!isOpen ? onOpen() : onClose())}>
            {isOpen ? t('hide') : t('showMore')}
          </Box>
        </HStack>
      )}
    </Box>
  );
};
