import { useEffect } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';

import { db } from '@app/db';
import { useFindChatsQuery } from '@app/api/schemas/chat.mongo.generated';

const sort = {
  updatedAt: -1,
};

export const useActiveChatsWatch = () => {
  const { chats } = db;
  const chatsData = useLiveQuery(() =>
    chats.orderBy('updatedAt').reverse().toArray(),
  );
  const { data: { findChats } = {} } = useFindChatsQuery({
    variables: {
      input: {
        sort,
        limit: 8,
        filter: {
          isUnread: true,
        },
      },
    },
    pollInterval: 60000,
  });

  useEffect(() => {
    if (findChats?.total) {
      chats.bulkPut(findChats?.items);
    }
  }, [findChats]);

  return {
    chats: chatsData,
    isLoading: false,
  };
};
