import React, { KeyboardEvent } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { type SignInInput } from 'aws-amplify/auth';

import { InputPassword } from '@app/components/FormElements/InputPassword';
import { FormField } from '@app/components/formInputs/FormField';
import { useAuth } from '@app/hooks/useAuth';
import { useLoginValidationSchema } from '@app/views/Login/useLoginValidationSchema';
import { useErrorNotification } from '@app/hooks/useErrorNotification';

import { initialValues } from '../../initialValues';

import { Submit } from './Submit';

export const LoginForm = () => {
  const errorToast = useErrorNotification();
  const validationSchema = useLoginValidationSchema();
  const { t } = useTranslation('forms');
  const { handleSignIn, isLoading } = useAuth();
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const onSubmit = async (values: SignInInput) => {
    try {
      const { nextStep } = await handleSignIn(values);

      if (nextStep?.signInStep === 'CONFIRM_SIGN_UP') {
        navigate('/verification', { state: { username: values.username } });
        return;
      }
    } catch (error) {
      errorToast(error);
    }
  };

  const onkeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      void form.handleSubmit(onSubmit)();
    }
  };

  return (
    <FormProvider {...form}>
      <FormField
        isRequired
        mb="25px"
        ms={{ base: '0px', md: '0px' }}
        name="username"
        placeholder={t('email')}
        size="lg"
        type="email"
        variant="auth"
        onKeyDown={onkeyDown}
      />

      <FormField
        Component={InputPassword}
        mb="25px"
        name="password"
        placeholder={t('password')}
        variant="auth"
        onKeyDown={onkeyDown}
      />

      <Flex
        alignItems="flex-end"
        justifyContent="flex-end"
        mb="25px"
        mt="-10px"
        w="full">
        <Button
          as={NavLink}
          fontWeight="500"
          ms="5px"
          to="/forgot"
          variant="link">
          {t('isPasswordForgotten')}
        </Button>
      </Flex>
      <Submit
        loading={isLoading}
        title={t('signIn')}
        onSubmit={form.handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};
