import {
  VStack,
  Box,
  Text,
  Flex,
  Button,
  useDisclosure,
  Textarea,
} from '@chakra-ui/react';
import React, {
  MouseEvent,
  UIEvent,
  useMemo,
  Fragment,
  FC,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import _groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import { useApolloClient } from '@apollo/client';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import { Chat } from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/hooks/useApolloLoadingStatus';
import useCurrentAccount from '@app/hooks/useCurrentAccount';
import Popup from '@app/components/Popup';
import NotifyPopup from '@app/components/NotifyPopup';
import { ReactComponent as SeccussReportIcon } from '@app/icons/seccuss-report-icon.svg';
import { db } from '@app/db';
import { DateFormatter } from '@app/components/DateFormatter';
import {
  ChatPreviewFragmentDoc,
  useChatReadAllMutation,
} from '@app/api/schemas/chat.mongo.generated';
import {
  MessageFragment,
  useFindMessagesQuery,
} from '@app/api/schemas/message.mongo.generated';
import {
  GetCountersDocument,
  GetCountersQuery,
} from '@app/api/schemas/account.mongo.generated';

import { useContextMenuActions } from '../../hooks/contextMenuActions';
import { useContextMenu } from '../../hooks/useContextMenu';

import { ContextMenu } from './ContextMenu';
import { MessageItem } from './MessageItem';

interface MessagesProps {
  chatId?: string;
  isWidget?: boolean;
  unreadCount?: number;
}

export const Messages: FC<MessagesProps> = ({
  chatId,
  isWidget = false,
  unreadCount,
}) => {
  const { account } = useCurrentAccount();
  const { t } = useTranslation(['modalsAndMenus']);
  const [chatRead] = useChatReadAllMutation();
  const ref = useRef<boolean>();

  const { setDisableReport, setOpenMenu, setSpam } = useContextMenuActions();

  const { state } = useContextMenu();
  const client = useApolloClient();
  const reportSuccessModal = useDisclosure();

  const filter = {
    chatId,
  };

  const {
    data: { findMessages: messageData } = {},
    fetchMore,
    networkStatus,
  } = useFindMessagesQuery({
    variables: {
      input: {
        filter,
        limit: 20,
      },
    },
    skip: !chatId,
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const messagesOrder = useMemo(
    () =>
      _groupBy(
        uniqBy(messageData?.items ?? [], '_id'),
        (item) => new Date(item.createdAt).toISOString().split('T')[0],
      ),
    [messageData?.items],
  );

  const { isLoadingData, isFetchingNextPage } =
    useApolloLoadingStatus(networkStatus);
  const fetchNextPage = async () => {
    try {
      if (messageData?.hasNextPage && !isLoadingData) {
        await fetchMore(createPaginationVariables(messageData, 20, filter));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onContextMenu = (
    event: MouseEvent<HTMLDivElement>,
    message: MessageFragment,
    isOwnerId: boolean,
  ) => {
    event.preventDefault();
    const newPosition = {
      x: event.pageX,
      y: event.pageY,
    };
    if (isOwnerId) setDisableReport(true);
    setOpenMenu({ isOpen: true, replyMessage: message, points: newPosition });
    setDisableReport(isOwnerId);
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (state.isOpen) setOpenMenu({ isOpen: false });
    const target = e.currentTarget as HTMLTableElement;
    if (
      Math.ceil(-target.scrollTop + target.clientHeight) >=
      target.scrollHeight - target.clientHeight &&
      !isFetchingNextPage
    ) {
      void fetchNextPage();
    }
  };

  const onReport = () => {
    setSpam(false);
    reportSuccessModal.onOpen();
  };

  const updateCounters = useCallback(() => {
    client.cache.updateQuery<GetCountersQuery>(
      {
        query: GetCountersDocument,
      },
      (data) => ({
        ...data,
        getCounters: {
          ...data?.getCounters,
          totalUnreadChats:
            data?.getCounters?.totalUnreadChats > 0
              ? data?.getCounters?.totalUnreadChats - unreadCount
              : 0,
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (!!chatId && !!unreadCount && !ref?.current) {
      void chatRead({
        variables: { input: { ids: [chatId] } },
        update(cache, _result, { variables }) {
          variables?.input?.ids.forEach((id) => {
            cache.updateFragment<Chat>(
              {
                id: `Chat:${id}`,
                fragment: ChatPreviewFragmentDoc,
                fragmentName: 'ChatPreview',
              },
              (data) => ({
                ...data,
                unreadCount: 0,
              }),
            );
          });
        },
      });
      updateCounters();
      ref.current = true;
      void db.chats.update(chatId, {
        unreadCount: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  return (
    <VStack
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      flex={1}
      flexDirection="column-reverse"
      h="full"
      margin="auto"
      maxWidth="680px"
      overflowY="auto"
      px={isWidget ? '18px' : '0'}
      w="100%"
      onScrollCapture={handleScroll}>
      {Object.entries(messagesOrder).map(([date, items]) => {
        return (
          <Fragment key={date}>
            {items.map((message) => (
              <MessageItem
                key={message?._id}
                isOwnerId={message?.ownerId?._id === account?._id}
                isWidget={isWidget}
                message={message}
                onContextMenu={onContextMenu}
              />
            ))}
            {date && (
              <Box my="25px" textAlign="center" w="100%">
                <Text color="grey.200" fontSize="14px" fontWeight="500">
                  <DateFormatter date={new Date(date)} />
                </Text>
              </Box>
            )}
          </Fragment>
        );
      })}

      {state?.isOpen && (
        <ContextMenu left={state?.points.x} top={state?.points.y} />
      )}
      {state?.isSpam && (
        <Popup
          closeOnOverlayClick
          isOpen={state?.isSpam}
          maxW={'506px'}
          title={t('spamModal.title')}
          onClose={() => setSpam(false)}>
          <Box p="0px 40px 30px">
            <Text
              color="black.100"
              fontSize="16px"
              my="14px"
              textAlign="center">
              {t('spamModal.description')}
            </Text>
            <Text color="black.100" fontSize="16px" my="8px" textAlign="left">
              {t('spamModal.reason')}
            </Text>
            <Text color="grey.300" fontSize="12px">
              {t('spamModal.helpUnderstand')}
            </Text>
            <Textarea
              _placeholder={{ color: 'grey.300' }}
              bg="grey.100"
              border="none"
              borderRadius="10px"
              color="black.100"
              fontSize="12px"
              h="90px"
              maxH="90px"
              mt="14px"
              placeholder={t('spamModal.input')}
              resize="none"
            />
            <Flex direction="column" mt="20px">
              <Button bg="primary.red" variant="primary" onClick={onReport}>
                {t('spamModal.submit')}
              </Button>
              <Button variant="cancel" onClick={() => setSpam(false)}>
                {t('cancel')}
              </Button>
            </Flex>
          </Box>
        </Popup>
      )}
      {reportSuccessModal.isOpen && (
        <NotifyPopup
          {...reportSuccessModal}
          icon={<SeccussReportIcon />}
          width="350px">
          <Box p="0 54px 64px">
            <Text
              color="black.100"
              fontSize="24px"
              fontWeight="700"
              lineHeight="36px"
              textAlign="center">
              {t('spamModal.thankText')}
            </Text>
          </Box>
        </NotifyPopup>
      )}
    </VStack>
  );
};
