import React from 'react';
import capitalize from 'lodash/capitalize';

import { formatDate } from '@app/utils/formatDate';
import { useLocale } from '@app/hooks/useLocale';

interface DateFormatterProps {
  date?: Date;
  format?: string;
  capitalizeFirstLetter?: boolean;
}

export const DateFormatter: React.FC<DateFormatterProps> = ({
  date,
  format,
  capitalizeFirstLetter = true,
}) => {
  const locale = useLocale();

  const formattedDate = formatDate(date, format, { locale });

  if (!date) {
    return null;
  }

  return (
    <>{capitalizeFirstLetter ? capitalize(formattedDate) : formattedDate}</>
  );
};
