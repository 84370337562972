import React, { FC } from 'react';
import { BSON } from 'realm-web';

import { MessageReference } from '@app/api/gql/generated-types';
import { useRequestQuery } from '@app/api/schemas/request.mongo.generated';
import { useGetOrderQuery } from '@app/api/schemas/order.mongo.generated';
import { useGetOfferQuery } from '@app/api/schemas/offer.mongo.generated';

import OfferRef from './OfferRef';
import { OrderRef } from './OrderRef';
import { RequestRef } from './RequestRef';

interface MessageReferenceItemProps {
  item: MessageReference;
  isWidget?: boolean;
}

export const MessageReferenceItem: FC<MessageReferenceItemProps> = ({
  item,
  isWidget = false,
}) => {
  const skipGetOrder =
    !item?.orderId || (!!item?.orderId && !!item?.orderId?._id);
  const skipGetOffer =
    !item?.offerId || (!!item?.offerId && !!item?.offerId?._id);
  const skipGetRequest =
    !item?.requestId || (!!item?.requestId && !!item?.requestId?._id);

  const orderId = !!item?.orderId && !item?.orderId?._id ? item?.orderId : null;
  const offerId = !!item?.offerId && !item?.offerId?._id ? item?.offerId : null;
  const requestId =
    !!item?.requestId && !item?.requestId?._id ? item?.requestId : null;

  const { data: { getOrder: orderData } = {} } = useGetOrderQuery({
    variables: {
      input: {
        id: orderId,
      },
    },
    skip: skipGetOrder,
  });

  const { data: { getOffer: offerData } = {} } = useGetOfferQuery({
    variables: {
      input: {
        id: offerId,
      },
    },
    skip: skipGetOffer,
  });

  const { data: { request: requestData } = {} } = useRequestQuery({
    variables: {
      query: {
        _id: new BSON.ObjectID(requestId),
      },
    },
    skip: skipGetRequest,
  });

  const order = {
    ...(skipGetOrder && item?.orderId),
    ...orderData,
  };

  const offer = {
    ...(skipGetOffer && item?.offerId),
    ...offerData,
  };
  const request = {
    ...(skipGetRequest && item?.requestId),
    ...requestData,
  };

  return (
    <>
      {!!item?.offerId && <OfferRef isWidget={isWidget} item={offer} />}
      {!!item?.orderId && <OrderRef isWidget={isWidget} item={order} />}
      {!!item?.requestId && <RequestRef isWidget={isWidget} item={request} />}
    </>
  );
};
