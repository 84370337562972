const primaryCheckbox = {
  control: {
    borderRadius: '4px',
    border: '1px',
    borderColor: 'grey.250',
    width: '18px',
    height: '18px',
    backgroundColor: 'transparent',
    _hover: {
      backgroundColor: 'transparent',
      borderColor: 'primary.green',
    },
    _checked: {
      backgroundColor: 'primary.green',
      color: 'white',
      borderColor: 'primary.green',
      _focus: {
        backgroundColor: 'primary.green',
        color: 'white',
        borderColor: 'primary.green',
      },
    },
    _focus: {
      border: '1px solid primary.green',
      backgroundColor: 'transparent',
      boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
    },
  },
  label: {
    fontSize: '14px',
    color: 'grey.300',
  },
  icon: {
    height: '10px',
    color: 'white',
  },
};

const multiListCheckbox = {
  container: {
    padding: '4px 12px',
    borderRadius: '20px',
    width: '100%',
    color: 'unset',
  },
  label: {
    ...primaryCheckbox.label,
    color: 'unset',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const CheckboxStyles = {
  components: {
    Checkbox: {
      variants: {
        primary: {
          ...primaryCheckbox,
        },
        multiList: {
          ...primaryCheckbox,
          ...multiListCheckbox,
        },
        status: (props) => ({
          container: {
            px: '5px',
            py: '3px',
            borderRadius: 5,
            backgroundColor: `${props?.color}:` ?? 'gray.300',
            _checked: {
              backgroundColor: `${props?.color}:` ?? 'gray.300',
            },
          },
          control: {
            width: '7px',
            height: '7px',
            borderRadius: '100%',
            backgroundColor: props?.color ?? 'gray.300',
            border: '0 none',
            _checked: {
              backgroundColor: props?.color ?? 'gray.300',
            },
          },
          icon: {
            display: 'none',
          },
        }),
      },
    },
  },
};
