import React from 'react';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useNumberInput,
} from '@chakra-ui/react';
import { isNil } from 'lodash';

import { ReactComponent as Minus } from '@app/icons/minus-icon-input.svg';
import { ReactComponent as Plus } from '@app/icons/plus-icon-input.svg';

export const InputNumber = ({
  showButtons = true,
  precision = 0,
  ...props
}) => {
  const { getIncrementButtonProps, getDecrementButtonProps, getInputProps } =
    useNumberInput({
      step: 1,
      precision,
      ...props,
      value: !isNil(props?.value) ? props.value : undefined,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <InputGroup alignItems="center" {...props}>
      {showButtons && (
        <InputLeftElement alignItems="center" ml={1.5} w={4}>
          <IconButton
            {...dec}
            _hover={{
              backgroundColor: 'backgrounds.green30',
              color: 'grey.250',
            }}
            aria-label="minus"
            backgroundColor="white"
            borderRadius="5px"
            color="grey.300"
            h={4}
            minW={4}>
            <Minus />
          </IconButton>
        </InputLeftElement>
      )}
      <Input {...input} textAlign="center" variant="main" />
      {showButtons && (
        <InputRightElement alignItems="center" mr={1.5} w={4}>
          <IconButton
            {...inc}
            _hover={{
              backgroundColor: 'backgrounds.green30',
              color: 'grey.250',
            }}
            aria-label="minus"
            backgroundColor="white"
            borderRadius="5px"
            color="grey.300"
            h={4}
            minW={4}>
            <Plus />
          </IconButton>
        </InputRightElement>
      )}
    </InputGroup>
  );
};
