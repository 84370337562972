export const RadioStyles = {
  components: {
    Radio: {
      variants: {
        primary: {
          control: {
            border: '1px',
            borderColor: 'grey.300',
            width: '18px',
            height: '18px',
            backgroundColor: 'transparent',
            _hover: {
              backgroundColor: 'transparent',
              borderColor: 'primary.green',
            },
            _checked: {
              backgroundColor: 'transparent',
              color: 'primary.green',
              borderColor: 'grey.300',
              _focus: {
                backgroundColor: 'transparent',
              },
              _hover: {
                backgroundColor: 'transparent',
              },
            },
            _focus: {
              backgroundColor: 'transparent',
            },
          },
          label: {
            fontSize: '14px',
            color: 'grey.250',
          },
        },
      },
    },
  },
};
