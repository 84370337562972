import { Box, HStack, Text, useToast, Link } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Icon } from '@app/icons/error-outline.svg';
import { botUrls } from '@app/utils/botUrls';

export const useErrorNotification = (message?: string) => {
  const toast = useToast();
  const { t, i18n } = useTranslation(['addOffer']);

  return (error?: { message: string }) =>
    toast({
      title: message ? message : error.message,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      render: ({ title, description, onClose }) => (
        <HStack
          backgroundColor="rgb(255 206 204)"
          borderRadius={10}
          m={1}
          maxW="300px"
          p="15px 25px"
          spacing={3}
          onClick={onClose}>
          <Box color="primary.red">
            <Icon />
          </Box>
          <Box>
            <Text color="primary.red" fontSize={12}>
              {title}{' '}
              <Link
                href={botUrls?.[i18n.resolvedLanguage]}
                target="_blank"
                textDecoration="underline">
                {t('contactTelegram')}
              </Link>
              {t('contactTelegramEnd')}
            </Text>
            <Text color="primary.red" fontSize={12}>
              {description}
            </Text>
          </Box>
        </HStack>
      ),
    });
};
