import React, { forwardRef } from 'react';
import Select, { GroupBase, Props } from 'react-select';

import { customStyles } from './styles';

interface SelectProps<T, M extends boolean, G extends GroupBase<T>>
  extends Props<T, M, G> {
  error?: string;
  variant?: string;
  menuHeight?: number;
}

export interface SelectOption {
  label: string;
  value: string | null;
}

export const SelectField = forwardRef(
  ({ isSearchable, error, variant, ...props }: SelectProps<T, M, G>, ref) => {
    return (
      <Select
        ref={ref}
        isSearchable={!!isSearchable}
        styles={customStyles(error, variant)}
        {...props} // Note: Removed the eslint-disable line
      />
    );
  },
);
SelectField.displayName = 'SelectField';
