import React from 'react';
import { Box } from '@chakra-ui/react';

import Item from './Item';

const EmptyState = () => {
  return (
    <Box my={4}>
      <Item items={['70', '90']} />
      <Item items={['50', '20']} />
      <Item items={['45', '20']} />
      <Item items={['70', '20']} />
      <Item items={['78', '50']} />
      <Box
        backgroundColor="#EEEEEE"
        borderRadius={5}
        height="120px"
        mt={6}
        w="full"
      />
    </Box>
  );
};

export default EmptyState;
