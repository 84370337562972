import React from 'react';
import { Text } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/theme-tools';

interface FullRequestInfoDescriptionProps {
  description: string;
}

export const scrollbarStylesCreator = (
  params?: SystemStyleObject,
): SystemStyleObject => {
  return {
    padding: '0 8px 0 0',
    marginRight: '7px',

    '::-webkit-scrollbar': {
      width: '5px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#E9EDF7',
      margin: '12px 0',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#ABB0B5',
      borderRadius: '3px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#ABB0B5',
    },
    ...params,
  };
};

export const FullRequestInfoDescription: React.FC<
  FullRequestInfoDescriptionProps
> = ({ description }) => {
  return (
    <Text
      color="mainGray.100"
      fontSize="14px"
      fontWeight={400}
      maxHeight={105}
      overflow="auto"
      pt="0px !important"
      sx={scrollbarStylesCreator()}>
      {description}
    </Text>
  );
};
