import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

interface SubmitProps {
  title?: string;
  loading?: boolean;
  onSubmit: (...val: any) => any;
}

export const Submit: FC<SubmitProps> = ({
  title = 'Sign In',
  loading = false,
  onSubmit,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <Button
      fontSize="16px"
      fontWeight="500"
      h="50"
      isDisabled={isSubmitting}
      isLoading={loading || isSubmitting}
      minH="40px"
      variant="primary"
      w="100%"
      onClick={handleSubmit(onSubmit)}>
      {title}
    </Button>
  );
};
