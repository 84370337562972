export const FormLabel = {
  components: {
    FormLabel: {
      baseStyle: {
        color: 'grey.300',
        fontSize: '12px',
        fontWeight: 400,
      },
    },
  },
};
