import { Badge } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { useUnreadNotificationsQuery } from '@app/api/schemas/notification.amazon.generated';

export const BadgeCount = () => {
  const { data: { notifications: items } = {} } = useUnreadNotificationsQuery({
    variables: {
      filter: {
        items: {
          fieldName: 'isRead',
          value: 'false',
          filterOption: 'Equals',
        },
      },
      pagination: {
        limit: 10,
      },
    },
    pollInterval: 60000,
    context: {
      clientName: 'amazon',
    },
  });

  const total = useMemo(
    () => (items?.total > 9 ? '9+' : items?.total),
    [items?.total],
  );

  if (!items?.total) {
    return null;
  }

  return (
    <Badge
      alignItems="center"
      backgroundColor="red"
      color="white"
      display="flex"
      h={4}
      justifyContent="center"
      p="2px"
      pl="4px"
      position="absolute"
      pr="4px"
      right="-2px"
      top="-4px"
      w={4}>
      {total}
    </Badge>
  );
};
