import { mode } from '@chakra-ui/theme-tools';
export const buttonStyles = {
  components: {
    IconButton: {
      size: {
        inputButton: {
          minW: '20px',
          h: '20px',
        },
      },
    },
    Button: {
      size: {
        roundedIcon: {
          minW: '32px',
          h: '32px',
        },
      },
      baseStyle: {
        borderRadius: '10px',
        boxShadow: '45px 76px 113px 7px rgba(shadow.100)',
        transition: '.25s all ease',
        boxSizing: 'border-box',
        minW: '55px',
        _focus: {
          boxShadow: 'none',
        },
        _active: {
          boxShadow: 'none',
        },
      },
      variants: {
        outline: () => ({
          p: '10px 8px',
          fontSize: '14px',
          fontWeight: 400,
          borderRadius: '10px',
          border: `1px solid`,
          borderColor: 'primary.green',
          color: 'primary.green',
          _hover: {
            bg: 'primary.green',
            color: 'white',
          },
        }),
        outlineGray: () => ({
          p: '10px 8px',
          borderRadius: '10px',
          border: `1px solid`,
          borderColor: 'gray.200',
          color: 'gray.200',
          _hover: {
            bg: 'grey.200',
            color: 'white',
          },
        }),
        rounded: (props: any) => ({
          bg: 'navy.700',
          borderRadius: '45px',
          cursor: 'pointer',
          h: '32px',
          m: '10px 6px 10px 10px',
          minW: '32px',
          w: '32px',
          _hover: {
            color: 'primary.green',
            bg: 'primary.green',
          },
        }),
        brand: (props: any) => ({
          bg: mode('primary.green', 'brand.400')(props),
          color: 'white',
          opacity: 1,
          _focus: {
            bg: 'green.600',
            opacity: 1,
          },
          _active: {
            bg: 'green.600',
            opacity: 1,
          },
          _hover: {
            bg: 'green.600',
            opacity: 1,
          },
        }),
        icon: (props: any) => ({
          bg: 'transparent',
          borderRadius: '50%',
          span: {
            margin: '0',
          },
          _focus: {
            bg: 'gray.100',
          },
          _active: {
            bg: 'gray.100',
          },
          _hover: {
            bg: 'gray.100',
          },
        }),
        light: (props: any) => ({
          fontSize: '14px',
          bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
          color: mode('brand.900', 'white')(props),
          borderRadius: '16px',
          _focus: {
            bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
          },
          _active: {
            bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
          },
          _hover: {
            bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
          },
        }),

        danger: (props: any) => ({
          width: '100%',
          height: '46px',
          bg: mode('white', 'white')(props),
          color: mode('red.600', 'red.600')(props),
          border: '1px solid #E31A1A',
          _focus: {
            bg: mode('whiteAlpha.100', 'whiteAlpha.100')(props),
          },
          _active: {
            bg: mode('whiteAlpha.100', 'whiteAlpha.100')(props),
          },
          _hover: {
            bg: mode('whiteAlpha.100', 'whiteAlpha.200')(props),
          },
        }),
        primary: (props: any) => {
          const colorMode = mode('primary.green', 'primary.green')(props);
          return {
            width: '100%',
            height: '46px',
            bg: colorMode,
            color: mode('white', 'white')(props),
            _focus: {
              bg: 'green.600',
              opacity: 1,
            },
            _active: {
              bg: 'green.600',
              opacity: 1,
            },
            _hover: {
              bg: 'green.600',
              opacity: 1,
              _disabled: {
                bg: 'primary.green',
                opacity: 0.5,
                cursor: 'default',
              },
            },
            _disabled: {
              bg: 'primary.green',
              opacity: 0.5,
              cursor: 'default',
            },
          };
        },
        orange: (props: any) => {
          const colorMode = mode('orange.600', 'orange.600')(props);
          return {
            width: '100%',
            height: '46px',
            bg: colorMode,
            color: mode('white', 'white')(props),
            _focus: {
              bg: 'orange.700',
              opacity: 1,
            },
            _active: {
              bg: 'orange.700',
              opacity: 1,
            },
            _hover: {
              bg: 'orange.700',
              opacity: 1,
            },
          };
        },
        green: (props: any) => {
          const colorMode = mode('primary.green', 'primary.green')(props);
          return {
            width: '100%',
            height: '46px',
            bg: colorMode,
            color: mode('white', 'white')(props),
            _focus: {
              bg: 'orange.700',
              opacity: 1,
            },
            _active: {
              bg: 'orange.700',
              opacity: 1,
            },
            _hover: {
              bg: 'orange.700',
              opacity: 1,
            },
          };
        },
        gray: (props: any) => {
          return {
            width: '100%',
            height: '46px',
            bg: 'grey.250',
            color: 'white',
            _focus: {
              bg: 'grey.250',
              opacity: 0.9,
            },
            _active: {
              bg: 'grey.250',
              opacity: 0.9,
            },
            _hover: {
              bg: 'grey.250',
              opacity: 0.9,
            },
          };
        },
        dark: (props: any) => {
          return {
            width: '100%',
            height: '46px',
            bg: 'grey.300',
            fontSize: 12,
            fontWeight: 400,
            color: 'white',
            _focus: {
              bg: 'grey.300',
              opacity: 0.9,
            },
            _active: {
              bg: 'grey.300',
              opacity: 0.9,
            },
            _hover: {
              bg: 'grey.300',
              opacity: 0.9,
            },
          };
        },
        secondaryPurple: (props: any) => {
          const colorMode = mode('brand.900', 'brand.900')(props);
          return {
            width: '100%',
            height: '34px',
            bg: colorMode,
            color: mode('white', 'white')(props),
            _focus: {
              bg: colorMode,
              opacity: 0.9,
            },
            _active: {
              bg: colorMode,
              opacity: 0.7,
            },
            _hover: {
              bg: colorMode,
              opacity: 0.9,
            },
          };
        },

        link: {
          fontSize: '14px',
          lineHeight: '16px',
          backgroundColor: 'transparent',
          color: 'primary.green',
          fontWeight: 'normal',
          fontStyle: 'normal',
          textDecoration: 'underline',
          textDecorationColor: 'primary.green',
          _hover: {
            color: 'green.600',
            textDecoration: 'none',
            textDecorationColor: 'green.600',
            _disabled: {
              textDecoration: 'underline',
              color: 'secondaryGray.600',
            },
          },
          _active: {
            color: 'green.600',
            textDecorationColor: 'green.600',
          },
          _focus: {
            color: 'green.600',
            textDecorationColor: 'green.600',
            textDecoration: 'none',
          },
          _disabled: {
            color: 'secondaryGray.600',
            opacity: 'unset',
            textDecorationColor: 'secondaryGray.600',
          },
        },
        cancel: {
          fontSize: '14px',
          lineHeight: '16px',
          backgroundColor: 'transparent',
          color: 'grey.300',
          fontWeight: 'normal',
          fontStyle: 'normal',
          _hover: {
            color: 'grey.200',
            _disabled: {
              opacity: '0.5',
            },
          },
          _active: {
            color: 'grey.200',
          },
          _focus: {
            color: 'grey.200',
          },
          _disabled: {
            color: 'grey.300',
            opacity: '0.5',
          },
        },
      },
    },
  },
};
