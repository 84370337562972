import { mixed, object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Business } from '@app/api/gql/generated-types';

export const useValidationSchema = () => {
  const { t } = useTranslation('validations');

  const validationSchema = object().shape({
    companyName: string().trim().required(t('companyNameRequired')),

    country: mixed().label('').required(t('countryRequired')),
    address: string().trim().label('Address'),
    city: string().trim().label('City'),
  });

  return yupResolver<Business>(validationSchema);
};
