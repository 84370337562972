import {
  Box,
  HStack,
  Image,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { SelectAppLanguage } from '@app/components/SelectAppLanguage';
import Popup from '@app/components/Popup';

import { Hero } from './components/Promo/Hero';
import { Solutions } from './components/Promo/Solutions';
import { Benefits } from './components/Promo/Benefits';
import { Video } from './components/Promo/Video';
import { Footer } from './components/Promo/Footer';
import { Support } from './components/Promo/Support';
import { Form } from './components/Promo/Form';

export const SignUpAgents = () => {
  const registerRef = useRef();
  const solutionsRef = useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleRegister = () =>
    registerRef.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <Box flex={1} h="100dvh" minH="full" overflowY="auto">
      <HStack
        justifyContent="space-between"
        position="relative"
        px={10}
        py={2}
        spacing={5}
        zIndex={5}>
        <Link to="/">
          <Image maxH="60px" src="/logo.png" />
        </Link>
        <Box>
          <SelectAppLanguage />
        </Box>
      </HStack>
      <Box ref={registerRef}>
        <Hero
          onClickMore={() =>
            solutionsRef.current.scrollIntoView({ behavior: 'smooth' })
          }
        />
      </Box>
      <Box ref={solutionsRef}>
        <Solutions onMoreInfo={onOpen} onRegister={handleRegister} />
      </Box>
      <Video />
      <Benefits onMoreInfo={onOpen} onRegister={handleRegister} />
      <Support />
      <Footer />
      {isOpen && (
        <Popup
          closeOnOverlayClick
          hideCloseIcon={false}
          isOpen={isOpen}
          maxW="500px"
          onClose={onClose}>
          <Box p="30px 60px 30px">
            <Text
              color="mainGray.500"
              fontSize={{ base: '28px', lg: '34px' }}
              fontWeight={600}
              lineHeight={{ base: '30px', lg: '38px' }}
              mb={8}
              textAlign="center">
              Хочеш дізнатись більше?
            </Text>
            <Text
              color="mainGray.500"
              fontSize={{ base: '12px', lg: '16px' }}
              fontWeight={600}
              mb={10}
              textAlign="center">
              Залишай свій номер! <br /> Ми передзвонимо і розповімо деталі
            </Text>

            <HStack justifyContent="center">
              <VStack justifyContent="center" maxW="400px" minW="300px">
                <Form onSuccess={onClose} />
              </VStack>
            </HStack>
          </Box>
        </Popup>
      )}
    </Box>
  );
};
