import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { fetchAuthSession } from 'aws-amplify/auth';

import { cursorAmazonPagination, cursorPagination } from './apollo-pagination';

const graphqlUri = process.env.REACT_APP_GRAPHQL_API;
const graphqlAmazonUri = process.env.REACT_APP_NEW_GRAPHQL_API;

export const initApolloClient = (() => {
  const httpLink = createHttpLink({
    uri: graphqlUri,
  });

  const authLink = setContext(async (_, { headers }) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};

    const token = idToken.toString();

    return {
      headers: {
        ...headers,
        ['jwtTokenString']: token && token?.toString(),
      } as Record<string, string>,
    };
  });

  const amazonLink = createHttpLink({
    uri: graphqlAmazonUri,
  });

  const authAmazonLink = setContext(async (_, { headers }) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};

    const token = idToken.toString();

    return {
      headers: {
        ...headers,
        ['Authorization']: token && `Bearer ${token?.toString()}`,
      } as Record<string, string>,
    };
  });

  const link = split(
    (operation) => operation.getContext().clientName === 'amazon',
    from([authAmazonLink, amazonLink]),
    from([authLink, httpLink]),
  );

  return new ApolloClient({
    link,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            findRequest: cursorPagination([
              'input',
              ['filter', 'sort', 'limit'],
            ]),
            findOffers: cursorPagination([
              'input',
              ['filter', 'sort', 'limit'],
            ]),
            findMessages: cursorPagination(['input', ['filter']]),
            findChats: cursorPagination(['input', ['sort', 'filter', 'limit']]),
            findOrders: cursorPagination(['input', ['filter', 'limit']]),
            findAgents: cursorPagination(['input', ['filter', 'limit']]),
            findAccounts: cursorPagination(['input', ['limit']]),
            notifications: cursorAmazonPagination([
              'filter',
              'pagination',
              ['sort'],
            ]),
            countries: cursorAmazonPagination(),
            accounts: cursorAmazonPagination(['filter']),
            businesses: cursorAmazonPagination([
              'filter',
              'pagination',
              ['sort'],
            ]),
          },
        },
      },
    }),
  });
})();
