import {
  Box,
  Button,
  Flex,
  Image,
  Textarea,
  UseDisclosureProps,
} from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getOS } from '@app/utils/getOS';
import { UploadPreviewModal } from '@app/components/ImagePopup/UploadPrevieModal';

interface UploadPhotoProps extends UseDisclosureProps {
  image: File;
  isLoading: boolean;
  onSubmit: (text: string) => Promise<void>;
}

export const UploadPhoto: FC<UploadPhotoProps> = ({
  isOpen,
  onClose,
  image,
  isLoading,
  onSubmit,
}) => {
  const { t } = useTranslation(['forms']);
  const [inputMessage, setInputMessage] = useState<string>();
  const [row, setRows] = useState<number>(1);
  const isDisabled = useMemo(
    () =>
      ((!inputMessage || inputMessage?.trim().length <= 0) && !image) ||
      isLoading,
    [image, inputMessage, isLoading],
  );

  const handleSendMessage = useCallback(async () => {
    await onSubmit(inputMessage);
  }, [inputMessage]);

  const fileUrl = useMemo(
    () => (image ? URL.createObjectURL(image) : ''),
    [image],
  );

  useEffect(() => {
    const rowlen = inputMessage?.split?.('\n');

    if (rowlen?.length >= 1 && rowlen?.length <= 4) {
      setRows(rowlen?.length);
    }
  }, [inputMessage]);
  return (
    <UploadPreviewModal
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="400px"
      title={t('sendImage')}
      onClose={onClose}>
      <Box p="0px 48px 22px">
        <Box height="225px !important" width="100%">
          <Image height="225px !important" objectFit="contain" src={fileUrl} />
        </Box>
        <Box mt="18px">
          <Textarea
            bg="transparent"
            borderBottom="1px"
            borderColor="grey.300"
            borderRadius="0px"
            color="black.100"
            fontSize={14}
            fontWeight="500"
            lineHeight="24px"
            overflow="hidden"
            overflowY="auto"
            placeholder={t('writeMessage')}
            px={0}
            resize="none"
            rows={row}
            value={inputMessage}
            variant="search"
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                (getOS() === 'macos' ? e.metaKey : e.ctrlKey)
              ) {
                void handleSendMessage();
              }
            }}
          />
        </Box>
        <Flex alignItems="center" justifyContent="flex-end" mt="10px">
          <Button variant="cancel" onClick={onClose}>
            {t('сancel')}
          </Button>
          <Button
            isDisabled={isLoading || isDisabled}
            isLoading={isLoading}
            maxW="150px"
            variant="primary"
            onClick={handleSendMessage}>
            {t('сonfirmBtnText')}
          </Button>
        </Flex>
      </Box>
    </UploadPreviewModal>
  );
};
