import { Box, Button, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { ReactComponent as Icon } from '@app/icons/ic_right.svg';

const items = [
  {
    title: 'Заробляйте більше, працюючи з нами',
    description: 'Пропонуйте ваші тури тисячам потенційних клієнтів',
  },
  {
    title: 'Отримуйте безкоштовну рекламу для вашого бізнесу',
    description: 'Доступ до найбільшої спільноти туристів в одному застосунку',
  },
  {
    title: 'Спрощуйте управління фінансами',
    description: 'Автоматичні виплати прямо на ваш рахунок',
  },
];

export const Solutions = ({ onRegister, onMoreInfo }) => {
  return (
    <Box px={{ base: 4, lg: 10 }} py={10}>
      <Heading
        color="mainGray.500"
        fontSize={{ base: '28px', lg: '54px' }}
        fontWeight={600}
        lineHeight={{ base: '30px', lg: '58px' }}
        mb={10}
        textAlign="center">
        Наші рішення
      </Heading>

      <HStack
        alignItems={{ base: 'center', lg: 'flex-start' }}
        flexDirection={{ base: 'column', lg: 'row' }}>
        {items?.map((item, index) => (
          <VStack
            key={index}
            _hover={{
              opacity: 0.9,
            }}
            alignItems="flex-start"
            backgroundColor="#23252C"
            cursor="pointer"
            flex={1}
            justifyContent="space-between"
            minH={{ base: 'auto', lg: '300px' }}
            px={8}
            py={6}
            spacing={4}
            transition="all 0.2s"
            w="300px"
            onClick={onMoreInfo}>
            <Box>
              <Text
                color="whiteAlpha.900"
                fontSize={{ base: 20, lg: 28 }}
                fontWeight={600}
                lineHeight={{ base: '26px', lg: '36px' }}
                mb={4}>
                {item.title}
              </Text>
              <Text color="whiteAlpha.700" fontSize={16}>
                {item.description}
              </Text>
            </Box>
            <Button variant="link" w="full" onClick={onMoreInfo}>
              <HStack justifyContent="space-between" w="full">
                <Text color="primary.green">Дізнатись більше</Text>
                <Icon />
              </HStack>
            </Button>
          </VStack>
        ))}
      </HStack>
      <HStack justifyContent="center" mt={14}>
        <Button maxW="300px" variant="primary" onClick={onRegister}>
          Зареєструватись
        </Button>
      </HStack>
    </Box>
  );
};
