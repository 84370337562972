import { Avatar, Box, Button, HStack, Text } from '@chakra-ui/react';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useGoChat } from '@app/hooks/useGoChat';
import { OrderFullFragment } from '@app/api/schemas/order.mongo.generated';

interface OrderAccountInfoProps {
  account: OrderFullFragment['owner'];
  orderId: string;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const OrderAccountInfo: FC<OrderAccountInfoProps> = ({
  account,
  orderId,
}) => {
  const { t } = useTranslation('forms');
  const { openChat } = useGoChat();
  const name =
    account?.fullName ?? `${account?.firstName} ${account?.lastName}`;
  const avatar =
    account?.avatar?.key &&
    `${imageServer}/${account?.avatar?.key}?width=200&format=webp`;

  const handleSubmit = useCallback(async () => {
    await openChat(account?._id, { orderId });
  }, [account?._id, orderId]);

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      spacing={4}
      w="full">
      <HStack spacing={4}>
        <Avatar h={10} name={name} src={avatar} w={10} />
        <Box>
          <Text fontWeight={400} mb={1}>
            {name}
          </Text>
          <Box>
            <Text color="grey.250" fontSize={12} fontWeight={400}>
              {account?.email}
            </Text>
          </Box>
          <Box>
            <Text color="grey.250" fontSize={12} fontWeight={400}>
              {account?.phone}
            </Text>
          </Box>
        </Box>
      </HStack>
      <Button
        borderRadius={10}
        fontSize={14}
        fontWeight={600}
        h="31px"
        px="9px"
        py="5px"
        variant="primary"
        w="auto"
        onClick={handleSubmit}>
        {t('message')}
      </Button>
    </HStack>
  );
};
