import React, { forwardRef } from 'react';
import { InputGroup, InputRightElement, Text } from '@chakra-ui/react';

import './datepicker.css';
import { ReactComponent as CalendarIcon } from '@app/icons/calendar-icon.svg';
import { formatDate } from '@app/utils/formatDate';
import { useLocale } from '@app/hooks/useLocale';

export const DatepickerInput = forwardRef<
  HTMLDivElement,
  {
    onClick?: () => void;
    placeholder?: string;
    date: string | Date;
    showTimeSelect?: boolean;
    isInvalid?: boolean;
  }
>(({ onClick, placeholder = null, date, showTimeSelect, isInvalid }, ref) => {
  const locale = useLocale();
  const value =
    date &&
    formatDate(new Date(date), showTimeSelect ? 'P h:mm aa' : 'P', {
      locale,
    });

  return (
    <InputGroup
      ref={ref}
      border="1px solid"
      borderColor={isInvalid ? 'red.600' : 'gray.100'}
      borderRadius="10px"
      cursor="pointer"
      minW="150px"
      overflow="hidden"
      variant="unstyled"
      w={{ base: '100%' }}
      onClick={() => onClick?.()}>
      <Text
        background="gray.100"
        color={value ? 'black.100' : 'secondaryGray.500'}
        height="auto"
        padding="8px 9px 9px"
        variant="input">
        {value ? value : placeholder}
      </Text>
      <InputRightElement
        alignItems="center"
        display="flex"
        height="100%"
        mr="9px">
        <CalendarIcon />
      </InputRightElement>
    </InputGroup>
  );
});

DatepickerInput.displayName = 'DatepickerInput';
