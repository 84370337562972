import React, { FC, useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ChatPreviewFragment } from '@app/api/schemas/chat.mongo.generated';

interface TooltipNamesViewProps {
  items?: ChatPreviewFragment[];
}
interface TooltipNameProps {
  chat: ChatPreviewFragment;
}

const TooltipName: FC<TooltipNameProps> = ({ chat }) => {
  const { t } = useTranslation(['profile']);
  const companion = chat?.participantsExcludingSelf?.[0];
  const title = useMemo(() => {
    if (chat?.title) {
      return chat.title;
    }
    if (companion?.fullName) {
      return `${t('chatWith')} ${companion?.fullName}`;
    }

    if (companion?.firstName || companion?.lastName) {
      return `${t('chatWith')} ${companion?.firstName} ${companion?.lastName}`;
    }

    return t('chat');
  }, [
    chat?.title,
    companion?.fullName,
    companion?.firstName,
    companion?.lastName,
    t,
  ]);
  return (
    <Text color="grey.300" fontSize={14} lineHeight="17px">
      {title} ({chat?.unreadCount})
    </Text>
  );
};

export const TooltipNamesView: FC<TooltipNamesViewProps> = ({ items }) => {
  return items?.map((item) => {
    return (
      <Box key={item._id}>
        <TooltipName chat={item} />
      </Box>
    );
  });
};
