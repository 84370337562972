import { uk, enUS } from 'date-fns/locale';

export const languagesMap = {
  uk: uk,
  en: enUS,
};
export const defaultLanguageLocale = enUS;
export const getLanguageLocale = (keyLanguage: string) => {
  if (keyLanguage) {
    return languagesMap[keyLanguage] as typeof languagesMap;
  } else {
    return enUS;
  }
};
