import { createContext } from 'react';

interface SidebarContextProps {
  toggleSidebar: boolean;
  onToggleSideBar: () => void;
  onCloseSidebar: () => void;
  onOpenSidebar: () => void;
}

export const SidebarContext = createContext<SidebarContextProps>(null);
