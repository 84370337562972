import { Box, HStack, Link, Text } from '@chakra-ui/react';
import React from 'react';

import { AuthFooter } from '@app/components/Auth/Footer';
import { ReactComponent as Instagram } from '@app/icons/social-instagram.svg';
import { ReactComponent as Linkedin } from '@app/icons/social-linkedin.svg';

export const Footer = () => {
  return (
    <Box mb={10} px={{ base: 4, lg: 10 }} py={10}>
      <HStack alignItems="flex-end">
        <AuthFooter color="mainGray.500" />
        <HStack flexDirection={{ base: 'column', md: 'row' }}>
          <Text minW="70px">Find us</Text>
          <Link
            href="https://www.instagram.com/mira.travel.marketplace"
            target="_blank">
            <Instagram />
          </Link>
          <Link
            href="https://www.linkedin.com/company/mira-travel"
            target="_blank">
            <Linkedin />
          </Link>
        </HStack>
      </HStack>
    </Box>
  );
};
