import {
  Box,
  Button,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import EmojiPicker from 'emoji-picker-react';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmojiIcon } from '@app/icons/emoji-pleased-svgrepo-com.svg';
import { emojiCategoriesUk } from '@app/utils/emojiData';

interface EmojiButtonProps {
  handleChange: (val: unknown) => void;
}

export const EmojiButton: FC<EmojiButtonProps> = ({ handleChange }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { i18n } = useTranslation();
  const categories = i18n.language === 'uk' ? emojiCategoriesUk : null;
  const popoverRef = useRef(null);
  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
  });

  return (
    <Box ref={popoverRef}>
      <Popover autoFocus={false} closeOnBlur={false} isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            borderRadius="50%"
            color="primary.green"
            h={8}
            minW={8}
            p={0}
            transition="0.3s 0.1s"
            variant="icon"
            w={8}
            onClick={onToggle}>
            <EmojiIcon height="20px" width="20px" />
          </Button>
        </PopoverTrigger>
        <PopoverContent border="0 none" w="full">
          <PopoverCloseButton />

          <EmojiPicker
            skinTonesDisabled
            categories={categories}
            onEmojiClick={handleChange}
          />
        </PopoverContent>
      </Popover>
    </Box>
  );
};
