import { extendTheme, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';

import { SideBarComponent } from '@app/components/Sidebar/components/sideBarStyles';
import { SpendingsComponent } from '@app/views/Requests/components/ReportPanel/spendingsStyles';

import { CardComponent } from './additions/card/card';
import { badgeStyles } from './components/badge';
import { buttonStyles } from './components/button';
import { CheckboxStyles } from './components/checkbox';
import { FormLabel } from './components/formLabel';
import { inputStyles } from './components/input';
import { linkStyles } from './components/link';
import { progressStyles } from './components/progress';
import { sliderStyles } from './components/slider';
import { switchStyles } from './components/switch';
import { TextStyles } from './components/text';
import { textareaStyles } from './components/textarea';
import { Tooltip } from './components/tooltip';
import { breakpoints } from './foundations/breakpoints';
import { globalStyles } from './globalStyles';
import { tableStyles } from './components/table';
import { RadioStyles } from './components/radio';

export default extendTheme(
  { breakpoints },
  globalStyles,
  badgeStyles,
  buttonStyles,
  inputStyles,
  linkStyles,
  progressStyles,
  sliderStyles,
  switchStyles,
  textareaStyles,
  CardComponent,
  SideBarComponent,
  SpendingsComponent,
  CheckboxStyles,
  FormLabel,
  Tooltip,
  TextStyles,
  tableStyles,
  RadioStyles,
);

export interface CustomCardProps extends HTMLChakraProps<'div'>, ThemingProps { }
