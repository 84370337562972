import { Box, HStack, Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';

export const SkeletonSidebar = () => {
  return (
    <Box h="full" position="relative">
      <Skeleton
        endColor="grey.150"
        height="105px"
        startColor="grey.100"
        width="full"
      />
      <VStack alignItems="flex-start" mx={5} my="30px" spacing="20px">
        <HStack spacing="10px">
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="120px"
          />
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="100px"
          />
        </HStack>
        <HStack spacing="10px">
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="120px"
          />
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="60px"
          />
        </HStack>
        <HStack spacing="10px">
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="120px"
          />
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="80px"
          />
        </HStack>
        <HStack spacing="10px">
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="120px"
          />
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="120px"
          />
        </HStack>
      </VStack>
      <VStack
        alignItems="flex-start"
        bottom={0}
        height="105px"
        left={0}
        position="absolute"
        px={5}
        spacing={5}
        width="full">
        <HStack spacing="10px">
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="120px"
          />
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="120px"
          />
        </HStack>
        <HStack spacing="10px">
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="120px"
          />
          <Skeleton
            endColor="grey.150"
            height="20px"
            startColor="grey.100"
            width="80px"
          />
        </HStack>
      </VStack>
    </Box>
  );
};
