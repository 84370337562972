import { Box, Text, Flex, useColorModeValue } from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { contentfulClient } from '@app/api/contentful';
import Popup from 'components/Popup';
import { getLocale } from '@app/components/FormatNumber/utils';
import { ReactComponent as RequestsIcon } from '@app/icons/new-requests-icon.svg';
import { ReactComponent as OffersIcon } from '@app/icons/new-offers-icon.svg';
import { ReactComponent as OrdersIcon } from '@app/icons/new-orders-icon.svg';

import { HelpCategory } from './HelpCategory';
import { HelpSearch } from './HelpSearch';
import { Footer } from './components/Footer';

interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const HelpModal: FC<HelpModalProps> = ({ isOpen, onClose }) => {
  const { i18n, t } = useTranslation('forms');
  const navigate = useNavigate();
  const [helpSearch, setHelpSearch] = useState<string>('');
  const [categories, setCategories] = useState([]);
  const [id, setId] = useState<string>();

  const [searchParams] = useSearchParams(location.search);
  const categoryId = searchParams.get('categoryId');
  const node = searchParams.get('node');
  const modal = searchParams.get('modal');
  const [isLoading, setIsLoading] = useState(false);

  const activeIcon = useColorModeValue('primary.green', 'white');
  const inactiveColor = useColorModeValue('grey.300', 'secondaryGray.600');

  const getIcon = (name: string) => {
    switch (name) {
      case 'requests':
        return <RequestsIcon />;

      case 'offers':
        return <OffersIcon />;

      case 'orders':
        return <OrdersIcon />;
    }
  };

  const getCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const { items } = await contentfulClient.getEntries({
        limit: 100,
        locale: getLocale(i18n.resolvedLanguage),
        content_type: 'faqAgentApp',
        order: 'fields.sort',
      });

      setCategories(items);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [i18n.resolvedLanguage]);

  const onModalClose = () => {
    navigate(`?`);
    setHelpSearch('');
    onClose();
  };

  useEffect(() => {
    void getCategories();
    return () => {
      setHelpSearch('');
      setId('');
    };
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (categories) {
      const getId = (slug: string) => {
        const currId = categories?.find((x) => slug === x.fields?.slug)?.sys
          ?.id;
        setId(currId);
      };
      if (location.search && modal) {
        return getId('welcome');
      }
      switch (location.pathname) {
        case '/requests':
          return getId('requests');
        case '/offers':
          return getId('offers');
        case '/orders':
          return getId('orders');
        default:
          return getId('welcome');
      }
    }
  }, [location.pathname, categories, isOpen]);

  useEffect(() => {
    if (categoryId && node) {
      setId(categoryId);
    }
  }, [categoryId, node]);

  if (isLoading) {
    return null;
  }

  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="1037px"
      width={{ base: '100%', md: '1037px' }}
      onClose={onModalClose}>
      <Box
        borderRadius="30px 30px 0px 0px"
        overflow="hidden"
        position="relative"
        width={{ base: '100%', md: '1037px' }}>
        <Flex flexDirection={{ base: 'column', md: 'row' }} w="100%">
          <Flex
            borderColor="grey.300"
            borderRight="1px solid"
            display={{ base: 'none', md: 'flex' }}
            flex="0 0 267px"
            flexDirection="column"
            justifyContent="space-between"
            p="40px 30px">
            <Flex alignItems="left" flexDirection="column">
              <Text fontSize="20px" fontWeight={600} mb="60px" ml="20px">
                {t('trainingCenter')}
              </Text>
              <Flex flexDirection="column" gap="30px" w="100%">
                {categories?.map((item) => (
                  <Flex
                    key={item?.sys?.id}
                    as={Link}
                    cursor={'pointer'}
                    to="?"
                    onClick={() => setId(item?.sys?.id)}>
                    <Box
                      _groupHover={{
                        color: activeIcon,
                      }}
                      color={item?.sys?.id === id ? activeIcon : inactiveColor}
                      mx="10px"
                      transition="0.2s">
                      {getIcon(item?.fields?.slug)}
                    </Box>
                    <Text fontSize={16} fontWeight={600}>
                      {item?.fields?.name}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Footer />
          </Flex>
          <Box
            flex={1}
            height={{ base: 'full', md: '590px' }}
            mb={{ base: '0px', md: '0' }}
            overflow={{ base: 'auto', md: 'scroll' }}>
            {!helpSearch ? (
              <HelpCategory
                activeNode={node}
                categoriesId={id}
                onSetSearch={setHelpSearch}
              />
            ) : (
              <HelpSearch q={helpSearch} onSetSearch={setHelpSearch} />
            )}
          </Box>
        </Flex>
      </Box>
    </Popup>
  );
};
