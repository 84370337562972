export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  Long: { input: any; output: any; }
  ObjectId: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  _id: Scalars['ObjectId']['output'];
  activities: Maybe<Array<Maybe<Activity>>>;
  avatar: Maybe<AccountAvatar>;
  business: Maybe<Business>;
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  enabled: Maybe<Scalars['Boolean']['output']>;
  fcmToken: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  isAnonymous: Maybe<Scalars['Boolean']['output']>;
  isOnline: Maybe<Scalars['Boolean']['output']>;
  isSubmitted: Maybe<Scalars['Boolean']['output']>;
  language: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  lastOnlineDate: Maybe<Scalars['DateTime']['output']>;
  ownerId: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  role: Maybe<AccountRole>;
  settings: Maybe<AccountSetting>;
  status: Maybe<Scalars['String']['output']>;
  sub: Maybe<Scalars['String']['output']>;
  tags: Maybe<Array<Maybe<Tag>>>;
  travelCategories: Maybe<Array<Maybe<TravelCategory>>>;
  travelerCharacteristics: Maybe<Array<Maybe<TravelerCharacteristic>>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type AccountActivitiesRelationInput = {
  create: InputMaybe<Array<InputMaybe<ActivityInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type AccountAvatar = {
  __typename?: 'AccountAvatar';
  bucket: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
};

export type AccountAvatarInsertInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type AccountAvatarQueryInput = {
  AND: InputMaybe<Array<AccountAvatarQueryInput>>;
  OR: InputMaybe<Array<AccountAvatarQueryInput>>;
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_exists: InputMaybe<Scalars['Boolean']['input']>;
  bucket_gt: InputMaybe<Scalars['String']['input']>;
  bucket_gte: InputMaybe<Scalars['String']['input']>;
  bucket_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bucket_lt: InputMaybe<Scalars['String']['input']>;
  bucket_lte: InputMaybe<Scalars['String']['input']>;
  bucket_ne: InputMaybe<Scalars['String']['input']>;
  bucket_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key: InputMaybe<Scalars['String']['input']>;
  key_exists: InputMaybe<Scalars['Boolean']['input']>;
  key_gt: InputMaybe<Scalars['String']['input']>;
  key_gte: InputMaybe<Scalars['String']['input']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_lt: InputMaybe<Scalars['String']['input']>;
  key_lte: InputMaybe<Scalars['String']['input']>;
  key_ne: InputMaybe<Scalars['String']['input']>;
  key_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location: InputMaybe<Scalars['String']['input']>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
  location_gt: InputMaybe<Scalars['String']['input']>;
  location_gte: InputMaybe<Scalars['String']['input']>;
  location_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_lt: InputMaybe<Scalars['String']['input']>;
  location_lte: InputMaybe<Scalars['String']['input']>;
  location_ne: InputMaybe<Scalars['String']['input']>;
  location_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AccountAvatarUpdateInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_unset: InputMaybe<Scalars['Boolean']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  key_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountBusinessRelationInput = {
  create: InputMaybe<BusinessInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type AccountCountryRelationInput = {
  create: InputMaybe<CountryInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type AccountInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  activities: InputMaybe<AccountActivitiesRelationInput>;
  avatar: InputMaybe<AccountAvatarInsertInput>;
  business: InputMaybe<AccountBusinessRelationInput>;
  country: InputMaybe<AccountCountryRelationInput>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  fcmToken: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  fullName: InputMaybe<Scalars['String']['input']>;
  isAnonymous: InputMaybe<Scalars['Boolean']['input']>;
  isOnline: InputMaybe<Scalars['Boolean']['input']>;
  isSubmitted: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  lastOnlineDate: InputMaybe<Scalars['DateTime']['input']>;
  ownerId: Scalars['String']['input'];
  phone: InputMaybe<Scalars['String']['input']>;
  role: InputMaybe<AccountRole>;
  settings: InputMaybe<AccountSettingInsertInput>;
  status: InputMaybe<Scalars['String']['input']>;
  sub: InputMaybe<Scalars['String']['input']>;
  tags: InputMaybe<AccountTagsRelationInput>;
  travelCategories: InputMaybe<AccountTravelCategoriesRelationInput>;
  travelerCharacteristics: InputMaybe<AccountTravelerCharacteristicsRelationInput>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountQueryInput = {
  AND: InputMaybe<Array<AccountQueryInput>>;
  OR: InputMaybe<Array<AccountQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  activities: InputMaybe<Array<InputMaybe<ActivityQueryInput>>>;
  activities_exists: InputMaybe<Scalars['Boolean']['input']>;
  activities_in: InputMaybe<Array<InputMaybe<ActivityQueryInput>>>;
  activities_nin: InputMaybe<Array<InputMaybe<ActivityQueryInput>>>;
  avatar: InputMaybe<AccountAvatarQueryInput>;
  avatar_exists: InputMaybe<Scalars['Boolean']['input']>;
  business: InputMaybe<BusinessQueryInput>;
  business_exists: InputMaybe<Scalars['Boolean']['input']>;
  country: InputMaybe<CountryQueryInput>;
  country_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  description: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_gt: InputMaybe<Scalars['String']['input']>;
  description_gte: InputMaybe<Scalars['String']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_lt: InputMaybe<Scalars['String']['input']>;
  description_lte: InputMaybe<Scalars['String']['input']>;
  description_ne: InputMaybe<Scalars['String']['input']>;
  description_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email: InputMaybe<Scalars['String']['input']>;
  email_exists: InputMaybe<Scalars['Boolean']['input']>;
  email_gt: InputMaybe<Scalars['String']['input']>;
  email_gte: InputMaybe<Scalars['String']['input']>;
  email_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_lt: InputMaybe<Scalars['String']['input']>;
  email_lte: InputMaybe<Scalars['String']['input']>;
  email_ne: InputMaybe<Scalars['String']['input']>;
  email_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  enabled_exists: InputMaybe<Scalars['Boolean']['input']>;
  enabled_ne: InputMaybe<Scalars['Boolean']['input']>;
  fcmToken: InputMaybe<Scalars['String']['input']>;
  fcmToken_exists: InputMaybe<Scalars['Boolean']['input']>;
  fcmToken_gt: InputMaybe<Scalars['String']['input']>;
  fcmToken_gte: InputMaybe<Scalars['String']['input']>;
  fcmToken_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fcmToken_lt: InputMaybe<Scalars['String']['input']>;
  fcmToken_lte: InputMaybe<Scalars['String']['input']>;
  fcmToken_ne: InputMaybe<Scalars['String']['input']>;
  fcmToken_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName: InputMaybe<Scalars['String']['input']>;
  firstName_exists: InputMaybe<Scalars['Boolean']['input']>;
  firstName_gt: InputMaybe<Scalars['String']['input']>;
  firstName_gte: InputMaybe<Scalars['String']['input']>;
  firstName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName_lt: InputMaybe<Scalars['String']['input']>;
  firstName_lte: InputMaybe<Scalars['String']['input']>;
  firstName_ne: InputMaybe<Scalars['String']['input']>;
  firstName_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fullName: InputMaybe<Scalars['String']['input']>;
  fullName_exists: InputMaybe<Scalars['Boolean']['input']>;
  fullName_gt: InputMaybe<Scalars['String']['input']>;
  fullName_gte: InputMaybe<Scalars['String']['input']>;
  fullName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fullName_lt: InputMaybe<Scalars['String']['input']>;
  fullName_lte: InputMaybe<Scalars['String']['input']>;
  fullName_ne: InputMaybe<Scalars['String']['input']>;
  fullName_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isAnonymous: InputMaybe<Scalars['Boolean']['input']>;
  isAnonymous_exists: InputMaybe<Scalars['Boolean']['input']>;
  isAnonymous_ne: InputMaybe<Scalars['Boolean']['input']>;
  isOnline: InputMaybe<Scalars['Boolean']['input']>;
  isOnline_exists: InputMaybe<Scalars['Boolean']['input']>;
  isOnline_ne: InputMaybe<Scalars['Boolean']['input']>;
  isSubmitted: InputMaybe<Scalars['Boolean']['input']>;
  isSubmitted_exists: InputMaybe<Scalars['Boolean']['input']>;
  isSubmitted_ne: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Scalars['String']['input']>;
  language_exists: InputMaybe<Scalars['Boolean']['input']>;
  language_gt: InputMaybe<Scalars['String']['input']>;
  language_gte: InputMaybe<Scalars['String']['input']>;
  language_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language_lt: InputMaybe<Scalars['String']['input']>;
  language_lte: InputMaybe<Scalars['String']['input']>;
  language_ne: InputMaybe<Scalars['String']['input']>;
  language_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName: InputMaybe<Scalars['String']['input']>;
  lastName_exists: InputMaybe<Scalars['Boolean']['input']>;
  lastName_gt: InputMaybe<Scalars['String']['input']>;
  lastName_gte: InputMaybe<Scalars['String']['input']>;
  lastName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName_lt: InputMaybe<Scalars['String']['input']>;
  lastName_lte: InputMaybe<Scalars['String']['input']>;
  lastName_ne: InputMaybe<Scalars['String']['input']>;
  lastName_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastOnlineDate: InputMaybe<Scalars['DateTime']['input']>;
  lastOnlineDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineDate_gt: InputMaybe<Scalars['DateTime']['input']>;
  lastOnlineDate_gte: InputMaybe<Scalars['DateTime']['input']>;
  lastOnlineDate_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lastOnlineDate_lt: InputMaybe<Scalars['DateTime']['input']>;
  lastOnlineDate_lte: InputMaybe<Scalars['DateTime']['input']>;
  lastOnlineDate_ne: InputMaybe<Scalars['DateTime']['input']>;
  lastOnlineDate_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  ownerId_gt: InputMaybe<Scalars['String']['input']>;
  ownerId_gte: InputMaybe<Scalars['String']['input']>;
  ownerId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_lt: InputMaybe<Scalars['String']['input']>;
  ownerId_lte: InputMaybe<Scalars['String']['input']>;
  ownerId_ne: InputMaybe<Scalars['String']['input']>;
  ownerId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone: InputMaybe<Scalars['String']['input']>;
  phone_exists: InputMaybe<Scalars['Boolean']['input']>;
  phone_gt: InputMaybe<Scalars['String']['input']>;
  phone_gte: InputMaybe<Scalars['String']['input']>;
  phone_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_lt: InputMaybe<Scalars['String']['input']>;
  phone_lte: InputMaybe<Scalars['String']['input']>;
  phone_ne: InputMaybe<Scalars['String']['input']>;
  phone_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  role: InputMaybe<AccountRole>;
  role_exists: InputMaybe<Scalars['Boolean']['input']>;
  role_gt: InputMaybe<AccountRole>;
  role_gte: InputMaybe<AccountRole>;
  role_in: InputMaybe<Array<InputMaybe<AccountRole>>>;
  role_lt: InputMaybe<AccountRole>;
  role_lte: InputMaybe<AccountRole>;
  role_ne: InputMaybe<AccountRole>;
  role_nin: InputMaybe<Array<InputMaybe<AccountRole>>>;
  settings: InputMaybe<AccountSettingQueryInput>;
  settings_exists: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
  status_exists: InputMaybe<Scalars['Boolean']['input']>;
  status_gt: InputMaybe<Scalars['String']['input']>;
  status_gte: InputMaybe<Scalars['String']['input']>;
  status_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status_lt: InputMaybe<Scalars['String']['input']>;
  status_lte: InputMaybe<Scalars['String']['input']>;
  status_ne: InputMaybe<Scalars['String']['input']>;
  status_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sub: InputMaybe<Scalars['String']['input']>;
  sub_exists: InputMaybe<Scalars['Boolean']['input']>;
  sub_gt: InputMaybe<Scalars['String']['input']>;
  sub_gte: InputMaybe<Scalars['String']['input']>;
  sub_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sub_lt: InputMaybe<Scalars['String']['input']>;
  sub_lte: InputMaybe<Scalars['String']['input']>;
  sub_ne: InputMaybe<Scalars['String']['input']>;
  sub_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags: InputMaybe<Array<InputMaybe<TagQueryInput>>>;
  tags_exists: InputMaybe<Scalars['Boolean']['input']>;
  tags_in: InputMaybe<Array<InputMaybe<TagQueryInput>>>;
  tags_nin: InputMaybe<Array<InputMaybe<TagQueryInput>>>;
  travelCategories: InputMaybe<Array<InputMaybe<TravelCategoryQueryInput>>>;
  travelCategories_exists: InputMaybe<Scalars['Boolean']['input']>;
  travelCategories_in: InputMaybe<Array<InputMaybe<TravelCategoryQueryInput>>>;
  travelCategories_nin: InputMaybe<Array<InputMaybe<TravelCategoryQueryInput>>>;
  travelerCharacteristics: InputMaybe<Array<InputMaybe<TravelerCharacteristicQueryInput>>>;
  travelerCharacteristics_exists: InputMaybe<Scalars['Boolean']['input']>;
  travelerCharacteristics_in: InputMaybe<Array<InputMaybe<TravelerCharacteristicQueryInput>>>;
  travelerCharacteristics_nin: InputMaybe<Array<InputMaybe<TravelerCharacteristicQueryInput>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

export enum AccountRole {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  MANAGER = 'MANAGER'
}

export type AccountSetting = {
  __typename?: 'AccountSetting';
  countries: Maybe<Array<Maybe<Country>>>;
  currency: Maybe<Currency>;
  languages: Maybe<Array<Maybe<Language>>>;
  location: Maybe<AccountSettingLocation>;
};

export type AccountSettingCountriesRelationInput = {
  create: InputMaybe<Array<InputMaybe<CountryInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type AccountSettingCurrencyRelationInput = {
  create: InputMaybe<CurrencyInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type AccountSettingInsertInput = {
  countries: InputMaybe<AccountSettingCountriesRelationInput>;
  currency: InputMaybe<AccountSettingCurrencyRelationInput>;
  languages: InputMaybe<AccountSettingLanguagesRelationInput>;
  location: InputMaybe<AccountSettingLocationInsertInput>;
};

export type AccountSettingLanguagesRelationInput = {
  create: InputMaybe<Array<InputMaybe<LanguageInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type AccountSettingLocation = {
  __typename?: 'AccountSettingLocation';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type AccountSettingLocationInsertInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type AccountSettingLocationQueryInput = {
  AND: InputMaybe<Array<AccountSettingLocationQueryInput>>;
  OR: InputMaybe<Array<AccountSettingLocationQueryInput>>;
  latitude: InputMaybe<Scalars['Float']['input']>;
  latitude_exists: InputMaybe<Scalars['Boolean']['input']>;
  latitude_gt: InputMaybe<Scalars['Float']['input']>;
  latitude_gte: InputMaybe<Scalars['Float']['input']>;
  latitude_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  latitude_lt: InputMaybe<Scalars['Float']['input']>;
  latitude_lte: InputMaybe<Scalars['Float']['input']>;
  latitude_ne: InputMaybe<Scalars['Float']['input']>;
  latitude_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  longitude: InputMaybe<Scalars['Float']['input']>;
  longitude_exists: InputMaybe<Scalars['Boolean']['input']>;
  longitude_gt: InputMaybe<Scalars['Float']['input']>;
  longitude_gte: InputMaybe<Scalars['Float']['input']>;
  longitude_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  longitude_lt: InputMaybe<Scalars['Float']['input']>;
  longitude_lte: InputMaybe<Scalars['Float']['input']>;
  longitude_ne: InputMaybe<Scalars['Float']['input']>;
  longitude_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type AccountSettingLocationUpdateInput = {
  latitude: InputMaybe<Scalars['Float']['input']>;
  latitude_inc: InputMaybe<Scalars['Float']['input']>;
  latitude_unset: InputMaybe<Scalars['Boolean']['input']>;
  longitude: InputMaybe<Scalars['Float']['input']>;
  longitude_inc: InputMaybe<Scalars['Float']['input']>;
  longitude_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountSettingQueryInput = {
  AND: InputMaybe<Array<AccountSettingQueryInput>>;
  OR: InputMaybe<Array<AccountSettingQueryInput>>;
  countries: InputMaybe<Array<InputMaybe<CountryQueryInput>>>;
  countries_exists: InputMaybe<Scalars['Boolean']['input']>;
  countries_in: InputMaybe<Array<InputMaybe<CountryQueryInput>>>;
  countries_nin: InputMaybe<Array<InputMaybe<CountryQueryInput>>>;
  currency: InputMaybe<CurrencyQueryInput>;
  currency_exists: InputMaybe<Scalars['Boolean']['input']>;
  languages: InputMaybe<Array<InputMaybe<LanguageQueryInput>>>;
  languages_exists: InputMaybe<Scalars['Boolean']['input']>;
  languages_in: InputMaybe<Array<InputMaybe<LanguageQueryInput>>>;
  languages_nin: InputMaybe<Array<InputMaybe<LanguageQueryInput>>>;
  location: InputMaybe<AccountSettingLocationQueryInput>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountSettingUpdateInput = {
  countries: InputMaybe<AccountSettingCountriesRelationInput>;
  countries_unset: InputMaybe<Scalars['Boolean']['input']>;
  currency: InputMaybe<AccountSettingCurrencyRelationInput>;
  currency_unset: InputMaybe<Scalars['Boolean']['input']>;
  languages: InputMaybe<AccountSettingLanguagesRelationInput>;
  languages_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<AccountSettingLocationUpdateInput>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AccountSortByInput {
  BUSINESS_ASC = 'BUSINESS_ASC',
  BUSINESS_DESC = 'BUSINESS_DESC',
  COUNTRY_ASC = 'COUNTRY_ASC',
  COUNTRY_DESC = 'COUNTRY_DESC',
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  FCMTOKEN_ASC = 'FCMTOKEN_ASC',
  FCMTOKEN_DESC = 'FCMTOKEN_DESC',
  FIRSTNAME_ASC = 'FIRSTNAME_ASC',
  FIRSTNAME_DESC = 'FIRSTNAME_DESC',
  FULLNAME_ASC = 'FULLNAME_ASC',
  FULLNAME_DESC = 'FULLNAME_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  LASTNAME_ASC = 'LASTNAME_ASC',
  LASTNAME_DESC = 'LASTNAME_DESC',
  LASTONLINEDATE_ASC = 'LASTONLINEDATE_ASC',
  LASTONLINEDATE_DESC = 'LASTONLINEDATE_DESC',
  OWNERID_ASC = 'OWNERID_ASC',
  OWNERID_DESC = 'OWNERID_DESC',
  PHONE_ASC = 'PHONE_ASC',
  PHONE_DESC = 'PHONE_DESC',
  ROLE_ASC = 'ROLE_ASC',
  ROLE_DESC = 'ROLE_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  SUB_ASC = 'SUB_ASC',
  SUB_DESC = 'SUB_DESC',
  UPDATEDAT_ASC = 'UPDATEDAT_ASC',
  UPDATEDAT_DESC = 'UPDATEDAT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type AccountTagsRelationInput = {
  create: InputMaybe<Array<InputMaybe<TagInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type AccountTravelCategoriesRelationInput = {
  create: InputMaybe<Array<InputMaybe<TravelCategoryInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type AccountTravelerCharacteristicsRelationInput = {
  create: InputMaybe<Array<InputMaybe<TravelerCharacteristicInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type AccountUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  activities: InputMaybe<AccountActivitiesRelationInput>;
  activities_unset: InputMaybe<Scalars['Boolean']['input']>;
  avatar: InputMaybe<AccountAvatarUpdateInput>;
  avatar_unset: InputMaybe<Scalars['Boolean']['input']>;
  business: InputMaybe<AccountBusinessRelationInput>;
  business_unset: InputMaybe<Scalars['Boolean']['input']>;
  country: InputMaybe<AccountCountryRelationInput>;
  country_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_unset: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  email_unset: InputMaybe<Scalars['Boolean']['input']>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  enabled_unset: InputMaybe<Scalars['Boolean']['input']>;
  fcmToken: InputMaybe<Scalars['String']['input']>;
  fcmToken_unset: InputMaybe<Scalars['Boolean']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  firstName_unset: InputMaybe<Scalars['Boolean']['input']>;
  fullName: InputMaybe<Scalars['String']['input']>;
  fullName_unset: InputMaybe<Scalars['Boolean']['input']>;
  isAnonymous: InputMaybe<Scalars['Boolean']['input']>;
  isAnonymous_unset: InputMaybe<Scalars['Boolean']['input']>;
  isOnline: InputMaybe<Scalars['Boolean']['input']>;
  isOnline_unset: InputMaybe<Scalars['Boolean']['input']>;
  isSubmitted: InputMaybe<Scalars['Boolean']['input']>;
  isSubmitted_unset: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Scalars['String']['input']>;
  language_unset: InputMaybe<Scalars['Boolean']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  lastName_unset: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineDate: InputMaybe<Scalars['DateTime']['input']>;
  lastOnlineDate_unset: InputMaybe<Scalars['Boolean']['input']>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_unset: InputMaybe<Scalars['Boolean']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  phone_unset: InputMaybe<Scalars['Boolean']['input']>;
  role: InputMaybe<AccountRole>;
  role_unset: InputMaybe<Scalars['Boolean']['input']>;
  settings: InputMaybe<AccountSettingUpdateInput>;
  settings_unset: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
  status_unset: InputMaybe<Scalars['Boolean']['input']>;
  sub: InputMaybe<Scalars['String']['input']>;
  sub_unset: InputMaybe<Scalars['Boolean']['input']>;
  tags: InputMaybe<AccountTagsRelationInput>;
  tags_unset: InputMaybe<Scalars['Boolean']['input']>;
  travelCategories: InputMaybe<AccountTravelCategoriesRelationInput>;
  travelCategories_unset: InputMaybe<Scalars['Boolean']['input']>;
  travelerCharacteristics: InputMaybe<AccountTravelerCharacteristicsRelationInput>;
  travelerCharacteristics_unset: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivitiesDetail = {
  __typename?: 'ActivitiesDetail';
  _id: Maybe<Scalars['ObjectId']['output']>;
  emoji: Maybe<Scalars['String']['output']>;
  lang: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type ActivitiesDetailInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  emoji: InputMaybe<Scalars['String']['input']>;
  lang: InputMaybe<Scalars['String']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
};

export type ActivitiesDetailQueryInput = {
  AND: InputMaybe<Array<ActivitiesDetailQueryInput>>;
  OR: InputMaybe<Array<ActivitiesDetailQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  emoji: InputMaybe<Scalars['String']['input']>;
  emoji_exists: InputMaybe<Scalars['Boolean']['input']>;
  emoji_gt: InputMaybe<Scalars['String']['input']>;
  emoji_gte: InputMaybe<Scalars['String']['input']>;
  emoji_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  emoji_lt: InputMaybe<Scalars['String']['input']>;
  emoji_lte: InputMaybe<Scalars['String']['input']>;
  emoji_ne: InputMaybe<Scalars['String']['input']>;
  emoji_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lang: InputMaybe<Scalars['String']['input']>;
  lang_exists: InputMaybe<Scalars['Boolean']['input']>;
  lang_gt: InputMaybe<Scalars['String']['input']>;
  lang_gte: InputMaybe<Scalars['String']['input']>;
  lang_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lang_lt: InputMaybe<Scalars['String']['input']>;
  lang_lte: InputMaybe<Scalars['String']['input']>;
  lang_ne: InputMaybe<Scalars['String']['input']>;
  lang_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_gt: InputMaybe<Scalars['String']['input']>;
  title_gte: InputMaybe<Scalars['String']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_lt: InputMaybe<Scalars['String']['input']>;
  title_lte: InputMaybe<Scalars['String']['input']>;
  title_ne: InputMaybe<Scalars['String']['input']>;
  title_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActivitiesDetailUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  emoji: InputMaybe<Scalars['String']['input']>;
  emoji_unset: InputMaybe<Scalars['Boolean']['input']>;
  lang: InputMaybe<Scalars['String']['input']>;
  lang_unset: InputMaybe<Scalars['Boolean']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  title_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Activity = {
  __typename?: 'Activity';
  _id: Maybe<Scalars['ObjectId']['output']>;
  color: Maybe<Scalars['String']['output']>;
  details: Maybe<Array<Maybe<ActivitiesDetail>>>;
  index: Maybe<Scalars['Float']['output']>;
  parent: Maybe<Activity>;
};

export type ActivityInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  color: InputMaybe<Scalars['String']['input']>;
  details: InputMaybe<Array<InputMaybe<ActivitiesDetailInsertInput>>>;
  index: InputMaybe<Scalars['Float']['input']>;
  parent: InputMaybe<ActivityParentRelationInput>;
};

export type ActivityParentRelationInput = {
  create: InputMaybe<ActivityInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type ActivityQueryInput = {
  AND: InputMaybe<Array<ActivityQueryInput>>;
  OR: InputMaybe<Array<ActivityQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  color: InputMaybe<Scalars['String']['input']>;
  color_exists: InputMaybe<Scalars['Boolean']['input']>;
  color_gt: InputMaybe<Scalars['String']['input']>;
  color_gte: InputMaybe<Scalars['String']['input']>;
  color_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color_lt: InputMaybe<Scalars['String']['input']>;
  color_lte: InputMaybe<Scalars['String']['input']>;
  color_ne: InputMaybe<Scalars['String']['input']>;
  color_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  details: InputMaybe<Array<InputMaybe<ActivitiesDetailQueryInput>>>;
  details_exists: InputMaybe<Scalars['Boolean']['input']>;
  details_in: InputMaybe<Array<InputMaybe<ActivitiesDetailQueryInput>>>;
  details_nin: InputMaybe<Array<InputMaybe<ActivitiesDetailQueryInput>>>;
  index: InputMaybe<Scalars['Float']['input']>;
  index_exists: InputMaybe<Scalars['Boolean']['input']>;
  index_gt: InputMaybe<Scalars['Float']['input']>;
  index_gte: InputMaybe<Scalars['Float']['input']>;
  index_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  index_lt: InputMaybe<Scalars['Float']['input']>;
  index_lte: InputMaybe<Scalars['Float']['input']>;
  index_ne: InputMaybe<Scalars['Float']['input']>;
  index_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  parent: InputMaybe<ActivityQueryInput>;
  parent_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ActivitySortByInput {
  COLOR_ASC = 'COLOR_ASC',
  COLOR_DESC = 'COLOR_DESC',
  INDEX_ASC = 'INDEX_ASC',
  INDEX_DESC = 'INDEX_DESC',
  PARENT_ASC = 'PARENT_ASC',
  PARENT_DESC = 'PARENT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type ActivityUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  color: InputMaybe<Scalars['String']['input']>;
  color_unset: InputMaybe<Scalars['Boolean']['input']>;
  details: InputMaybe<Array<InputMaybe<ActivitiesDetailUpdateInput>>>;
  details_unset: InputMaybe<Scalars['Boolean']['input']>;
  index: InputMaybe<Scalars['Float']['input']>;
  index_inc: InputMaybe<Scalars['Float']['input']>;
  index_unset: InputMaybe<Scalars['Boolean']['input']>;
  parent: InputMaybe<ActivityParentRelationInput>;
  parent_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Business = {
  __typename?: 'Business';
  _id: Scalars['ObjectId']['output'];
  accounts: Maybe<Array<Maybe<Account>>>;
  address: Maybe<Scalars['String']['output']>;
  avatar: Maybe<BusinessFile>;
  city: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  country: Maybe<Country>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  docs: Maybe<Array<Maybe<BusinessDoc>>>;
  email: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  status: Maybe<BusinessStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  website: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  zip: Maybe<Scalars['String']['output']>;
};

export type BusinessAccountsRelationInput = {
  create: InputMaybe<Array<InputMaybe<AccountInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type BusinessCountryRelationInput = {
  create: InputMaybe<CountryInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type BusinessDoc = {
  __typename?: 'BusinessDoc';
  bucket: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
};

export type BusinessDocInsertInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type BusinessDocQueryInput = {
  AND: InputMaybe<Array<BusinessDocQueryInput>>;
  OR: InputMaybe<Array<BusinessDocQueryInput>>;
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_exists: InputMaybe<Scalars['Boolean']['input']>;
  bucket_gt: InputMaybe<Scalars['String']['input']>;
  bucket_gte: InputMaybe<Scalars['String']['input']>;
  bucket_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bucket_lt: InputMaybe<Scalars['String']['input']>;
  bucket_lte: InputMaybe<Scalars['String']['input']>;
  bucket_ne: InputMaybe<Scalars['String']['input']>;
  bucket_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key: InputMaybe<Scalars['String']['input']>;
  key_exists: InputMaybe<Scalars['Boolean']['input']>;
  key_gt: InputMaybe<Scalars['String']['input']>;
  key_gte: InputMaybe<Scalars['String']['input']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_lt: InputMaybe<Scalars['String']['input']>;
  key_lte: InputMaybe<Scalars['String']['input']>;
  key_ne: InputMaybe<Scalars['String']['input']>;
  key_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location: InputMaybe<Scalars['String']['input']>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
  location_gt: InputMaybe<Scalars['String']['input']>;
  location_gte: InputMaybe<Scalars['String']['input']>;
  location_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_lt: InputMaybe<Scalars['String']['input']>;
  location_lte: InputMaybe<Scalars['String']['input']>;
  location_ne: InputMaybe<Scalars['String']['input']>;
  location_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BusinessDocUpdateInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_unset: InputMaybe<Scalars['Boolean']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  key_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type BusinessFile = {
  __typename?: 'BusinessFile';
  bucket: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
};

export type BusinessFileInsertInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type BusinessFileQueryInput = {
  AND: InputMaybe<Array<BusinessFileQueryInput>>;
  OR: InputMaybe<Array<BusinessFileQueryInput>>;
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_exists: InputMaybe<Scalars['Boolean']['input']>;
  bucket_gt: InputMaybe<Scalars['String']['input']>;
  bucket_gte: InputMaybe<Scalars['String']['input']>;
  bucket_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bucket_lt: InputMaybe<Scalars['String']['input']>;
  bucket_lte: InputMaybe<Scalars['String']['input']>;
  bucket_ne: InputMaybe<Scalars['String']['input']>;
  bucket_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key: InputMaybe<Scalars['String']['input']>;
  key_exists: InputMaybe<Scalars['Boolean']['input']>;
  key_gt: InputMaybe<Scalars['String']['input']>;
  key_gte: InputMaybe<Scalars['String']['input']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_lt: InputMaybe<Scalars['String']['input']>;
  key_lte: InputMaybe<Scalars['String']['input']>;
  key_ne: InputMaybe<Scalars['String']['input']>;
  key_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location: InputMaybe<Scalars['String']['input']>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
  location_gt: InputMaybe<Scalars['String']['input']>;
  location_gte: InputMaybe<Scalars['String']['input']>;
  location_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_lt: InputMaybe<Scalars['String']['input']>;
  location_lte: InputMaybe<Scalars['String']['input']>;
  location_ne: InputMaybe<Scalars['String']['input']>;
  location_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BusinessFileUpdateInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_unset: InputMaybe<Scalars['Boolean']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  key_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type BusinessInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  accounts: InputMaybe<BusinessAccountsRelationInput>;
  address: InputMaybe<Scalars['String']['input']>;
  avatar: InputMaybe<BusinessFileInsertInput>;
  city: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  country: InputMaybe<BusinessCountryRelationInput>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  docs: InputMaybe<Array<InputMaybe<BusinessDocInsertInput>>>;
  email: InputMaybe<Scalars['String']['input']>;
  ownerId: Scalars['String']['input'];
  phone: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<BusinessStatus>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  website: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zip: InputMaybe<Scalars['String']['input']>;
};

export type BusinessQueryInput = {
  AND: InputMaybe<Array<BusinessQueryInput>>;
  OR: InputMaybe<Array<BusinessQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  accounts: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  accounts_exists: InputMaybe<Scalars['Boolean']['input']>;
  accounts_in: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  accounts_nin: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  address: InputMaybe<Scalars['String']['input']>;
  address_exists: InputMaybe<Scalars['Boolean']['input']>;
  address_gt: InputMaybe<Scalars['String']['input']>;
  address_gte: InputMaybe<Scalars['String']['input']>;
  address_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  address_lt: InputMaybe<Scalars['String']['input']>;
  address_lte: InputMaybe<Scalars['String']['input']>;
  address_ne: InputMaybe<Scalars['String']['input']>;
  address_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  avatar: InputMaybe<BusinessFileQueryInput>;
  avatar_exists: InputMaybe<Scalars['Boolean']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  city_exists: InputMaybe<Scalars['Boolean']['input']>;
  city_gt: InputMaybe<Scalars['String']['input']>;
  city_gte: InputMaybe<Scalars['String']['input']>;
  city_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city_lt: InputMaybe<Scalars['String']['input']>;
  city_lte: InputMaybe<Scalars['String']['input']>;
  city_ne: InputMaybe<Scalars['String']['input']>;
  city_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyName: InputMaybe<Scalars['String']['input']>;
  companyName_exists: InputMaybe<Scalars['Boolean']['input']>;
  companyName_gt: InputMaybe<Scalars['String']['input']>;
  companyName_gte: InputMaybe<Scalars['String']['input']>;
  companyName_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyName_lt: InputMaybe<Scalars['String']['input']>;
  companyName_lte: InputMaybe<Scalars['String']['input']>;
  companyName_ne: InputMaybe<Scalars['String']['input']>;
  companyName_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country: InputMaybe<CountryQueryInput>;
  country_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  description: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_gt: InputMaybe<Scalars['String']['input']>;
  description_gte: InputMaybe<Scalars['String']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_lt: InputMaybe<Scalars['String']['input']>;
  description_lte: InputMaybe<Scalars['String']['input']>;
  description_ne: InputMaybe<Scalars['String']['input']>;
  description_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  docs: InputMaybe<Array<InputMaybe<BusinessDocQueryInput>>>;
  docs_exists: InputMaybe<Scalars['Boolean']['input']>;
  docs_in: InputMaybe<Array<InputMaybe<BusinessDocQueryInput>>>;
  docs_nin: InputMaybe<Array<InputMaybe<BusinessDocQueryInput>>>;
  email: InputMaybe<Scalars['String']['input']>;
  email_exists: InputMaybe<Scalars['Boolean']['input']>;
  email_gt: InputMaybe<Scalars['String']['input']>;
  email_gte: InputMaybe<Scalars['String']['input']>;
  email_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_lt: InputMaybe<Scalars['String']['input']>;
  email_lte: InputMaybe<Scalars['String']['input']>;
  email_ne: InputMaybe<Scalars['String']['input']>;
  email_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  ownerId_gt: InputMaybe<Scalars['String']['input']>;
  ownerId_gte: InputMaybe<Scalars['String']['input']>;
  ownerId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_lt: InputMaybe<Scalars['String']['input']>;
  ownerId_lte: InputMaybe<Scalars['String']['input']>;
  ownerId_ne: InputMaybe<Scalars['String']['input']>;
  ownerId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone: InputMaybe<Scalars['String']['input']>;
  phone_exists: InputMaybe<Scalars['Boolean']['input']>;
  phone_gt: InputMaybe<Scalars['String']['input']>;
  phone_gte: InputMaybe<Scalars['String']['input']>;
  phone_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_lt: InputMaybe<Scalars['String']['input']>;
  phone_lte: InputMaybe<Scalars['String']['input']>;
  phone_ne: InputMaybe<Scalars['String']['input']>;
  phone_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status: InputMaybe<BusinessStatus>;
  status_exists: InputMaybe<Scalars['Boolean']['input']>;
  status_gt: InputMaybe<BusinessStatus>;
  status_gte: InputMaybe<BusinessStatus>;
  status_in: InputMaybe<Array<InputMaybe<BusinessStatus>>>;
  status_lt: InputMaybe<BusinessStatus>;
  status_lte: InputMaybe<BusinessStatus>;
  status_ne: InputMaybe<BusinessStatus>;
  status_nin: InputMaybe<Array<InputMaybe<BusinessStatus>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  website: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website_exists: InputMaybe<Scalars['Boolean']['input']>;
  website_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zip: InputMaybe<Scalars['String']['input']>;
  zip_exists: InputMaybe<Scalars['Boolean']['input']>;
  zip_gt: InputMaybe<Scalars['String']['input']>;
  zip_gte: InputMaybe<Scalars['String']['input']>;
  zip_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zip_lt: InputMaybe<Scalars['String']['input']>;
  zip_lte: InputMaybe<Scalars['String']['input']>;
  zip_ne: InputMaybe<Scalars['String']['input']>;
  zip_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum BusinessSortByInput {
  ADDRESS_ASC = 'ADDRESS_ASC',
  ADDRESS_DESC = 'ADDRESS_DESC',
  CITY_ASC = 'CITY_ASC',
  CITY_DESC = 'CITY_DESC',
  COMPANYNAME_ASC = 'COMPANYNAME_ASC',
  COMPANYNAME_DESC = 'COMPANYNAME_DESC',
  COUNTRY_ASC = 'COUNTRY_ASC',
  COUNTRY_DESC = 'COUNTRY_DESC',
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  OWNERID_ASC = 'OWNERID_ASC',
  OWNERID_DESC = 'OWNERID_DESC',
  PHONE_ASC = 'PHONE_ASC',
  PHONE_DESC = 'PHONE_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  UPDATEDAT_ASC = 'UPDATEDAT_ASC',
  UPDATEDAT_DESC = 'UPDATEDAT_DESC',
  ZIP_ASC = 'ZIP_ASC',
  ZIP_DESC = 'ZIP_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export enum BusinessStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REVIEW = 'REVIEW'
}

export type BusinessUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  accounts: InputMaybe<BusinessAccountsRelationInput>;
  accounts_unset: InputMaybe<Scalars['Boolean']['input']>;
  address: InputMaybe<Scalars['String']['input']>;
  address_unset: InputMaybe<Scalars['Boolean']['input']>;
  avatar: InputMaybe<BusinessFileUpdateInput>;
  avatar_unset: InputMaybe<Scalars['Boolean']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  city_unset: InputMaybe<Scalars['Boolean']['input']>;
  companyName: InputMaybe<Scalars['String']['input']>;
  companyName_unset: InputMaybe<Scalars['Boolean']['input']>;
  country: InputMaybe<BusinessCountryRelationInput>;
  country_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_unset: InputMaybe<Scalars['Boolean']['input']>;
  docs: InputMaybe<Array<InputMaybe<BusinessDocUpdateInput>>>;
  docs_unset: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  email_unset: InputMaybe<Scalars['Boolean']['input']>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_unset: InputMaybe<Scalars['Boolean']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  phone_unset: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<BusinessStatus>;
  status_unset: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  website: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  website_unset: InputMaybe<Scalars['Boolean']['input']>;
  zip: InputMaybe<Scalars['String']['input']>;
  zip_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryFile = {
  __typename?: 'CategoryFile';
  bucket: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
};

export type CategoryFileInsertInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type CategoryFileQueryInput = {
  AND: InputMaybe<Array<CategoryFileQueryInput>>;
  OR: InputMaybe<Array<CategoryFileQueryInput>>;
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_exists: InputMaybe<Scalars['Boolean']['input']>;
  bucket_gt: InputMaybe<Scalars['String']['input']>;
  bucket_gte: InputMaybe<Scalars['String']['input']>;
  bucket_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bucket_lt: InputMaybe<Scalars['String']['input']>;
  bucket_lte: InputMaybe<Scalars['String']['input']>;
  bucket_ne: InputMaybe<Scalars['String']['input']>;
  bucket_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key: InputMaybe<Scalars['String']['input']>;
  key_exists: InputMaybe<Scalars['Boolean']['input']>;
  key_gt: InputMaybe<Scalars['String']['input']>;
  key_gte: InputMaybe<Scalars['String']['input']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_lt: InputMaybe<Scalars['String']['input']>;
  key_lte: InputMaybe<Scalars['String']['input']>;
  key_ne: InputMaybe<Scalars['String']['input']>;
  key_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location: InputMaybe<Scalars['String']['input']>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
  location_gt: InputMaybe<Scalars['String']['input']>;
  location_gte: InputMaybe<Scalars['String']['input']>;
  location_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_lt: InputMaybe<Scalars['String']['input']>;
  location_lte: InputMaybe<Scalars['String']['input']>;
  location_ne: InputMaybe<Scalars['String']['input']>;
  location_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CategoryFileUpdateInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_unset: InputMaybe<Scalars['Boolean']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  key_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Chat = {
  __typename?: 'Chat';
  _id: Scalars['ObjectId']['output'];
  archivedBy: Maybe<Array<Maybe<Scalars['ObjectId']['output']>>>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  lastMessage: Maybe<Message>;
  ownerId: Maybe<Account>;
  participants: Maybe<Array<Maybe<Account>>>;
  participantsExcludingSelf: Maybe<Array<Maybe<Account>>>;
  pinnedMessages: Maybe<Array<Maybe<Message>>>;
  replyTo: Maybe<Message>;
  status: ChatStatus;
  title: Maybe<Scalars['String']['output']>;
  titles: Maybe<Array<Maybe<ChatTitle>>>;
  unreadCount: Maybe<Scalars['Int']['output']>;
  unreadCounts: Maybe<Array<Maybe<ChatUnreadCount>>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ChatInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  archivedBy: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  lastMessage: InputMaybe<ChatLastMessageRelationInput>;
  ownerId: InputMaybe<ChatOwnerIdRelationInput>;
  participants: InputMaybe<ChatParticipantsRelationInput>;
  participantsExcludingSelf: InputMaybe<ChatParticipantsExcludingSelfRelationInput>;
  pinnedMessages: InputMaybe<ChatPinnedMessagesRelationInput>;
  replyTo: InputMaybe<ChatReplyToRelationInput>;
  status: ChatStatus;
  title: InputMaybe<Scalars['String']['input']>;
  titles: InputMaybe<Array<InputMaybe<ChatTitleInsertInput>>>;
  unreadCount: InputMaybe<Scalars['Int']['input']>;
  unreadCounts: InputMaybe<Array<InputMaybe<ChatUnreadCountInsertInput>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
};

export type ChatLastMessageRelationInput = {
  create: InputMaybe<MessageInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type ChatOwnerIdRelationInput = {
  create: InputMaybe<AccountInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type ChatParticipantsExcludingSelfRelationInput = {
  create: InputMaybe<Array<InputMaybe<AccountInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type ChatParticipantsRelationInput = {
  create: InputMaybe<Array<InputMaybe<AccountInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type ChatPinnedMessagesRelationInput = {
  create: InputMaybe<Array<InputMaybe<MessageInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type ChatQueryInput = {
  AND: InputMaybe<Array<ChatQueryInput>>;
  OR: InputMaybe<Array<ChatQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  archivedBy: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  archivedBy_exists: InputMaybe<Scalars['Boolean']['input']>;
  archivedBy_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  archivedBy_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lastMessage: InputMaybe<MessageQueryInput>;
  lastMessage_exists: InputMaybe<Scalars['Boolean']['input']>;
  ownerId: InputMaybe<AccountQueryInput>;
  ownerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  participants: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  participantsExcludingSelf: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  participantsExcludingSelf_exists: InputMaybe<Scalars['Boolean']['input']>;
  participantsExcludingSelf_in: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  participantsExcludingSelf_nin: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  participants_exists: InputMaybe<Scalars['Boolean']['input']>;
  participants_in: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  participants_nin: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  pinnedMessages: InputMaybe<Array<InputMaybe<MessageQueryInput>>>;
  pinnedMessages_exists: InputMaybe<Scalars['Boolean']['input']>;
  pinnedMessages_in: InputMaybe<Array<InputMaybe<MessageQueryInput>>>;
  pinnedMessages_nin: InputMaybe<Array<InputMaybe<MessageQueryInput>>>;
  replyTo: InputMaybe<MessageQueryInput>;
  replyTo_exists: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<ChatStatus>;
  status_exists: InputMaybe<Scalars['Boolean']['input']>;
  status_gt: InputMaybe<ChatStatus>;
  status_gte: InputMaybe<ChatStatus>;
  status_in: InputMaybe<Array<InputMaybe<ChatStatus>>>;
  status_lt: InputMaybe<ChatStatus>;
  status_lte: InputMaybe<ChatStatus>;
  status_ne: InputMaybe<ChatStatus>;
  status_nin: InputMaybe<Array<InputMaybe<ChatStatus>>>;
  title: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_gt: InputMaybe<Scalars['String']['input']>;
  title_gte: InputMaybe<Scalars['String']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_lt: InputMaybe<Scalars['String']['input']>;
  title_lte: InputMaybe<Scalars['String']['input']>;
  title_ne: InputMaybe<Scalars['String']['input']>;
  title_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titles: InputMaybe<Array<InputMaybe<ChatTitleQueryInput>>>;
  titles_exists: InputMaybe<Scalars['Boolean']['input']>;
  titles_in: InputMaybe<Array<InputMaybe<ChatTitleQueryInput>>>;
  titles_nin: InputMaybe<Array<InputMaybe<ChatTitleQueryInput>>>;
  unreadCount: InputMaybe<Scalars['Int']['input']>;
  unreadCount_exists: InputMaybe<Scalars['Boolean']['input']>;
  unreadCount_gt: InputMaybe<Scalars['Int']['input']>;
  unreadCount_gte: InputMaybe<Scalars['Int']['input']>;
  unreadCount_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  unreadCount_lt: InputMaybe<Scalars['Int']['input']>;
  unreadCount_lte: InputMaybe<Scalars['Int']['input']>;
  unreadCount_ne: InputMaybe<Scalars['Int']['input']>;
  unreadCount_nin: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  unreadCounts: InputMaybe<Array<InputMaybe<ChatUnreadCountQueryInput>>>;
  unreadCounts_exists: InputMaybe<Scalars['Boolean']['input']>;
  unreadCounts_in: InputMaybe<Array<InputMaybe<ChatUnreadCountQueryInput>>>;
  unreadCounts_nin: InputMaybe<Array<InputMaybe<ChatUnreadCountQueryInput>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

export type ChatReadAllInput = {
  ids: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isAllSelected: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChatReplyToRelationInput = {
  create: InputMaybe<MessageInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export enum ChatSortByInput {
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  LASTMESSAGE_ASC = 'LASTMESSAGE_ASC',
  LASTMESSAGE_DESC = 'LASTMESSAGE_DESC',
  OWNERID_ASC = 'OWNERID_ASC',
  OWNERID_DESC = 'OWNERID_DESC',
  REPLYTO_ASC = 'REPLYTO_ASC',
  REPLYTO_DESC = 'REPLYTO_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  UNREADCOUNT_ASC = 'UNREADCOUNT_ASC',
  UNREADCOUNT_DESC = 'UNREADCOUNT_DESC',
  UPDATEDAT_ASC = 'UPDATEDAT_ASC',
  UPDATEDAT_DESC = 'UPDATEDAT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export enum ChatStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE'
}

export type ChatStatusInput = {
  ids: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isAllSelected: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<ChatStatusInputStatus>;
};

export enum ChatStatusInputStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE'
}

export type ChatTitle = {
  __typename?: 'ChatTitle';
  participantId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ChatTitleInsertInput = {
  participantId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ChatTitleQueryInput = {
  AND: InputMaybe<Array<ChatTitleQueryInput>>;
  OR: InputMaybe<Array<ChatTitleQueryInput>>;
  participantId: InputMaybe<Scalars['String']['input']>;
  participantId_exists: InputMaybe<Scalars['Boolean']['input']>;
  participantId_gt: InputMaybe<Scalars['String']['input']>;
  participantId_gte: InputMaybe<Scalars['String']['input']>;
  participantId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  participantId_lt: InputMaybe<Scalars['String']['input']>;
  participantId_lte: InputMaybe<Scalars['String']['input']>;
  participantId_ne: InputMaybe<Scalars['String']['input']>;
  participantId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_gt: InputMaybe<Scalars['String']['input']>;
  title_gte: InputMaybe<Scalars['String']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_lt: InputMaybe<Scalars['String']['input']>;
  title_lte: InputMaybe<Scalars['String']['input']>;
  title_ne: InputMaybe<Scalars['String']['input']>;
  title_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ChatTitleUpdateInput = {
  participantId: InputMaybe<Scalars['String']['input']>;
  participantId_unset: InputMaybe<Scalars['Boolean']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  title_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChatUnreadCount = {
  __typename?: 'ChatUnreadCount';
  count: Scalars['Int']['output'];
  participantId: Scalars['String']['output'];
};

export type ChatUnreadCountInsertInput = {
  count: Scalars['Int']['input'];
  participantId: Scalars['String']['input'];
};

export type ChatUnreadCountQueryInput = {
  AND: InputMaybe<Array<ChatUnreadCountQueryInput>>;
  OR: InputMaybe<Array<ChatUnreadCountQueryInput>>;
  count: InputMaybe<Scalars['Int']['input']>;
  count_exists: InputMaybe<Scalars['Boolean']['input']>;
  count_gt: InputMaybe<Scalars['Int']['input']>;
  count_gte: InputMaybe<Scalars['Int']['input']>;
  count_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  count_lt: InputMaybe<Scalars['Int']['input']>;
  count_lte: InputMaybe<Scalars['Int']['input']>;
  count_ne: InputMaybe<Scalars['Int']['input']>;
  count_nin: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  participantId: InputMaybe<Scalars['String']['input']>;
  participantId_exists: InputMaybe<Scalars['Boolean']['input']>;
  participantId_gt: InputMaybe<Scalars['String']['input']>;
  participantId_gte: InputMaybe<Scalars['String']['input']>;
  participantId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  participantId_lt: InputMaybe<Scalars['String']['input']>;
  participantId_lte: InputMaybe<Scalars['String']['input']>;
  participantId_ne: InputMaybe<Scalars['String']['input']>;
  participantId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ChatUnreadCountUpdateInput = {
  count: InputMaybe<Scalars['Int']['input']>;
  count_inc: InputMaybe<Scalars['Int']['input']>;
  count_unset: InputMaybe<Scalars['Boolean']['input']>;
  participantId: InputMaybe<Scalars['String']['input']>;
  participantId_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChatUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  archivedBy: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  archivedBy_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  lastMessage: InputMaybe<ChatLastMessageRelationInput>;
  lastMessage_unset: InputMaybe<Scalars['Boolean']['input']>;
  ownerId: InputMaybe<ChatOwnerIdRelationInput>;
  ownerId_unset: InputMaybe<Scalars['Boolean']['input']>;
  participants: InputMaybe<ChatParticipantsRelationInput>;
  participantsExcludingSelf: InputMaybe<ChatParticipantsExcludingSelfRelationInput>;
  participantsExcludingSelf_unset: InputMaybe<Scalars['Boolean']['input']>;
  participants_unset: InputMaybe<Scalars['Boolean']['input']>;
  pinnedMessages: InputMaybe<ChatPinnedMessagesRelationInput>;
  pinnedMessages_unset: InputMaybe<Scalars['Boolean']['input']>;
  replyTo: InputMaybe<ChatReplyToRelationInput>;
  replyTo_unset: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<ChatStatus>;
  status_unset: InputMaybe<Scalars['Boolean']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  title_unset: InputMaybe<Scalars['Boolean']['input']>;
  titles: InputMaybe<Array<InputMaybe<ChatTitleUpdateInput>>>;
  titles_unset: InputMaybe<Scalars['Boolean']['input']>;
  unreadCount: InputMaybe<Scalars['Int']['input']>;
  unreadCount_inc: InputMaybe<Scalars['Int']['input']>;
  unreadCount_unset: InputMaybe<Scalars['Boolean']['input']>;
  unreadCounts: InputMaybe<Array<InputMaybe<ChatUnreadCountUpdateInput>>>;
  unreadCounts_unset: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Country = {
  __typename?: 'Country';
  _id: Scalars['ObjectId']['output'];
  country: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phoneCode: Maybe<Scalars['String']['output']>;
  translations: Maybe<CountryTranslation>;
};

export type CountryInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  latitude: InputMaybe<Scalars['Float']['input']>;
  longitude: InputMaybe<Scalars['Float']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  phoneCode: InputMaybe<Scalars['String']['input']>;
  translations: InputMaybe<CountryTranslationInsertInput>;
};

export type CountryQueryInput = {
  AND: InputMaybe<Array<CountryQueryInput>>;
  OR: InputMaybe<Array<CountryQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  country: InputMaybe<Scalars['String']['input']>;
  country_exists: InputMaybe<Scalars['Boolean']['input']>;
  country_gt: InputMaybe<Scalars['String']['input']>;
  country_gte: InputMaybe<Scalars['String']['input']>;
  country_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country_lt: InputMaybe<Scalars['String']['input']>;
  country_lte: InputMaybe<Scalars['String']['input']>;
  country_ne: InputMaybe<Scalars['String']['input']>;
  country_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Scalars['Float']['input']>;
  latitude_exists: InputMaybe<Scalars['Boolean']['input']>;
  latitude_gt: InputMaybe<Scalars['Float']['input']>;
  latitude_gte: InputMaybe<Scalars['Float']['input']>;
  latitude_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  latitude_lt: InputMaybe<Scalars['Float']['input']>;
  latitude_lte: InputMaybe<Scalars['Float']['input']>;
  latitude_ne: InputMaybe<Scalars['Float']['input']>;
  latitude_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  longitude: InputMaybe<Scalars['Float']['input']>;
  longitude_exists: InputMaybe<Scalars['Boolean']['input']>;
  longitude_gt: InputMaybe<Scalars['Float']['input']>;
  longitude_gte: InputMaybe<Scalars['Float']['input']>;
  longitude_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  longitude_lt: InputMaybe<Scalars['Float']['input']>;
  longitude_lte: InputMaybe<Scalars['Float']['input']>;
  longitude_ne: InputMaybe<Scalars['Float']['input']>;
  longitude_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_gt: InputMaybe<Scalars['String']['input']>;
  name_gte: InputMaybe<Scalars['String']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_lt: InputMaybe<Scalars['String']['input']>;
  name_lte: InputMaybe<Scalars['String']['input']>;
  name_ne: InputMaybe<Scalars['String']['input']>;
  name_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneCode: InputMaybe<Scalars['String']['input']>;
  phoneCode_exists: InputMaybe<Scalars['Boolean']['input']>;
  phoneCode_gt: InputMaybe<Scalars['String']['input']>;
  phoneCode_gte: InputMaybe<Scalars['String']['input']>;
  phoneCode_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneCode_lt: InputMaybe<Scalars['String']['input']>;
  phoneCode_lte: InputMaybe<Scalars['String']['input']>;
  phoneCode_ne: InputMaybe<Scalars['String']['input']>;
  phoneCode_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translations: InputMaybe<CountryTranslationQueryInput>;
  translations_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CountrySortByInput {
  COUNTRY_ASC = 'COUNTRY_ASC',
  COUNTRY_DESC = 'COUNTRY_DESC',
  LATITUDE_ASC = 'LATITUDE_ASC',
  LATITUDE_DESC = 'LATITUDE_DESC',
  LONGITUDE_ASC = 'LONGITUDE_ASC',
  LONGITUDE_DESC = 'LONGITUDE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  PHONECODE_ASC = 'PHONECODE_ASC',
  PHONECODE_DESC = 'PHONECODE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type CountryTranslation = {
  __typename?: 'CountryTranslation';
  en: Maybe<Scalars['String']['output']>;
  uk: Maybe<Scalars['String']['output']>;
};

export type CountryTranslationInsertInput = {
  en: InputMaybe<Scalars['String']['input']>;
  uk: InputMaybe<Scalars['String']['input']>;
};

export type CountryTranslationQueryInput = {
  AND: InputMaybe<Array<CountryTranslationQueryInput>>;
  OR: InputMaybe<Array<CountryTranslationQueryInput>>;
  en: InputMaybe<Scalars['String']['input']>;
  en_exists: InputMaybe<Scalars['Boolean']['input']>;
  en_gt: InputMaybe<Scalars['String']['input']>;
  en_gte: InputMaybe<Scalars['String']['input']>;
  en_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  en_lt: InputMaybe<Scalars['String']['input']>;
  en_lte: InputMaybe<Scalars['String']['input']>;
  en_ne: InputMaybe<Scalars['String']['input']>;
  en_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uk: InputMaybe<Scalars['String']['input']>;
  uk_exists: InputMaybe<Scalars['Boolean']['input']>;
  uk_gt: InputMaybe<Scalars['String']['input']>;
  uk_gte: InputMaybe<Scalars['String']['input']>;
  uk_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uk_lt: InputMaybe<Scalars['String']['input']>;
  uk_lte: InputMaybe<Scalars['String']['input']>;
  uk_ne: InputMaybe<Scalars['String']['input']>;
  uk_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CountryTranslationUpdateInput = {
  en: InputMaybe<Scalars['String']['input']>;
  en_unset: InputMaybe<Scalars['Boolean']['input']>;
  uk: InputMaybe<Scalars['String']['input']>;
  uk_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type CountryUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  country_unset: InputMaybe<Scalars['Boolean']['input']>;
  latitude: InputMaybe<Scalars['Float']['input']>;
  latitude_inc: InputMaybe<Scalars['Float']['input']>;
  latitude_unset: InputMaybe<Scalars['Boolean']['input']>;
  longitude: InputMaybe<Scalars['Float']['input']>;
  longitude_inc: InputMaybe<Scalars['Float']['input']>;
  longitude_unset: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_unset: InputMaybe<Scalars['Boolean']['input']>;
  phoneCode: InputMaybe<Scalars['String']['input']>;
  phoneCode_unset: InputMaybe<Scalars['Boolean']['input']>;
  translations: InputMaybe<CountryTranslationUpdateInput>;
  translations_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateAccountInput = {
  activities: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  isAnonymous: InputMaybe<Scalars['Boolean']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  settings: InputMaybe<CreateAccountInputSetting>;
  tags: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  travelCategories: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  travelerCharacteristics: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateAccountInputSetting = {
  location: InputMaybe<CreateAccountInputSettingLocation>;
};

export type CreateAccountInputSettingLocation = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type CreateBusinessInput = {
  address: InputMaybe<Scalars['String']['input']>;
  avatar: InputMaybe<CreateBusinessInputAvatar>;
  city: InputMaybe<Scalars['String']['input']>;
  companyName: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  docs: InputMaybe<Array<InputMaybe<CreateBusinessInputDoc>>>;
  email: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  website: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zip: InputMaybe<Scalars['String']['input']>;
};

export type CreateBusinessInputAvatar = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type CreateBusinessInputDoc = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type CreateManagerAccountInput = {
  avatar: InputMaybe<CreateManagerAccountInputAvatar>;
  firstName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
};

export type CreateManagerAccountInputAvatar = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type CreateOfferInput = {
  activities: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  days: InputMaybe<Scalars['String']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  isPublic: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<CreateOfferInputLocation>;
  offerAddition: InputMaybe<Array<InputMaybe<CreateOfferInputOfferAddition>>>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  person: InputMaybe<Scalars['Float']['input']>;
  photo: InputMaybe<Array<InputMaybe<CreateOfferInputPhoto>>>;
  price: InputMaybe<CreateOfferPrice>;
  priceEstimateType: InputMaybe<CreateOfferInputPriceEstimateType>;
  requestId: InputMaybe<Scalars['String']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<CreateOfferInputStatus>;
  tags: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
  travelCategory: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<CreateOfferInputType>;
  video: InputMaybe<CreateOfferInputVideo>;
};

export type CreateOfferInputLocation = {
  coordinates: CreateOfferInputLocationCoordinate;
  description: Scalars['String']['input'];
  place_id: Scalars['String']['input'];
};

export type CreateOfferInputLocationCoordinate = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<CreateOfferInputLocationCoordinateType>;
};

export enum CreateOfferInputLocationCoordinateType {
  POINT = 'POINT'
}

export type CreateOfferInputOfferAddition = {
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  days: InputMaybe<Scalars['String']['input']>;
  price: InputMaybe<CreateOfferInputOfferAdditionPrice>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateOfferInputOfferAdditionPrice = {
  currency: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

export type CreateOfferInputPhoto = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export enum CreateOfferInputPriceEstimateType {
  ESTIMATE = 'ESTIMATE',
  FINAL = 'FINAL'
}

export enum CreateOfferInputStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  HOT = 'HOT'
}

export enum CreateOfferInputType {
  PERSON = 'PERSON',
  TOUR = 'TOUR'
}

export type CreateOfferInputVideo = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type CreateOfferPayload = {
  __typename?: 'CreateOfferPayload';
  id: Scalars['String']['output'];
};

export type CreateOfferPrice = {
  currency: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type CreateOrderInput = {
  additionalNotes: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['String']['input'];
  offerPriceId: InputMaybe<Scalars['String']['input']>;
  persons: CreateOrderInputPerson;
  status: CreateOrderInputStatus;
};

export type CreateOrderInputPerson = {
  adults: Scalars['Int']['input'];
  children: InputMaybe<Scalars['Int']['input']>;
  infant: InputMaybe<Scalars['Int']['input']>;
};

export enum CreateOrderInputStatus {
  CANCELLED = 'CANCELLED',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  CONFIRMED = 'CONFIRMED',
  PENDING_AGENT = 'PENDING_AGENT',
  PENDING_TRAVELER = 'PENDING_TRAVELER'
}

export type CreateRequestInput = {
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  childrenAge: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  currency: InputMaybe<Scalars['String']['input']>;
  date: InputMaybe<Scalars['DateTime']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  location: InputMaybe<CreateRequestInputLocation>;
  locationStart: InputMaybe<CreateRequestInputLocationStart>;
  nights: InputMaybe<Scalars['Float']['input']>;
  periodDays: InputMaybe<Scalars['Float']['input']>;
  price: InputMaybe<Scalars['Float']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<CreateRequestInputStatus>;
};

export type CreateRequestInputLocation = {
  coordinates: CreateRequestInputLocationCoordinate;
  description: Scalars['String']['input'];
  place_id: Scalars['String']['input'];
};

export type CreateRequestInputLocationCoordinate = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<CreateRequestInputLocationCoordinateType>;
};

export enum CreateRequestInputLocationCoordinateType {
  POINT = 'POINT'
}

export type CreateRequestInputLocationStart = {
  coordinates: CreateRequestInputLocationStartCoordinate;
  description: Scalars['String']['input'];
  place_id: Scalars['String']['input'];
};

export type CreateRequestInputLocationStartCoordinate = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<CreateRequestInputLocationStartCoordinateType>;
};

export enum CreateRequestInputLocationStartCoordinateType {
  POINT = 'POINT'
}

export enum CreateRequestInputStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED'
}

export type Currency = {
  __typename?: 'Currency';
  _id: Scalars['ObjectId']['output'];
  code: Maybe<Scalars['String']['output']>;
  decimal_digits: Maybe<Scalars['Float']['output']>;
  enabled: Maybe<Scalars['Boolean']['output']>;
  name: Maybe<Scalars['String']['output']>;
  name_plural: Maybe<Scalars['String']['output']>;
  rate: Maybe<Scalars['Float']['output']>;
  rounding: Maybe<Scalars['Float']['output']>;
  symbol: Maybe<Scalars['String']['output']>;
  symbol_native: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type CurrencyInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  code: InputMaybe<Scalars['String']['input']>;
  decimal_digits: InputMaybe<Scalars['Float']['input']>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_plural: InputMaybe<Scalars['String']['input']>;
  rate: InputMaybe<Scalars['Float']['input']>;
  rounding: InputMaybe<Scalars['Float']['input']>;
  symbol: InputMaybe<Scalars['String']['input']>;
  symbol_native: InputMaybe<Scalars['String']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
};

export type CurrencyQueryInput = {
  AND: InputMaybe<Array<CurrencyQueryInput>>;
  OR: InputMaybe<Array<CurrencyQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  code: InputMaybe<Scalars['String']['input']>;
  code_exists: InputMaybe<Scalars['Boolean']['input']>;
  code_gt: InputMaybe<Scalars['String']['input']>;
  code_gte: InputMaybe<Scalars['String']['input']>;
  code_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code_lt: InputMaybe<Scalars['String']['input']>;
  code_lte: InputMaybe<Scalars['String']['input']>;
  code_ne: InputMaybe<Scalars['String']['input']>;
  code_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_digits: InputMaybe<Scalars['Float']['input']>;
  decimal_digits_exists: InputMaybe<Scalars['Boolean']['input']>;
  decimal_digits_gt: InputMaybe<Scalars['Float']['input']>;
  decimal_digits_gte: InputMaybe<Scalars['Float']['input']>;
  decimal_digits_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  decimal_digits_lt: InputMaybe<Scalars['Float']['input']>;
  decimal_digits_lte: InputMaybe<Scalars['Float']['input']>;
  decimal_digits_ne: InputMaybe<Scalars['Float']['input']>;
  decimal_digits_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  enabled_exists: InputMaybe<Scalars['Boolean']['input']>;
  enabled_ne: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_gt: InputMaybe<Scalars['String']['input']>;
  name_gte: InputMaybe<Scalars['String']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_lt: InputMaybe<Scalars['String']['input']>;
  name_lte: InputMaybe<Scalars['String']['input']>;
  name_ne: InputMaybe<Scalars['String']['input']>;
  name_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_plural: InputMaybe<Scalars['String']['input']>;
  name_plural_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_plural_gt: InputMaybe<Scalars['String']['input']>;
  name_plural_gte: InputMaybe<Scalars['String']['input']>;
  name_plural_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_plural_lt: InputMaybe<Scalars['String']['input']>;
  name_plural_lte: InputMaybe<Scalars['String']['input']>;
  name_plural_ne: InputMaybe<Scalars['String']['input']>;
  name_plural_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rate: InputMaybe<Scalars['Float']['input']>;
  rate_exists: InputMaybe<Scalars['Boolean']['input']>;
  rate_gt: InputMaybe<Scalars['Float']['input']>;
  rate_gte: InputMaybe<Scalars['Float']['input']>;
  rate_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  rate_lt: InputMaybe<Scalars['Float']['input']>;
  rate_lte: InputMaybe<Scalars['Float']['input']>;
  rate_ne: InputMaybe<Scalars['Float']['input']>;
  rate_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  rounding: InputMaybe<Scalars['Float']['input']>;
  rounding_exists: InputMaybe<Scalars['Boolean']['input']>;
  rounding_gt: InputMaybe<Scalars['Float']['input']>;
  rounding_gte: InputMaybe<Scalars['Float']['input']>;
  rounding_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  rounding_lt: InputMaybe<Scalars['Float']['input']>;
  rounding_lte: InputMaybe<Scalars['Float']['input']>;
  rounding_ne: InputMaybe<Scalars['Float']['input']>;
  rounding_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  symbol: InputMaybe<Scalars['String']['input']>;
  symbol_exists: InputMaybe<Scalars['Boolean']['input']>;
  symbol_gt: InputMaybe<Scalars['String']['input']>;
  symbol_gte: InputMaybe<Scalars['String']['input']>;
  symbol_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_lt: InputMaybe<Scalars['String']['input']>;
  symbol_lte: InputMaybe<Scalars['String']['input']>;
  symbol_native: InputMaybe<Scalars['String']['input']>;
  symbol_native_exists: InputMaybe<Scalars['Boolean']['input']>;
  symbol_native_gt: InputMaybe<Scalars['String']['input']>;
  symbol_native_gte: InputMaybe<Scalars['String']['input']>;
  symbol_native_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_native_lt: InputMaybe<Scalars['String']['input']>;
  symbol_native_lte: InputMaybe<Scalars['String']['input']>;
  symbol_native_ne: InputMaybe<Scalars['String']['input']>;
  symbol_native_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  symbol_ne: InputMaybe<Scalars['String']['input']>;
  symbol_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

export enum CurrencySortByInput {
  CODE_ASC = 'CODE_ASC',
  CODE_DESC = 'CODE_DESC',
  DECIMAL_DIGITS_ASC = 'DECIMAL_DIGITS_ASC',
  DECIMAL_DIGITS_DESC = 'DECIMAL_DIGITS_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NAME_PLURAL_ASC = 'NAME_PLURAL_ASC',
  NAME_PLURAL_DESC = 'NAME_PLURAL_DESC',
  RATE_ASC = 'RATE_ASC',
  RATE_DESC = 'RATE_DESC',
  ROUNDING_ASC = 'ROUNDING_ASC',
  ROUNDING_DESC = 'ROUNDING_DESC',
  SYMBOL_ASC = 'SYMBOL_ASC',
  SYMBOL_DESC = 'SYMBOL_DESC',
  SYMBOL_NATIVE_ASC = 'SYMBOL_NATIVE_ASC',
  SYMBOL_NATIVE_DESC = 'SYMBOL_NATIVE_DESC',
  UPDATEDAT_ASC = 'UPDATEDAT_ASC',
  UPDATEDAT_DESC = 'UPDATEDAT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type CurrencyUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  code: InputMaybe<Scalars['String']['input']>;
  code_unset: InputMaybe<Scalars['Boolean']['input']>;
  decimal_digits: InputMaybe<Scalars['Float']['input']>;
  decimal_digits_inc: InputMaybe<Scalars['Float']['input']>;
  decimal_digits_unset: InputMaybe<Scalars['Boolean']['input']>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  enabled_unset: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_plural: InputMaybe<Scalars['String']['input']>;
  name_plural_unset: InputMaybe<Scalars['Boolean']['input']>;
  name_unset: InputMaybe<Scalars['Boolean']['input']>;
  rate: InputMaybe<Scalars['Float']['input']>;
  rate_inc: InputMaybe<Scalars['Float']['input']>;
  rate_unset: InputMaybe<Scalars['Boolean']['input']>;
  rounding: InputMaybe<Scalars['Float']['input']>;
  rounding_inc: InputMaybe<Scalars['Float']['input']>;
  rounding_unset: InputMaybe<Scalars['Boolean']['input']>;
  symbol: InputMaybe<Scalars['String']['input']>;
  symbol_native: InputMaybe<Scalars['String']['input']>;
  symbol_native_unset: InputMaybe<Scalars['Boolean']['input']>;
  symbol_unset: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type DefaultPayload = {
  __typename?: 'DefaultPayload';
  status: Scalars['String']['output'];
};

export type DeleteManyPayload = {
  __typename?: 'DeleteManyPayload';
  deletedCount: Scalars['Int']['output'];
};

export type FindAccountsInput = {
  filter: InputMaybe<FindAccountsInputFilter>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextCreatedAt: InputMaybe<Scalars['String']['input']>;
  nextId: InputMaybe<Scalars['String']['input']>;
};

export type FindAccountsInputFilter = {
  role: InputMaybe<Scalars['String']['input']>;
};

export type FindAccountsPayload = {
  __typename?: 'FindAccountsPayload';
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  items: Maybe<Array<Maybe<Account>>>;
  nextCreatedAt: Maybe<Scalars['String']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  statusCounts: Maybe<Array<Maybe<StatusCount>>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type FindActivitiesInput = {
  lang: InputMaybe<Scalars['String']['input']>;
};

export type FindActivitiesPayload = {
  __typename?: 'FindActivitiesPayload';
  items: Maybe<Array<Maybe<Activity>>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type FindAgentsInput = {
  filter: InputMaybe<FindAgentsInputFilter>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextCreatedAt: InputMaybe<Scalars['String']['input']>;
  nextId: InputMaybe<Scalars['String']['input']>;
};

export type FindAgentsInputFilter = {
  status: InputMaybe<FindAgentsInputFilterStatus>;
};

export enum FindAgentsInputFilterStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REVIEW = 'REVIEW'
}

export type FindAgentsPayload = {
  __typename?: 'FindAgentsPayload';
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  items: Maybe<Array<Maybe<Business>>>;
  nextCreatedAt: Maybe<Scalars['String']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  statusCounts: Maybe<Array<Maybe<StatusCount>>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type FindChatsInput = {
  filter: InputMaybe<FindChatsInputFilter>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextCreatedAt: InputMaybe<Scalars['String']['input']>;
  nextId: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<FindChatsInputSort>;
};

export type FindChatsInputFilter = {
  isUnread: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<FindChatsInputFilterStatus>;
};

export enum FindChatsInputFilterStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE'
}

export type FindChatsInputSort = {
  updatedAt: InputMaybe<Scalars['Int']['input']>;
};

export type FindChatsPayload = {
  __typename?: 'FindChatsPayload';
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  items: Maybe<Array<Maybe<Chat>>>;
  nextCreatedAt: Maybe<Scalars['String']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Maybe<Scalars['Int']['output']>;
};

export type FindMessagesInput = {
  filter: FindMessagesInputFilter;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextCreatedAt: InputMaybe<Scalars['String']['input']>;
  nextId: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<FindMessagesInputSort>;
};

export type FindMessagesInputFilter = {
  chatId: Scalars['String']['input'];
  createdAt: InputMaybe<Scalars['String']['input']>;
};

export type FindMessagesInputSort = {
  createdAt: InputMaybe<Scalars['Int']['input']>;
};

export type FindMessagesPayload = {
  __typename?: 'FindMessagesPayload';
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  items: Maybe<Array<Maybe<Message>>>;
  nextCreatedAt: Maybe<Scalars['String']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Maybe<Scalars['Int']['output']>;
};

export type FindNotificationsInput = {
  limit: InputMaybe<Scalars['Int']['input']>;
  nextCreatedAt: InputMaybe<Scalars['String']['input']>;
  nextId: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<FindNotificationsInputSort>;
};

export type FindNotificationsInputSort = {
  createdAt: InputMaybe<Scalars['Int']['input']>;
};

export type FindNotificationsPayload = {
  __typename?: 'FindNotificationsPayload';
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  items: Maybe<Array<Maybe<Notification>>>;
  nextCreatedAt: Maybe<Scalars['String']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Maybe<Scalars['Int']['output']>;
  unreadByEntityType: Maybe<FindNotificationsPayloadUnreadByEntityType>;
  unreadCount: Maybe<Scalars['Int']['output']>;
};

export type FindNotificationsPayloadUnreadByEntityType = {
  __typename?: 'FindNotificationsPayloadUnreadByEntityType';
  CHAT: Maybe<Scalars['Int']['output']>;
  OFFER: Maybe<Scalars['Int']['output']>;
  ORDER: Maybe<Scalars['Int']['output']>;
  REQUEST: Maybe<Scalars['Int']['output']>;
};

export type FindOfferPayload = {
  __typename?: 'FindOfferPayload';
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  items: Maybe<Array<Maybe<Offer>>>;
  nextCreatedAt: Maybe<Scalars['String']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  statusCounts: Maybe<Array<Maybe<StatusCount>>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type FindOffersClientInput = {
  filter: InputMaybe<FindOffersClientInputFilter>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextCreatedAt: InputMaybe<Scalars['String']['input']>;
  nextId: InputMaybe<Scalars['String']['input']>;
};

export type FindOffersClientInputFilter = {
  activities: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  excludeIds: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isFavourite: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: InputMaybe<Scalars['Boolean']['input']>;
  isRandom: InputMaybe<Scalars['Boolean']['input']>;
  locations: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  owner: InputMaybe<Scalars['String']['input']>;
  price: InputMaybe<FindOffersClientInputFilterPrice>;
  requestId: InputMaybe<Scalars['String']['input']>;
  startDate: InputMaybe<FindOffersClientInputFilterStartDate>;
  status: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  travelCategories: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FindOffersClientInputFilterPrice = {
  from: InputMaybe<Scalars['Float']['input']>;
  to: InputMaybe<Scalars['Float']['input']>;
};

export type FindOffersClientInputFilterStartDate = {
  from: InputMaybe<Scalars['String']['input']>;
  to: InputMaybe<Scalars['String']['input']>;
};

export type FindOffersClientPayload = {
  __typename?: 'FindOffersClientPayload';
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  items: Maybe<Array<Maybe<Offer>>>;
  nextCreatedAt: Maybe<Scalars['String']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  statusCounts: Maybe<Array<Maybe<StatusCount>>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type FindOffersInput = {
  filter: InputMaybe<FindOffersInputFilter>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextCreatedAt: InputMaybe<Scalars['String']['input']>;
  nextId: InputMaybe<Scalars['String']['input']>;
};

export type FindOffersInputFilter = {
  activities: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  excludeIds: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isFavourite: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: InputMaybe<Scalars['Boolean']['input']>;
  isRandom: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<FindOffersInputFilterLocation>;
  locations: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  owner: InputMaybe<Scalars['String']['input']>;
  price: InputMaybe<FindOffersInputFilterPrice>;
  requestId: InputMaybe<Scalars['String']['input']>;
  startDate: InputMaybe<FindOffersInputFilterStartDate>;
  status: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  travelCategories: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FindOffersInputFilterLocation = {
  latitude: InputMaybe<Scalars['Float']['input']>;
  longitude: InputMaybe<Scalars['Float']['input']>;
  radius: InputMaybe<Scalars['Float']['input']>;
};

export type FindOffersInputFilterPrice = {
  from: InputMaybe<Scalars['Float']['input']>;
  to: InputMaybe<Scalars['Float']['input']>;
};

export type FindOffersInputFilterStartDate = {
  from: InputMaybe<Scalars['String']['input']>;
  to: InputMaybe<Scalars['String']['input']>;
};

export type FindOrdersInput = {
  filter: InputMaybe<FindOrdersInputFilter>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextCreatedAt: InputMaybe<Scalars['String']['input']>;
  nextId: InputMaybe<Scalars['String']['input']>;
};

export type FindOrdersInputFilter = {
  condition: InputMaybe<FindOrdersInputFilterCondition>;
  endAt: InputMaybe<Scalars['String']['input']>;
  orderNumber: InputMaybe<Scalars['Float']['input']>;
  price: InputMaybe<FindOrdersInputFilterPrice>;
  startAt: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum FindOrdersInputFilterCondition {
  ACTIVE = 'ACTIVE',
  DECLINED = 'DECLINED',
  END = 'END'
}

export type FindOrdersInputFilterPrice = {
  from: InputMaybe<Scalars['Float']['input']>;
  to: InputMaybe<Scalars['Float']['input']>;
};

export type FindOrdersPayload = {
  __typename?: 'FindOrdersPayload';
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  items: Maybe<Array<Maybe<Order>>>;
  nextCreatedAt: Maybe<Scalars['String']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  statusCounts: Maybe<Array<Maybe<StatusCount>>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type FindRequestInput = {
  filter: InputMaybe<FindRequestInputFilter>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextCreatedAt: InputMaybe<Scalars['String']['input']>;
  nextId: InputMaybe<Scalars['String']['input']>;
};

export type FindRequestInputFilter = {
  activities: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  dates: InputMaybe<FindRequestInputFilterDate>;
  isNew: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Scalars['String']['input']>;
  locations: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  price: InputMaybe<FindRequestInputFilterPrice>;
  startDate: InputMaybe<FindRequestInputFilterStartDate>;
  status: InputMaybe<FindRequestInputFilterStatus>;
};

export type FindRequestInputFilterDate = {
  from: InputMaybe<Scalars['String']['input']>;
  to: InputMaybe<Scalars['String']['input']>;
};

export type FindRequestInputFilterPrice = {
  from: InputMaybe<Scalars['Float']['input']>;
  to: InputMaybe<Scalars['Float']['input']>;
};

export type FindRequestInputFilterStartDate = {
  month: InputMaybe<Scalars['Float']['input']>;
  year: InputMaybe<Scalars['Float']['input']>;
};

export enum FindRequestInputFilterStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED'
}

export type FindRequestPayload = {
  __typename?: 'FindRequestPayload';
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  items: Maybe<Array<Maybe<Request>>>;
  nextCreatedAt: Maybe<Scalars['String']['output']>;
  nextId: Maybe<Scalars['String']['output']>;
  total: Maybe<Scalars['Int']['output']>;
  totalNew: Maybe<Scalars['Int']['output']>;
};

export type FindTravelerCharacteristicsPayload = {
  __typename?: 'FindTravelerCharacteristicsPayload';
  items: Maybe<Array<Maybe<TravelerCharacteristic>>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type GetChatInput = {
  chatId: InputMaybe<Scalars['String']['input']>;
};

export type GetChatPayload = {
  __typename?: 'GetChatPayload';
  chatId: Maybe<Scalars['String']['output']>;
  isNew: Maybe<Scalars['Boolean']['output']>;
};

export type GetChatStatusInput = {
  accountId: InputMaybe<Scalars['String']['input']>;
};

export type GetClientCountersPayload = {
  __typename?: 'GetClientCountersPayload';
  totalUnreadChats: Maybe<Scalars['Int']['output']>;
};

export type GetCountersPayload = {
  __typename?: 'GetCountersPayload';
  totalUnreadChats: Maybe<Scalars['Int']['output']>;
  totalUnreadOrders: Maybe<Scalars['Int']['output']>;
  totalUnreadRequests: Maybe<Scalars['Int']['output']>;
};

export type GetOfferInput = {
  id: Scalars['String']['input'];
};

export type GetOrderInput = {
  id: Scalars['String']['input'];
};

export type InsertManyPayload = {
  __typename?: 'InsertManyPayload';
  insertedIds: Array<Maybe<Scalars['ObjectId']['output']>>;
};

export type InsertTemplateInput = {
  name: Scalars['String']['input'];
  options: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Language = {
  __typename?: 'Language';
  _id: Scalars['ObjectId']['output'];
  code: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type LanguageInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  code: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
};

export type LanguageQueryInput = {
  AND: InputMaybe<Array<LanguageQueryInput>>;
  OR: InputMaybe<Array<LanguageQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  code: InputMaybe<Scalars['String']['input']>;
  code_exists: InputMaybe<Scalars['Boolean']['input']>;
  code_gt: InputMaybe<Scalars['String']['input']>;
  code_gte: InputMaybe<Scalars['String']['input']>;
  code_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code_lt: InputMaybe<Scalars['String']['input']>;
  code_lte: InputMaybe<Scalars['String']['input']>;
  code_ne: InputMaybe<Scalars['String']['input']>;
  code_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_gt: InputMaybe<Scalars['String']['input']>;
  name_gte: InputMaybe<Scalars['String']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_lt: InputMaybe<Scalars['String']['input']>;
  name_lte: InputMaybe<Scalars['String']['input']>;
  name_ne: InputMaybe<Scalars['String']['input']>;
  name_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum LanguageSortByInput {
  CODE_ASC = 'CODE_ASC',
  CODE_DESC = 'CODE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type LanguageUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  code: InputMaybe<Scalars['String']['input']>;
  code_unset: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Message = {
  __typename?: 'Message';
  _id: Scalars['ObjectId']['output'];
  attachments: Maybe<Array<Maybe<MessageAttachment>>>;
  chatId: Maybe<Scalars['String']['output']>;
  content: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  ownerId: Maybe<Account>;
  participants: Maybe<Array<Maybe<Account>>>;
  reference: Maybe<MessageReference>;
  replyTo: Maybe<Message>;
  status: Maybe<MessageStatus>;
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  filename: Scalars['String']['output'];
  type: MessageAttachmentType;
  url: Scalars['String']['output'];
};

export type MessageAttachmentInsertInput = {
  filename: Scalars['String']['input'];
  type: MessageAttachmentType;
  url: Scalars['String']['input'];
};

export type MessageAttachmentQueryInput = {
  AND: InputMaybe<Array<MessageAttachmentQueryInput>>;
  OR: InputMaybe<Array<MessageAttachmentQueryInput>>;
  filename: InputMaybe<Scalars['String']['input']>;
  filename_exists: InputMaybe<Scalars['Boolean']['input']>;
  filename_gt: InputMaybe<Scalars['String']['input']>;
  filename_gte: InputMaybe<Scalars['String']['input']>;
  filename_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename_lt: InputMaybe<Scalars['String']['input']>;
  filename_lte: InputMaybe<Scalars['String']['input']>;
  filename_ne: InputMaybe<Scalars['String']['input']>;
  filename_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<MessageAttachmentType>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_gt: InputMaybe<MessageAttachmentType>;
  type_gte: InputMaybe<MessageAttachmentType>;
  type_in: InputMaybe<Array<InputMaybe<MessageAttachmentType>>>;
  type_lt: InputMaybe<MessageAttachmentType>;
  type_lte: InputMaybe<MessageAttachmentType>;
  type_ne: InputMaybe<MessageAttachmentType>;
  type_nin: InputMaybe<Array<InputMaybe<MessageAttachmentType>>>;
  url: InputMaybe<Scalars['String']['input']>;
  url_exists: InputMaybe<Scalars['Boolean']['input']>;
  url_gt: InputMaybe<Scalars['String']['input']>;
  url_gte: InputMaybe<Scalars['String']['input']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_lt: InputMaybe<Scalars['String']['input']>;
  url_lte: InputMaybe<Scalars['String']['input']>;
  url_ne: InputMaybe<Scalars['String']['input']>;
  url_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum MessageAttachmentType {
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  OTHER = 'OTHER',
  VIDEO = 'VIDEO'
}

export type MessageAttachmentUpdateInput = {
  filename: InputMaybe<Scalars['String']['input']>;
  filename_unset: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<MessageAttachmentType>;
  type_unset: InputMaybe<Scalars['Boolean']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
  url_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type MessageInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  attachments: InputMaybe<Array<InputMaybe<MessageAttachmentInsertInput>>>;
  chatId: InputMaybe<Scalars['String']['input']>;
  content: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  ownerId: InputMaybe<MessageOwnerIdRelationInput>;
  participants: InputMaybe<MessageParticipantsRelationInput>;
  reference: InputMaybe<MessageReferenceInsertInput>;
  replyTo: InputMaybe<MessageReplyToRelationInput>;
  status: InputMaybe<MessageStatus>;
};

export type MessageOwnerIdRelationInput = {
  create: InputMaybe<AccountInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type MessageParticipantsRelationInput = {
  create: InputMaybe<Array<InputMaybe<AccountInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type MessageQueryInput = {
  AND: InputMaybe<Array<MessageQueryInput>>;
  OR: InputMaybe<Array<MessageQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  attachments: InputMaybe<Array<InputMaybe<MessageAttachmentQueryInput>>>;
  attachments_exists: InputMaybe<Scalars['Boolean']['input']>;
  attachments_in: InputMaybe<Array<InputMaybe<MessageAttachmentQueryInput>>>;
  attachments_nin: InputMaybe<Array<InputMaybe<MessageAttachmentQueryInput>>>;
  chatId: InputMaybe<Scalars['String']['input']>;
  chatId_exists: InputMaybe<Scalars['Boolean']['input']>;
  chatId_gt: InputMaybe<Scalars['String']['input']>;
  chatId_gte: InputMaybe<Scalars['String']['input']>;
  chatId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  chatId_lt: InputMaybe<Scalars['String']['input']>;
  chatId_lte: InputMaybe<Scalars['String']['input']>;
  chatId_ne: InputMaybe<Scalars['String']['input']>;
  chatId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content: InputMaybe<Scalars['String']['input']>;
  content_exists: InputMaybe<Scalars['Boolean']['input']>;
  content_gt: InputMaybe<Scalars['String']['input']>;
  content_gte: InputMaybe<Scalars['String']['input']>;
  content_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_lt: InputMaybe<Scalars['String']['input']>;
  content_lte: InputMaybe<Scalars['String']['input']>;
  content_ne: InputMaybe<Scalars['String']['input']>;
  content_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  ownerId: InputMaybe<AccountQueryInput>;
  ownerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  participants: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  participants_exists: InputMaybe<Scalars['Boolean']['input']>;
  participants_in: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  participants_nin: InputMaybe<Array<InputMaybe<AccountQueryInput>>>;
  reference: InputMaybe<MessageReferenceQueryInput>;
  reference_exists: InputMaybe<Scalars['Boolean']['input']>;
  replyTo: InputMaybe<MessageQueryInput>;
  replyTo_exists: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<MessageStatus>;
  status_exists: InputMaybe<Scalars['Boolean']['input']>;
  status_gt: InputMaybe<MessageStatus>;
  status_gte: InputMaybe<MessageStatus>;
  status_in: InputMaybe<Array<InputMaybe<MessageStatus>>>;
  status_lt: InputMaybe<MessageStatus>;
  status_lte: InputMaybe<MessageStatus>;
  status_ne: InputMaybe<MessageStatus>;
  status_nin: InputMaybe<Array<InputMaybe<MessageStatus>>>;
};

export type MessageReference = {
  __typename?: 'MessageReference';
  offerId: Maybe<Offer>;
  orderId: Maybe<Order>;
  requestId: Maybe<Request>;
};

export type MessageReferenceInsertInput = {
  offerId: InputMaybe<MessageReferenceOfferIdRelationInput>;
  orderId: InputMaybe<MessageReferenceOrderIdRelationInput>;
  requestId: InputMaybe<MessageReferenceRequestIdRelationInput>;
};

export type MessageReferenceOfferIdRelationInput = {
  create: InputMaybe<OfferInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type MessageReferenceOrderIdRelationInput = {
  create: InputMaybe<OrderInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type MessageReferenceQueryInput = {
  AND: InputMaybe<Array<MessageReferenceQueryInput>>;
  OR: InputMaybe<Array<MessageReferenceQueryInput>>;
  offerId: InputMaybe<OfferQueryInput>;
  offerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  orderId: InputMaybe<OrderQueryInput>;
  orderId_exists: InputMaybe<Scalars['Boolean']['input']>;
  requestId: InputMaybe<RequestQueryInput>;
  requestId_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type MessageReferenceRequestIdRelationInput = {
  create: InputMaybe<RequestInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type MessageReferenceUpdateInput = {
  offerId: InputMaybe<MessageReferenceOfferIdRelationInput>;
  offerId_unset: InputMaybe<Scalars['Boolean']['input']>;
  orderId: InputMaybe<MessageReferenceOrderIdRelationInput>;
  orderId_unset: InputMaybe<Scalars['Boolean']['input']>;
  requestId: InputMaybe<MessageReferenceRequestIdRelationInput>;
  requestId_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type MessageReplyToRelationInput = {
  create: InputMaybe<MessageInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export enum MessageSortByInput {
  CHATID_ASC = 'CHATID_ASC',
  CHATID_DESC = 'CHATID_DESC',
  CONTENT_ASC = 'CONTENT_ASC',
  CONTENT_DESC = 'CONTENT_DESC',
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  OWNERID_ASC = 'OWNERID_ASC',
  OWNERID_DESC = 'OWNERID_DESC',
  REPLYTO_ASC = 'REPLYTO_ASC',
  REPLYTO_DESC = 'REPLYTO_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export enum MessageStatus {
  DELIVERED = 'DELIVERED',
  READ = 'READ',
  SENT = 'SENT',
  UNDELIVERED = 'UNDELIVERED'
}

export type MessageUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  attachments: InputMaybe<Array<InputMaybe<MessageAttachmentUpdateInput>>>;
  attachments_unset: InputMaybe<Scalars['Boolean']['input']>;
  chatId: InputMaybe<Scalars['String']['input']>;
  chatId_unset: InputMaybe<Scalars['Boolean']['input']>;
  content: InputMaybe<Scalars['String']['input']>;
  content_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  ownerId: InputMaybe<MessageOwnerIdRelationInput>;
  ownerId_unset: InputMaybe<Scalars['Boolean']['input']>;
  participants: InputMaybe<MessageParticipantsRelationInput>;
  participants_unset: InputMaybe<Scalars['Boolean']['input']>;
  reference: InputMaybe<MessageReferenceUpdateInput>;
  reference_unset: InputMaybe<Scalars['Boolean']['input']>;
  replyTo: InputMaybe<MessageReplyToRelationInput>;
  replyTo_unset: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<MessageStatus>;
  status_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  chatReadAll: Maybe<DefaultPayload>;
  chatStatus: Maybe<DefaultPayload>;
  createAccount: Maybe<DefaultPayload>;
  createBusiness: Maybe<DefaultPayload>;
  createManagerAccount: Maybe<DefaultPayload>;
  createOffer: Maybe<CreateOfferPayload>;
  createOrder: Maybe<Order>;
  createRequest: Maybe<Request>;
  deleteManyAccounts: Maybe<DeleteManyPayload>;
  deleteManyActivities: Maybe<DeleteManyPayload>;
  deleteManyBusinesses: Maybe<DeleteManyPayload>;
  deleteManyChats: Maybe<DeleteManyPayload>;
  deleteManyCountries: Maybe<DeleteManyPayload>;
  deleteManyCurrencies: Maybe<DeleteManyPayload>;
  deleteManyLanguages: Maybe<DeleteManyPayload>;
  deleteManyMessages: Maybe<DeleteManyPayload>;
  deleteManyNotifications: Maybe<DeleteManyPayload>;
  deleteManyOfferAdditions: Maybe<DeleteManyPayload>;
  deleteManyOffers: Maybe<DeleteManyPayload>;
  deleteManyOrders: Maybe<DeleteManyPayload>;
  deleteManyRequests: Maybe<DeleteManyPayload>;
  deleteManyTags: Maybe<DeleteManyPayload>;
  deleteManyTemplates: Maybe<DeleteManyPayload>;
  deleteManyTravelCategories: Maybe<DeleteManyPayload>;
  deleteManyTravelerCharacteristics: Maybe<DeleteManyPayload>;
  deleteOneAccount: Maybe<Account>;
  deleteOneActivity: Maybe<Activity>;
  deleteOneBusiness: Maybe<Business>;
  deleteOneChat: Maybe<Chat>;
  deleteOneCountry: Maybe<Country>;
  deleteOneCurrency: Maybe<Currency>;
  deleteOneLanguage: Maybe<Language>;
  deleteOneMessage: Maybe<Message>;
  deleteOneNotification: Maybe<Notification>;
  deleteOneOffer: Maybe<Offer>;
  deleteOneOfferAddition: Maybe<OfferAddition>;
  deleteOneOrder: Maybe<Order>;
  deleteOneRequest: Maybe<Request>;
  deleteOneTag: Maybe<Tag>;
  deleteOneTemplate: Maybe<Template>;
  deleteOneTravelCategory: Maybe<TravelCategory>;
  deleteOneTravelerCharacteristic: Maybe<TravelerCharacteristic>;
  insertManyAccounts: Maybe<InsertManyPayload>;
  insertManyActivities: Maybe<InsertManyPayload>;
  insertManyBusinesses: Maybe<InsertManyPayload>;
  insertManyChats: Maybe<InsertManyPayload>;
  insertManyCountries: Maybe<InsertManyPayload>;
  insertManyCurrencies: Maybe<InsertManyPayload>;
  insertManyLanguages: Maybe<InsertManyPayload>;
  insertManyMessages: Maybe<InsertManyPayload>;
  insertManyNotifications: Maybe<InsertManyPayload>;
  insertManyOfferAdditions: Maybe<InsertManyPayload>;
  insertManyOffers: Maybe<InsertManyPayload>;
  insertManyOrders: Maybe<InsertManyPayload>;
  insertManyRequests: Maybe<InsertManyPayload>;
  insertManyTags: Maybe<InsertManyPayload>;
  insertManyTemplates: Maybe<InsertManyPayload>;
  insertManyTravelCategories: Maybe<InsertManyPayload>;
  insertManyTravelerCharacteristics: Maybe<InsertManyPayload>;
  insertOneAccount: Maybe<Account>;
  insertOneActivity: Maybe<Activity>;
  insertOneBusiness: Maybe<Business>;
  insertOneChat: Maybe<Chat>;
  insertOneCountry: Maybe<Country>;
  insertOneCurrency: Maybe<Currency>;
  insertOneLanguage: Maybe<Language>;
  insertOneMessage: Maybe<Message>;
  insertOneNotification: Maybe<Notification>;
  insertOneOffer: Maybe<Offer>;
  insertOneOfferAddition: Maybe<OfferAddition>;
  insertOneOrder: Maybe<Order>;
  insertOneRequest: Maybe<Request>;
  insertOneTag: Maybe<Tag>;
  insertOneTemplate: Maybe<Template>;
  insertOneTravelCategory: Maybe<TravelCategory>;
  insertOneTravelerCharacteristic: Maybe<TravelerCharacteristic>;
  insertTemplate: Maybe<Template>;
  replaceOneAccount: Maybe<Account>;
  replaceOneActivity: Maybe<Activity>;
  replaceOneBusiness: Maybe<Business>;
  replaceOneChat: Maybe<Chat>;
  replaceOneCountry: Maybe<Country>;
  replaceOneCurrency: Maybe<Currency>;
  replaceOneLanguage: Maybe<Language>;
  replaceOneMessage: Maybe<Message>;
  replaceOneNotification: Maybe<Notification>;
  replaceOneOffer: Maybe<Offer>;
  replaceOneOfferAddition: Maybe<OfferAddition>;
  replaceOneOrder: Maybe<Order>;
  replaceOneRequest: Maybe<Request>;
  replaceOneTag: Maybe<Tag>;
  replaceOneTemplate: Maybe<Template>;
  replaceOneTravelCategory: Maybe<TravelCategory>;
  replaceOneTravelerCharacteristic: Maybe<TravelerCharacteristic>;
  sendAccountsPush: Maybe<DefaultPayload>;
  sendMessage: Maybe<Message>;
  setLike: Maybe<DefaultPayload>;
  setOnlineAccount: Maybe<DefaultPayload>;
  setReadRequest: Maybe<DefaultPayload>;
  unsetLike: Maybe<DefaultPayload>;
  updateAccount: Maybe<Account>;
  updateBusiness: Maybe<DefaultPayload>;
  updateManyAccounts: Maybe<UpdateManyPayload>;
  updateManyActivities: Maybe<UpdateManyPayload>;
  updateManyBusinesses: Maybe<UpdateManyPayload>;
  updateManyChats: Maybe<UpdateManyPayload>;
  updateManyCountries: Maybe<UpdateManyPayload>;
  updateManyCurrencies: Maybe<UpdateManyPayload>;
  updateManyLanguages: Maybe<UpdateManyPayload>;
  updateManyMessages: Maybe<UpdateManyPayload>;
  updateManyNotifications: Maybe<UpdateManyPayload>;
  updateManyOfferAdditions: Maybe<UpdateManyPayload>;
  updateManyOffers: Maybe<UpdateManyPayload>;
  updateManyOrders: Maybe<UpdateManyPayload>;
  updateManyRequests: Maybe<UpdateManyPayload>;
  updateManyTags: Maybe<UpdateManyPayload>;
  updateManyTemplates: Maybe<UpdateManyPayload>;
  updateManyTravelCategories: Maybe<UpdateManyPayload>;
  updateManyTravelerCharacteristics: Maybe<UpdateManyPayload>;
  updateOffer: Maybe<Offer>;
  updateOneAccount: Maybe<Account>;
  updateOneActivity: Maybe<Activity>;
  updateOneBusiness: Maybe<Business>;
  updateOneChat: Maybe<Chat>;
  updateOneCountry: Maybe<Country>;
  updateOneCurrency: Maybe<Currency>;
  updateOneLanguage: Maybe<Language>;
  updateOneMessage: Maybe<Message>;
  updateOneNotification: Maybe<Notification>;
  updateOneOffer: Maybe<Offer>;
  updateOneOfferAddition: Maybe<OfferAddition>;
  updateOneOrder: Maybe<Order>;
  updateOneRequest: Maybe<Request>;
  updateOneTag: Maybe<Tag>;
  updateOneTemplate: Maybe<Template>;
  updateOneTravelCategory: Maybe<TravelCategory>;
  updateOneTravelerCharacteristic: Maybe<TravelerCharacteristic>;
  updateOrder: Maybe<Order>;
  updateRequest: Maybe<Request>;
  upsertOneAccount: Maybe<Account>;
  upsertOneActivity: Maybe<Activity>;
  upsertOneBusiness: Maybe<Business>;
  upsertOneChat: Maybe<Chat>;
  upsertOneCountry: Maybe<Country>;
  upsertOneCurrency: Maybe<Currency>;
  upsertOneLanguage: Maybe<Language>;
  upsertOneMessage: Maybe<Message>;
  upsertOneNotification: Maybe<Notification>;
  upsertOneOffer: Maybe<Offer>;
  upsertOneOfferAddition: Maybe<OfferAddition>;
  upsertOneOrder: Maybe<Order>;
  upsertOneRequest: Maybe<Request>;
  upsertOneTag: Maybe<Tag>;
  upsertOneTemplate: Maybe<Template>;
  upsertOneTravelCategory: Maybe<TravelCategory>;
  upsertOneTravelerCharacteristic: Maybe<TravelerCharacteristic>;
};


export type MutationChatReadAllArgs = {
  input: InputMaybe<ChatReadAllInput>;
};


export type MutationChatStatusArgs = {
  input: InputMaybe<ChatStatusInput>;
};


export type MutationCreateAccountArgs = {
  input: InputMaybe<CreateAccountInput>;
};


export type MutationCreateBusinessArgs = {
  input: InputMaybe<CreateBusinessInput>;
};


export type MutationCreateManagerAccountArgs = {
  input: InputMaybe<CreateManagerAccountInput>;
};


export type MutationCreateOfferArgs = {
  input: InputMaybe<CreateOfferInput>;
};


export type MutationCreateOrderArgs = {
  input: InputMaybe<CreateOrderInput>;
};


export type MutationCreateRequestArgs = {
  input: InputMaybe<CreateRequestInput>;
};


export type MutationDeleteManyAccountsArgs = {
  query: InputMaybe<AccountQueryInput>;
};


export type MutationDeleteManyActivitiesArgs = {
  query: InputMaybe<ActivityQueryInput>;
};


export type MutationDeleteManyBusinessesArgs = {
  query: InputMaybe<BusinessQueryInput>;
};


export type MutationDeleteManyChatsArgs = {
  query: InputMaybe<ChatQueryInput>;
};


export type MutationDeleteManyCountriesArgs = {
  query: InputMaybe<CountryQueryInput>;
};


export type MutationDeleteManyCurrenciesArgs = {
  query: InputMaybe<CurrencyQueryInput>;
};


export type MutationDeleteManyLanguagesArgs = {
  query: InputMaybe<LanguageQueryInput>;
};


export type MutationDeleteManyMessagesArgs = {
  query: InputMaybe<MessageQueryInput>;
};


export type MutationDeleteManyNotificationsArgs = {
  query: InputMaybe<NotificationQueryInput>;
};


export type MutationDeleteManyOfferAdditionsArgs = {
  query: InputMaybe<OfferAdditionQueryInput>;
};


export type MutationDeleteManyOffersArgs = {
  query: InputMaybe<OfferQueryInput>;
};


export type MutationDeleteManyOrdersArgs = {
  query: InputMaybe<OrderQueryInput>;
};


export type MutationDeleteManyRequestsArgs = {
  query: InputMaybe<RequestQueryInput>;
};


export type MutationDeleteManyTagsArgs = {
  query: InputMaybe<TagQueryInput>;
};


export type MutationDeleteManyTemplatesArgs = {
  query: InputMaybe<TemplateQueryInput>;
};


export type MutationDeleteManyTravelCategoriesArgs = {
  query: InputMaybe<TravelCategoryQueryInput>;
};


export type MutationDeleteManyTravelerCharacteristicsArgs = {
  query: InputMaybe<TravelerCharacteristicQueryInput>;
};


export type MutationDeleteOneAccountArgs = {
  query: AccountQueryInput;
};


export type MutationDeleteOneActivityArgs = {
  query: ActivityQueryInput;
};


export type MutationDeleteOneBusinessArgs = {
  query: BusinessQueryInput;
};


export type MutationDeleteOneChatArgs = {
  query: ChatQueryInput;
};


export type MutationDeleteOneCountryArgs = {
  query: CountryQueryInput;
};


export type MutationDeleteOneCurrencyArgs = {
  query: CurrencyQueryInput;
};


export type MutationDeleteOneLanguageArgs = {
  query: LanguageQueryInput;
};


export type MutationDeleteOneMessageArgs = {
  query: MessageQueryInput;
};


export type MutationDeleteOneNotificationArgs = {
  query: NotificationQueryInput;
};


export type MutationDeleteOneOfferArgs = {
  query: OfferQueryInput;
};


export type MutationDeleteOneOfferAdditionArgs = {
  query: OfferAdditionQueryInput;
};


export type MutationDeleteOneOrderArgs = {
  query: OrderQueryInput;
};


export type MutationDeleteOneRequestArgs = {
  query: RequestQueryInput;
};


export type MutationDeleteOneTagArgs = {
  query: TagQueryInput;
};


export type MutationDeleteOneTemplateArgs = {
  query: TemplateQueryInput;
};


export type MutationDeleteOneTravelCategoryArgs = {
  query: TravelCategoryQueryInput;
};


export type MutationDeleteOneTravelerCharacteristicArgs = {
  query: TravelerCharacteristicQueryInput;
};


export type MutationInsertManyAccountsArgs = {
  data: Array<AccountInsertInput>;
};


export type MutationInsertManyActivitiesArgs = {
  data: Array<ActivityInsertInput>;
};


export type MutationInsertManyBusinessesArgs = {
  data: Array<BusinessInsertInput>;
};


export type MutationInsertManyChatsArgs = {
  data: Array<ChatInsertInput>;
};


export type MutationInsertManyCountriesArgs = {
  data: Array<CountryInsertInput>;
};


export type MutationInsertManyCurrenciesArgs = {
  data: Array<CurrencyInsertInput>;
};


export type MutationInsertManyLanguagesArgs = {
  data: Array<LanguageInsertInput>;
};


export type MutationInsertManyMessagesArgs = {
  data: Array<MessageInsertInput>;
};


export type MutationInsertManyNotificationsArgs = {
  data: Array<NotificationInsertInput>;
};


export type MutationInsertManyOfferAdditionsArgs = {
  data: Array<OfferAdditionInsertInput>;
};


export type MutationInsertManyOffersArgs = {
  data: Array<OfferInsertInput>;
};


export type MutationInsertManyOrdersArgs = {
  data: Array<OrderInsertInput>;
};


export type MutationInsertManyRequestsArgs = {
  data: Array<RequestInsertInput>;
};


export type MutationInsertManyTagsArgs = {
  data: Array<TagInsertInput>;
};


export type MutationInsertManyTemplatesArgs = {
  data: Array<TemplateInsertInput>;
};


export type MutationInsertManyTravelCategoriesArgs = {
  data: Array<TravelCategoryInsertInput>;
};


export type MutationInsertManyTravelerCharacteristicsArgs = {
  data: Array<TravelerCharacteristicInsertInput>;
};


export type MutationInsertOneAccountArgs = {
  data: AccountInsertInput;
};


export type MutationInsertOneActivityArgs = {
  data: ActivityInsertInput;
};


export type MutationInsertOneBusinessArgs = {
  data: BusinessInsertInput;
};


export type MutationInsertOneChatArgs = {
  data: ChatInsertInput;
};


export type MutationInsertOneCountryArgs = {
  data: CountryInsertInput;
};


export type MutationInsertOneCurrencyArgs = {
  data: CurrencyInsertInput;
};


export type MutationInsertOneLanguageArgs = {
  data: LanguageInsertInput;
};


export type MutationInsertOneMessageArgs = {
  data: MessageInsertInput;
};


export type MutationInsertOneNotificationArgs = {
  data: NotificationInsertInput;
};


export type MutationInsertOneOfferArgs = {
  data: OfferInsertInput;
};


export type MutationInsertOneOfferAdditionArgs = {
  data: OfferAdditionInsertInput;
};


export type MutationInsertOneOrderArgs = {
  data: OrderInsertInput;
};


export type MutationInsertOneRequestArgs = {
  data: RequestInsertInput;
};


export type MutationInsertOneTagArgs = {
  data: TagInsertInput;
};


export type MutationInsertOneTemplateArgs = {
  data: TemplateInsertInput;
};


export type MutationInsertOneTravelCategoryArgs = {
  data: TravelCategoryInsertInput;
};


export type MutationInsertOneTravelerCharacteristicArgs = {
  data: TravelerCharacteristicInsertInput;
};


export type MutationInsertTemplateArgs = {
  input: InputMaybe<InsertTemplateInput>;
};


export type MutationReplaceOneAccountArgs = {
  data: AccountInsertInput;
  query: InputMaybe<AccountQueryInput>;
};


export type MutationReplaceOneActivityArgs = {
  data: ActivityInsertInput;
  query: InputMaybe<ActivityQueryInput>;
};


export type MutationReplaceOneBusinessArgs = {
  data: BusinessInsertInput;
  query: InputMaybe<BusinessQueryInput>;
};


export type MutationReplaceOneChatArgs = {
  data: ChatInsertInput;
  query: InputMaybe<ChatQueryInput>;
};


export type MutationReplaceOneCountryArgs = {
  data: CountryInsertInput;
  query: InputMaybe<CountryQueryInput>;
};


export type MutationReplaceOneCurrencyArgs = {
  data: CurrencyInsertInput;
  query: InputMaybe<CurrencyQueryInput>;
};


export type MutationReplaceOneLanguageArgs = {
  data: LanguageInsertInput;
  query: InputMaybe<LanguageQueryInput>;
};


export type MutationReplaceOneMessageArgs = {
  data: MessageInsertInput;
  query: InputMaybe<MessageQueryInput>;
};


export type MutationReplaceOneNotificationArgs = {
  data: NotificationInsertInput;
  query: InputMaybe<NotificationQueryInput>;
};


export type MutationReplaceOneOfferArgs = {
  data: OfferInsertInput;
  query: InputMaybe<OfferQueryInput>;
};


export type MutationReplaceOneOfferAdditionArgs = {
  data: OfferAdditionInsertInput;
  query: InputMaybe<OfferAdditionQueryInput>;
};


export type MutationReplaceOneOrderArgs = {
  data: OrderInsertInput;
  query: InputMaybe<OrderQueryInput>;
};


export type MutationReplaceOneRequestArgs = {
  data: RequestInsertInput;
  query: InputMaybe<RequestQueryInput>;
};


export type MutationReplaceOneTagArgs = {
  data: TagInsertInput;
  query: InputMaybe<TagQueryInput>;
};


export type MutationReplaceOneTemplateArgs = {
  data: TemplateInsertInput;
  query: InputMaybe<TemplateQueryInput>;
};


export type MutationReplaceOneTravelCategoryArgs = {
  data: TravelCategoryInsertInput;
  query: InputMaybe<TravelCategoryQueryInput>;
};


export type MutationReplaceOneTravelerCharacteristicArgs = {
  data: TravelerCharacteristicInsertInput;
  query: InputMaybe<TravelerCharacteristicQueryInput>;
};


export type MutationSendAccountsPushArgs = {
  input: InputMaybe<SendAccountsPushInput>;
};


export type MutationSendMessageArgs = {
  input: InputMaybe<SendMessageInput>;
};


export type MutationSetLikeArgs = {
  input: InputMaybe<SetOfferLike>;
};


export type MutationSetOnlineAccountArgs = {
  input: InputMaybe<SetOnlineAccountInput>;
};


export type MutationSetReadRequestArgs = {
  input: InputMaybe<UpdateRequestRead>;
};


export type MutationUnsetLikeArgs = {
  input: InputMaybe<UnsetOfferLike>;
};


export type MutationUpdateAccountArgs = {
  input: InputMaybe<UpdateAccountInput>;
};


export type MutationUpdateBusinessArgs = {
  input: InputMaybe<UpdateBusinessInput>;
};


export type MutationUpdateManyAccountsArgs = {
  query: InputMaybe<AccountQueryInput>;
  set: AccountUpdateInput;
};


export type MutationUpdateManyActivitiesArgs = {
  query: InputMaybe<ActivityQueryInput>;
  set: ActivityUpdateInput;
};


export type MutationUpdateManyBusinessesArgs = {
  query: InputMaybe<BusinessQueryInput>;
  set: BusinessUpdateInput;
};


export type MutationUpdateManyChatsArgs = {
  query: InputMaybe<ChatQueryInput>;
  set: ChatUpdateInput;
};


export type MutationUpdateManyCountriesArgs = {
  query: InputMaybe<CountryQueryInput>;
  set: CountryUpdateInput;
};


export type MutationUpdateManyCurrenciesArgs = {
  query: InputMaybe<CurrencyQueryInput>;
  set: CurrencyUpdateInput;
};


export type MutationUpdateManyLanguagesArgs = {
  query: InputMaybe<LanguageQueryInput>;
  set: LanguageUpdateInput;
};


export type MutationUpdateManyMessagesArgs = {
  query: InputMaybe<MessageQueryInput>;
  set: MessageUpdateInput;
};


export type MutationUpdateManyNotificationsArgs = {
  query: InputMaybe<NotificationQueryInput>;
  set: NotificationUpdateInput;
};


export type MutationUpdateManyOfferAdditionsArgs = {
  query: InputMaybe<OfferAdditionQueryInput>;
  set: OfferAdditionUpdateInput;
};


export type MutationUpdateManyOffersArgs = {
  query: InputMaybe<OfferQueryInput>;
  set: OfferUpdateInput;
};


export type MutationUpdateManyOrdersArgs = {
  query: InputMaybe<OrderQueryInput>;
  set: OrderUpdateInput;
};


export type MutationUpdateManyRequestsArgs = {
  query: InputMaybe<RequestQueryInput>;
  set: RequestUpdateInput;
};


export type MutationUpdateManyTagsArgs = {
  query: InputMaybe<TagQueryInput>;
  set: TagUpdateInput;
};


export type MutationUpdateManyTemplatesArgs = {
  query: InputMaybe<TemplateQueryInput>;
  set: TemplateUpdateInput;
};


export type MutationUpdateManyTravelCategoriesArgs = {
  query: InputMaybe<TravelCategoryQueryInput>;
  set: TravelCategoryUpdateInput;
};


export type MutationUpdateManyTravelerCharacteristicsArgs = {
  query: InputMaybe<TravelerCharacteristicQueryInput>;
  set: TravelerCharacteristicUpdateInput;
};


export type MutationUpdateOfferArgs = {
  input: InputMaybe<UpdateOfferInput>;
};


export type MutationUpdateOneAccountArgs = {
  query: InputMaybe<AccountQueryInput>;
  set: AccountUpdateInput;
};


export type MutationUpdateOneActivityArgs = {
  query: InputMaybe<ActivityQueryInput>;
  set: ActivityUpdateInput;
};


export type MutationUpdateOneBusinessArgs = {
  query: InputMaybe<BusinessQueryInput>;
  set: BusinessUpdateInput;
};


export type MutationUpdateOneChatArgs = {
  query: InputMaybe<ChatQueryInput>;
  set: ChatUpdateInput;
};


export type MutationUpdateOneCountryArgs = {
  query: InputMaybe<CountryQueryInput>;
  set: CountryUpdateInput;
};


export type MutationUpdateOneCurrencyArgs = {
  query: InputMaybe<CurrencyQueryInput>;
  set: CurrencyUpdateInput;
};


export type MutationUpdateOneLanguageArgs = {
  query: InputMaybe<LanguageQueryInput>;
  set: LanguageUpdateInput;
};


export type MutationUpdateOneMessageArgs = {
  query: InputMaybe<MessageQueryInput>;
  set: MessageUpdateInput;
};


export type MutationUpdateOneNotificationArgs = {
  query: InputMaybe<NotificationQueryInput>;
  set: NotificationUpdateInput;
};


export type MutationUpdateOneOfferArgs = {
  query: InputMaybe<OfferQueryInput>;
  set: OfferUpdateInput;
};


export type MutationUpdateOneOfferAdditionArgs = {
  query: InputMaybe<OfferAdditionQueryInput>;
  set: OfferAdditionUpdateInput;
};


export type MutationUpdateOneOrderArgs = {
  query: InputMaybe<OrderQueryInput>;
  set: OrderUpdateInput;
};


export type MutationUpdateOneRequestArgs = {
  query: InputMaybe<RequestQueryInput>;
  set: RequestUpdateInput;
};


export type MutationUpdateOneTagArgs = {
  query: InputMaybe<TagQueryInput>;
  set: TagUpdateInput;
};


export type MutationUpdateOneTemplateArgs = {
  query: InputMaybe<TemplateQueryInput>;
  set: TemplateUpdateInput;
};


export type MutationUpdateOneTravelCategoryArgs = {
  query: InputMaybe<TravelCategoryQueryInput>;
  set: TravelCategoryUpdateInput;
};


export type MutationUpdateOneTravelerCharacteristicArgs = {
  query: InputMaybe<TravelerCharacteristicQueryInput>;
  set: TravelerCharacteristicUpdateInput;
};


export type MutationUpdateOrderArgs = {
  input: InputMaybe<UpdateOrderInput>;
};


export type MutationUpdateRequestArgs = {
  input: InputMaybe<UpdateRequestInput>;
};


export type MutationUpsertOneAccountArgs = {
  data: AccountInsertInput;
  query: InputMaybe<AccountQueryInput>;
};


export type MutationUpsertOneActivityArgs = {
  data: ActivityInsertInput;
  query: InputMaybe<ActivityQueryInput>;
};


export type MutationUpsertOneBusinessArgs = {
  data: BusinessInsertInput;
  query: InputMaybe<BusinessQueryInput>;
};


export type MutationUpsertOneChatArgs = {
  data: ChatInsertInput;
  query: InputMaybe<ChatQueryInput>;
};


export type MutationUpsertOneCountryArgs = {
  data: CountryInsertInput;
  query: InputMaybe<CountryQueryInput>;
};


export type MutationUpsertOneCurrencyArgs = {
  data: CurrencyInsertInput;
  query: InputMaybe<CurrencyQueryInput>;
};


export type MutationUpsertOneLanguageArgs = {
  data: LanguageInsertInput;
  query: InputMaybe<LanguageQueryInput>;
};


export type MutationUpsertOneMessageArgs = {
  data: MessageInsertInput;
  query: InputMaybe<MessageQueryInput>;
};


export type MutationUpsertOneNotificationArgs = {
  data: NotificationInsertInput;
  query: InputMaybe<NotificationQueryInput>;
};


export type MutationUpsertOneOfferArgs = {
  data: OfferInsertInput;
  query: InputMaybe<OfferQueryInput>;
};


export type MutationUpsertOneOfferAdditionArgs = {
  data: OfferAdditionInsertInput;
  query: InputMaybe<OfferAdditionQueryInput>;
};


export type MutationUpsertOneOrderArgs = {
  data: OrderInsertInput;
  query: InputMaybe<OrderQueryInput>;
};


export type MutationUpsertOneRequestArgs = {
  data: RequestInsertInput;
  query: InputMaybe<RequestQueryInput>;
};


export type MutationUpsertOneTagArgs = {
  data: TagInsertInput;
  query: InputMaybe<TagQueryInput>;
};


export type MutationUpsertOneTemplateArgs = {
  data: TemplateInsertInput;
  query: InputMaybe<TemplateQueryInput>;
};


export type MutationUpsertOneTravelCategoryArgs = {
  data: TravelCategoryInsertInput;
  query: InputMaybe<TravelCategoryQueryInput>;
};


export type MutationUpsertOneTravelerCharacteristicArgs = {
  data: TravelerCharacteristicInsertInput;
  query: InputMaybe<TravelerCharacteristicQueryInput>;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ObjectId']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  entityId: Maybe<Scalars['String']['output']>;
  entityType: Maybe<NotificationEntityType>;
  imageUrl: Maybe<Scalars['String']['output']>;
  isBusiness: Maybe<Scalars['Boolean']['output']>;
  isRead: Maybe<Scalars['Boolean']['output']>;
  operationType: Maybe<NotificationOperationType>;
  owner: Maybe<Account>;
  status: Maybe<NotificationStatus>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export enum NotificationEntityType {
  CHAT = 'CHAT',
  OFFER = 'OFFER',
  ORDER = 'ORDER',
  REQUEST = 'REQUEST'
}

export type NotificationInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  entityId: InputMaybe<Scalars['String']['input']>;
  entityType: InputMaybe<NotificationEntityType>;
  imageUrl: InputMaybe<Scalars['String']['input']>;
  isBusiness: InputMaybe<Scalars['Boolean']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  operationType: InputMaybe<NotificationOperationType>;
  owner: InputMaybe<NotificationOwnerRelationInput>;
  status: InputMaybe<NotificationStatus>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
};

export enum NotificationOperationType {
  DELETE = 'DELETE',
  INSERT = 'INSERT',
  UPDATE = 'UPDATE'
}

export type NotificationOwnerRelationInput = {
  create: InputMaybe<AccountInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type NotificationQueryInput = {
  AND: InputMaybe<Array<NotificationQueryInput>>;
  OR: InputMaybe<Array<NotificationQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  entityId: InputMaybe<Scalars['String']['input']>;
  entityId_exists: InputMaybe<Scalars['Boolean']['input']>;
  entityId_gt: InputMaybe<Scalars['String']['input']>;
  entityId_gte: InputMaybe<Scalars['String']['input']>;
  entityId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  entityId_lt: InputMaybe<Scalars['String']['input']>;
  entityId_lte: InputMaybe<Scalars['String']['input']>;
  entityId_ne: InputMaybe<Scalars['String']['input']>;
  entityId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  entityType: InputMaybe<NotificationEntityType>;
  entityType_exists: InputMaybe<Scalars['Boolean']['input']>;
  entityType_gt: InputMaybe<NotificationEntityType>;
  entityType_gte: InputMaybe<NotificationEntityType>;
  entityType_in: InputMaybe<Array<InputMaybe<NotificationEntityType>>>;
  entityType_lt: InputMaybe<NotificationEntityType>;
  entityType_lte: InputMaybe<NotificationEntityType>;
  entityType_ne: InputMaybe<NotificationEntityType>;
  entityType_nin: InputMaybe<Array<InputMaybe<NotificationEntityType>>>;
  imageUrl: InputMaybe<Scalars['String']['input']>;
  imageUrl_exists: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl_gt: InputMaybe<Scalars['String']['input']>;
  imageUrl_gte: InputMaybe<Scalars['String']['input']>;
  imageUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  imageUrl_lt: InputMaybe<Scalars['String']['input']>;
  imageUrl_lte: InputMaybe<Scalars['String']['input']>;
  imageUrl_ne: InputMaybe<Scalars['String']['input']>;
  imageUrl_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isBusiness: InputMaybe<Scalars['Boolean']['input']>;
  isBusiness_exists: InputMaybe<Scalars['Boolean']['input']>;
  isBusiness_ne: InputMaybe<Scalars['Boolean']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  isRead_exists: InputMaybe<Scalars['Boolean']['input']>;
  isRead_ne: InputMaybe<Scalars['Boolean']['input']>;
  operationType: InputMaybe<NotificationOperationType>;
  operationType_exists: InputMaybe<Scalars['Boolean']['input']>;
  operationType_gt: InputMaybe<NotificationOperationType>;
  operationType_gte: InputMaybe<NotificationOperationType>;
  operationType_in: InputMaybe<Array<InputMaybe<NotificationOperationType>>>;
  operationType_lt: InputMaybe<NotificationOperationType>;
  operationType_lte: InputMaybe<NotificationOperationType>;
  operationType_ne: InputMaybe<NotificationOperationType>;
  operationType_nin: InputMaybe<Array<InputMaybe<NotificationOperationType>>>;
  owner: InputMaybe<AccountQueryInput>;
  owner_exists: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<NotificationStatus>;
  status_exists: InputMaybe<Scalars['Boolean']['input']>;
  status_gt: InputMaybe<NotificationStatus>;
  status_gte: InputMaybe<NotificationStatus>;
  status_in: InputMaybe<Array<InputMaybe<NotificationStatus>>>;
  status_lt: InputMaybe<NotificationStatus>;
  status_lte: InputMaybe<NotificationStatus>;
  status_ne: InputMaybe<NotificationStatus>;
  status_nin: InputMaybe<Array<InputMaybe<NotificationStatus>>>;
  text: InputMaybe<Scalars['String']['input']>;
  text_exists: InputMaybe<Scalars['Boolean']['input']>;
  text_gt: InputMaybe<Scalars['String']['input']>;
  text_gte: InputMaybe<Scalars['String']['input']>;
  text_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_lt: InputMaybe<Scalars['String']['input']>;
  text_lte: InputMaybe<Scalars['String']['input']>;
  text_ne: InputMaybe<Scalars['String']['input']>;
  text_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_gt: InputMaybe<Scalars['String']['input']>;
  title_gte: InputMaybe<Scalars['String']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_lt: InputMaybe<Scalars['String']['input']>;
  title_lte: InputMaybe<Scalars['String']['input']>;
  title_ne: InputMaybe<Scalars['String']['input']>;
  title_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

export enum NotificationSortByInput {
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  ENTITYID_ASC = 'ENTITYID_ASC',
  ENTITYID_DESC = 'ENTITYID_DESC',
  ENTITYTYPE_ASC = 'ENTITYTYPE_ASC',
  ENTITYTYPE_DESC = 'ENTITYTYPE_DESC',
  IMAGEURL_ASC = 'IMAGEURL_ASC',
  IMAGEURL_DESC = 'IMAGEURL_DESC',
  OPERATIONTYPE_ASC = 'OPERATIONTYPE_ASC',
  OPERATIONTYPE_DESC = 'OPERATIONTYPE_DESC',
  OWNER_ASC = 'OWNER_ASC',
  OWNER_DESC = 'OWNER_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  TEXT_ASC = 'TEXT_ASC',
  TEXT_DESC = 'TEXT_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  UPDATEDAT_ASC = 'UPDATEDAT_ASC',
  UPDATEDAT_DESC = 'UPDATEDAT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export enum NotificationStatus {
  CANCELLED = 'CANCELLED',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  CONFIRMED = 'CONFIRMED',
  PENDING_AGENT = 'PENDING_AGENT',
  PENDING_TRAVELER = 'PENDING_TRAVELER'
}

export type NotificationUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  entityId: InputMaybe<Scalars['String']['input']>;
  entityId_unset: InputMaybe<Scalars['Boolean']['input']>;
  entityType: InputMaybe<NotificationEntityType>;
  entityType_unset: InputMaybe<Scalars['Boolean']['input']>;
  imageUrl: InputMaybe<Scalars['String']['input']>;
  imageUrl_unset: InputMaybe<Scalars['Boolean']['input']>;
  isBusiness: InputMaybe<Scalars['Boolean']['input']>;
  isBusiness_unset: InputMaybe<Scalars['Boolean']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  isRead_unset: InputMaybe<Scalars['Boolean']['input']>;
  operationType: InputMaybe<NotificationOperationType>;
  operationType_unset: InputMaybe<Scalars['Boolean']['input']>;
  owner: InputMaybe<NotificationOwnerRelationInput>;
  owner_unset: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<NotificationStatus>;
  status_unset: InputMaybe<Scalars['Boolean']['input']>;
  text: InputMaybe<Scalars['String']['input']>;
  text_unset: InputMaybe<Scalars['Boolean']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  title_unset: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Offer = {
  __typename?: 'Offer';
  _id: Scalars['ObjectId']['output'];
  activities: Maybe<Array<Maybe<Activity>>>;
  adults: Maybe<Scalars['Float']['output']>;
  children: Maybe<Scalars['Float']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  days: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  isBlocked: Maybe<Scalars['Boolean']['output']>;
  isPublic: Maybe<Scalars['Boolean']['output']>;
  like: Maybe<OfferLike>;
  likeCount: Maybe<Scalars['Float']['output']>;
  likes: Maybe<Array<Maybe<OfferLike>>>;
  location: Maybe<OfferLocation>;
  offerAddition: Maybe<Array<Maybe<OfferAddition>>>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['String']['output']>;
  person: Maybe<Scalars['Float']['output']>;
  photo: Maybe<Array<Maybe<OfferPhoto>>>;
  price: Maybe<OfferPrice>;
  priceEstimateType: Maybe<OfferPriceEstimateType>;
  requestId: Maybe<Request>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: OfferStatus;
  tags: Maybe<Array<Maybe<Tag>>>;
  title: Scalars['String']['output'];
  travelCategory: Maybe<TravelCategory>;
  type: Maybe<OfferType>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  video: Maybe<OfferVideo>;
};

export type OfferActivitiesRelationInput = {
  create: InputMaybe<Array<InputMaybe<ActivityInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type OfferAddition = {
  __typename?: 'OfferAddition';
  _id: Scalars['ObjectId']['output'];
  adults: Maybe<Scalars['Float']['output']>;
  children: Maybe<Scalars['Float']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  days: Maybe<Scalars['String']['output']>;
  offerId: Scalars['ObjectId']['output'];
  price: Maybe<OfferAdditionPrice>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OfferAdditionInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  days: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['ObjectId']['input'];
  price: InputMaybe<OfferAdditionPriceInsertInput>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
};

export type OfferAdditionPrice = {
  __typename?: 'OfferAdditionPrice';
  currency: Maybe<Currency>;
  value: Scalars['Float']['output'];
};

export type OfferAdditionPriceCurrencyRelationInput = {
  create: InputMaybe<CurrencyInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type OfferAdditionPriceInsertInput = {
  currency: InputMaybe<OfferAdditionPriceCurrencyRelationInput>;
  value: Scalars['Float']['input'];
};

export type OfferAdditionPriceQueryInput = {
  AND: InputMaybe<Array<OfferAdditionPriceQueryInput>>;
  OR: InputMaybe<Array<OfferAdditionPriceQueryInput>>;
  currency: InputMaybe<CurrencyQueryInput>;
  currency_exists: InputMaybe<Scalars['Boolean']['input']>;
  value: InputMaybe<Scalars['Float']['input']>;
  value_exists: InputMaybe<Scalars['Boolean']['input']>;
  value_gt: InputMaybe<Scalars['Float']['input']>;
  value_gte: InputMaybe<Scalars['Float']['input']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  value_lt: InputMaybe<Scalars['Float']['input']>;
  value_lte: InputMaybe<Scalars['Float']['input']>;
  value_ne: InputMaybe<Scalars['Float']['input']>;
  value_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type OfferAdditionPriceUpdateInput = {
  currency: InputMaybe<OfferAdditionPriceCurrencyRelationInput>;
  currency_unset: InputMaybe<Scalars['Boolean']['input']>;
  value: InputMaybe<Scalars['Float']['input']>;
  value_inc: InputMaybe<Scalars['Float']['input']>;
  value_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferAdditionQueryInput = {
  AND: InputMaybe<Array<OfferAdditionQueryInput>>;
  OR: InputMaybe<Array<OfferAdditionQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  adults: InputMaybe<Scalars['Float']['input']>;
  adults_exists: InputMaybe<Scalars['Boolean']['input']>;
  adults_gt: InputMaybe<Scalars['Float']['input']>;
  adults_gte: InputMaybe<Scalars['Float']['input']>;
  adults_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  adults_lt: InputMaybe<Scalars['Float']['input']>;
  adults_lte: InputMaybe<Scalars['Float']['input']>;
  adults_ne: InputMaybe<Scalars['Float']['input']>;
  adults_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  children: InputMaybe<Scalars['Float']['input']>;
  children_exists: InputMaybe<Scalars['Boolean']['input']>;
  children_gt: InputMaybe<Scalars['Float']['input']>;
  children_gte: InputMaybe<Scalars['Float']['input']>;
  children_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  children_lt: InputMaybe<Scalars['Float']['input']>;
  children_lte: InputMaybe<Scalars['Float']['input']>;
  children_ne: InputMaybe<Scalars['Float']['input']>;
  children_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  days: InputMaybe<Scalars['String']['input']>;
  days_exists: InputMaybe<Scalars['Boolean']['input']>;
  days_gt: InputMaybe<Scalars['String']['input']>;
  days_gte: InputMaybe<Scalars['String']['input']>;
  days_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  days_lt: InputMaybe<Scalars['String']['input']>;
  days_lte: InputMaybe<Scalars['String']['input']>;
  days_ne: InputMaybe<Scalars['String']['input']>;
  days_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offerId: InputMaybe<Scalars['ObjectId']['input']>;
  offerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  offerId_gt: InputMaybe<Scalars['ObjectId']['input']>;
  offerId_gte: InputMaybe<Scalars['ObjectId']['input']>;
  offerId_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  offerId_lt: InputMaybe<Scalars['ObjectId']['input']>;
  offerId_lte: InputMaybe<Scalars['ObjectId']['input']>;
  offerId_ne: InputMaybe<Scalars['ObjectId']['input']>;
  offerId_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  price: InputMaybe<OfferAdditionPriceQueryInput>;
  price_exists: InputMaybe<Scalars['Boolean']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  startDate_gt: InputMaybe<Scalars['DateTime']['input']>;
  startDate_gte: InputMaybe<Scalars['DateTime']['input']>;
  startDate_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startDate_lt: InputMaybe<Scalars['DateTime']['input']>;
  startDate_lte: InputMaybe<Scalars['DateTime']['input']>;
  startDate_ne: InputMaybe<Scalars['DateTime']['input']>;
  startDate_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

export enum OfferAdditionSortByInput {
  ADULTS_ASC = 'ADULTS_ASC',
  ADULTS_DESC = 'ADULTS_DESC',
  CHILDREN_ASC = 'CHILDREN_ASC',
  CHILDREN_DESC = 'CHILDREN_DESC',
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  DAYS_ASC = 'DAYS_ASC',
  DAYS_DESC = 'DAYS_DESC',
  OFFERID_ASC = 'OFFERID_ASC',
  OFFERID_DESC = 'OFFERID_DESC',
  STARTDATE_ASC = 'STARTDATE_ASC',
  STARTDATE_DESC = 'STARTDATE_DESC',
  UPDATEDAT_ASC = 'UPDATEDAT_ASC',
  UPDATEDAT_DESC = 'UPDATEDAT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type OfferAdditionUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  adults: InputMaybe<Scalars['Float']['input']>;
  adults_inc: InputMaybe<Scalars['Float']['input']>;
  adults_unset: InputMaybe<Scalars['Boolean']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  children_inc: InputMaybe<Scalars['Float']['input']>;
  children_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  days: InputMaybe<Scalars['String']['input']>;
  days_unset: InputMaybe<Scalars['Boolean']['input']>;
  offerId: InputMaybe<Scalars['ObjectId']['input']>;
  offerId_unset: InputMaybe<Scalars['Boolean']['input']>;
  price: InputMaybe<OfferAdditionPriceUpdateInput>;
  price_unset: InputMaybe<Scalars['Boolean']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate_unset: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  activities: InputMaybe<OfferActivitiesRelationInput>;
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  days: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  isBlocked: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: InputMaybe<Scalars['Boolean']['input']>;
  like: InputMaybe<OfferLikeInsertInput>;
  likeCount: InputMaybe<Scalars['Float']['input']>;
  likes: InputMaybe<Array<InputMaybe<OfferLikeInsertInput>>>;
  location: InputMaybe<OfferLocationInsertInput>;
  offerAddition: InputMaybe<OfferOfferAdditionRelationInput>;
  owner: InputMaybe<OfferOwnerRelationInput>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  person: InputMaybe<Scalars['Float']['input']>;
  photo: InputMaybe<Array<InputMaybe<OfferPhotoInsertInput>>>;
  price: InputMaybe<OfferPriceInsertInput>;
  priceEstimateType: InputMaybe<OfferPriceEstimateType>;
  requestId: InputMaybe<OfferRequestIdRelationInput>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: OfferStatus;
  tags: InputMaybe<OfferTagsRelationInput>;
  title: Scalars['String']['input'];
  travelCategory: InputMaybe<OfferTravelCategoryRelationInput>;
  type: InputMaybe<OfferType>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  video: InputMaybe<OfferVideoInsertInput>;
};

export type OfferLike = {
  __typename?: 'OfferLike';
  requestId: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['String']['output']>;
};

export type OfferLikeInsertInput = {
  requestId: InputMaybe<Scalars['String']['input']>;
  userId: InputMaybe<Scalars['String']['input']>;
};

export type OfferLikeQueryInput = {
  AND: InputMaybe<Array<OfferLikeQueryInput>>;
  OR: InputMaybe<Array<OfferLikeQueryInput>>;
  requestId: InputMaybe<Scalars['String']['input']>;
  requestId_exists: InputMaybe<Scalars['Boolean']['input']>;
  requestId_gt: InputMaybe<Scalars['String']['input']>;
  requestId_gte: InputMaybe<Scalars['String']['input']>;
  requestId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  requestId_lt: InputMaybe<Scalars['String']['input']>;
  requestId_lte: InputMaybe<Scalars['String']['input']>;
  requestId_ne: InputMaybe<Scalars['String']['input']>;
  requestId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userId: InputMaybe<Scalars['String']['input']>;
  userId_exists: InputMaybe<Scalars['Boolean']['input']>;
  userId_gt: InputMaybe<Scalars['String']['input']>;
  userId_gte: InputMaybe<Scalars['String']['input']>;
  userId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userId_lt: InputMaybe<Scalars['String']['input']>;
  userId_lte: InputMaybe<Scalars['String']['input']>;
  userId_ne: InputMaybe<Scalars['String']['input']>;
  userId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OfferLikeUpdateInput = {
  requestId: InputMaybe<Scalars['String']['input']>;
  requestId_unset: InputMaybe<Scalars['Boolean']['input']>;
  userId: InputMaybe<Scalars['String']['input']>;
  userId_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLocation = {
  __typename?: 'OfferLocation';
  coordinates: Maybe<OfferLocationCoordinate>;
  description: Scalars['String']['output'];
  place_id: Scalars['String']['output'];
};

export type OfferLocationCoordinate = {
  __typename?: 'OfferLocationCoordinate';
  coordinates: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type: Maybe<OfferLocationCoordinateType>;
};

export type OfferLocationCoordinateInsertInput = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<OfferLocationCoordinateType>;
};

export type OfferLocationCoordinateQueryInput = {
  AND: InputMaybe<Array<OfferLocationCoordinateQueryInput>>;
  OR: InputMaybe<Array<OfferLocationCoordinateQueryInput>>;
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  coordinates_exists: InputMaybe<Scalars['Boolean']['input']>;
  coordinates_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  coordinates_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<OfferLocationCoordinateType>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_gt: InputMaybe<OfferLocationCoordinateType>;
  type_gte: InputMaybe<OfferLocationCoordinateType>;
  type_in: InputMaybe<Array<InputMaybe<OfferLocationCoordinateType>>>;
  type_lt: InputMaybe<OfferLocationCoordinateType>;
  type_lte: InputMaybe<OfferLocationCoordinateType>;
  type_ne: InputMaybe<OfferLocationCoordinateType>;
  type_nin: InputMaybe<Array<InputMaybe<OfferLocationCoordinateType>>>;
};

export enum OfferLocationCoordinateType {
  POINT = 'POINT'
}

export type OfferLocationCoordinateUpdateInput = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  coordinates_unset: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<OfferLocationCoordinateType>;
  type_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferLocationInsertInput = {
  coordinates: InputMaybe<OfferLocationCoordinateInsertInput>;
  description: Scalars['String']['input'];
  place_id: Scalars['String']['input'];
};

export type OfferLocationQueryInput = {
  AND: InputMaybe<Array<OfferLocationQueryInput>>;
  OR: InputMaybe<Array<OfferLocationQueryInput>>;
  coordinates: InputMaybe<OfferLocationCoordinateQueryInput>;
  coordinates_exists: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_gt: InputMaybe<Scalars['String']['input']>;
  description_gte: InputMaybe<Scalars['String']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_lt: InputMaybe<Scalars['String']['input']>;
  description_lte: InputMaybe<Scalars['String']['input']>;
  description_ne: InputMaybe<Scalars['String']['input']>;
  description_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  place_id: InputMaybe<Scalars['String']['input']>;
  place_id_exists: InputMaybe<Scalars['Boolean']['input']>;
  place_id_gt: InputMaybe<Scalars['String']['input']>;
  place_id_gte: InputMaybe<Scalars['String']['input']>;
  place_id_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  place_id_lt: InputMaybe<Scalars['String']['input']>;
  place_id_lte: InputMaybe<Scalars['String']['input']>;
  place_id_ne: InputMaybe<Scalars['String']['input']>;
  place_id_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OfferLocationUpdateInput = {
  coordinates: InputMaybe<OfferLocationCoordinateUpdateInput>;
  coordinates_unset: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_unset: InputMaybe<Scalars['Boolean']['input']>;
  place_id: InputMaybe<Scalars['String']['input']>;
  place_id_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferOfferAdditionRelationInput = {
  create: InputMaybe<Array<InputMaybe<OfferAdditionInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type OfferOwnerRelationInput = {
  create: InputMaybe<AccountInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type OfferPhoto = {
  __typename?: 'OfferPhoto';
  bucket: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
};

export type OfferPhotoInsertInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type OfferPhotoQueryInput = {
  AND: InputMaybe<Array<OfferPhotoQueryInput>>;
  OR: InputMaybe<Array<OfferPhotoQueryInput>>;
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_exists: InputMaybe<Scalars['Boolean']['input']>;
  bucket_gt: InputMaybe<Scalars['String']['input']>;
  bucket_gte: InputMaybe<Scalars['String']['input']>;
  bucket_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bucket_lt: InputMaybe<Scalars['String']['input']>;
  bucket_lte: InputMaybe<Scalars['String']['input']>;
  bucket_ne: InputMaybe<Scalars['String']['input']>;
  bucket_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key: InputMaybe<Scalars['String']['input']>;
  key_exists: InputMaybe<Scalars['Boolean']['input']>;
  key_gt: InputMaybe<Scalars['String']['input']>;
  key_gte: InputMaybe<Scalars['String']['input']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_lt: InputMaybe<Scalars['String']['input']>;
  key_lte: InputMaybe<Scalars['String']['input']>;
  key_ne: InputMaybe<Scalars['String']['input']>;
  key_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location: InputMaybe<Scalars['String']['input']>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
  location_gt: InputMaybe<Scalars['String']['input']>;
  location_gte: InputMaybe<Scalars['String']['input']>;
  location_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_lt: InputMaybe<Scalars['String']['input']>;
  location_lte: InputMaybe<Scalars['String']['input']>;
  location_ne: InputMaybe<Scalars['String']['input']>;
  location_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OfferPhotoUpdateInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_unset: InputMaybe<Scalars['Boolean']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  key_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferPrice = {
  __typename?: 'OfferPrice';
  currency: Maybe<Currency>;
  value: Scalars['Float']['output'];
};

export type OfferPriceCurrencyRelationInput = {
  create: InputMaybe<CurrencyInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export enum OfferPriceEstimateType {
  ESTIMATE = 'ESTIMATE',
  FINAL = 'FINAL'
}

export type OfferPriceInsertInput = {
  currency: InputMaybe<OfferPriceCurrencyRelationInput>;
  value: Scalars['Float']['input'];
};

export type OfferPriceQueryInput = {
  AND: InputMaybe<Array<OfferPriceQueryInput>>;
  OR: InputMaybe<Array<OfferPriceQueryInput>>;
  currency: InputMaybe<CurrencyQueryInput>;
  currency_exists: InputMaybe<Scalars['Boolean']['input']>;
  value: InputMaybe<Scalars['Float']['input']>;
  value_exists: InputMaybe<Scalars['Boolean']['input']>;
  value_gt: InputMaybe<Scalars['Float']['input']>;
  value_gte: InputMaybe<Scalars['Float']['input']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  value_lt: InputMaybe<Scalars['Float']['input']>;
  value_lte: InputMaybe<Scalars['Float']['input']>;
  value_ne: InputMaybe<Scalars['Float']['input']>;
  value_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type OfferPriceUpdateInput = {
  currency: InputMaybe<OfferPriceCurrencyRelationInput>;
  currency_unset: InputMaybe<Scalars['Boolean']['input']>;
  value: InputMaybe<Scalars['Float']['input']>;
  value_inc: InputMaybe<Scalars['Float']['input']>;
  value_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferQueryInput = {
  AND: InputMaybe<Array<OfferQueryInput>>;
  OR: InputMaybe<Array<OfferQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  activities: InputMaybe<Array<InputMaybe<ActivityQueryInput>>>;
  activities_exists: InputMaybe<Scalars['Boolean']['input']>;
  activities_in: InputMaybe<Array<InputMaybe<ActivityQueryInput>>>;
  activities_nin: InputMaybe<Array<InputMaybe<ActivityQueryInput>>>;
  adults: InputMaybe<Scalars['Float']['input']>;
  adults_exists: InputMaybe<Scalars['Boolean']['input']>;
  adults_gt: InputMaybe<Scalars['Float']['input']>;
  adults_gte: InputMaybe<Scalars['Float']['input']>;
  adults_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  adults_lt: InputMaybe<Scalars['Float']['input']>;
  adults_lte: InputMaybe<Scalars['Float']['input']>;
  adults_ne: InputMaybe<Scalars['Float']['input']>;
  adults_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  children: InputMaybe<Scalars['Float']['input']>;
  children_exists: InputMaybe<Scalars['Boolean']['input']>;
  children_gt: InputMaybe<Scalars['Float']['input']>;
  children_gte: InputMaybe<Scalars['Float']['input']>;
  children_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  children_lt: InputMaybe<Scalars['Float']['input']>;
  children_lte: InputMaybe<Scalars['Float']['input']>;
  children_ne: InputMaybe<Scalars['Float']['input']>;
  children_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  days: InputMaybe<Scalars['String']['input']>;
  days_exists: InputMaybe<Scalars['Boolean']['input']>;
  days_gt: InputMaybe<Scalars['String']['input']>;
  days_gte: InputMaybe<Scalars['String']['input']>;
  days_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  days_lt: InputMaybe<Scalars['String']['input']>;
  days_lte: InputMaybe<Scalars['String']['input']>;
  days_ne: InputMaybe<Scalars['String']['input']>;
  days_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_gt: InputMaybe<Scalars['String']['input']>;
  description_gte: InputMaybe<Scalars['String']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_lt: InputMaybe<Scalars['String']['input']>;
  description_lte: InputMaybe<Scalars['String']['input']>;
  description_ne: InputMaybe<Scalars['String']['input']>;
  description_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isBlocked: InputMaybe<Scalars['Boolean']['input']>;
  isBlocked_exists: InputMaybe<Scalars['Boolean']['input']>;
  isBlocked_ne: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: InputMaybe<Scalars['Boolean']['input']>;
  isPublic_exists: InputMaybe<Scalars['Boolean']['input']>;
  isPublic_ne: InputMaybe<Scalars['Boolean']['input']>;
  like: InputMaybe<OfferLikeQueryInput>;
  likeCount: InputMaybe<Scalars['Float']['input']>;
  likeCount_exists: InputMaybe<Scalars['Boolean']['input']>;
  likeCount_gt: InputMaybe<Scalars['Float']['input']>;
  likeCount_gte: InputMaybe<Scalars['Float']['input']>;
  likeCount_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  likeCount_lt: InputMaybe<Scalars['Float']['input']>;
  likeCount_lte: InputMaybe<Scalars['Float']['input']>;
  likeCount_ne: InputMaybe<Scalars['Float']['input']>;
  likeCount_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  like_exists: InputMaybe<Scalars['Boolean']['input']>;
  likes: InputMaybe<Array<InputMaybe<OfferLikeQueryInput>>>;
  likes_exists: InputMaybe<Scalars['Boolean']['input']>;
  likes_in: InputMaybe<Array<InputMaybe<OfferLikeQueryInput>>>;
  likes_nin: InputMaybe<Array<InputMaybe<OfferLikeQueryInput>>>;
  location: InputMaybe<OfferLocationQueryInput>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
  offerAddition: InputMaybe<Array<InputMaybe<OfferAdditionQueryInput>>>;
  offerAddition_exists: InputMaybe<Scalars['Boolean']['input']>;
  offerAddition_in: InputMaybe<Array<InputMaybe<OfferAdditionQueryInput>>>;
  offerAddition_nin: InputMaybe<Array<InputMaybe<OfferAdditionQueryInput>>>;
  owner: InputMaybe<AccountQueryInput>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  ownerId_gt: InputMaybe<Scalars['String']['input']>;
  ownerId_gte: InputMaybe<Scalars['String']['input']>;
  ownerId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_lt: InputMaybe<Scalars['String']['input']>;
  ownerId_lte: InputMaybe<Scalars['String']['input']>;
  ownerId_ne: InputMaybe<Scalars['String']['input']>;
  ownerId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  owner_exists: InputMaybe<Scalars['Boolean']['input']>;
  person: InputMaybe<Scalars['Float']['input']>;
  person_exists: InputMaybe<Scalars['Boolean']['input']>;
  person_gt: InputMaybe<Scalars['Float']['input']>;
  person_gte: InputMaybe<Scalars['Float']['input']>;
  person_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  person_lt: InputMaybe<Scalars['Float']['input']>;
  person_lte: InputMaybe<Scalars['Float']['input']>;
  person_ne: InputMaybe<Scalars['Float']['input']>;
  person_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  photo: InputMaybe<Array<InputMaybe<OfferPhotoQueryInput>>>;
  photo_exists: InputMaybe<Scalars['Boolean']['input']>;
  photo_in: InputMaybe<Array<InputMaybe<OfferPhotoQueryInput>>>;
  photo_nin: InputMaybe<Array<InputMaybe<OfferPhotoQueryInput>>>;
  price: InputMaybe<OfferPriceQueryInput>;
  priceEstimateType: InputMaybe<OfferPriceEstimateType>;
  priceEstimateType_exists: InputMaybe<Scalars['Boolean']['input']>;
  priceEstimateType_gt: InputMaybe<OfferPriceEstimateType>;
  priceEstimateType_gte: InputMaybe<OfferPriceEstimateType>;
  priceEstimateType_in: InputMaybe<Array<InputMaybe<OfferPriceEstimateType>>>;
  priceEstimateType_lt: InputMaybe<OfferPriceEstimateType>;
  priceEstimateType_lte: InputMaybe<OfferPriceEstimateType>;
  priceEstimateType_ne: InputMaybe<OfferPriceEstimateType>;
  priceEstimateType_nin: InputMaybe<Array<InputMaybe<OfferPriceEstimateType>>>;
  price_exists: InputMaybe<Scalars['Boolean']['input']>;
  requestId: InputMaybe<RequestQueryInput>;
  requestId_exists: InputMaybe<Scalars['Boolean']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  startDate_gt: InputMaybe<Scalars['DateTime']['input']>;
  startDate_gte: InputMaybe<Scalars['DateTime']['input']>;
  startDate_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startDate_lt: InputMaybe<Scalars['DateTime']['input']>;
  startDate_lte: InputMaybe<Scalars['DateTime']['input']>;
  startDate_ne: InputMaybe<Scalars['DateTime']['input']>;
  startDate_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  status: InputMaybe<OfferStatus>;
  status_exists: InputMaybe<Scalars['Boolean']['input']>;
  status_gt: InputMaybe<OfferStatus>;
  status_gte: InputMaybe<OfferStatus>;
  status_in: InputMaybe<Array<InputMaybe<OfferStatus>>>;
  status_lt: InputMaybe<OfferStatus>;
  status_lte: InputMaybe<OfferStatus>;
  status_ne: InputMaybe<OfferStatus>;
  status_nin: InputMaybe<Array<InputMaybe<OfferStatus>>>;
  tags: InputMaybe<Array<InputMaybe<TagQueryInput>>>;
  tags_exists: InputMaybe<Scalars['Boolean']['input']>;
  tags_in: InputMaybe<Array<InputMaybe<TagQueryInput>>>;
  tags_nin: InputMaybe<Array<InputMaybe<TagQueryInput>>>;
  title: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_gt: InputMaybe<Scalars['String']['input']>;
  title_gte: InputMaybe<Scalars['String']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_lt: InputMaybe<Scalars['String']['input']>;
  title_lte: InputMaybe<Scalars['String']['input']>;
  title_ne: InputMaybe<Scalars['String']['input']>;
  title_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  travelCategory: InputMaybe<TravelCategoryQueryInput>;
  travelCategory_exists: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<OfferType>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_gt: InputMaybe<OfferType>;
  type_gte: InputMaybe<OfferType>;
  type_in: InputMaybe<Array<InputMaybe<OfferType>>>;
  type_lt: InputMaybe<OfferType>;
  type_lte: InputMaybe<OfferType>;
  type_ne: InputMaybe<OfferType>;
  type_nin: InputMaybe<Array<InputMaybe<OfferType>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  video: InputMaybe<OfferVideoQueryInput>;
  video_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferRequestIdRelationInput = {
  create: InputMaybe<RequestInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export enum OfferSortByInput {
  ADULTS_ASC = 'ADULTS_ASC',
  ADULTS_DESC = 'ADULTS_DESC',
  CHILDREN_ASC = 'CHILDREN_ASC',
  CHILDREN_DESC = 'CHILDREN_DESC',
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  DAYS_ASC = 'DAYS_ASC',
  DAYS_DESC = 'DAYS_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  LIKECOUNT_ASC = 'LIKECOUNT_ASC',
  LIKECOUNT_DESC = 'LIKECOUNT_DESC',
  OWNERID_ASC = 'OWNERID_ASC',
  OWNERID_DESC = 'OWNERID_DESC',
  OWNER_ASC = 'OWNER_ASC',
  OWNER_DESC = 'OWNER_DESC',
  PERSON_ASC = 'PERSON_ASC',
  PERSON_DESC = 'PERSON_DESC',
  PRICEESTIMATETYPE_ASC = 'PRICEESTIMATETYPE_ASC',
  PRICEESTIMATETYPE_DESC = 'PRICEESTIMATETYPE_DESC',
  REQUESTID_ASC = 'REQUESTID_ASC',
  REQUESTID_DESC = 'REQUESTID_DESC',
  STARTDATE_ASC = 'STARTDATE_ASC',
  STARTDATE_DESC = 'STARTDATE_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  TRAVELCATEGORY_ASC = 'TRAVELCATEGORY_ASC',
  TRAVELCATEGORY_DESC = 'TRAVELCATEGORY_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  UPDATEDAT_ASC = 'UPDATEDAT_ASC',
  UPDATEDAT_DESC = 'UPDATEDAT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export enum OfferStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  HOT = 'HOT'
}

export type OfferTagsRelationInput = {
  create: InputMaybe<Array<InputMaybe<TagInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type OfferTravelCategoryRelationInput = {
  create: InputMaybe<TravelCategoryInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export enum OfferType {
  PERSON = 'PERSON',
  TOUR = 'TOUR'
}

export type OfferUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  activities: InputMaybe<OfferActivitiesRelationInput>;
  activities_unset: InputMaybe<Scalars['Boolean']['input']>;
  adults: InputMaybe<Scalars['Float']['input']>;
  adults_inc: InputMaybe<Scalars['Float']['input']>;
  adults_unset: InputMaybe<Scalars['Boolean']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  children_inc: InputMaybe<Scalars['Float']['input']>;
  children_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  days: InputMaybe<Scalars['String']['input']>;
  days_unset: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_unset: InputMaybe<Scalars['Boolean']['input']>;
  isBlocked: InputMaybe<Scalars['Boolean']['input']>;
  isBlocked_unset: InputMaybe<Scalars['Boolean']['input']>;
  isPublic: InputMaybe<Scalars['Boolean']['input']>;
  isPublic_unset: InputMaybe<Scalars['Boolean']['input']>;
  like: InputMaybe<OfferLikeUpdateInput>;
  likeCount: InputMaybe<Scalars['Float']['input']>;
  likeCount_inc: InputMaybe<Scalars['Float']['input']>;
  likeCount_unset: InputMaybe<Scalars['Boolean']['input']>;
  like_unset: InputMaybe<Scalars['Boolean']['input']>;
  likes: InputMaybe<Array<InputMaybe<OfferLikeUpdateInput>>>;
  likes_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<OfferLocationUpdateInput>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
  offerAddition: InputMaybe<OfferOfferAdditionRelationInput>;
  offerAddition_unset: InputMaybe<Scalars['Boolean']['input']>;
  owner: InputMaybe<OfferOwnerRelationInput>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_unset: InputMaybe<Scalars['Boolean']['input']>;
  owner_unset: InputMaybe<Scalars['Boolean']['input']>;
  person: InputMaybe<Scalars['Float']['input']>;
  person_inc: InputMaybe<Scalars['Float']['input']>;
  person_unset: InputMaybe<Scalars['Boolean']['input']>;
  photo: InputMaybe<Array<InputMaybe<OfferPhotoUpdateInput>>>;
  photo_unset: InputMaybe<Scalars['Boolean']['input']>;
  price: InputMaybe<OfferPriceUpdateInput>;
  priceEstimateType: InputMaybe<OfferPriceEstimateType>;
  priceEstimateType_unset: InputMaybe<Scalars['Boolean']['input']>;
  price_unset: InputMaybe<Scalars['Boolean']['input']>;
  requestId: InputMaybe<OfferRequestIdRelationInput>;
  requestId_unset: InputMaybe<Scalars['Boolean']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate_unset: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<OfferStatus>;
  status_unset: InputMaybe<Scalars['Boolean']['input']>;
  tags: InputMaybe<OfferTagsRelationInput>;
  tags_unset: InputMaybe<Scalars['Boolean']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  title_unset: InputMaybe<Scalars['Boolean']['input']>;
  travelCategory: InputMaybe<OfferTravelCategoryRelationInput>;
  travelCategory_unset: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<OfferType>;
  type_unset: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  video: InputMaybe<OfferVideoUpdateInput>;
  video_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferVideo = {
  __typename?: 'OfferVideo';
  bucket: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
};

export type OfferVideoInsertInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type OfferVideoQueryInput = {
  AND: InputMaybe<Array<OfferVideoQueryInput>>;
  OR: InputMaybe<Array<OfferVideoQueryInput>>;
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_exists: InputMaybe<Scalars['Boolean']['input']>;
  bucket_gt: InputMaybe<Scalars['String']['input']>;
  bucket_gte: InputMaybe<Scalars['String']['input']>;
  bucket_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bucket_lt: InputMaybe<Scalars['String']['input']>;
  bucket_lte: InputMaybe<Scalars['String']['input']>;
  bucket_ne: InputMaybe<Scalars['String']['input']>;
  bucket_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key: InputMaybe<Scalars['String']['input']>;
  key_exists: InputMaybe<Scalars['Boolean']['input']>;
  key_gt: InputMaybe<Scalars['String']['input']>;
  key_gte: InputMaybe<Scalars['String']['input']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_lt: InputMaybe<Scalars['String']['input']>;
  key_lte: InputMaybe<Scalars['String']['input']>;
  key_ne: InputMaybe<Scalars['String']['input']>;
  key_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location: InputMaybe<Scalars['String']['input']>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
  location_gt: InputMaybe<Scalars['String']['input']>;
  location_gte: InputMaybe<Scalars['String']['input']>;
  location_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_lt: InputMaybe<Scalars['String']['input']>;
  location_lte: InputMaybe<Scalars['String']['input']>;
  location_ne: InputMaybe<Scalars['String']['input']>;
  location_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OfferVideoUpdateInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_unset: InputMaybe<Scalars['Boolean']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  key_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Order = {
  __typename?: 'Order';
  _id: Scalars['ObjectId']['output'];
  additionalNotes: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  endAt: Maybe<Scalars['DateTime']['output']>;
  isRead: Maybe<Scalars['Boolean']['output']>;
  offer: Maybe<Offer>;
  offerOwner: Maybe<Account>;
  orderNumber: Maybe<Scalars['Float']['output']>;
  originalOffer: Maybe<Offer>;
  owner: Maybe<Account>;
  ownerId: Maybe<Scalars['String']['output']>;
  persons: Maybe<OrderPerson>;
  reasons: Maybe<Array<Maybe<OrderReason>>>;
  startAt: Maybe<Scalars['DateTime']['output']>;
  status: OrderStatus;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type OrderInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  additionalNotes: InputMaybe<Scalars['String']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  endAt: InputMaybe<Scalars['DateTime']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  offer: InputMaybe<OrderOfferRelationInput>;
  offerOwner: InputMaybe<OrderOfferOwnerRelationInput>;
  orderNumber: InputMaybe<Scalars['Float']['input']>;
  originalOffer: InputMaybe<OrderOriginalOfferRelationInput>;
  owner: InputMaybe<OrderOwnerRelationInput>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  persons: InputMaybe<OrderPersonInsertInput>;
  reasons: InputMaybe<Array<InputMaybe<OrderReasonInsertInput>>>;
  startAt: InputMaybe<Scalars['DateTime']['input']>;
  status: OrderStatus;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrderOfferOwnerRelationInput = {
  create: InputMaybe<AccountInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type OrderOfferRelationInput = {
  create: InputMaybe<OfferInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type OrderOriginalOfferRelationInput = {
  create: InputMaybe<OfferInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type OrderOwnerRelationInput = {
  create: InputMaybe<AccountInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type OrderPerson = {
  __typename?: 'OrderPerson';
  adults: Scalars['Int']['output'];
  children: Maybe<Scalars['Int']['output']>;
  infant: Maybe<Scalars['Int']['output']>;
};

export type OrderPersonInsertInput = {
  adults: Scalars['Int']['input'];
  children: InputMaybe<Scalars['Int']['input']>;
  infant: InputMaybe<Scalars['Int']['input']>;
};

export type OrderPersonQueryInput = {
  AND: InputMaybe<Array<OrderPersonQueryInput>>;
  OR: InputMaybe<Array<OrderPersonQueryInput>>;
  adults: InputMaybe<Scalars['Int']['input']>;
  adults_exists: InputMaybe<Scalars['Boolean']['input']>;
  adults_gt: InputMaybe<Scalars['Int']['input']>;
  adults_gte: InputMaybe<Scalars['Int']['input']>;
  adults_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  adults_lt: InputMaybe<Scalars['Int']['input']>;
  adults_lte: InputMaybe<Scalars['Int']['input']>;
  adults_ne: InputMaybe<Scalars['Int']['input']>;
  adults_nin: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  children: InputMaybe<Scalars['Int']['input']>;
  children_exists: InputMaybe<Scalars['Boolean']['input']>;
  children_gt: InputMaybe<Scalars['Int']['input']>;
  children_gte: InputMaybe<Scalars['Int']['input']>;
  children_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  children_lt: InputMaybe<Scalars['Int']['input']>;
  children_lte: InputMaybe<Scalars['Int']['input']>;
  children_ne: InputMaybe<Scalars['Int']['input']>;
  children_nin: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  infant: InputMaybe<Scalars['Int']['input']>;
  infant_exists: InputMaybe<Scalars['Boolean']['input']>;
  infant_gt: InputMaybe<Scalars['Int']['input']>;
  infant_gte: InputMaybe<Scalars['Int']['input']>;
  infant_in: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  infant_lt: InputMaybe<Scalars['Int']['input']>;
  infant_lte: InputMaybe<Scalars['Int']['input']>;
  infant_ne: InputMaybe<Scalars['Int']['input']>;
  infant_nin: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type OrderPersonUpdateInput = {
  adults: InputMaybe<Scalars['Int']['input']>;
  adults_inc: InputMaybe<Scalars['Int']['input']>;
  adults_unset: InputMaybe<Scalars['Boolean']['input']>;
  children: InputMaybe<Scalars['Int']['input']>;
  children_inc: InputMaybe<Scalars['Int']['input']>;
  children_unset: InputMaybe<Scalars['Boolean']['input']>;
  infant: InputMaybe<Scalars['Int']['input']>;
  infant_inc: InputMaybe<Scalars['Int']['input']>;
  infant_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderQueryInput = {
  AND: InputMaybe<Array<OrderQueryInput>>;
  OR: InputMaybe<Array<OrderQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  additionalNotes: InputMaybe<Scalars['String']['input']>;
  additionalNotes_exists: InputMaybe<Scalars['Boolean']['input']>;
  additionalNotes_gt: InputMaybe<Scalars['String']['input']>;
  additionalNotes_gte: InputMaybe<Scalars['String']['input']>;
  additionalNotes_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  additionalNotes_lt: InputMaybe<Scalars['String']['input']>;
  additionalNotes_lte: InputMaybe<Scalars['String']['input']>;
  additionalNotes_ne: InputMaybe<Scalars['String']['input']>;
  additionalNotes_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  endAt: InputMaybe<Scalars['DateTime']['input']>;
  endAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  endAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  endAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  endAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  endAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  endAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  endAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  endAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  isRead_exists: InputMaybe<Scalars['Boolean']['input']>;
  isRead_ne: InputMaybe<Scalars['Boolean']['input']>;
  offer: InputMaybe<OfferQueryInput>;
  offerOwner: InputMaybe<AccountQueryInput>;
  offerOwner_exists: InputMaybe<Scalars['Boolean']['input']>;
  offer_exists: InputMaybe<Scalars['Boolean']['input']>;
  orderNumber: InputMaybe<Scalars['Float']['input']>;
  orderNumber_exists: InputMaybe<Scalars['Boolean']['input']>;
  orderNumber_gt: InputMaybe<Scalars['Float']['input']>;
  orderNumber_gte: InputMaybe<Scalars['Float']['input']>;
  orderNumber_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  orderNumber_lt: InputMaybe<Scalars['Float']['input']>;
  orderNumber_lte: InputMaybe<Scalars['Float']['input']>;
  orderNumber_ne: InputMaybe<Scalars['Float']['input']>;
  orderNumber_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  originalOffer: InputMaybe<OfferQueryInput>;
  originalOffer_exists: InputMaybe<Scalars['Boolean']['input']>;
  owner: InputMaybe<AccountQueryInput>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  ownerId_gt: InputMaybe<Scalars['String']['input']>;
  ownerId_gte: InputMaybe<Scalars['String']['input']>;
  ownerId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_lt: InputMaybe<Scalars['String']['input']>;
  ownerId_lte: InputMaybe<Scalars['String']['input']>;
  ownerId_ne: InputMaybe<Scalars['String']['input']>;
  ownerId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  owner_exists: InputMaybe<Scalars['Boolean']['input']>;
  persons: InputMaybe<OrderPersonQueryInput>;
  persons_exists: InputMaybe<Scalars['Boolean']['input']>;
  reasons: InputMaybe<Array<InputMaybe<OrderReasonQueryInput>>>;
  reasons_exists: InputMaybe<Scalars['Boolean']['input']>;
  reasons_in: InputMaybe<Array<InputMaybe<OrderReasonQueryInput>>>;
  reasons_nin: InputMaybe<Array<InputMaybe<OrderReasonQueryInput>>>;
  startAt: InputMaybe<Scalars['DateTime']['input']>;
  startAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  startAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  startAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  startAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  startAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  startAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  startAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  status: InputMaybe<OrderStatus>;
  status_exists: InputMaybe<Scalars['Boolean']['input']>;
  status_gt: InputMaybe<OrderStatus>;
  status_gte: InputMaybe<OrderStatus>;
  status_in: InputMaybe<Array<InputMaybe<OrderStatus>>>;
  status_lt: InputMaybe<OrderStatus>;
  status_lte: InputMaybe<OrderStatus>;
  status_ne: InputMaybe<OrderStatus>;
  status_nin: InputMaybe<Array<InputMaybe<OrderStatus>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

export type OrderReason = {
  __typename?: 'OrderReason';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
};

export type OrderReasonInsertInput = {
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
};

export type OrderReasonQueryInput = {
  AND: InputMaybe<Array<OrderReasonQueryInput>>;
  OR: InputMaybe<Array<OrderReasonQueryInput>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  description: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_gt: InputMaybe<Scalars['String']['input']>;
  description_gte: InputMaybe<Scalars['String']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_lt: InputMaybe<Scalars['String']['input']>;
  description_lte: InputMaybe<Scalars['String']['input']>;
  description_ne: InputMaybe<Scalars['String']['input']>;
  description_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OrderReasonUpdateInput = {
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrderSortByInput {
  ADDITIONALNOTES_ASC = 'ADDITIONALNOTES_ASC',
  ADDITIONALNOTES_DESC = 'ADDITIONALNOTES_DESC',
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  ENDAT_ASC = 'ENDAT_ASC',
  ENDAT_DESC = 'ENDAT_DESC',
  OFFEROWNER_ASC = 'OFFEROWNER_ASC',
  OFFEROWNER_DESC = 'OFFEROWNER_DESC',
  OFFER_ASC = 'OFFER_ASC',
  OFFER_DESC = 'OFFER_DESC',
  ORDERNUMBER_ASC = 'ORDERNUMBER_ASC',
  ORDERNUMBER_DESC = 'ORDERNUMBER_DESC',
  ORIGINALOFFER_ASC = 'ORIGINALOFFER_ASC',
  ORIGINALOFFER_DESC = 'ORIGINALOFFER_DESC',
  OWNERID_ASC = 'OWNERID_ASC',
  OWNERID_DESC = 'OWNERID_DESC',
  OWNER_ASC = 'OWNER_ASC',
  OWNER_DESC = 'OWNER_DESC',
  STARTAT_ASC = 'STARTAT_ASC',
  STARTAT_DESC = 'STARTAT_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  UPDATEDAT_ASC = 'UPDATEDAT_ASC',
  UPDATEDAT_DESC = 'UPDATEDAT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export enum OrderStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  PENDING_AGENT = 'PENDING_AGENT',
  PENDING_TRAVELER = 'PENDING_TRAVELER'
}

export type OrderUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  additionalNotes: InputMaybe<Scalars['String']['input']>;
  additionalNotes_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  endAt: InputMaybe<Scalars['DateTime']['input']>;
  endAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  isRead_unset: InputMaybe<Scalars['Boolean']['input']>;
  offer: InputMaybe<OrderOfferRelationInput>;
  offerOwner: InputMaybe<OrderOfferOwnerRelationInput>;
  offerOwner_unset: InputMaybe<Scalars['Boolean']['input']>;
  offer_unset: InputMaybe<Scalars['Boolean']['input']>;
  orderNumber: InputMaybe<Scalars['Float']['input']>;
  orderNumber_inc: InputMaybe<Scalars['Float']['input']>;
  orderNumber_unset: InputMaybe<Scalars['Boolean']['input']>;
  originalOffer: InputMaybe<OrderOriginalOfferRelationInput>;
  originalOffer_unset: InputMaybe<Scalars['Boolean']['input']>;
  owner: InputMaybe<OrderOwnerRelationInput>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_unset: InputMaybe<Scalars['Boolean']['input']>;
  owner_unset: InputMaybe<Scalars['Boolean']['input']>;
  persons: InputMaybe<OrderPersonUpdateInput>;
  persons_unset: InputMaybe<Scalars['Boolean']['input']>;
  reasons: InputMaybe<Array<InputMaybe<OrderReasonUpdateInput>>>;
  reasons_unset: InputMaybe<Scalars['Boolean']['input']>;
  startAt: InputMaybe<Scalars['DateTime']['input']>;
  startAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<OrderStatus>;
  status_unset: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Query = {
  __typename?: 'Query';
  account: Maybe<Account>;
  accounts: Array<Maybe<Account>>;
  activities: Array<Maybe<Activity>>;
  activity: Maybe<Activity>;
  business: Maybe<Business>;
  businesses: Array<Maybe<Business>>;
  chat: Maybe<Chat>;
  chats: Array<Maybe<Chat>>;
  countries: Array<Maybe<Country>>;
  country: Maybe<Country>;
  currencies: Array<Maybe<Currency>>;
  currency: Maybe<Currency>;
  findAccounts: Maybe<FindAccountsPayload>;
  findActivities: Maybe<FindActivitiesPayload>;
  findAgents: Maybe<FindAgentsPayload>;
  findChats: Maybe<FindChatsPayload>;
  findMessages: Maybe<FindMessagesPayload>;
  findNotifications: Maybe<FindNotificationsPayload>;
  findOffers: Maybe<FindOfferPayload>;
  findOffersClient: Maybe<FindOffersClientPayload>;
  findOrders: Maybe<FindOrdersPayload>;
  findRequest: Maybe<FindRequestPayload>;
  findTravelerCharacteristics: Maybe<FindTravelerCharacteristicsPayload>;
  getAccount: Maybe<Account>;
  getChat: Maybe<Chat>;
  getChatStatus: Maybe<GetChatPayload>;
  getClientCounters: Maybe<GetClientCountersPayload>;
  getCounters: Maybe<GetCountersPayload>;
  getOffer: Maybe<Offer>;
  getOrder: Maybe<Order>;
  language: Maybe<Language>;
  languages: Array<Maybe<Language>>;
  message: Maybe<Message>;
  messages: Array<Maybe<Message>>;
  notification: Maybe<Notification>;
  notifications: Array<Maybe<Notification>>;
  offer: Maybe<Offer>;
  offerAddition: Maybe<OfferAddition>;
  offerAdditions: Array<Maybe<OfferAddition>>;
  offers: Array<Maybe<Offer>>;
  order: Maybe<Order>;
  orders: Array<Maybe<Order>>;
  request: Maybe<Request>;
  requests: Array<Maybe<Request>>;
  tag: Maybe<Tag>;
  tags: Array<Maybe<Tag>>;
  template: Maybe<Template>;
  templates: Array<Maybe<Template>>;
  travelCategories: Array<Maybe<TravelCategory>>;
  travelCategory: Maybe<TravelCategory>;
  travelerCharacteristic: Maybe<TravelerCharacteristic>;
  travelerCharacteristics: Array<Maybe<TravelerCharacteristic>>;
};


export type QueryAccountArgs = {
  query: InputMaybe<AccountQueryInput>;
};


export type QueryAccountsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<AccountQueryInput>;
  sortBy: InputMaybe<AccountSortByInput>;
};


export type QueryActivitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<ActivityQueryInput>;
  sortBy: InputMaybe<ActivitySortByInput>;
};


export type QueryActivityArgs = {
  query: InputMaybe<ActivityQueryInput>;
};


export type QueryBusinessArgs = {
  query: InputMaybe<BusinessQueryInput>;
};


export type QueryBusinessesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<BusinessQueryInput>;
  sortBy: InputMaybe<BusinessSortByInput>;
};


export type QueryChatArgs = {
  query: InputMaybe<ChatQueryInput>;
};


export type QueryChatsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<ChatQueryInput>;
  sortBy: InputMaybe<ChatSortByInput>;
};


export type QueryCountriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<CountryQueryInput>;
  sortBy: InputMaybe<CountrySortByInput>;
};


export type QueryCountryArgs = {
  query: InputMaybe<CountryQueryInput>;
};


export type QueryCurrenciesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<CurrencyQueryInput>;
  sortBy: InputMaybe<CurrencySortByInput>;
};


export type QueryCurrencyArgs = {
  query: InputMaybe<CurrencyQueryInput>;
};


export type QueryFindAccountsArgs = {
  input: InputMaybe<FindAccountsInput>;
};


export type QueryFindActivitiesArgs = {
  input: InputMaybe<FindActivitiesInput>;
};


export type QueryFindAgentsArgs = {
  input: InputMaybe<FindAgentsInput>;
};


export type QueryFindChatsArgs = {
  input: InputMaybe<FindChatsInput>;
};


export type QueryFindMessagesArgs = {
  input: InputMaybe<FindMessagesInput>;
};


export type QueryFindNotificationsArgs = {
  input: InputMaybe<FindNotificationsInput>;
};


export type QueryFindOffersArgs = {
  input: InputMaybe<FindOffersInput>;
};


export type QueryFindOffersClientArgs = {
  input: InputMaybe<FindOffersClientInput>;
};


export type QueryFindOrdersArgs = {
  input: InputMaybe<FindOrdersInput>;
};


export type QueryFindRequestArgs = {
  input: InputMaybe<FindRequestInput>;
};


export type QueryGetChatArgs = {
  input: InputMaybe<GetChatInput>;
};


export type QueryGetChatStatusArgs = {
  input: InputMaybe<GetChatStatusInput>;
};


export type QueryGetOfferArgs = {
  input: InputMaybe<GetOfferInput>;
};


export type QueryGetOrderArgs = {
  input: InputMaybe<GetOrderInput>;
};


export type QueryLanguageArgs = {
  query: InputMaybe<LanguageQueryInput>;
};


export type QueryLanguagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<LanguageQueryInput>;
  sortBy: InputMaybe<LanguageSortByInput>;
};


export type QueryMessageArgs = {
  query: InputMaybe<MessageQueryInput>;
};


export type QueryMessagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<MessageQueryInput>;
  sortBy: InputMaybe<MessageSortByInput>;
};


export type QueryNotificationArgs = {
  query: InputMaybe<NotificationQueryInput>;
};


export type QueryNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<NotificationQueryInput>;
  sortBy: InputMaybe<NotificationSortByInput>;
};


export type QueryOfferArgs = {
  query: InputMaybe<OfferQueryInput>;
};


export type QueryOfferAdditionArgs = {
  query: InputMaybe<OfferAdditionQueryInput>;
};


export type QueryOfferAdditionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<OfferAdditionQueryInput>;
  sortBy: InputMaybe<OfferAdditionSortByInput>;
};


export type QueryOffersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<OfferQueryInput>;
  sortBy: InputMaybe<OfferSortByInput>;
};


export type QueryOrderArgs = {
  query: InputMaybe<OrderQueryInput>;
};


export type QueryOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<OrderQueryInput>;
  sortBy: InputMaybe<OrderSortByInput>;
};


export type QueryRequestArgs = {
  query: InputMaybe<RequestQueryInput>;
};


export type QueryRequestsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<RequestQueryInput>;
  sortBy: InputMaybe<RequestSortByInput>;
};


export type QueryTagArgs = {
  query: InputMaybe<TagQueryInput>;
};


export type QueryTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<TagQueryInput>;
  sortBy: InputMaybe<TagSortByInput>;
};


export type QueryTemplateArgs = {
  query: InputMaybe<TemplateQueryInput>;
};


export type QueryTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<TemplateQueryInput>;
  sortBy: InputMaybe<TemplateSortByInput>;
};


export type QueryTravelCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<TravelCategoryQueryInput>;
  sortBy: InputMaybe<TravelCategorySortByInput>;
};


export type QueryTravelCategoryArgs = {
  query: InputMaybe<TravelCategoryQueryInput>;
};


export type QueryTravelerCharacteristicArgs = {
  query: InputMaybe<TravelerCharacteristicQueryInput>;
};


export type QueryTravelerCharacteristicsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: InputMaybe<TravelerCharacteristicQueryInput>;
  sortBy: InputMaybe<TravelerCharacteristicSortByInput>;
};

export type Request = {
  __typename?: 'Request';
  _id: Scalars['ObjectId']['output'];
  adults: Maybe<Scalars['Float']['output']>;
  archiveOffers: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  children: Maybe<Scalars['Float']['output']>;
  childrenAge: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  date: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  isHidden: Maybe<Scalars['Boolean']['output']>;
  isRead: Maybe<Scalars['Boolean']['output']>;
  likedOffers: Maybe<Array<Maybe<Offer>>>;
  location: Maybe<RequestLocation>;
  locationStart: Maybe<RequestLocationStart>;
  nights: Maybe<Scalars['Float']['output']>;
  offersCount: Maybe<Scalars['Long']['output']>;
  owner: Maybe<Account>;
  ownerId: Scalars['String']['output'];
  periodDays: Maybe<Scalars['Float']['output']>;
  price: Maybe<RequestPrice>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: Maybe<RequestStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  viewedAccounts: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type RequestInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  adults: InputMaybe<Scalars['Float']['input']>;
  archiveOffers: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  children: InputMaybe<Scalars['Float']['input']>;
  childrenAge: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  date: InputMaybe<Scalars['DateTime']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  isHidden: InputMaybe<Scalars['Boolean']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  likedOffers: InputMaybe<RequestLikedOffersRelationInput>;
  location: InputMaybe<RequestLocationInsertInput>;
  locationStart: InputMaybe<RequestLocationStartInsertInput>;
  nights: InputMaybe<Scalars['Float']['input']>;
  offersCount: InputMaybe<Scalars['Long']['input']>;
  owner: InputMaybe<RequestOwnerRelationInput>;
  ownerId: Scalars['String']['input'];
  periodDays: InputMaybe<Scalars['Float']['input']>;
  price: InputMaybe<RequestPriceInsertInput>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<RequestStatus>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  viewedAccounts: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RequestLikedOffersRelationInput = {
  create: InputMaybe<Array<InputMaybe<OfferInsertInput>>>;
  link: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export type RequestLocation = {
  __typename?: 'RequestLocation';
  coordinates: RequestLocationCoordinate;
  description: Scalars['String']['output'];
  place_id: Scalars['String']['output'];
};

export type RequestLocationCoordinate = {
  __typename?: 'RequestLocationCoordinate';
  coordinates: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type: Maybe<RequestLocationCoordinateType>;
};

export type RequestLocationCoordinateInsertInput = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<RequestLocationCoordinateType>;
};

export type RequestLocationCoordinateQueryInput = {
  AND: InputMaybe<Array<RequestLocationCoordinateQueryInput>>;
  OR: InputMaybe<Array<RequestLocationCoordinateQueryInput>>;
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  coordinates_exists: InputMaybe<Scalars['Boolean']['input']>;
  coordinates_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  coordinates_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<RequestLocationCoordinateType>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_gt: InputMaybe<RequestLocationCoordinateType>;
  type_gte: InputMaybe<RequestLocationCoordinateType>;
  type_in: InputMaybe<Array<InputMaybe<RequestLocationCoordinateType>>>;
  type_lt: InputMaybe<RequestLocationCoordinateType>;
  type_lte: InputMaybe<RequestLocationCoordinateType>;
  type_ne: InputMaybe<RequestLocationCoordinateType>;
  type_nin: InputMaybe<Array<InputMaybe<RequestLocationCoordinateType>>>;
};

export enum RequestLocationCoordinateType {
  POINT = 'POINT'
}

export type RequestLocationCoordinateUpdateInput = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  coordinates_unset: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<RequestLocationCoordinateType>;
  type_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestLocationInsertInput = {
  coordinates: RequestLocationCoordinateInsertInput;
  description: Scalars['String']['input'];
  place_id: Scalars['String']['input'];
};

export type RequestLocationQueryInput = {
  AND: InputMaybe<Array<RequestLocationQueryInput>>;
  OR: InputMaybe<Array<RequestLocationQueryInput>>;
  coordinates: InputMaybe<RequestLocationCoordinateQueryInput>;
  coordinates_exists: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_gt: InputMaybe<Scalars['String']['input']>;
  description_gte: InputMaybe<Scalars['String']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_lt: InputMaybe<Scalars['String']['input']>;
  description_lte: InputMaybe<Scalars['String']['input']>;
  description_ne: InputMaybe<Scalars['String']['input']>;
  description_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  place_id: InputMaybe<Scalars['String']['input']>;
  place_id_exists: InputMaybe<Scalars['Boolean']['input']>;
  place_id_gt: InputMaybe<Scalars['String']['input']>;
  place_id_gte: InputMaybe<Scalars['String']['input']>;
  place_id_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  place_id_lt: InputMaybe<Scalars['String']['input']>;
  place_id_lte: InputMaybe<Scalars['String']['input']>;
  place_id_ne: InputMaybe<Scalars['String']['input']>;
  place_id_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RequestLocationStart = {
  __typename?: 'RequestLocationStart';
  coordinates: RequestLocationStartCoordinate;
  description: Scalars['String']['output'];
  place_id: Scalars['String']['output'];
};

export type RequestLocationStartCoordinate = {
  __typename?: 'RequestLocationStartCoordinate';
  coordinates: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type: Maybe<RequestLocationStartCoordinateType>;
};

export type RequestLocationStartCoordinateInsertInput = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<RequestLocationStartCoordinateType>;
};

export type RequestLocationStartCoordinateQueryInput = {
  AND: InputMaybe<Array<RequestLocationStartCoordinateQueryInput>>;
  OR: InputMaybe<Array<RequestLocationStartCoordinateQueryInput>>;
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  coordinates_exists: InputMaybe<Scalars['Boolean']['input']>;
  coordinates_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  coordinates_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<RequestLocationStartCoordinateType>;
  type_exists: InputMaybe<Scalars['Boolean']['input']>;
  type_gt: InputMaybe<RequestLocationStartCoordinateType>;
  type_gte: InputMaybe<RequestLocationStartCoordinateType>;
  type_in: InputMaybe<Array<InputMaybe<RequestLocationStartCoordinateType>>>;
  type_lt: InputMaybe<RequestLocationStartCoordinateType>;
  type_lte: InputMaybe<RequestLocationStartCoordinateType>;
  type_ne: InputMaybe<RequestLocationStartCoordinateType>;
  type_nin: InputMaybe<Array<InputMaybe<RequestLocationStartCoordinateType>>>;
};

export enum RequestLocationStartCoordinateType {
  POINT = 'POINT'
}

export type RequestLocationStartCoordinateUpdateInput = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  coordinates_unset: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<RequestLocationStartCoordinateType>;
  type_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestLocationStartInsertInput = {
  coordinates: RequestLocationStartCoordinateInsertInput;
  description: Scalars['String']['input'];
  place_id: Scalars['String']['input'];
};

export type RequestLocationStartQueryInput = {
  AND: InputMaybe<Array<RequestLocationStartQueryInput>>;
  OR: InputMaybe<Array<RequestLocationStartQueryInput>>;
  coordinates: InputMaybe<RequestLocationStartCoordinateQueryInput>;
  coordinates_exists: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_gt: InputMaybe<Scalars['String']['input']>;
  description_gte: InputMaybe<Scalars['String']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_lt: InputMaybe<Scalars['String']['input']>;
  description_lte: InputMaybe<Scalars['String']['input']>;
  description_ne: InputMaybe<Scalars['String']['input']>;
  description_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  place_id: InputMaybe<Scalars['String']['input']>;
  place_id_exists: InputMaybe<Scalars['Boolean']['input']>;
  place_id_gt: InputMaybe<Scalars['String']['input']>;
  place_id_gte: InputMaybe<Scalars['String']['input']>;
  place_id_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  place_id_lt: InputMaybe<Scalars['String']['input']>;
  place_id_lte: InputMaybe<Scalars['String']['input']>;
  place_id_ne: InputMaybe<Scalars['String']['input']>;
  place_id_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RequestLocationStartUpdateInput = {
  coordinates: InputMaybe<RequestLocationStartCoordinateUpdateInput>;
  coordinates_unset: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_unset: InputMaybe<Scalars['Boolean']['input']>;
  place_id: InputMaybe<Scalars['String']['input']>;
  place_id_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestLocationUpdateInput = {
  coordinates: InputMaybe<RequestLocationCoordinateUpdateInput>;
  coordinates_unset: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_unset: InputMaybe<Scalars['Boolean']['input']>;
  place_id: InputMaybe<Scalars['String']['input']>;
  place_id_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestOwnerRelationInput = {
  create: InputMaybe<AccountInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type RequestPrice = {
  __typename?: 'RequestPrice';
  currency: Maybe<Currency>;
  value: Scalars['Float']['output'];
};

export type RequestPriceCurrencyRelationInput = {
  create: InputMaybe<CurrencyInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type RequestPriceInsertInput = {
  currency: InputMaybe<RequestPriceCurrencyRelationInput>;
  value: Scalars['Float']['input'];
};

export type RequestPriceQueryInput = {
  AND: InputMaybe<Array<RequestPriceQueryInput>>;
  OR: InputMaybe<Array<RequestPriceQueryInput>>;
  currency: InputMaybe<CurrencyQueryInput>;
  currency_exists: InputMaybe<Scalars['Boolean']['input']>;
  value: InputMaybe<Scalars['Float']['input']>;
  value_exists: InputMaybe<Scalars['Boolean']['input']>;
  value_gt: InputMaybe<Scalars['Float']['input']>;
  value_gte: InputMaybe<Scalars['Float']['input']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  value_lt: InputMaybe<Scalars['Float']['input']>;
  value_lte: InputMaybe<Scalars['Float']['input']>;
  value_ne: InputMaybe<Scalars['Float']['input']>;
  value_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type RequestPriceUpdateInput = {
  currency: InputMaybe<RequestPriceCurrencyRelationInput>;
  currency_unset: InputMaybe<Scalars['Boolean']['input']>;
  value: InputMaybe<Scalars['Float']['input']>;
  value_inc: InputMaybe<Scalars['Float']['input']>;
  value_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequestQueryInput = {
  AND: InputMaybe<Array<RequestQueryInput>>;
  OR: InputMaybe<Array<RequestQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  adults: InputMaybe<Scalars['Float']['input']>;
  adults_exists: InputMaybe<Scalars['Boolean']['input']>;
  adults_gt: InputMaybe<Scalars['Float']['input']>;
  adults_gte: InputMaybe<Scalars['Float']['input']>;
  adults_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  adults_lt: InputMaybe<Scalars['Float']['input']>;
  adults_lte: InputMaybe<Scalars['Float']['input']>;
  adults_ne: InputMaybe<Scalars['Float']['input']>;
  adults_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  archiveOffers: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  archiveOffers_exists: InputMaybe<Scalars['Boolean']['input']>;
  archiveOffers_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  archiveOffers_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  children: InputMaybe<Scalars['Float']['input']>;
  childrenAge: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  childrenAge_exists: InputMaybe<Scalars['Boolean']['input']>;
  childrenAge_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  childrenAge_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  children_exists: InputMaybe<Scalars['Boolean']['input']>;
  children_gt: InputMaybe<Scalars['Float']['input']>;
  children_gte: InputMaybe<Scalars['Float']['input']>;
  children_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  children_lt: InputMaybe<Scalars['Float']['input']>;
  children_lte: InputMaybe<Scalars['Float']['input']>;
  children_ne: InputMaybe<Scalars['Float']['input']>;
  children_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  date: InputMaybe<Scalars['DateTime']['input']>;
  date_exists: InputMaybe<Scalars['Boolean']['input']>;
  date_gt: InputMaybe<Scalars['DateTime']['input']>;
  date_gte: InputMaybe<Scalars['DateTime']['input']>;
  date_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  date_lt: InputMaybe<Scalars['DateTime']['input']>;
  date_lte: InputMaybe<Scalars['DateTime']['input']>;
  date_ne: InputMaybe<Scalars['DateTime']['input']>;
  date_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  description: InputMaybe<Scalars['String']['input']>;
  description_exists: InputMaybe<Scalars['Boolean']['input']>;
  description_gt: InputMaybe<Scalars['String']['input']>;
  description_gte: InputMaybe<Scalars['String']['input']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_lt: InputMaybe<Scalars['String']['input']>;
  description_lte: InputMaybe<Scalars['String']['input']>;
  description_ne: InputMaybe<Scalars['String']['input']>;
  description_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  endDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  endDate_gt: InputMaybe<Scalars['DateTime']['input']>;
  endDate_gte: InputMaybe<Scalars['DateTime']['input']>;
  endDate_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  endDate_lt: InputMaybe<Scalars['DateTime']['input']>;
  endDate_lte: InputMaybe<Scalars['DateTime']['input']>;
  endDate_ne: InputMaybe<Scalars['DateTime']['input']>;
  endDate_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  isHidden: InputMaybe<Scalars['Boolean']['input']>;
  isHidden_exists: InputMaybe<Scalars['Boolean']['input']>;
  isHidden_ne: InputMaybe<Scalars['Boolean']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  isRead_exists: InputMaybe<Scalars['Boolean']['input']>;
  isRead_ne: InputMaybe<Scalars['Boolean']['input']>;
  likedOffers: InputMaybe<Array<InputMaybe<OfferQueryInput>>>;
  likedOffers_exists: InputMaybe<Scalars['Boolean']['input']>;
  likedOffers_in: InputMaybe<Array<InputMaybe<OfferQueryInput>>>;
  likedOffers_nin: InputMaybe<Array<InputMaybe<OfferQueryInput>>>;
  location: InputMaybe<RequestLocationQueryInput>;
  locationStart: InputMaybe<RequestLocationStartQueryInput>;
  locationStart_exists: InputMaybe<Scalars['Boolean']['input']>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
  nights: InputMaybe<Scalars['Float']['input']>;
  nights_exists: InputMaybe<Scalars['Boolean']['input']>;
  nights_gt: InputMaybe<Scalars['Float']['input']>;
  nights_gte: InputMaybe<Scalars['Float']['input']>;
  nights_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nights_lt: InputMaybe<Scalars['Float']['input']>;
  nights_lte: InputMaybe<Scalars['Float']['input']>;
  nights_ne: InputMaybe<Scalars['Float']['input']>;
  nights_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  offersCount: InputMaybe<Scalars['Long']['input']>;
  offersCount_exists: InputMaybe<Scalars['Boolean']['input']>;
  offersCount_gt: InputMaybe<Scalars['Long']['input']>;
  offersCount_gte: InputMaybe<Scalars['Long']['input']>;
  offersCount_in: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  offersCount_lt: InputMaybe<Scalars['Long']['input']>;
  offersCount_lte: InputMaybe<Scalars['Long']['input']>;
  offersCount_ne: InputMaybe<Scalars['Long']['input']>;
  offersCount_nin: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  owner: InputMaybe<AccountQueryInput>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  ownerId_gt: InputMaybe<Scalars['String']['input']>;
  ownerId_gte: InputMaybe<Scalars['String']['input']>;
  ownerId_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId_lt: InputMaybe<Scalars['String']['input']>;
  ownerId_lte: InputMaybe<Scalars['String']['input']>;
  ownerId_ne: InputMaybe<Scalars['String']['input']>;
  ownerId_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  owner_exists: InputMaybe<Scalars['Boolean']['input']>;
  periodDays: InputMaybe<Scalars['Float']['input']>;
  periodDays_exists: InputMaybe<Scalars['Boolean']['input']>;
  periodDays_gt: InputMaybe<Scalars['Float']['input']>;
  periodDays_gte: InputMaybe<Scalars['Float']['input']>;
  periodDays_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  periodDays_lt: InputMaybe<Scalars['Float']['input']>;
  periodDays_lte: InputMaybe<Scalars['Float']['input']>;
  periodDays_ne: InputMaybe<Scalars['Float']['input']>;
  periodDays_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  price: InputMaybe<RequestPriceQueryInput>;
  price_exists: InputMaybe<Scalars['Boolean']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate_exists: InputMaybe<Scalars['Boolean']['input']>;
  startDate_gt: InputMaybe<Scalars['DateTime']['input']>;
  startDate_gte: InputMaybe<Scalars['DateTime']['input']>;
  startDate_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startDate_lt: InputMaybe<Scalars['DateTime']['input']>;
  startDate_lte: InputMaybe<Scalars['DateTime']['input']>;
  startDate_ne: InputMaybe<Scalars['DateTime']['input']>;
  startDate_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  status: InputMaybe<RequestStatus>;
  status_exists: InputMaybe<Scalars['Boolean']['input']>;
  status_gt: InputMaybe<RequestStatus>;
  status_gte: InputMaybe<RequestStatus>;
  status_in: InputMaybe<Array<InputMaybe<RequestStatus>>>;
  status_lt: InputMaybe<RequestStatus>;
  status_lte: InputMaybe<RequestStatus>;
  status_ne: InputMaybe<RequestStatus>;
  status_nin: InputMaybe<Array<InputMaybe<RequestStatus>>>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  viewedAccounts: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  viewedAccounts_exists: InputMaybe<Scalars['Boolean']['input']>;
  viewedAccounts_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  viewedAccounts_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum RequestSortByInput {
  ADULTS_ASC = 'ADULTS_ASC',
  ADULTS_DESC = 'ADULTS_DESC',
  CHILDREN_ASC = 'CHILDREN_ASC',
  CHILDREN_DESC = 'CHILDREN_DESC',
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  ENDDATE_ASC = 'ENDDATE_ASC',
  ENDDATE_DESC = 'ENDDATE_DESC',
  NIGHTS_ASC = 'NIGHTS_ASC',
  NIGHTS_DESC = 'NIGHTS_DESC',
  OFFERSCOUNT_ASC = 'OFFERSCOUNT_ASC',
  OFFERSCOUNT_DESC = 'OFFERSCOUNT_DESC',
  OWNERID_ASC = 'OWNERID_ASC',
  OWNERID_DESC = 'OWNERID_DESC',
  OWNER_ASC = 'OWNER_ASC',
  OWNER_DESC = 'OWNER_DESC',
  PERIODDAYS_ASC = 'PERIODDAYS_ASC',
  PERIODDAYS_DESC = 'PERIODDAYS_DESC',
  STARTDATE_ASC = 'STARTDATE_ASC',
  STARTDATE_DESC = 'STARTDATE_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  UPDATEDAT_ASC = 'UPDATEDAT_ASC',
  UPDATEDAT_DESC = 'UPDATEDAT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export enum RequestStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED'
}

export type RequestUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  adults: InputMaybe<Scalars['Float']['input']>;
  adults_inc: InputMaybe<Scalars['Float']['input']>;
  adults_unset: InputMaybe<Scalars['Boolean']['input']>;
  archiveOffers: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  archiveOffers_unset: InputMaybe<Scalars['Boolean']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  childrenAge: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  childrenAge_unset: InputMaybe<Scalars['Boolean']['input']>;
  children_inc: InputMaybe<Scalars['Float']['input']>;
  children_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  date: InputMaybe<Scalars['DateTime']['input']>;
  date_unset: InputMaybe<Scalars['Boolean']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  description_unset: InputMaybe<Scalars['Boolean']['input']>;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  endDate_unset: InputMaybe<Scalars['Boolean']['input']>;
  isHidden: InputMaybe<Scalars['Boolean']['input']>;
  isHidden_unset: InputMaybe<Scalars['Boolean']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  isRead_unset: InputMaybe<Scalars['Boolean']['input']>;
  likedOffers: InputMaybe<RequestLikedOffersRelationInput>;
  likedOffers_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<RequestLocationUpdateInput>;
  locationStart: InputMaybe<RequestLocationStartUpdateInput>;
  locationStart_unset: InputMaybe<Scalars['Boolean']['input']>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
  nights: InputMaybe<Scalars['Float']['input']>;
  nights_inc: InputMaybe<Scalars['Float']['input']>;
  nights_unset: InputMaybe<Scalars['Boolean']['input']>;
  offersCount: InputMaybe<Scalars['Long']['input']>;
  offersCount_unset: InputMaybe<Scalars['Boolean']['input']>;
  owner: InputMaybe<RequestOwnerRelationInput>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  ownerId_unset: InputMaybe<Scalars['Boolean']['input']>;
  owner_unset: InputMaybe<Scalars['Boolean']['input']>;
  periodDays: InputMaybe<Scalars['Float']['input']>;
  periodDays_inc: InputMaybe<Scalars['Float']['input']>;
  periodDays_unset: InputMaybe<Scalars['Boolean']['input']>;
  price: InputMaybe<RequestPriceUpdateInput>;
  price_unset: InputMaybe<Scalars['Boolean']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  startDate_unset: InputMaybe<Scalars['Boolean']['input']>;
  status: InputMaybe<RequestStatus>;
  status_unset: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  viewedAccounts: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  viewedAccounts_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type SendAccountsPushInput = {
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type SendMessageInput = {
  attachments: InputMaybe<Array<InputMaybe<SendMessageInputAttachment>>>;
  chatId: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  otherAccountId: InputMaybe<Scalars['String']['input']>;
  reference: InputMaybe<SendMessageInputReference>;
  replyTo: InputMaybe<Scalars['String']['input']>;
};

export type SendMessageInputAttachment = {
  filename: Scalars['String']['input'];
  type: SendMessageInputAttachmentType;
  url: Scalars['String']['input'];
};

export enum SendMessageInputAttachmentType {
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  OTHER = 'OTHER',
  VIDEO = 'VIDEO'
}

export type SendMessageInputReference = {
  offerId: InputMaybe<Scalars['String']['input']>;
  orderId: InputMaybe<Scalars['String']['input']>;
  requestId: InputMaybe<Scalars['String']['input']>;
};

export type SetOfferLike = {
  id: Scalars['String']['input'];
  requestId: InputMaybe<Scalars['String']['input']>;
};

export type SetOnlineAccountInput = {
  fcmToken: InputMaybe<Scalars['String']['input']>;
  isOnline: InputMaybe<Scalars['Boolean']['input']>;
};

export type StatusCount = {
  __typename?: 'StatusCount';
  _id: Maybe<Scalars['String']['output']>;
  count: Maybe<Scalars['Int']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  _id: Scalars['ObjectId']['output'];
  image: Maybe<TagFile>;
  index: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  translations: Maybe<TagTranslation>;
};

export type TagFile = {
  __typename?: 'TagFile';
  bucket: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
};

export type TagFileInsertInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type TagFileQueryInput = {
  AND: InputMaybe<Array<TagFileQueryInput>>;
  OR: InputMaybe<Array<TagFileQueryInput>>;
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_exists: InputMaybe<Scalars['Boolean']['input']>;
  bucket_gt: InputMaybe<Scalars['String']['input']>;
  bucket_gte: InputMaybe<Scalars['String']['input']>;
  bucket_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bucket_lt: InputMaybe<Scalars['String']['input']>;
  bucket_lte: InputMaybe<Scalars['String']['input']>;
  bucket_ne: InputMaybe<Scalars['String']['input']>;
  bucket_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key: InputMaybe<Scalars['String']['input']>;
  key_exists: InputMaybe<Scalars['Boolean']['input']>;
  key_gt: InputMaybe<Scalars['String']['input']>;
  key_gte: InputMaybe<Scalars['String']['input']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_lt: InputMaybe<Scalars['String']['input']>;
  key_lte: InputMaybe<Scalars['String']['input']>;
  key_ne: InputMaybe<Scalars['String']['input']>;
  key_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location: InputMaybe<Scalars['String']['input']>;
  location_exists: InputMaybe<Scalars['Boolean']['input']>;
  location_gt: InputMaybe<Scalars['String']['input']>;
  location_gte: InputMaybe<Scalars['String']['input']>;
  location_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_lt: InputMaybe<Scalars['String']['input']>;
  location_lte: InputMaybe<Scalars['String']['input']>;
  location_ne: InputMaybe<Scalars['String']['input']>;
  location_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TagFileUpdateInput = {
  bucket: InputMaybe<Scalars['String']['input']>;
  bucket_unset: InputMaybe<Scalars['Boolean']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  key_unset: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  location_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type TagInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  image: InputMaybe<TagFileInsertInput>;
  index: Scalars['Long']['input'];
  name: Scalars['String']['input'];
  translations: InputMaybe<TagTranslationInsertInput>;
};

export type TagQueryInput = {
  AND: InputMaybe<Array<TagQueryInput>>;
  OR: InputMaybe<Array<TagQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  image: InputMaybe<TagFileQueryInput>;
  image_exists: InputMaybe<Scalars['Boolean']['input']>;
  index: InputMaybe<Scalars['Long']['input']>;
  index_exists: InputMaybe<Scalars['Boolean']['input']>;
  index_gt: InputMaybe<Scalars['Long']['input']>;
  index_gte: InputMaybe<Scalars['Long']['input']>;
  index_in: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  index_lt: InputMaybe<Scalars['Long']['input']>;
  index_lte: InputMaybe<Scalars['Long']['input']>;
  index_ne: InputMaybe<Scalars['Long']['input']>;
  index_nin: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_gt: InputMaybe<Scalars['String']['input']>;
  name_gte: InputMaybe<Scalars['String']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_lt: InputMaybe<Scalars['String']['input']>;
  name_lte: InputMaybe<Scalars['String']['input']>;
  name_ne: InputMaybe<Scalars['String']['input']>;
  name_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translations: InputMaybe<TagTranslationQueryInput>;
  translations_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TagSortByInput {
  INDEX_ASC = 'INDEX_ASC',
  INDEX_DESC = 'INDEX_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type TagTranslation = {
  __typename?: 'TagTranslation';
  en: Maybe<Scalars['String']['output']>;
  uk: Maybe<Scalars['String']['output']>;
};

export type TagTranslationInsertInput = {
  en: InputMaybe<Scalars['String']['input']>;
  uk: InputMaybe<Scalars['String']['input']>;
};

export type TagTranslationQueryInput = {
  AND: InputMaybe<Array<TagTranslationQueryInput>>;
  OR: InputMaybe<Array<TagTranslationQueryInput>>;
  en: InputMaybe<Scalars['String']['input']>;
  en_exists: InputMaybe<Scalars['Boolean']['input']>;
  en_gt: InputMaybe<Scalars['String']['input']>;
  en_gte: InputMaybe<Scalars['String']['input']>;
  en_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  en_lt: InputMaybe<Scalars['String']['input']>;
  en_lte: InputMaybe<Scalars['String']['input']>;
  en_ne: InputMaybe<Scalars['String']['input']>;
  en_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uk: InputMaybe<Scalars['String']['input']>;
  uk_exists: InputMaybe<Scalars['Boolean']['input']>;
  uk_gt: InputMaybe<Scalars['String']['input']>;
  uk_gte: InputMaybe<Scalars['String']['input']>;
  uk_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uk_lt: InputMaybe<Scalars['String']['input']>;
  uk_lte: InputMaybe<Scalars['String']['input']>;
  uk_ne: InputMaybe<Scalars['String']['input']>;
  uk_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TagTranslationUpdateInput = {
  en: InputMaybe<Scalars['String']['input']>;
  en_unset: InputMaybe<Scalars['Boolean']['input']>;
  uk: InputMaybe<Scalars['String']['input']>;
  uk_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type TagUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  image: InputMaybe<TagFileUpdateInput>;
  image_unset: InputMaybe<Scalars['Boolean']['input']>;
  index: InputMaybe<Scalars['Long']['input']>;
  index_unset: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_unset: InputMaybe<Scalars['Boolean']['input']>;
  translations: InputMaybe<TagTranslationUpdateInput>;
  translations_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type Template = {
  __typename?: 'Template';
  _id: Scalars['ObjectId']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  options: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ownerId: Maybe<Scalars['ObjectId']['output']>;
};

export type TemplateInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  options: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId: InputMaybe<Scalars['ObjectId']['input']>;
};

export type TemplateQueryInput = {
  AND: InputMaybe<Array<TemplateQueryInput>>;
  OR: InputMaybe<Array<TemplateQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_exists: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_gt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdAt_lt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_ne: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_nin: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  name: InputMaybe<Scalars['String']['input']>;
  name_exists: InputMaybe<Scalars['Boolean']['input']>;
  name_gt: InputMaybe<Scalars['String']['input']>;
  name_gte: InputMaybe<Scalars['String']['input']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_lt: InputMaybe<Scalars['String']['input']>;
  name_lte: InputMaybe<Scalars['String']['input']>;
  name_ne: InputMaybe<Scalars['String']['input']>;
  name_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  options: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  options_exists: InputMaybe<Scalars['Boolean']['input']>;
  options_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  options_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ownerId: InputMaybe<Scalars['ObjectId']['input']>;
  ownerId_exists: InputMaybe<Scalars['Boolean']['input']>;
  ownerId_gt: InputMaybe<Scalars['ObjectId']['input']>;
  ownerId_gte: InputMaybe<Scalars['ObjectId']['input']>;
  ownerId_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  ownerId_lt: InputMaybe<Scalars['ObjectId']['input']>;
  ownerId_lte: InputMaybe<Scalars['ObjectId']['input']>;
  ownerId_ne: InputMaybe<Scalars['ObjectId']['input']>;
  ownerId_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
};

export enum TemplateSortByInput {
  CREATEDAT_ASC = 'CREATEDAT_ASC',
  CREATEDAT_DESC = 'CREATEDAT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  OWNERID_ASC = 'OWNERID_ASC',
  OWNERID_DESC = 'OWNERID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type TemplateUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  createdAt: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_unset: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  name_unset: InputMaybe<Scalars['Boolean']['input']>;
  options: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  options_unset: InputMaybe<Scalars['Boolean']['input']>;
  ownerId: InputMaybe<Scalars['ObjectId']['input']>;
  ownerId_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelCategory = {
  __typename?: 'TravelCategory';
  _id: Scalars['ObjectId']['output'];
  image: Maybe<CategoryFile>;
  index: Scalars['Long']['output'];
  title: Scalars['String']['output'];
  translations: Maybe<TravelCategoryTranslation>;
};

export type TravelCategoryInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  image: InputMaybe<CategoryFileInsertInput>;
  index: Scalars['Long']['input'];
  title: Scalars['String']['input'];
  translations: InputMaybe<TravelCategoryTranslationInsertInput>;
};

export type TravelCategoryQueryInput = {
  AND: InputMaybe<Array<TravelCategoryQueryInput>>;
  OR: InputMaybe<Array<TravelCategoryQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  image: InputMaybe<CategoryFileQueryInput>;
  image_exists: InputMaybe<Scalars['Boolean']['input']>;
  index: InputMaybe<Scalars['Long']['input']>;
  index_exists: InputMaybe<Scalars['Boolean']['input']>;
  index_gt: InputMaybe<Scalars['Long']['input']>;
  index_gte: InputMaybe<Scalars['Long']['input']>;
  index_in: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  index_lt: InputMaybe<Scalars['Long']['input']>;
  index_lte: InputMaybe<Scalars['Long']['input']>;
  index_ne: InputMaybe<Scalars['Long']['input']>;
  index_nin: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_gt: InputMaybe<Scalars['String']['input']>;
  title_gte: InputMaybe<Scalars['String']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_lt: InputMaybe<Scalars['String']['input']>;
  title_lte: InputMaybe<Scalars['String']['input']>;
  title_ne: InputMaybe<Scalars['String']['input']>;
  title_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translations: InputMaybe<TravelCategoryTranslationQueryInput>;
  translations_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TravelCategorySortByInput {
  INDEX_ASC = 'INDEX_ASC',
  INDEX_DESC = 'INDEX_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type TravelCategoryTranslation = {
  __typename?: 'TravelCategoryTranslation';
  en: Maybe<Scalars['String']['output']>;
  uk: Maybe<Scalars['String']['output']>;
};

export type TravelCategoryTranslationInsertInput = {
  en: InputMaybe<Scalars['String']['input']>;
  uk: InputMaybe<Scalars['String']['input']>;
};

export type TravelCategoryTranslationQueryInput = {
  AND: InputMaybe<Array<TravelCategoryTranslationQueryInput>>;
  OR: InputMaybe<Array<TravelCategoryTranslationQueryInput>>;
  en: InputMaybe<Scalars['String']['input']>;
  en_exists: InputMaybe<Scalars['Boolean']['input']>;
  en_gt: InputMaybe<Scalars['String']['input']>;
  en_gte: InputMaybe<Scalars['String']['input']>;
  en_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  en_lt: InputMaybe<Scalars['String']['input']>;
  en_lte: InputMaybe<Scalars['String']['input']>;
  en_ne: InputMaybe<Scalars['String']['input']>;
  en_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uk: InputMaybe<Scalars['String']['input']>;
  uk_exists: InputMaybe<Scalars['Boolean']['input']>;
  uk_gt: InputMaybe<Scalars['String']['input']>;
  uk_gte: InputMaybe<Scalars['String']['input']>;
  uk_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uk_lt: InputMaybe<Scalars['String']['input']>;
  uk_lte: InputMaybe<Scalars['String']['input']>;
  uk_ne: InputMaybe<Scalars['String']['input']>;
  uk_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TravelCategoryTranslationUpdateInput = {
  en: InputMaybe<Scalars['String']['input']>;
  en_unset: InputMaybe<Scalars['Boolean']['input']>;
  uk: InputMaybe<Scalars['String']['input']>;
  uk_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelCategoryUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  image: InputMaybe<CategoryFileUpdateInput>;
  image_unset: InputMaybe<Scalars['Boolean']['input']>;
  index: InputMaybe<Scalars['Long']['input']>;
  index_unset: InputMaybe<Scalars['Boolean']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  title_unset: InputMaybe<Scalars['Boolean']['input']>;
  translations: InputMaybe<TravelCategoryTranslationUpdateInput>;
  translations_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelerCharacteristic = {
  __typename?: 'TravelerCharacteristic';
  _id: Maybe<Scalars['ObjectId']['output']>;
  details: Maybe<Array<Maybe<TravelerCharacteristicsDetail>>>;
  index: Maybe<Scalars['Float']['output']>;
  parent: Maybe<TravelerCharacteristic>;
};

export type TravelerCharacteristicInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  details: InputMaybe<Array<InputMaybe<TravelerCharacteristicsDetailInsertInput>>>;
  index: InputMaybe<Scalars['Float']['input']>;
  parent: InputMaybe<TravelerCharacteristicParentRelationInput>;
};

export type TravelerCharacteristicParentRelationInput = {
  create: InputMaybe<TravelerCharacteristicInsertInput>;
  link: InputMaybe<Scalars['ObjectId']['input']>;
};

export type TravelerCharacteristicQueryInput = {
  AND: InputMaybe<Array<TravelerCharacteristicQueryInput>>;
  OR: InputMaybe<Array<TravelerCharacteristicQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  details: InputMaybe<Array<InputMaybe<TravelerCharacteristicsDetailQueryInput>>>;
  details_exists: InputMaybe<Scalars['Boolean']['input']>;
  details_in: InputMaybe<Array<InputMaybe<TravelerCharacteristicsDetailQueryInput>>>;
  details_nin: InputMaybe<Array<InputMaybe<TravelerCharacteristicsDetailQueryInput>>>;
  index: InputMaybe<Scalars['Float']['input']>;
  index_exists: InputMaybe<Scalars['Boolean']['input']>;
  index_gt: InputMaybe<Scalars['Float']['input']>;
  index_gte: InputMaybe<Scalars['Float']['input']>;
  index_in: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  index_lt: InputMaybe<Scalars['Float']['input']>;
  index_lte: InputMaybe<Scalars['Float']['input']>;
  index_ne: InputMaybe<Scalars['Float']['input']>;
  index_nin: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  parent: InputMaybe<TravelerCharacteristicQueryInput>;
  parent_exists: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TravelerCharacteristicSortByInput {
  INDEX_ASC = 'INDEX_ASC',
  INDEX_DESC = 'INDEX_DESC',
  PARENT_ASC = 'PARENT_ASC',
  PARENT_DESC = 'PARENT_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC'
}

export type TravelerCharacteristicUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  details: InputMaybe<Array<InputMaybe<TravelerCharacteristicsDetailUpdateInput>>>;
  details_unset: InputMaybe<Scalars['Boolean']['input']>;
  index: InputMaybe<Scalars['Float']['input']>;
  index_inc: InputMaybe<Scalars['Float']['input']>;
  index_unset: InputMaybe<Scalars['Boolean']['input']>;
  parent: InputMaybe<TravelerCharacteristicParentRelationInput>;
  parent_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelerCharacteristicsDetail = {
  __typename?: 'TravelerCharacteristicsDetail';
  _id: Maybe<Scalars['ObjectId']['output']>;
  lang: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type TravelerCharacteristicsDetailInsertInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  lang: InputMaybe<Scalars['String']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
};

export type TravelerCharacteristicsDetailQueryInput = {
  AND: InputMaybe<Array<TravelerCharacteristicsDetailQueryInput>>;
  OR: InputMaybe<Array<TravelerCharacteristicsDetailQueryInput>>;
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_exists: InputMaybe<Scalars['Boolean']['input']>;
  _id_gt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_gte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_in: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  _id_lt: InputMaybe<Scalars['ObjectId']['input']>;
  _id_lte: InputMaybe<Scalars['ObjectId']['input']>;
  _id_ne: InputMaybe<Scalars['ObjectId']['input']>;
  _id_nin: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  lang: InputMaybe<Scalars['String']['input']>;
  lang_exists: InputMaybe<Scalars['Boolean']['input']>;
  lang_gt: InputMaybe<Scalars['String']['input']>;
  lang_gte: InputMaybe<Scalars['String']['input']>;
  lang_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lang_lt: InputMaybe<Scalars['String']['input']>;
  lang_lte: InputMaybe<Scalars['String']['input']>;
  lang_ne: InputMaybe<Scalars['String']['input']>;
  lang_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
  title_exists: InputMaybe<Scalars['Boolean']['input']>;
  title_gt: InputMaybe<Scalars['String']['input']>;
  title_gte: InputMaybe<Scalars['String']['input']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_lt: InputMaybe<Scalars['String']['input']>;
  title_lte: InputMaybe<Scalars['String']['input']>;
  title_ne: InputMaybe<Scalars['String']['input']>;
  title_nin: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TravelerCharacteristicsDetailUpdateInput = {
  _id: InputMaybe<Scalars['ObjectId']['input']>;
  _id_unset: InputMaybe<Scalars['Boolean']['input']>;
  lang: InputMaybe<Scalars['String']['input']>;
  lang_unset: InputMaybe<Scalars['Boolean']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  title_unset: InputMaybe<Scalars['Boolean']['input']>;
};

export type UnsetOfferLike = {
  id: Scalars['String']['input'];
  requestId: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAccountInput = {
  activities: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  avatar: InputMaybe<UpdateAccountInputAvatar>;
  country: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  fcmToken: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  role: InputMaybe<Scalars['String']['input']>;
  settings: InputMaybe<UpdateAccountInputSetting>;
  tags: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  travelCategories: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  travelerCharacteristics: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateAccountInputAvatar = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAccountInputSetting = {
  countries: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currency: InputMaybe<Scalars['String']['input']>;
  languages: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location: InputMaybe<UpdateAccountInputSettingLocation>;
};

export type UpdateAccountInputSettingLocation = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type UpdateBusinessInput = {
  address: InputMaybe<Scalars['String']['input']>;
  avatar: InputMaybe<UpdateBusinessInputAvatar>;
  city: InputMaybe<Scalars['String']['input']>;
  companyName: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  docs: InputMaybe<Array<InputMaybe<UpdateBusinessInputDoc>>>;
  email: InputMaybe<Scalars['String']['input']>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<UpdateBusinessInputStatus>;
  website: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zip: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBusinessInputAvatar = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBusinessInputDoc = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export enum UpdateBusinessInputStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REVIEW = 'REVIEW'
}

export type UpdateManyPayload = {
  __typename?: 'UpdateManyPayload';
  matchedCount: Scalars['Int']['output'];
  modifiedCount: Scalars['Int']['output'];
};

export type UpdateOfferInput = {
  _id: Scalars['String']['input'];
  activities: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  days: InputMaybe<Scalars['String']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  isPublic: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<UpdateOfferInputLocation>;
  offerAddition: InputMaybe<Array<InputMaybe<UpdateOfferInputOfferAddition>>>;
  ownerId: InputMaybe<Scalars['String']['input']>;
  person: InputMaybe<Scalars['Float']['input']>;
  photo: InputMaybe<Array<InputMaybe<UpdateOfferInputPhoto>>>;
  price: InputMaybe<UpdateOfferPrice>;
  priceEstimateType: InputMaybe<UpdateOfferInputPriceEstimateType>;
  requestId: InputMaybe<Scalars['String']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<UpdateOfferInputStatus>;
  tags: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
  travelCategory: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<UpdateOfferInputType>;
  video: InputMaybe<UpdateOfferInputVideo>;
};

export type UpdateOfferInputLocation = {
  coordinates: UpdateOfferInputLocationCoordinate;
  description: Scalars['String']['input'];
  place_id: Scalars['String']['input'];
};

export type UpdateOfferInputLocationCoordinate = {
  coordinates: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type: InputMaybe<UpdateOfferInputLocationCoordinateType>;
};

export enum UpdateOfferInputLocationCoordinateType {
  POINT = 'POINT'
}

export type UpdateOfferInputOfferAddition = {
  adults: InputMaybe<Scalars['Float']['input']>;
  children: InputMaybe<Scalars['Float']['input']>;
  days: InputMaybe<Scalars['String']['input']>;
  price: InputMaybe<UpdateOfferInputOfferAdditionPrice>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateOfferInputOfferAdditionPrice = {
  currency: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

export type UpdateOfferInputPhoto = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export enum UpdateOfferInputPriceEstimateType {
  ESTIMATE = 'ESTIMATE',
  FINAL = 'FINAL'
}

export enum UpdateOfferInputStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  HOT = 'HOT'
}

export enum UpdateOfferInputType {
  PERSON = 'PERSON',
  TOUR = 'TOUR'
}

export type UpdateOfferInputVideo = {
  bucket: InputMaybe<Scalars['String']['input']>;
  key: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOfferPrice = {
  currency: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type UpdateOrderInput = {
  _id: InputMaybe<Scalars['String']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  reason: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<UpdateOrderInputStatus>;
};

export enum UpdateOrderInputStatus {
  CANCELLED = 'CANCELLED',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  CONFIRMED = 'CONFIRMED',
  PENDING_AGENT = 'PENDING_AGENT',
  PENDING_TRAVELER = 'PENDING_TRAVELER'
}

export type UpdateRequestInput = {
  _id: InputMaybe<Scalars['String']['input']>;
  archiveOffer: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<UpdateRequestInputStatus>;
};

export enum UpdateRequestInputStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED'
}

export type UpdateRequestRead = {
  id: Scalars['String']['input'];
};
