import {
  Box,
  CloseButton,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { DropzoneOptions, FileRejection } from 'react-dropzone';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactPixel from 'react-facebook-pixel';

import { Business, CreateBusinessInput } from '@app/api/gql/generated-types';
import { ProfileAvatarDropzone } from '@app/components/CustomDropzone/ProfileAvatarDropzone';
import { SelectCountry } from '@app/components/SelectCountry';
import { FormField } from '@app/components/formInputs/FormField';
import { useErrorNotification } from '@app/hooks/useErrorNotification';
import { useUploadFiles } from '@app/hooks/useUploadFiles';
import { ReactComponent as UploadIcon } from '@app/icons/upload-icon.svg';
import { useValidationSchema } from '@app/views/AgencyInformationPage/useValidationSchema';
import { Submit } from '@app/views/Login/components/LoginForm/Submit';
import { ProfileSteps } from '@app/components/ProfileSteps';
import { MultipleInput } from '@app/components/formInputs/MultipleInput';
import {
  useUpdateAccountMutation,
  WhoAmIDocument,
} from '@app/api/schemas/account.amazon.generated';
import { useCreateBusinessMutation } from '@app/api/schemas/business.amazon.generated';
import { lngs } from '@app/translations/translations';
import { amplitude } from '@app/amplitude';

import { initialValues } from './initialValues';

export const AgencyInformationPage = () => {
  const { uploadToAccount } = useUploadFiles();
  const errorNotification = useErrorNotification();
  const [mutate, { loading: isLoading }] = useCreateBusinessMutation({
    refetchQueries: [WhoAmIDocument],
    context: {
      clientName: 'amazon',
    },
    awaitRefetchQueries: true,
  });
  const [updateAccount] = useUpdateAccountMutation({
    context: {
      clientName: 'amazon',
    },
  });
  const resolver = useValidationSchema();
  const { t, i18n } = useTranslation('forms');

  const onSubmit = async (values?: CreateBusinessInput) => {
    try {
      let avatar = null;
      if (!isEmpty(values?.avatar)) {
        const file = values?.avatar;
        avatar = await uploadToAccount(file);
      }

      await updateAccount({
        variables: {
          input: {
            settings: {
              appLanguage:
                lngs[i18n.resolvedLanguage]?.nativeName?.toUpperCase(),
            },
          },
        },
      });

      await mutate({
        variables: {
          input: {
            ...values,
            country: values?.country?._id,
            ...(!!avatar && { avatar }),
            ...(!!values.website?.length && {
              website: values.website?.map((val) => val?.value),
            }),
          },
        },
      });
      amplitude.logEvent('Onboarding Step 2', { platform: 'web' });
      ReactPixel.track('OnboardingRegister');
    } catch (error) {
      console.error(error);
      errorNotification(error);
    }
  };
  const form = useForm<Business>({
    defaultValues: initialValues,
    resolver,
    mode: 'all',
  });

  const onDrop = (uploadedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 0) {
      const rejectedFilesString = rejectedFiles
        ?.map((file) => file.file.name)
        ?.join();
      errorNotification({
        message: `${rejectedFilesString}. ${t('uploadFile')}`,
      });
    }
    form.setValue('avatar', uploadedFiles?.[0]);
  };

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    maxFiles: 1,
    maxSize: 1024 * 1024 * 5,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
  };

  const [avatar] = form.watch(['avatar']);

  return (
    <>
      <Box marginBottom={20} minW="full">
        <ProfileSteps index={1} />
      </Box>
      <Box h="full" pt={10} w="full">
        <FormProvider {...form}>
          <Box w="full">
            <VStack pb={10} spacing="25px">
              <Box w="full">
                <FormField
                  isRequired
                  mb="25px"
                  name="companyName"
                  placeholder={t('companyName')}
                  size="lg"
                  variant="auth"
                />

                <Controller
                  control={form.control}
                  name="country"
                  render={({ field }) => (
                    <SelectCountry field={field} variant="auth" />
                  )}
                />

                <FormField
                  mb="25px"
                  name="city"
                  placeholder={t('city')}
                  size="lg"
                  variant="auth"
                />
                <FormField
                  mb="25px"
                  name="address"
                  placeholder={t('address')}
                  size="lg"
                  variant="auth"
                />
                <MultipleInput />
              </Box>
              <VStack>
                <Box
                  backgroundColor="secondaryGray.300"
                  borderRadius={15}
                  height="80px"
                  mb="10px"
                  mt="15px"
                  position="relative"
                  w="80px">
                  <ProfileAvatarDropzone
                    content={
                      !!avatar ? (
                        <Image
                          borderRadius="15px"
                          height="80px !important"
                          src={URL.createObjectURL(avatar)}
                          w="80px"
                        />
                      ) : (
                        <HStack
                          alignItems="center"
                          h="80px"
                          justifyContent="center"
                          w="80px">
                          <UploadIcon />
                        </HStack>
                      )
                    }
                    dropzoneOptions={dropzoneOptions}
                  />
                  {!!avatar && (
                    <CloseButton
                      borderRadius="50px"
                      position="absolute"
                      right="-25px"
                      top="-10px"
                      onClick={() => form.setValue('avatar', null)}
                    />
                  )}
                </Box>
                <Text color="black" fontSize="14px" fontWeight={700}>
                  {t('agencyLogoTitle')}
                </Text>
                <Text color="grey.200" fontSize={12} textAlign="center">
                  {t('logoAllowedExtensions')}
                </Text>
              </VStack>
              <Submit
                loading={isLoading}
                title={t('nextStep')}
                onSubmit={onSubmit}
              />
            </VStack>
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};
