import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { signInWithRedirect } from 'aws-amplify/auth';
import ReactPixel from 'react-facebook-pixel';

import { HSeparator } from '@app/components/Separator';
import { ReactComponent as GoogleIcon } from '@app/icons/google-icon.svg';
import { useErrorNotification } from '@app/hooks/useErrorNotification';

import { SignInLink } from './components/SignInLink';
import SignUpForm from './components/SignUpForm';

const options = {
  autoConfig: true,
  debug: process.env.NODE_ENV !== 'production',
};

const pixelId = process.env.REACT_APP_FB_PIXEL;

export const SignUp = () => {
  const errorNotify = useErrorNotification();
  const { t, i18n } = useTranslation(['forms', 'addOffer']);
  const handleSignInGoogle = async () => {
    try {
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      errorNotify(error);
    }
  };

  useEffect(() => {
    if (pixelId) {
      ReactPixel.init(pixelId, null, options);
      ReactPixel.pageView();
    }
  }, []);

  return (
    <>
      <Heading color="grey.300" fontSize="34px" fontWeight={600} mb="5px">
        {t('welcomeToMira')}
      </Heading>
      <SignInLink />

      <Box mt="25px" w="full">
        <SignUpForm />
      </Box>
      <Flex alignItems="center" my="25px" w="full">
        <HSeparator backgroundColor="grey.250" />
        <Text color="grey.250" mx="20px" textAlign="center">
          {t('or')}
        </Text>
        <HSeparator backgroundColor="grey.250" />
      </Flex>
      <HStack justifyContent="center" w="full">
        <Button variant="gray" onClick={handleSignInGoogle}>
          <HStack>
            <Text color="white">{t('loginWith')}</Text>
            <GoogleIcon />
          </HStack>
        </Button>
      </HStack>
      <Text color="grey.250" fontSize={12} mt="25px">
        <Trans
          components={{
            terms: (
              <Text
                as={Link}
                color="grey.250"
                fontSize={12}
                href={`https://mira.travel/docs/${i18n.resolvedLanguage}/user_agreement_MIRA.travel.pdf`}
                target="_blank"
                textDecoration="underline"
              />
            ),
            privacy: (
              <Text
                as={Link}
                color="grey.250"
                fontSize={12}
                href={`https://mira.travel/docs/${i18n.resolvedLanguage}/agent_offer_MIRA.travel.pdf`}
                target="_blank"
                textDecoration="underline"
              />
            ),
          }}
          i18nKey="bySigninUp"
          ns="forms"
        />
      </Text>
    </>
  );
};
