import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  hasDigitChar,
  hasLowercaseChar,
  hasSpecialChar,
  hasUppercaseChar,
} from '@app/utils/validation';

export const useNewPasswordSchema = () => {
  const { t } = useTranslation('validations');
  const validationSchema = object().shape({
    password: string()
      .label('Password')
      .test(
        'is-length-enough',
        'Must be at least 8 characters long',
        (value) => value?.length >= 8,
      )
      .test(
        'is-contains-uppercase',
        'Must contain at least one uppercase letter',
        (value) => hasUppercaseChar(value),
      )
      .test(
        'is-contains-lowercase',
        'Must contain at least one lowercase letter',
        (value) => hasLowercaseChar(value),
      )
      .test('is-contains-digits', 'Must contain at least one digit', (value) =>
        hasDigitChar(value),
      )
      .test(
        'is-contains-special-char',
        'Must contain at least one special character',
        (value) => hasSpecialChar(value),
      )
      .required(t('passwordRequired')),
    code: string().min(6).max(6).required(''),
  });

  return yupResolver(validationSchema);
};
