import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import validator from 'validator';

export const useValidationSchema = () => {
  const { t } = useTranslation('validations');
  const validationSchema = object().shape({
    email: string()
      .email(t('emailValid'))
      .required(t('emailRequired'))
      .test('email', t('emailValid'), (value) => validator.isEmail(value)),
  });

  return yupResolver(validationSchema);
};
