import { Categories } from 'emoji-picker-react';

export const emojiCategoriesUk = [
  {
    category: Categories.SUGGESTED,
    name: 'Часто використовувані',
  },
  {
    category: Categories.CUSTOM,
    name: 'Користувацькі емодзі',
  },
  {
    category: Categories.SMILEYS_PEOPLE,
    name: 'Смайлики та люди',
  },
  {
    category: Categories.ANIMALS_NATURE,
    name: 'Тварини та природа',
  },
  {
    category: Categories.FOOD_DRINK,
    name: 'Їжа та напої',
  },
  {
    category: Categories.TRAVEL_PLACES,
    name: 'Подорожі та місця',
  },
  {
    category: Categories.ACTIVITIES,
    name: 'Активності',
  },
  {
    category: Categories.OBJECTS,
    name: `Об'єкти`,
  },
  {
    category: Categories.SYMBOLS,
    name: 'Символи',
  },
  {
    category: Categories.FLAGS,
    name: 'Прапори',
  },
];
