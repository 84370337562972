import {
  Box,
  HStack,
  Step,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  Text,
  VStack,
  useSteps,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIcon } from '@app/icons/check-icon.svg';

interface ProfileStepsProps {
  index: number;
}

export const ProfileSteps: FC<ProfileStepsProps> = ({ index = 0 }) => {
  const { t } = useTranslation('forms');
  const steps = [
    { title: t('profileInformation') },
    { title: t('agencyInformation') },
  ];
  const { activeStep } = useSteps({
    index,
    count: steps.length,
  });
  return (
    <Stepper
      gap={1}
      index={activeStep}
      justifyContent="flex-start"
      maxW="400px"
      size="lg"
      w="full">
      {steps.map((step, index) => (
        <Step key={index}>
          <VStack justifyContent="flex-start">
            <StepIndicator
              sx={{
                '[data-status=complete] &': {
                  background: 'primary.green',
                  borderColor: 'primary.green',
                },
                '[data-status=active] &': {
                  background: 'transparent',
                  borderColor: 'primary.green',
                },
                '[data-status=incomplete] &': {
                  background: 'transparent',
                  borderColor: 'grey.250',
                },
              }}>
              <StepStatus
                active={<StepNumber />}
                complete={
                  <HStack alignItems="center" h="full" mt={1}>
                    <CheckIcon />
                  </HStack>
                }
                incomplete={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0" maxW="100px">
              <Text fontWeight={600} textAlign="center">
                {step.title}
              </Text>
            </Box>
          </VStack>
          <StepSeparator
            sx={{
              '[data-status=complete] &': {
                background: 'grey.250',
              },
              '[data-status=active] &': {
                background: 'grey.250',
              },
              '[data-status=incomplete] &': {
                background: 'grey.250',
              },
            }}
          />
        </Step>
      ))}
    </Stepper>
  );
};
