import { Box, BoxProps } from '@chakra-ui/react';
import React, { FC } from 'react';

import { Content } from './Content';
import { Header } from './Header';

export const TableSkeleton: FC<BoxProps> = (props) => {
  return (
    <Box width="100%" {...props}>
      <Header />

      <Content />
    </Box>
  );
};
