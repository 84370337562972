export const tableStyles = {
  components: {
    Table: {
      variants: {
        bordered: {
          table: {
            borderCollapse: 'collapse',
          },
          thead: {
            height: '65px',

            background: 'gray.100',
          },
          tbody: {
            tr: {
              _notLast: {},
            },
          },
          th: {
            height: '50px',
            fontFamily: "'Open Sans', sans-serif",
            textTransform: 'none',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '18px',
            color: 'grey.250',
            userSelect: 'none',
            letterSpacing: 'normal',
            padding: '15px 5px',

            _first: {
              paddingLeft: '15px',
            },
            _last: {
              paddingRight: '15px',
            },
          },
          tr: {},
          td: {
            textTransform: 'none',

            padding: '15px 5px',
            _first: {
              paddingLeft: '15px',
            },
            _last: {
              paddingRight: '15px',
            },
          },
        },
      },
    },
  },
};
