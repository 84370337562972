import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
export type CountryFragment = { __typename?: 'Country', _id: any, country: string | null, name: string | null, latitude: number | null, longitude: number | null, translations: { __typename?: 'CountryTranslation', en: string | null, uk: string | null } | null };

export const CountryFragmentDoc = gql`
    fragment Country on Country {
  _id
  country
  name
  translations {
    en
    uk
  }
  latitude
  longitude
}
    `;