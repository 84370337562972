import React, { FC } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Popup from '../Popup';

interface OrderChangeStatusModalProps extends UseDisclosureProps {
  onSubmit: () => void;
  title?: string;
  width?: string;
  confirmBtnText?: string;
  value: string;
  onChange: (val: string) => void;
}

export const OrderChangeStatusModal: FC<OrderChangeStatusModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  width = '500px',
  confirmBtnText,
  value,
  onChange,
}) => {
  const { t } = useTranslation(['modalsAndMenus']);
  const popupTitle = title || t('confirmation');
  const popupConfirmBtnText = confirmBtnText || t('confirmBtnText');
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW={width}
      title={popupTitle}
      onClose={onClose}>
      <Box p="30px 60px 30px">
        <Textarea
          bg="grey.100"
          borderRadius="16px"
          color="brand.900"
          colorScheme="green"
          placeholder={t('reason')}
          value={value}
          variant="main"
          onChange={(val) => onChange(val.target.value)}
        />
        <Flex direction="column" mt="40px">
          <Button isDisabled={!value} variant="primary" onClick={onSubmit}>
            {popupConfirmBtnText}
          </Button>
          <Button variant="cancel" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
