import { useCallback, useEffect, useReducer } from 'react';
import {
  AuthUser,
  FetchUserAttributesOutput,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import * as Realm from 'realm-web';

const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
// initial state
const initialState = {
  registered: false,
  bootstrapped: false,
  user: {} as AuthUser,
  userAttributes: undefined as FetchUserAttributesOutput,
};

type Action =
  | {
      type: 'login';
      user: AuthUser;
      userAttributes: FetchUserAttributesOutput;
    }
  | { type: 'logout' };

const bootstrapReducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'login':
      return {
        registered: true,
        bootstrapped: true,
        user: action.user,
        userAttributes: action.userAttributes,
      };
    case 'logout':
      return { ...initialState, bootstrapped: true, registered: false };
    default:
      return state;
  }
};

export const useBootstrap = () => {
  const [state, dispatch] = useReducer(bootstrapReducer, initialState);
  const getAuth = useCallback(async () => {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const currentUser = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      const credentials = Realm.Credentials.jwt(idToken.toString());
      await app.logIn(credentials);

      dispatch({
        type: 'login',
        user: currentUser,
        userAttributes,
      });
    } catch (error) {
      dispatch({ type: 'logout' });
    }
  }, []);
  useEffect(() => {
    const hubListenerCancel = Hub.listen('auth', (data) => {
      const event = data?.payload?.event;
      if (
        event === 'signedIn' ||
        event === 'signInWithRedirect' ||
        event === 'tokenRefresh'
      ) {
        void getAuth();
      } else {
        dispatch({
          type: 'logout',
        });
      }
    });
    void getAuth();

    return () => {
      hubListenerCancel();
    };
  }, []);

  return {
    user: state.user,
    registered: state.registered,
    userAttributes: state.userAttributes,
    bootstrapped: state.bootstrapped,
  };
};
