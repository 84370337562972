export const SideBarIconStyles = {
  baseStyle: (props: { fillColor: string }) => {
    return {
      path: {
        fill: props.fillColor ? props.fillColor : 'brand',
      },
    };
  },
};

export const SideBarComponent = {
  components: {
    SideBarIconStyles,
  },
};
