import { useColorModeValue } from '@chakra-ui/react';

export const useColorMode = () => {
  const bgColor = useColorModeValue('white', 'navy.900');
  const textColor = useColorModeValue('grey.300', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('primary.green', 'white');
  const brandStars = useColorModeValue('brand.200', 'brand.400');
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const googconstext = useColorModeValue('navy.700', 'white');
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );

  const sidebarBg = useColorModeValue('white', 'navy.800');
  const sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
  const menuColor = useColorModeValue('gray.400', 'white');

  const activeColor = useColorModeValue('gray.700', 'white');
  const inactiveColor = useColorModeValue(
    'secondaryGray.600',
    'secondaryGray.600',
  );
  const activeIcon = useColorModeValue('primary.green', 'white');
  const brandColor = useColorModeValue('primary.green', 'brand.400');

  const mainText = useColorModeValue('navy.700', 'white');
  const secondaryText = useColorModeValue('gray.700', 'white');
  const navbarPosition = 'fixed';
  const navbarFilter = 'none';
  const navbarBackdrop = 'blur(20px)';
  const navbarShadow = 'none';
  const navbarBg = useColorModeValue(
    'rgba(244, 247, 254, 0.7)',
    'rgba(11,20,55,0.5)',
  );

  const navbarIcon = useColorModeValue('gray.400', 'white');
  const menuBg = useColorModeValue('white', 'navy.800');
  const textColorNavbar = useColorModeValue('brand.900', 'white');
  const textColorBrandNavbar = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

  return {
    bgColor,
    textColor,
    textColorSecondary,
    textColorDetails,
    textColorBrand,
    brandStars,
    googleBg,
    googconstext,
    googleHover,
    googleActive,
    sidebarBg,
    sidebarBackgroundColor,
    menuColor,
    activeColor,
    inactiveColor,
    activeIcon,
    brandColor,
    mainText,
    secondaryText,
    navbarPosition,
    navbarFilter,
    navbarBackdrop,
    navbarShadow,
    navbarBg,
    navbarIcon,
    menuBg,
    textColorNavbar,
    textColorBrandNavbar,
    ethColor,
    borderColor,
    ethBg,
    ethBox,
    shadow,
    borderButton,
  };
};
