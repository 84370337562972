import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '@app/icons/close-icon.svg';

import { useContextMenuActions } from '../../hooks/contextMenuActions';
import { useContextMenu } from '../../hooks/useContextMenu';

import { MessageReferenceItem } from './MessageReferenceItem';

interface MessageReferencePlateProps {
  isWidget: boolean;
}

export const MessageReferencePlate: FC<MessageReferencePlateProps> = ({
  isWidget = false,
}) => {
  const { t } = useTranslation('forms');
  const { state } = useContextMenu();
  const { setShowReference } = useContextMenuActions();

  return (
    <Flex
      alignItems="center"
      bg={isWidget ? 'transparent' : 'grey.150'}
      borderRadius="10px 10px 0px 0px"
      gap="15px"
      left="0px"
      p={isWidget ? '10px 0' : '15px 35px 15px 15px'}
      w="100%">
      <Box flex={1} mr={10}>
        <MessageReferenceItem isWidget={isWidget} item={state.reference} />
      </Box>
      <Tooltip hasArrow label={t('removeOrderTooltip')} placement="top-end">
        <IconButton
          alignItems="center"
          aria-label="Close"
          color="grey.300"
          cursor="pointer"
          position="absolute"
          right={3}
          size="xs"
          top={4}
          variant="icon"
          onClick={() => setShowReference(false)}>
          <CloseIcon width="20px" />
        </IconButton>
      </Tooltip>
    </Flex>
  );
};
