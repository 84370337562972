import { AuthUser, FetchUserAttributesOutput } from 'aws-amplify/auth';
import { useContext } from 'react';

import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';
import { Account, Business } from '@app/api/gql/generated-types-amazon';

interface CurrentAccountType {
  account: Account;
  business: Business;
  user: AuthUser;
  userAttributes: FetchUserAttributesOutput;
}

export default (): CurrentAccountType => {
  const { account, business, user, userAttributes } = useContext(CurrentAccountContext);

  return { account, business, user, userAttributes };
};
