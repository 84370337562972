import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CountryFragment = { __typename?: 'Country', _id: string | null, country: string | null, name: string | null, latitude: number | null, longitude: number | null, translations: { __typename?: 'TranslationData', en: string | null, uk: string | null } | null };

export type CountriesQueryVariables = Types.Exact<{
  pagination: Types.InputMaybe<Types.PaginationInput>;
}>;


export type CountriesQuery = { __typename?: 'Query', countries: { __typename?: 'CountryList', hasNext: boolean, nextCreatedAt: string | null, nextId: string | null, total: number, items: Array<{ __typename?: 'Country', _id: string | null, country: string | null, name: string | null, latitude: number | null, longitude: number | null, translations: { __typename?: 'TranslationData', en: string | null, uk: string | null } | null }> } };

export const CountryFragmentDoc = gql`
    fragment Country on Country {
  _id
  country
  name
  translations {
    en
    uk
  }
  latitude
  longitude
}
    `;
export const CountriesDocument = gql`
    query Countries($pagination: PaginationInput) {
  countries(pagination: $pagination) {
    hasNext
    items {
      ...Country
    }
    nextCreatedAt
    nextId
    total
  }
}
    ${CountryFragmentDoc}`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export function useCountriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesSuspenseQueryHookResult = ReturnType<typeof useCountriesSuspenseQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;