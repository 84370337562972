import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { Box } from '@chakra-ui/react';

import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';
import { getFile } from '@app/utils/getFile';

import { MobileInformation } from '../AddOfferForm/InformationAndPreview/RequestInformation/MobileInformation';

export const OfferPreviewMobile = () => {
  const offerItem = useWatch<OfferFragment>();
  const photoUris = useMemo(() => {
    return offerItem?.photo?.map((photo) => getFile(photo));
  }, [offerItem?.photo]);
  return (
    <Box
      borderRadius={40}
      display={{ base: 'none', md: 'block' }}
      overflowY="auto"
      position={{ base: 'relative', xl: 'absolute' }}
      px="30px"
      right={{ base: 0, '2xl': 10 }}>
      <Box
        borderRadius={40}
        boxShadow="0px 8px 20px rgba(66, 66, 66, 0.46)"
        overflow="hidden">
        <MobileInformation offer={offerItem} photo={photoUris?.[0]} />
      </Box>
    </Box>
  );
};
