import { Accordion } from '@chakra-ui/react';
import React, { FC } from 'react';

import { Offer } from '@app/api/gql/generated-types';

import { OfferPriceAccordionItem } from './OfferPriceAccordionItem';

interface OfferPricesAccordionProps {
  offer: Offer;
}

export const OfferPricesAccordion: FC<OfferPricesAccordionProps> = ({
  offer,
}) => {
  return (
    <Accordion allowToggle>
      {offer?.offerAddition?.map((item, index) => (
        <OfferPriceAccordionItem
          key={`prices-${index}`}
          estimateType={offer?.priceEstimateType}
          item={item}
          type={offer?.type}
        />
      ))}
    </Accordion>
  );
};
