import { HStack, Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';

export const SkeletonList = () => {
  return (
    <VStack h="75vh" justifyContent="space-between" mt={28}>
      {Array(12)
        .fill(0)
        .map((_, key) => (
          <HStack key={key} spacing={4} w="full">
            {Array(3)
              .fill(0)
              .map((__, index) => (
                <Skeleton key={index} flex={1 + index} h={8} />
              ))}
          </HStack>
        ))}
    </VStack>
  );
};
