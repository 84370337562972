import { useGetCountersQuery } from '@app/api/schemas/account.mongo.generated';

export const useNotificationsWatch = () => {
  const { data: { getCounters: counters } = {} } = useGetCountersQuery({
    pollInterval: 60000,
    fetchPolicy: 'network-only',
  });

  return {
    requests: counters?.totalUnreadRequests ?? 0,
    orders: counters?.totalUnreadOrders ?? 0,
    chats: counters?.totalUnreadChats ?? 0,
  };
};
