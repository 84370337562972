import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from '@app/components/formInputs/FormField';
import { Offer } from '@app/api/gql/generated-types';

export const Title = () => {
  const { t } = useTranslation(['forms']);
  return (
    <FormField<Offer>
      aria-placeholder={t('title')}
      label={t('title')}
      mb="20px"
      name="title"
      placeholder={t('titlePlaceholder')}
      required={true}
    />
  );
};
