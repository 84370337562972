import isNumber from 'lodash/isNumber';

export const formatNumber = (
  value: number,
  locale = 'en-US',
  fractionDigits?: number,
  preventNegative = false,
  cutFraction = false,
): string => {
  if (!value || !isNumber(value) || value === 0) {
    return '0';
  }

  let localValue = value;

  if (preventNegative) {
    localValue = localValue >= 0 ? localValue : 0;
  }

  if (isNumber(fractionDigits)) {
    return localValue.toLocaleString(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  }

  if (cutFraction) {
    localValue = Math.trunc(localValue);
  }

  return localValue.toLocaleString(locale);
};

export const formatDash = (value: number) => {
  if (!value || !isNumber(value) || value === 0) {
    return '-';
  }
  return formatNumber(value);
};

export const formatInCents = (value: number) => {
  return formatNumber(value, undefined, 2).replace(/^0/, '');
};

export const getLocale = (locale: string) => {
  if (!locale) {
    return 'en-US';
  }
  const locales = {
    en: 'en-US',
    uk: 'uk',
  };
  return locales[locale];
};

export const getLang = (locale?: string) => {
  if (!locale) {
    return 'en';
  }
  const locales = {
    en: 'en',
    uk: 'ua',
  };
  return locales[locale];
};
