import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import { preparingForSendingPhoneNumber } from '@app/utils/formatPhone';

export const useValidationSchema = () => {
  const { t } = useTranslation('validations');

  const validationSchema = object().shape({
    phone: string()
      .required(t('phoneNumberRequired'))
      .test(
        'phone',
        t('phoneNumberValid'),
        (value) => preparingForSendingPhoneNumber(value).length > 12,
      ),
  });

  return yupResolver(validationSchema);
};
