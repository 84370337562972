import { CreateBusinessInput } from '@app/api/gql/generated-types';

export const initialValues: CreateBusinessInput = {
  companyName: '',
  avatar: null,
  country: null,
  address: '',
  city: '',
  website: [{}],
};
