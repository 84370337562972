import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const lg = defineStyle({
  fontSize: '12',
  px: '3',
  h: '45px',
});

const sizes = {
  lg: definePartsStyle({ field: lg, addon: lg }),
};

export const inputStyles = {
  components: {
    Input: {
      sizes,
      baseStyle: {
        field: {
          fontWeight: 400,
          borderRadius: '8px',
          _focus: {
            borderColor: 'primary.green',
          },
          _invalid: {
            borderColor: '#FF5630',
          },
        },
      },

      variants: {
        main: (props: any) => ({
          field: {
            bg: mode('gray.100', 'navy.800')(props),
            border: '1px solid',
            color: mode('black.100', 'white')(props),
            borderColor: mode('gray.100', 'whiteAlpha.100')(props),
            borderRadius: '10px',
            fontSize: 'sm',
            p: '12px 10px',
            _placeholder: { color: 'secondaryGray.500' },
            _active: {
              borderColor: 'primary.green',
            },
            _focus: {
              borderColor: 'primary.green',
            },
            _invalid: {
              borderColor: 'red.600',
            },
          },
        }),
        auth: (props: any) => ({
          field: {
            fontSize: '14px',
            fontWeight: '500',
            bg: 'mainGray.200',
            border: '1px solid',
            color: mode('black.100', 'white')(props),
            borderColor: 'mainGray.200',
            borderRadius: '10px',
            _placeholder: { color: 'mainGray.100', fontWeight: '600' },
            _active: {
              borderColor: 'primary.green',
            },
            _focus: {
              borderColor: 'primary.green',
            },
            _invalid: {
              borderColor: 'red.600',
            },
          },
        }),
        authSecondary: () => ({
          field: {
            bg: 'transparent',
            border: '1px solid',
            borderColor: 'secondaryGray.100',
            borderRadius: '16px',
            _placeholder: { color: 'secondaryGray.600' },
            _active: {
              borderColor: 'primary.green',
            },
            _focus: {
              borderColor: 'primary.green',
            },
            _invalid: {
              borderColor: 'red.600',
            },
          },
        }),
        search: () => ({
          field: {
            border: 'none',
            py: '11px',
            borderRadius: 'inherit',
            _placeholder: { color: 'secondaryGray.600' },
          },
        }),
      },
    },
    NumberInput: {
      baseStyle: {
        field: {
          fontWeight: 400,
        },
      },

      variants: {
        main: (props) => ({
          field: {
            bg: mode('grey.100', 'navy.800')(props),
            border: '1px solid',
            color: mode('brand.900', 'white')(props),
            borderColor: mode('secondaryGray.100', 'whiteAlpha.100')(props),
            borderRadius: '16px',
            fontSize: 'sm',
            p: '20px',
            _placeholder: { color: 'secondaryGray.500' },
            _active: {
              borderColor: 'primary.green',
            },
            _focus: {
              borderColor: 'primary.green',
            },
            _invalid: {
              borderColor: 'red.600',
            },
          },
        }),
        auth: () => ({
          field: {
            bg: 'grey.100',
            border: '1px solid',

            borderColor: 'secondaryGray.100',
            borderRadius: '16px',
            _placeholder: { color: 'secondaryGray.600' },
            _active: {
              borderColor: 'primary.green',
            },
            _focus: {
              borderColor: 'primary.green',
            },
            _invalid: {
              borderColor: 'red.600',
            },
          },
        }),
        authSecondary: () => ({
          field: {
            bg: 'grey.100',
            border: '1px solid',

            borderColor: 'secondaryGray.100',
            borderRadius: '16px',
            _placeholder: { color: 'secondaryGray.600' },
            _active: {
              borderColor: 'primary.green',
            },
            _focus: {
              borderColor: 'primary.green',
            },
            _invalid: {
              borderColor: 'red.600',
            },
          },
        }),
        search: () => ({
          field: {
            border: 'none',
            py: '11px',
            borderRadius: 'inherit',
            _placeholder: { color: 'secondaryGray.600' },
          },
        }),
      },
    },
    Select: {
      baseStyle: {
        field: {
          fontWeight: 400,
        },
      },

      variants: {
        main: (props: any) => ({
          field: {
            bg: mode('grey.100', 'navy.800')(props),
            borderColor: mode('secondaryGray.100', 'whiteAlpha.100')(props),
            borderRadius: '16px',
            _placeholder: { color: 'secondaryGray.600' },
          },
          icon: {
            color: 'secondaryGray.600',
          },
        }),
        mini: (props: any) => ({
          field: {
            bg: mode('grey.100', 'navy.800')(props),
            border: '0px solid transparent',
            fontSize: '0px',
            p: '10px',
            _placeholder: { color: 'secondaryGray.600' },
          },
          icon: {
            color: 'secondaryGray.600',
          },
        }),
        subtle: () => ({
          box: {
            width: 'unset',
          },
          field: {
            bg: 'transparent',
            border: '0px solid',
            color: 'secondaryGray.600',
            borderColor: 'transparent',
            width: 'max-content',
            _placeholder: { color: 'secondaryGray.600' },
          },
          icon: {
            color: 'secondaryGray.600',
          },
        }),
        transparent: (props: any) => ({
          field: {
            bg: 'transparent',
            border: '0px solid',
            width: 'min-content',
            color: mode('secondaryGray.600', 'secondaryGray.600')(props),
            borderColor: 'transparent',
            padding: '0px',
            paddingLeft: '8px',
            paddingRight: '20px',
            fontWeight: '700',
            fontSize: '14px',
            _placeholder: { color: 'secondaryGray.600' },
          },
          icon: {
            transform: 'none !important',
            position: 'unset !important',
            width: 'unset',
            color: 'secondaryGray.600',
            right: '0px',
          },
        }),
        auth: () => ({
          field: {
            bg: 'transparent',
            border: '1px solid',

            borderColor: 'secondaryGray.100',
            borderRadius: '16px',
            _placeholder: { color: 'secondaryGray.600' },
          },
        }),
        authSecondary: (props: any) => ({
          field: {
            bg: 'transparent',
            border: '1px solid',

            borderColor: 'secondaryGray.100',
            borderRadius: '16px',
            _placeholder: { color: 'secondaryGray.600' },
          },
        }),
        search: (props: any) => ({
          field: {
            border: 'none',
            py: '11px',
            borderRadius: 'inherit',
            _placeholder: {
              color: 'gray.400',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            },
          },
        }),
      },
    },
  },
};
