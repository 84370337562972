import { mode } from '@chakra-ui/theme-tools';
export const textareaStyles = {
  components: {
    Textarea: {
      baseStyle: {
        field: {
          fontWeight: 400,
          borderRadius: '8px',
        },
      },

      variants: {
        main: (props: any) => ({
          field: {
            _placeholder: { color: 'secondaryGray.500' },
            bg: mode('grey.100', 'navy.800')(props),
            border: '1px solid',
            color: mode('black.100', 'white')(props),
            borderColor: mode('grey.100', 'whiteAlpha.100')(props),
            borderRadius: '10px',
            fontSize: 'sm',
            p: '12px 6px',
          },
        }),
        auth: () => ({
          field: {
            bg: 'white',
            border: '1px solid',
            borderColor: 'secondaryGray.100',
            borderRadius: '16px',
            _placeholder: { color: 'secondaryGray.500' },
          },
        }),
        authSecondary: () => ({
          field: {
            bg: 'white',
            border: '1px solid',

            borderColor: 'secondaryGray.100',
            borderRadius: '16px',
            _placeholder: { color: 'secondaryGray.500' },
          },
        }),
        search: () => ({
          border: 'none',
          py: 2,
          borderRadius: 'inherit',
          _placeholder: {
            color: 'gray.400',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        }),
      },
    },
  },
};
