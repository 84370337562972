import React, { FC } from 'react';
import { HStack, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

import { ReactComponent as ReplayIcon } from '@app/icons/replay-icon.svg';
import { ReactComponent as SpamIcon } from '@app/icons/info-spam-icon.svg';

import { useContextMenuActions } from '../../hooks/contextMenuActions';
import { useContextMenu } from '../../hooks/useContextMenu';

interface MessengerProps {
  top: number;
  left: number;
}

export const ContextMenu: FC<MessengerProps> = ({ top, left }) => {
  const { setSpam, setOpenMenu, setMessage } = useContextMenuActions();

  const { state } = useContextMenu();
  const { t } = useTranslation(['modalsAndMenus']);

  const setReplayId = () => {
    setMessage();
    setOpenMenu({ isOpen: false });
  };

  const setSpamId = () => {
    setOpenMenu({ isOpen: false });
    setSpam(true);
  };
  return (
    <OutsideClickHandler onOutsideClick={() => setOpenMenu({ isOpen: false })}>
      <HStack
        alignItems="flex-start"
        background="grey.100"
        borderRadius="10px"
        boxShadow="2px 4px 10px 2px #00000012"
        flexDirection="column"
        left={left}
        minW="180px"
        padding="6px 10px"
        position="fixed"
        top={top}
        zIndex="10">
        <Button
          _hover={{
            background: 'transparent',
          }}
          borderRadius={0}
          color="grey.300"
          fontSize="14px"
          fontWeight="400"
          gap="10px"
          justifyContent="flex-start"
          pb="4px"
          pl="4px"
          w="100%"
          onClick={setReplayId}>
          <ReplayIcon />
          {t('reply')}
        </Button>
        {/* {!state?.disableReport && (
          <Button
            _hover={{
              background: 'transparent',
            }}
            borderRadius={0}
            color="grey.300"
            fontSize="14px"
            fontWeight="400"
            gap="10px"
            justifyContent="flex-start"
            pl="4px"
            w="100%"
            onClick={setSpamId}>
            <SpamIcon />
            {t('reportSpam')}
          </Button>
        )} */}
      </HStack>
    </OutsideClickHandler>
  );
};
