import { Box, Button, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { botUrls } from '@app/utils/botUrls';

export const Restricted = () => {
  const { t, i18n } = useTranslation('information');
  return (
    <VStack alignItems="center" justifyItems="center" w="full">
      <Text fontSize={32} lineHeight="38px" textAlign="center">
        {t('restricted.title')}
      </Text>
      <Text mt={5} textAlign="center">
        {t('restricted.description')}
      </Text>
      <Box backgroundColor="gray.100" borderRadius={12} mt={6} px={2} py={5}>
        <Text textAlign="center">
          <Trans
            components={{
              support: (
                <Text
                  as={Link}
                  color="primary.green"
                  href={botUrls?.[i18n.resolvedLanguage]}
                  target="_blank"
                  textDecoration="underline"
                />
              ),
            }}
            i18nKey="restricted.support"
            ns="information"
          />
        </Text>
      </Box>
      <Button
        as={RouterLink}
        maxW="160px"
        mt={10}
        to="/logout"
        variant="primary">
        {t('logOut', { ns: 'modalsAndMenus' })}
      </Button>
    </VStack>
  );
};
