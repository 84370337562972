import { StylesConfig } from 'react-select';

export const customStyles = (
  error?: string,
): StylesConfig<{ value: string; label: string; color?: string }, false> => ({
  control: (base, state) => {
    let border = 'none';
    // state.isFocused
    //   ? '1px solid var(--chakra-colors-brand-100)  !important'
    //   : '1px solid var(--chakra-colors-secondaryGray-100) !important';

    if (error) {
      border = '1px solid var(--chakra-colors-red-600) !important';
    }

    return {
      ...base,
      cursor: 'pointer',
      backgroundColor: state.isDisabled
        ? '#F3F6F9'
        : 'var(--chakra-colors-gray-100)',
      borderRadius: '10px',
      boxShadow: '0px 0px 0px transparent',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1px 6px',
      fontSize: '14px',
      color: state.isDisabled
        ? 'var(--chakra-colors-secondaryGray-500)'
        : 'var(--chakra-colors-brand-900)',
      minHeight: '40px',
      border,
      flexWrap: 'nowrap',
      // '&:hover': {
      //   border: '1px solid var(--chakra-colors-brand-100) !important',
      // },
    };
  },
  option: (base) => ({
    ...base,
    color: 'var(--chakra-colors-brand-900)',
    fontSize: '14px',
    cursor: 'pointer',
    background: 'transparent !important',
    padding: '0',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '20px',
    marginTop: '5px',
    overflow: 'hidden',
  }),
  menuList: (base, state) => ({
    ...base,
    padding: '8px 5px 8px 0px',
    marginRight: '5px',
    '::-webkit-scrollbar': {
      width: '5px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: 'white',
      margin: '12px 0',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#ABB0B5',
      borderRadius: '3px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#ABB0B5',
    },
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base, state) => {
    const readOnly = !!state.selectProps?.readOnly || state.isDisabled;

    return {
      ...base,
      color: readOnly
        ? 'var(--chakra-colors-grey-200)'
        : 'var(--chakra-colors-mainGray-300)',
      transition: 'all .3s ease',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      padding: '8px 5px',
      svg: {
        width: '20px',
        height: '20px',
        color: readOnly
          ? 'var(--chakra-colors-grey-200)'
          : 'var(--chakra-colors-mainGray-300)',
      },
    };
  },
  noOptionsMessage: (base) => ({
    ...base,
    padding: '0 6px',
    fontSize: '14px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0px !important',
    flexWrap: 'nowrap',
  }),
  multiValue: (base) => {
    return {
      ...base,
      backgroundColor: 'var(--chakra-colors-blue-200)',
      borderRadius: '10px',
      marginRight: '6px',
      padding: '0px 4px',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  },
  multiValueLabel: (base) => {
    return {
      ...base,
      color: 'var(--chakra-colors-blue-800)',
      fontSize: '14px',
    };
  },
  multiValueRemove: (base) => ({
    ...base,
    color: 'var(--chakra-colors-blue-800)',
    background: 'transparent',
    ':hover': {
      background: 'transparent',
      opacity: '0.8',
    },
  }),
  placeholder: (base, { hasValue }) => {
    return {
      ...base,
      color: 'var(--chakra-colors-secondaryGray-500)',
      margin: '0',
      padding: hasValue ? '0' : '0 10px',
      whiteSpace: 'nowrap',
    };
  },
});
