import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatus } from '@app/api/gql/generated-types';
import { useUpdateOrderMutation } from '@app/api/schemas/order.mongo.generated';
import { OrderChangeStatusModal } from '@app/components/OrderChangeStatusModal';
import { useSuccessNotification } from '@app/hooks/useSuccessNotification';
import { useAddOfferModalContext } from '@app/providers/AddOfferModalProvider';
interface OrderStatusButtonsProps {
  id?: string;
  offerId?: string;
  canUpdate?: boolean;
}

const OrderStatusButtons: FC<OrderStatusButtonsProps> = ({
  id,
  offerId,
  canUpdate = true,
}) => {
  const { t } = useTranslation(['forms']);
  const { handleOpen } = useAddOfferModalContext();
  const notification = useSuccessNotification();
  const reasonDisclosure = useDisclosure();
  const [mutate] = useUpdateOrderMutation();
  const [reason, setReason] = useState();
  const changeOrderStatus = async (status: OrderStatus) => {
    try {
      await mutate({
        variables: {
          input: {
            _id: id,
            status,
            reason,
          },
        },
        optimisticResponse: {
          updateOrder: {
            __typename: 'Order',
            _id: id,
            status,
          },
        },
      });
      reasonDisclosure.onClose();
      notification(t('notifications.order.suscessStatus'));
    } catch (error) {
      console.log(error);
    } finally {
      setReason(null);
    }
  };

  const handleEditOffer = useCallback(async () => {
    try {
      await handleOpen({ offerId, orderId: id });
    } catch (error) {}
  }, [id, offerId]);

  return (
    <>
      <HStack alignItems="center" justifyContent="center" py={2} spacing={4}>
        <Button
          flex={2}
          height={10}
          size="sm"
          variant="outlineGray"
          onClick={reasonDisclosure.onOpen}>
          {t('cancelOrder')}
        </Button>
        {canUpdate && (
          <Button
            flex={3}
            height={10}
            size="sm"
            variant="primary"
            onClick={handleEditOffer}>
            {t('updateDetails')}
          </Button>
        )}
      </HStack>
      {reasonDisclosure.isOpen && (
        <OrderChangeStatusModal
          {...reasonDisclosure}
          title={`${t('cancelOrderTitle')}?`}
          value={reason}
          onChange={setReason}
          onSubmit={() => changeOrderStatus(OrderStatus.CANCELLED)}
        />
      )}
    </>
  );
};

export default OrderStatusButtons;
