import { Button } from '@chakra-ui/react';
import React, { FC } from 'react';

import { ReactComponent as FilterIcon } from '@app/icons/filter-table-icon.svg';

interface FilterButtonProps {
  isActive: boolean;
  onClick: () => void;
}

export const FilterButton: FC<FilterButtonProps> = ({ isActive, onClick }) => {
  return (
    <Button
      backgroundColor="grey.100"
      borderRadius={'10px'}
      color={isActive ? 'primary.green' : 'mainGray.100'}
      h="34px"
      minW="34px"
      padding="4px"
      variant={'icon'}
      onClick={onClick}>
      <FilterIcon />
    </Button>
  );
};
