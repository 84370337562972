export interface ForgotFormProps {
  email: string;
}
export interface NewPasswordFormProps {
  code: string;
  password: string;
}

export const initialValues: ForgotFormProps = {
  email: '',
};

export const newPaswordInitialValues: NewPasswordFormProps = {
  code: '',
  password: '',
};
