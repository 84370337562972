import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatus } from '@app/api/gql/generated-types';
import { useUpdateOrderMutation } from '@app/api/schemas/order.mongo.generated';
import { useSuccessNotification } from '@app/hooks/useSuccessNotification';

import { OrderCancelSubmitModal } from './OrderCancelSubmitModal';

interface OrderStatusDescriptionProps {
  id: string;
}

export const OrderCancelRequest: FC<OrderStatusDescriptionProps> = ({ id }) => {
  const cancelDisclosure = useDisclosure();
  const { t } = useTranslation('tables');
  const notification = useSuccessNotification();
  const [mutate, { loading }] = useUpdateOrderMutation();
  const handleCancelSubmit = useCallback(async () => {
    try {
      await mutate({
        variables: {
          input: {
            _id: id,
            status: OrderStatus.CANCELLED,
          },
        },
      });
      cancelDisclosure.onClose();
      notification(t('notifications.order.suscessStatus'));
    } catch (error) {}
  }, [id]);
  const handleKeepBookingSubmit = useCallback(async () => {
    try {
      await mutate({
        variables: {
          input: {
            _id: id,
            status: OrderStatus.PENDING_AGENT,
          },
        },
      });
      notification(t('notifications.order.suscessStatus'));
    } catch (error) {}
  }, [id]);

  return (
    <>
      <HStack
        alignItems="center"
        justifyContent="flex-end"
        px={5}
        py={2}
        w="full">
        <Button
          height={10}
          isDisabled={loading}
          isLoading={loading}
          size="sm"
          variant="danger"
          onClick={cancelDisclosure.onOpen}>
          {t('cancelBooking')}
        </Button>
        <Button
          height={10}
          size="sm"
          variant="outline"
          w="full"
          onClick={handleKeepBookingSubmit}>
          {t('keepBooking')}
        </Button>
      </HStack>
      <OrderCancelSubmitModal
        {...cancelDisclosure}
        isLoading={loading}
        onSubmit={handleCancelSubmit}
      />
    </>
  );
};
