import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

import { useNotificationsWatch } from '@app/hooks/useNotificationsWatch';

interface NotificationContextProps {
  requests: number;
  orders: number;
  chats: number;
}

export const NotificationContext =
  createContext<NotificationContextProps>(null);

export const useCurrentAccountContext = () => {
  const context = useContext(NotificationContext);

  return context;
};

const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { requests, orders, chats } = useNotificationsWatch();
  return (
    <NotificationContext.Provider
      value={{
        requests,
        orders,
        chats,
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
