import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';

import { MessageAttachment } from '@app/api/gql/generated-types';
import ImagePopup from '@app/components/ImagePopup';
import { ReactComponent as CloseIcon } from '@app/icons/close-icon.svg';
import { ReactComponent as BackIcon } from '@app/icons/come-back-icon.svg';
import { ReactComponent as DownloadIcon } from '@app/icons/download-icon.svg';

const imageWidth = '1029';

interface MessageImageProps {
  attachment: MessageAttachment;
  isTextBelow?: boolean;
  hasTopPlate?: boolean;
  onReply: () => void;
  isOwnerId: boolean;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;
export const MessageImage: FC<MessageImageProps> = ({
  attachment,
  onReply,
  isTextBelow = false,
  hasTopPlate = false,
  isOwnerId = false,
}) => {
  const confirmRemove = useDisclosure();
  const photo = attachment?.url
    ? `${imageServer}/${attachment.url}?width=282&format=webp`
    : '';

  const fullImage = attachment?.url
    ? `${imageServer}/${attachment.url}?width=${imageWidth}&format=webp`
    : '';

  const onDownload = () => {
    const originImage = `${imageServer}/${attachment.url}`;
    fetch(originImage)
      .then((resp) => resp.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.target = '_blank';
        link.download = `${attachment.filename}`;
        link.href = href;
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => console.log(error));
  };

  const onReplyHandle = () => {
    onReply();
    confirmRemove.onClose();
  };

  const borderRadius = useMemo(() => {
    if (isTextBelow && hasTopPlate) {
      return '0';
    }
    if (hasTopPlate) {
      return '0 0 20px 0';
    }
    if (isTextBelow) {
      return '20px 20px 0 0';
    }
    return '20px 20px 0 20px';
  }, [hasTopPlate, isTextBelow]);

  return (
    <>
      <Box
        _hover={{
          opacity: 0.9,
        }}
        bg="grey.100"
        border="5px solid"
        borderBottom={isTextBelow ? '0 none' : '5px solid'}
        borderColor={isOwnerId ? 'primary.green' : 'grey.300'}
        borderRadius={borderRadius}
        cursor="pointer"
        overflow="hidden"
        transition="all 0.15s"
        w="100%"
        onClick={confirmRemove.onOpen}>
        <AspectRatio>
          <Image
            alt={`Image ${attachment?.filename}`}
            fallback={<Skeleton h="100px" maxW="420px" minW="100px" w="full" />}
            objectFit="cover"
            outline="none!important"
            src={photo}
          />
        </AspectRatio>
      </Box>
      {confirmRemove.isOpen && (
        <ImagePopup
          {...confirmRemove}
          closeOnOverlayClick
          maxW={`${imageWidth}px`}
          size="5xl"
          title={
            <HStack gap="20px" justifyContent="flex-end">
              <Button
                _hover={{
                  background: 'transparent',
                }}
                background="transparent"
                borderRadius="0px"
                h="30px"
                px="0"
                w="30px"
                onClick={onReplyHandle}>
                <BackIcon color="white" />
              </Button>
              <Button
                _hover={{
                  background: 'transparent',
                }}
                background="transparent"
                borderRadius="0px"
                h="30px"
                px="0"
                w="30px"
                onClick={onDownload}>
                <DownloadIcon />
              </Button>
              <Button
                _hover={{
                  background: 'transparent',
                }}
                background="transparent"
                borderRadius="0px"
                h="30px"
                px="0"
                w="30px"
                onClick={confirmRemove.onClose}>
                <CloseIcon color="white" fill="white" stroke="white" />
              </Button>
            </HStack>
          }
          width={`${imageWidth}px`}>
          <Image
            alt={`Image ${attachment?.filename}`}
            fallback={
              <Skeleton
                endColor="#00000030"
                h="100%"
                startColor="#00000080"
                w="100%"
              />
            }
            height="100%"
            objectFit="contain"
            outline="none!important"
            src={fullImage}
          />
        </ImagePopup>
      )}
    </>
  );
};
