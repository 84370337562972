import { Button } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { PhoneNumberInput } from '@app/components/PhoneNumberInput';
import { useErrorNotification } from '@app/hooks/useErrorNotification';
import { useSuccessNotification } from '@app/hooks/useSuccessNotification';

import { useValidationSchema } from '../../hooks/useValidationSchema';

const token = process.env.REACT_APP_AIRTABLE_TOKEN;
const url = process.env.REACT_APP_AIRTABLE_PHONE_FORM_URL;

interface FormProps {
  onSuccess?: () => void;
}

export const Form: FC<FormProps> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const errorToast = useErrorNotification();
  const successToast = useSuccessNotification();
  const validationSchema = useValidationSchema();
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    defaultValues: {
      phone: null,
    },
    resolver: validationSchema,
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      await axios.post(
        url,
        {
          fields: {
            ['Phone']: values.phone,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        },
      );

      onSuccess?.();
      form.resetField('phone');
      successToast(
        `Дякуємо! Наші менеджери зв'яжуться з Вами найближчим часом.`,
      );
    } catch (error) {
      errorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <PhoneNumberInput
        height="45px"
        mask="+99 (999) 999-99-99"
        mb="25px"
        name="phone"
        placeholder={t('phoneNumber', { ns: 'forms' })}
        size="lg"
        variant="auth"
      />
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        maxW="200px"
        variant="primary"
        onClick={form.handleSubmit(onSubmit)}>
        Відправити
      </Button>
    </FormProvider>
  );
};
