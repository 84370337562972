import React, { FC, PropsWithChildren } from 'react';
import { FormLabel, Text } from '@chakra-ui/react';
import { FormLabelProps } from '@chakra-ui/form-control/dist/form-label';

import { useColorMode } from '@app/hooks/useColorMode';

interface LabelProps extends PropsWithChildren, FormLabelProps {
  isRequired?: boolean;
  htmlFor?: string;
}

const Label: FC<LabelProps> = ({
  htmlFor,
  isRequired = false,
  children,
  ...props
}) => {
  const { textColor, brandStars } = useColorMode();
  return (
    <FormLabel
      color={textColor}
      display="flex"
      fontSize="xs"
      fontWeight="400"
      htmlFor={htmlFor}
      ms="4px"
      {...props}>
      {children}
      {isRequired && <Text color={brandStars}>*</Text>}
    </FormLabel>
  );
};

export default Label;
