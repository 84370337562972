import React, { FC } from 'react';
import {
  Box,
  Button,
  Image,
  Flex,
  Text,
  UseDisclosureProps,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { OfferPhoto, OfferStatus } from '@app/api/gql/generated-types';
import { InformationStatus } from '@app/views/Offers/components/Information/InformationStatus';

import Popup from '../Popup';

interface ConfirmationModalProps extends UseDisclosureProps {
  onSubmit: () => Promise<void> | void;
  title?: string;
  width?: string;
  confirmBtnText?: string;
  description?: string | string[];
  photo?: OfferPhoto;
  status?: OfferStatus;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  width = '400px',
  confirmBtnText,
  description,
  photo,
  status,
}) => {
  const { t } = useTranslation(['modalsAndMenus']);
  const popupTitle = title || t('confirmation');
  const popupConfirmBtnText = confirmBtnText || t('confirmBtnText');
  const popupDescription = description || t('confirmationDescription');

  const image = photo?.key
    ? `${imageServer}/${photo.key}?width=800&format=webp`
    : null;

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={!!image}
      isOpen={isOpen}
      maxW={width}
      title={image ? '' : popupTitle}
      onClose={onClose}>
      <Box p={image ? '0px' : '0px 40px 30px'}>
        {image && (
          <Box
            borderRadius="30px 30px 0px 0px"
            overflow="hidden"
            position="relative">
            <Box
              background="linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 80%);"
              h="100px"
              left={0}
              position="absolute"
              top={0}
              w="full"
              zIndex={2}
            />
            <ModalCloseButton
              _focus={{ outline: 'none' }}
              color="white"
              data-cy="close-btn"
              right="19px"
              size="md"
              top="19px"
              zIndex="3"
            />
            <Image
              h="200px"
              mb="20px"
              objectFit="cover"
              outline="none!important"
              src={image}
              w="full"
            />
            {status && (
              <Box bottom={8} left={5} position={'absolute'}>
                <InformationStatus status={status} />
              </Box>
            )}
          </Box>
        )}
        {popupDescription instanceof Array ? (
          <Box px={10}>
            {!!image && (
              <Text
                color="black.100"
                fontSize="24px"
                fontWeight="300"
                noOfLines={1}
                textAlign="center">
                {popupTitle}
              </Text>
            )}
            <Text
              color="black.100"
              fontSize="26px"
              fontWeight="700"
              my="25px"
              textAlign="center">
              {popupDescription?.[0]}
            </Text>
            <Text
              color="black.100"
              fontSize="14px"
              mb="18px"
              textAlign="center">
              {popupDescription?.[1]}
            </Text>
            <Text color="grey.250" fontSize="14px" mx="25px" textAlign="center">
              {popupDescription?.[2]}
            </Text>
          </Box>
        ) : (
          <Text color="secondaryGray.600" mx="20px" textAlign="center">
            {popupDescription}
          </Text>
        )}
        <Flex direction="column" mb="20px" mt="40px" mx="20px">
          <Button
            margin="auto"
            maxW="350px"
            variant="primary"
            onClick={onSubmit}>
            {popupConfirmBtnText}
          </Button>
          <Button margin="auto" maxW="350px" variant="cancel" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
