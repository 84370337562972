import {
  Box,
  Grid,
  Badge,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useWatchData } from '@app/hooks/useWatchData';
import { ReactComponent as OffersIcon } from '@app/icons/new-offers-icon.svg';
import { ReactComponent as OrdersIcon } from '@app/icons/new-orders-icon.svg';
import { ReactComponent as ChatsIcon } from '@app/icons/new-chats-icon.svg';
import { ReactComponent as RequestsIcon } from '@app/icons/new-requests-icon.svg';
import { ReactComponent as SettingsIcon } from '@app/icons/new-settings-icon.svg';

export const SidebarMobile = () => {
  const { requests, orders, chats } = useWatchData();
  const { t } = useTranslation('navbar');

  const inactiveColor = useColorModeValue('grey.300', 'secondaryGray.600');
  const activeIcon = useColorModeValue('primary.green', 'white');

  const links = [
    {
      name: t('requests'),
      to: '/requests',
      icon: <RequestsIcon />,
      count: requests,
    },
    {
      name: t('offers'),
      to: '/offers',
      icon: <OffersIcon />,
    },
    {
      name: t('orders'),
      to: '/orders',
      icon: <OrdersIcon />,
      count: orders,
    },
    {
      name: t('chat'),
      to: '/chat',
      icon: <ChatsIcon />,
      count: chats,
    },
    {
      name: t('settings'),
      to: '/settings',
      icon: <SettingsIcon />,
    },
  ];
  return (
    <Grid
      bg="white.0"
      bottom={0}
      boxShadow="0px 4px 20.9px 0px #00000040"
      display={{ base: 'grid', md: 'none' }}
      gap="10px"
      p="10px"
      position="sticky"
      right={0}
      templateColumns={'repeat(5, 1fr)'}
      w="full"
      zIndex="100">
      {links.map((x) => (
        <NavLink key={x.to} to={x.to}>
          {({ isActive }) => (
            <Flex
              alignItems="center"
              flexDirection="column"
              position="relative">
              <Box
                _groupHover={{
                  color: activeIcon,
                }}
                color={isActive ? activeIcon : inactiveColor}
                mx="10px"
                transition="0.2s">
                {x.icon}
              </Box>
              <Text fontSize="11px">{x.name}</Text>
              {!!x.count && (
                <Badge
                  alignItems="center"
                  backgroundColor="red"
                  color="white"
                  display="flex"
                  h={5}
                  justifyContent="center"
                  p="2px"
                  pl="4px"
                  position="absolute"
                  pr="4px"
                  right="6px"
                  top="-6px"
                  w={5}>
                  {x.count > 9 ? '9+' : x.count}
                </Badge>
              )}
            </Flex>
          )}
        </NavLink>
      ))}
    </Grid>
  );
};
