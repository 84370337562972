import { Box, Checkbox, Flex, HStack, Text } from '@chakra-ui/react';
import take from 'lodash/take';
import React, { forwardRef } from 'react';
import Select, {
  GroupBase,
  MenuListProps,
  OptionProps,
  Props,
  ValueContainerProps,
  components,
} from 'react-select';

import { customStyles } from './styles';

interface SelectProps<T, M, G> extends Props<T, M, G> {
  isReadOnly?: boolean;
  error?: string;
  color?: string;
  itemsLength?: number;
  columns?: number;
}

const DEFAULT_ITEMS_LENGTH = 3;
const DEFAULT_COLUMNS_COUNT = 1;

export const Option = <
  T = {
    label: string;
    value: string;
  },
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  props: OptionProps<T, M, G>,
) => {
  return (
    <components.Option {...props}>
      <HStack
        _hover={{
          color: 'primary.green',
        }}>
        <Checkbox
          key={props?.value}
          isChecked={props?.isSelected}
          pointerEvents="none"
          variant="multiList">
          {props?.label}
        </Checkbox>
        {!!props.data?.count && (
          <HStack
            alignItems="center"
            backgroundColor={props.data?.statusColor ?? 'primary.green'}
            borderRadius={3}
            justifyContent="center"
            minW={6}>
            <Text color="white" fontSize={12} fontWeight={600}>
              {props.data.count}
            </Text>
          </HStack>
        )}
      </HStack>
    </components.Option>
  );
};
export const ValueContainer = <
  T = {
    label: string;
    value: string;
  },
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>({
  children,
  ...rest
}: ValueContainerProps<T, M, G>) => {
  const itemsLength = rest.selectProps.itemsLength ?? DEFAULT_ITEMS_LENGTH;
  const selectedCount = rest.getValue().length;
  const conditional = selectedCount <= itemsLength;

  let items = [];

  if (!conditional) {
    items = [take(children[0], itemsLength), children[1]];
  }

  return (
    <components.ValueContainer {...rest}>
      <>
        {conditional ? children : items}
        {!conditional && (
          <Flex
            alignItems="center"
            bg="white"
            borderRadius="50px"
            color="gray.600"
            flex={1}
            fontWeight={500}
            h="32px"
            justifyContent="center"
            justifySelf="flex-end"
            maxW="32px"
            minW="32px"
            w="32px">
            +{selectedCount - itemsLength}
          </Flex>
        )}
        {selectedCount > 0 && selectedCount < itemsLength && (
          <Flex>
            <components.Placeholder {...rest}>
              {rest?.selectProps?.placeholder}
            </components.Placeholder>
          </Flex>
        )}
      </>
    </components.ValueContainer>
  );
};

const MenuList = <
  T = {
    label: string;
    value: string;
  },
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  props: MenuListProps<T, M, G>,
) => {
  const columnCount = props.selectProps.columns ?? DEFAULT_COLUMNS_COUNT;
  return (
    <components.MenuList {...props}>
      <Box columnGap={3} sx={{ columnCount }}>
        {props.children}
      </Box>
    </components.MenuList>
  );
};

// eslint-disable-next-line react/display-name
export const MultiSelectField = forwardRef(
  (
    { isSearchable, error, isReadOnly, ...props }: SelectProps<T, M, G>,
    ref,
  ) => {
    return (
      <Select
        ref={ref}
        components={{ Option, ValueContainer, MenuList }}
        isDisabled={isReadOnly}
        isSearchable={!!isSearchable}
        styles={customStyles(error)}
        {...props}
      />
    );
  },
);
