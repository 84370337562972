import { format } from 'date-fns';

interface FormatOptions {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}

export const formatDate = (
  date: Date,
  dateFormat = 'PP',
  locale?: FormatOptions,
) => {
  if (!date) return null;
  return format(date, dateFormat, locale);
};

export const formatDateFromMillis = (date: number, dateFormat = 'PP') => {
  if (!date) return null;

  return format(date, dateFormat);
};
