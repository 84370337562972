import {
  Box,
  Button,
  Checkbox,
  HStack,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Popup from '@app/components/Popup';

interface OrderCancelSubmitModalProps extends UseDisclosureProps {
  onSubmit: () => void;
  isLoading: boolean;
}

export const OrderCancelSubmitModal: FC<OrderCancelSubmitModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading = false,
}) => {
  const { t } = useTranslation(['forms']);
  const [agree, setAgree] = useState<boolean>(false);
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="500px"
      title={t('bookingCancelation')}
      onClose={onClose}>
      <Box p="30px 60px 30px">
        <Text mb={4}>{t('bookingCancelDescriptionRefund')}</Text>
        <Text mb={6}>{t('bookingCancelDescriptionCommission')}</Text>
        <Checkbox
          value={agree}
          variant="primary"
          onChange={() => setAgree((val) => !val)}>
          {t('bookingCancelConfirmText')}
        </Checkbox>
        <HStack direction="column" mt="40px">
          <Button
            isDisabled={!agree}
            isLoading={isLoading}
            variant="danger"
            onClick={onSubmit}>
            {t('cancelOrder')}
          </Button>
        </HStack>
      </Box>
    </Popup>
  );
};
