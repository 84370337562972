import { Box, Flex, IconButton } from '@chakra-ui/react';
import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { ReactComponent as Arrow } from '@app/icons/arrow-right-icon.svg';

import './datepicker.css';
import { DateFormatter } from '../DateFormatter';

export const CustomDatepickerHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: Partial<ReactDatePickerCustomHeaderProps>) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" mb="15px">
      <IconButton
        aria-label="Previous month"
        borderRadius="10px"
        color="white"
        disabled={prevMonthButtonDisabled}
        size="sm"
        variant="brand"
        onClick={decreaseMonth}>
        <Arrow />
      </IconButton>
      <Box fontSize="18px" fontWeight={700}>
        <DateFormatter date={date} format="LLLL yyyy" />
      </Box>
      <IconButton
        aria-label="Previous month"
        borderRadius="10px"
        disabled={nextMonthButtonDisabled}
        size="sm"
        transform="rotate(180deg)"
        variant="brand"
        onClick={increaseMonth}>
        <Arrow />
      </IconButton>
    </Flex>
  );
};
