import { Image } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';

import { getFile } from '@app/utils/getFile';
import { OfferFileFragment } from '@app/api/schemas/photo.mongo.generated';

interface DropFileIconProps {
  file: File | OfferFileFragment;
}

export const DropFileIcon: FC<DropFileIconProps> = ({ file }) => {
  const photoUri = useMemo(() => getFile(file), [file]);

  return (
    <Image height="100px" objectFit="cover" src={photoUri} width="100px" />
  );
};
