import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

interface InformationItemProps extends FlexProps {
  title?: string;
  children?: ReactNode;
}

export const InformationItem: FC<InformationItemProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <Flex alignItems="center" justifyContent="flex-start" py="6px" {...props}>
      <Box flex="0 0 130px">
        <Text color="grey.300" fontSize="14px" fontWeight={400}>
          {title}:
        </Text>
      </Box>
      <Box>{children}</Box>
    </Flex>
  );
};
