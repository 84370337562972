import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import { FileFragmentDoc } from './account.amazon.generated';
import { CurrencyFragmentDoc } from './currency.amazon.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BusinessFragment = { __typename?: 'Business', status: string | null, ownerId: string | null, companyName: string | null, createdAt: string | null, phone: string | null, city: string | null, address: string | null, email: string | null, balance: number | null, accounts: Array<{ __typename?: 'Account', fullName: string | null, firstName: string | null, lastName: string | null, email: string | null, phone: string | null, avatar: { __typename?: 'ImageData', bucket: string | null, key: string | null, location: string | null } | null }> | null, avatar: { __typename?: 'ImageData', bucket: string | null, key: string | null, location: string | null } | null, country: { __typename?: 'Country', name: string | null } | null, docs: Array<{ __typename?: 'ImageData', bucket: string | null, key: string | null, location: string | null }> | null };

export type CurrencyRatesFragment = { __typename?: 'CurrencyRateData', rate: number, currencyA: { __typename?: 'Currency', _id: string, code: string | null, name: string | null, symbol: string | null, isoCode: string | null } | null, currencyB: { __typename?: 'Currency', _id: string, code: string | null, name: string | null, symbol: string | null, isoCode: string | null } | null };

export type BusinessQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BusinessQuery = { __typename?: 'Query', business: { __typename?: 'Business', paymentProcessorName: Types.PaymentProcessorName | null, paymentProcessorToken: string | null, useCustomRates: boolean | null, status: string | null, ownerId: string | null, companyName: string | null, createdAt: string | null, phone: string | null, city: string | null, address: string | null, email: string | null, balance: number | null, currencyRates: Array<{ __typename?: 'CurrencyRateData', rate: number, currencyA: { __typename?: 'Currency', _id: string, code: string | null, name: string | null, symbol: string | null, isoCode: string | null } | null, currencyB: { __typename?: 'Currency', _id: string, code: string | null, name: string | null, symbol: string | null, isoCode: string | null } | null }> | null, accounts: Array<{ __typename?: 'Account', fullName: string | null, firstName: string | null, lastName: string | null, email: string | null, phone: string | null, avatar: { __typename?: 'ImageData', bucket: string | null, key: string | null, location: string | null } | null }> | null, avatar: { __typename?: 'ImageData', bucket: string | null, key: string | null, location: string | null } | null, country: { __typename?: 'Country', name: string | null } | null, docs: Array<{ __typename?: 'ImageData', bucket: string | null, key: string | null, location: string | null }> | null } | null };

export type FindAgentsQueryVariables = Types.Exact<{
  filter: Types.InputMaybe<Types.FilterInput>;
  pagination: Types.InputMaybe<Types.PaginationInput>;
}>;


export type FindAgentsQuery = { __typename?: 'Query', businesses: { __typename?: 'BusinessList', hasNext: boolean, nextCreatedAt: string | null, nextId: string | null, total: number, items: Array<{ __typename?: 'Business', _id: string | null, description: string | null, status: string | null, ownerId: string | null, companyName: string | null, createdAt: string | null, avatar: { __typename?: 'ImageData', bucket: string | null, key: string | null, location: string | null } | null, country: { __typename?: 'Country', name: string | null } | null, accounts: Array<{ __typename?: 'Account', email: string | null, _id: string }> | null }> } };

export type CreateBusinessMutationVariables = Types.Exact<{
  input: Types.CreateBusinessInput;
}>;


export type CreateBusinessMutation = { __typename?: 'Mutation', createBusiness: { __typename?: 'Business', _id: string | null } };

export type UpdateBusinessMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateBusinessInput;
}>;


export type UpdateBusinessMutation = { __typename?: 'Mutation', updateBusiness: boolean };

export type ConfigurePaymentProviderMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.ConfigurePaymentProviderInput;
}>;


export type ConfigurePaymentProviderMutation = { __typename?: 'Mutation', configurePaymentProvider: boolean };

export const BusinessFragmentDoc = gql`
    fragment Business on Business {
  accounts {
    avatar {
      ...File
    }
    fullName
    firstName
    lastName
    email
    phone
  }
  avatar {
    ...File
  }
  status
  ownerId
  companyName
  createdAt
  phone
  country {
    name
  }
  city
  address
  docs {
    ...File
  }
  email
  balance
}
    ${FileFragmentDoc}`;
export const CurrencyRatesFragmentDoc = gql`
    fragment CurrencyRates on CurrencyRateData {
  currencyA {
    ...Currency
  }
  currencyB {
    ...Currency
  }
  rate
}
    ${CurrencyFragmentDoc}`;
export const BusinessDocument = gql`
    query Business($id: ID!) {
  business(id: $id) {
    ...Business
    paymentProcessorName
    paymentProcessorToken
    useCustomRates
    currencyRates {
      ...CurrencyRates
    }
  }
}
    ${BusinessFragmentDoc}
${CurrencyRatesFragmentDoc}`;

/**
 * __useBusinessQuery__
 *
 * To run a query within a React component, call `useBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessQuery(baseOptions: Apollo.QueryHookOptions<BusinessQuery, BusinessQueryVariables> & ({ variables: BusinessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
      }
export function useBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
        }
export function useBusinessSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
        }
export type BusinessQueryHookResult = ReturnType<typeof useBusinessQuery>;
export type BusinessLazyQueryHookResult = ReturnType<typeof useBusinessLazyQuery>;
export type BusinessSuspenseQueryHookResult = ReturnType<typeof useBusinessSuspenseQuery>;
export type BusinessQueryResult = Apollo.QueryResult<BusinessQuery, BusinessQueryVariables>;
export const FindAgentsDocument = gql`
    query FindAgents($filter: FilterInput, $pagination: PaginationInput) {
  businesses(filter: $filter, pagination: $pagination) {
    hasNext
    items {
      _id
      description
      status
      avatar {
        ...File
      }
      ownerId
      companyName
      country {
        name
      }
      createdAt
      accounts {
        email
        _id
      }
    }
    nextCreatedAt
    nextId
    total
  }
}
    ${FileFragmentDoc}`;

/**
 * __useFindAgentsQuery__
 *
 * To run a query within a React component, call `useFindAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAgentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindAgentsQuery(baseOptions?: Apollo.QueryHookOptions<FindAgentsQuery, FindAgentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAgentsQuery, FindAgentsQueryVariables>(FindAgentsDocument, options);
      }
export function useFindAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAgentsQuery, FindAgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAgentsQuery, FindAgentsQueryVariables>(FindAgentsDocument, options);
        }
export function useFindAgentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAgentsQuery, FindAgentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAgentsQuery, FindAgentsQueryVariables>(FindAgentsDocument, options);
        }
export type FindAgentsQueryHookResult = ReturnType<typeof useFindAgentsQuery>;
export type FindAgentsLazyQueryHookResult = ReturnType<typeof useFindAgentsLazyQuery>;
export type FindAgentsSuspenseQueryHookResult = ReturnType<typeof useFindAgentsSuspenseQuery>;
export type FindAgentsQueryResult = Apollo.QueryResult<FindAgentsQuery, FindAgentsQueryVariables>;
export const CreateBusinessDocument = gql`
    mutation CreateBusiness($input: CreateBusinessInput!) {
  createBusiness(input: $input) {
    _id
  }
}
    `;
export type CreateBusinessMutationFn = Apollo.MutationFunction<CreateBusinessMutation, CreateBusinessMutationVariables>;

/**
 * __useCreateBusinessMutation__
 *
 * To run a mutation, you first call `useCreateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessMutation, { data, loading, error }] = useCreateBusinessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessMutation, CreateBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessMutation, CreateBusinessMutationVariables>(CreateBusinessDocument, options);
      }
export type CreateBusinessMutationHookResult = ReturnType<typeof useCreateBusinessMutation>;
export type CreateBusinessMutationResult = Apollo.MutationResult<CreateBusinessMutation>;
export type CreateBusinessMutationOptions = Apollo.BaseMutationOptions<CreateBusinessMutation, CreateBusinessMutationVariables>;
export const UpdateBusinessDocument = gql`
    mutation UpdateBusiness($id: ID!, $input: UpdateBusinessInput!) {
  updateBusiness(id: $id, input: $input)
}
    `;
export type UpdateBusinessMutationFn = Apollo.MutationFunction<UpdateBusinessMutation, UpdateBusinessMutationVariables>;

/**
 * __useUpdateBusinessMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessMutation, { data, loading, error }] = useUpdateBusinessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessMutation, UpdateBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessMutation, UpdateBusinessMutationVariables>(UpdateBusinessDocument, options);
      }
export type UpdateBusinessMutationHookResult = ReturnType<typeof useUpdateBusinessMutation>;
export type UpdateBusinessMutationResult = Apollo.MutationResult<UpdateBusinessMutation>;
export type UpdateBusinessMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessMutation, UpdateBusinessMutationVariables>;
export const ConfigurePaymentProviderDocument = gql`
    mutation ConfigurePaymentProvider($id: ID!, $input: ConfigurePaymentProviderInput!) {
  configurePaymentProvider(id: $id, input: $input)
}
    `;
export type ConfigurePaymentProviderMutationFn = Apollo.MutationFunction<ConfigurePaymentProviderMutation, ConfigurePaymentProviderMutationVariables>;

/**
 * __useConfigurePaymentProviderMutation__
 *
 * To run a mutation, you first call `useConfigurePaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigurePaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configurePaymentProviderMutation, { data, loading, error }] = useConfigurePaymentProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfigurePaymentProviderMutation(baseOptions?: Apollo.MutationHookOptions<ConfigurePaymentProviderMutation, ConfigurePaymentProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfigurePaymentProviderMutation, ConfigurePaymentProviderMutationVariables>(ConfigurePaymentProviderDocument, options);
      }
export type ConfigurePaymentProviderMutationHookResult = ReturnType<typeof useConfigurePaymentProviderMutation>;
export type ConfigurePaymentProviderMutationResult = Apollo.MutationResult<ConfigurePaymentProviderMutation>;
export type ConfigurePaymentProviderMutationOptions = Apollo.BaseMutationOptions<ConfigurePaymentProviderMutation, ConfigurePaymentProviderMutationVariables>;