import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const defaultDim = { width: null, height: null };
  const [windowDimensions, setWindowDimensions] = useState(defaultDim);

  useEffect(() => {
    setTimeout(() => {
      setWindowDimensions(getWindowDimensions());
    }, 2000);

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
