import { HStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

import { mapStyleArray } from '@app/utils/mapStyles';

interface GoogleMapViewProps {
  width: string;
  height: string;
  center: {
    lat: number;
    lng: number;
  };
}

export const GoogleMapView: FC<GoogleMapViewProps> = ({
  width = '400px',
  height = '400px',
  center,
}) => {
  return (
    <HStack>
      <GoogleMap
        center={center}
        mapContainerStyle={{
          width: width,
          height: height,
        }}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          styles: mapStyleArray,
        }}
        zoom={10}>
        <MarkerF key={center?.lat + center?.lng} position={center} />
      </GoogleMap>
    </HStack>
  );
};
