import React, { FC } from 'react';

import { OfferPrice } from '@app/api/gql/generated-types';

import { PriceField } from '.';

interface RequestPriceProps {
  value: OfferPrice;
}

export const RequestPrice: FC<RequestPriceProps> = ({ value }) => {
  return <PriceField value={value} />;
};
