import Dexie from 'dexie';

import { ChatsTable, messengerSchema } from './tables/messenger';

type DexieTables = ChatsTable;

export type DexieProps<T extends any = DexieTables> = Dexie & T;

export const db = new Dexie('mira') as DexieProps;

const schema = Object.assign({}, messengerSchema);

db.version(1).stores(schema);
