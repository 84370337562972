import { Box, Button } from '@chakra-ui/react';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { CreateOfferInput, OfferAddition } from '@app/api/gql/generated-types';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';

import { OfferTypeChange } from './MainInformation/OfferTypeChange';
import { OfferPriceSegment } from './OfferPrice/OfferPriceSegment';
import { useAdditionalPriceValues } from './OfferPrice/useAdditonalPriceValues';
import { OfferPriceEstimate } from './OfferPrice/OfferPriceEstimate';

export const defaultWidth = '240px';

interface OfferDetailsProps {
  isHideMultiplePrices?: boolean;
}

export const OfferDetails: FC<OfferDetailsProps> = ({
  isHideMultiplePrices = false,
}) => {
  const { t } = useTranslation('forms');
  const { initialValues } = useAdditionalPriceValues();
  const { control, trigger } = useFormContext<CreateOfferInput>();
  const { type } = useWatch<OfferFragment>('type');

  const { fields, remove, append } = useFieldArray<OfferAddition[]>({
    control,
    name: 'offerAddition',
  });

  const isShowButton = useMemo(() => {
    if (!isHideMultiplePrices) {
      return true;
    }
    if (!fields?.length || fields?.length < 1) {
      return true;
    }
    return false;
  }, [fields?.length, isHideMultiplePrices]);

  const handleInsert = useCallback(async () => {
    const lastIndex = fields?.length - 1;
    const isValidData = await trigger([
      `offerAddition.${lastIndex}.startDate`,
      `offerAddition.${lastIndex}.days`,
      `offerAddition.${lastIndex}.price`,
      `offerAddition.${lastIndex}.adults`,
      `offerAddition.${lastIndex}.children`,
    ]);

    if (!isValidData) {
      return;
    }

    const everyAdditionSavedArr = await Promise.allSettled(
      fields.map((_, index) => trigger([`offerAddition.${index}.isSaved`])),
    );
    const isDataSaved = everyAdditionSavedArr.every((result) => result.value);

    if (!isDataSaved) {
      return;
    }

    append(initialValues);
  }, [fields?.length, initialValues]);

  const handleRemove = useCallback((index: number) => {
    remove(index);
  }, []);

  return (
    <Box>
      <OfferPriceEstimate />

      <OfferTypeChange />
      {fields?.map((field, index) => {
        return (
          <OfferPriceSegment
            key={field.id}
            isFirst={index === 0}
            name={`offerAddition.${index}`}
            type={type}
            onRemove={() => handleRemove(index)}
          />
        );
      })}
      {isShowButton && (
        <Button mt={4} variant="link" onClick={handleInsert}>
          {t('addDates')}
        </Button>
      )}
    </Box>
  );
};
