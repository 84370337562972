import React from 'react';
import {
  Box,
  Button,
  Grid,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactSortable } from 'react-sortablejs';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Remove } from '@app/icons/remove-icon.svg';
import { ReactComponent as CloudUploadIcon } from '@app/icons/upload-icon.svg';

import { DropFileIcon } from './DropFileIcon';

interface DropzoneContentProps {
  title: string;
  subTitle?: string;
  files?: string[];
  allowedFileExtensionsText: string;
  onRemove: (item: number) => void;
  onSort: (files: string[]) => void;
  isSearchable?: boolean;
  onSearchOpen: () => void;
}

export const DropzoneContent: React.FC<DropzoneContentProps> = ({
  title,
  subTitle,
  files = [],
  allowedFileExtensionsText,
  onRemove,
  onSort,
  isSearchable = false,
  onSearchOpen,
}) => {
  const { t } = useTranslation('forms');
  const textColor = useColorModeValue('brand.900', 'white');
  const brand = useColorModeValue('primary.green', 'brand.400');
  const gridCount = files?.length > 5 ? 5 : files?.length;

  const onLocalRemove = (item: number, e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove(item);
  };
  const handleSearchOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSearchOpen();
  };

  return (
    <VStack
      alignItems="center"
      direction="column"
      overflow="hidden"
      p={3}
      spacing={1}>
      {!!files?.length ? (
        <Grid
          as={ReactSortable}
          columnGap="10px"
          gridTemplateColumns={`repeat(${gridCount}, 1fr)`}
          list={files}
          my="10px"
          rowGap="10px"
          setList={onSort}>
          {files.map((file, i) => (
            <Box key={i} position="relative">
              <DropFileIcon file={file} />
              <IconButton
                aria-label="Remove"
                color="secondaryGray.600"
                position="absolute"
                right={1}
                size="xs"
                top={1}
                onClick={(e) => onLocalRemove(i, e)}>
                <HStack justifyContent="center">
                  <Remove />
                </HStack>
              </IconButton>
            </Box>
          ))}
        </Grid>
      ) : (
        <Icon as={CloudUploadIcon} h="44px" w="44px" />
      )}
      <Text
        align="center"
        color={textColor}
        fontSize="md"
        fontWeight="700"
        mx="auto">
        {title}
      </Text>
      {!!subTitle && (
        <Text
          align="center"
          as="span"
          color={brand}
          fontSize="md"
          fontWeight="700">
          {subTitle}
        </Text>
      )}
      <Text align="center" color="gray.200" fontSize={12} fontWeight="500">
        {allowedFileExtensionsText}
      </Text>
      {isSearchable && (
        <HStack justifyContent="center">
          <Button h={7} mb={1} mt={3} variant="dark" onClick={handleSearchOpen}>
            {t('searchPhotos')}
          </Button>
        </HStack>
      )}
    </VStack>
  );
};
