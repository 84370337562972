import { Badge, BadgeProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OfferStatus } from '@app/api/gql/generated-types';
import { statusColor } from '@app/hooks/useStatusColor';

interface InformationStatusProps extends BadgeProps {
  status: OfferStatus;
}

export const InformationStatus: FC<InformationStatusProps> = ({
  status,
  ...props
}) => {
  const { t } = useTranslation(['tables']);

  return (
    <Badge
      background={statusColor(status)}
      color={'white.0'}
      fontSize={'14px'}
      fontWeight={500}
      textTransform="capitalize"
      {...props}>
      {t(`statuses.${status}`)}
    </Badge>
  );
};
