import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface LoadMoreButtonProps {
  isLoading?: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

export const LoadMoreButton: FC<LoadMoreButtonProps> = ({
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}) => {
  const { t } = useTranslation('tables');
  if (hasNextPage && !isLoading) {
    return (
      <Button
        disabled={isFetchingNextPage}
        h="46px"
        isLoading={isLoading}
        loadingText={t('loadMore')}
        size="sm"
        variant="outline"
        w="170px"
        onClick={fetchNextPage}>
        {t('loadMore')}
      </Button>
    );
  }

  return null;
};
