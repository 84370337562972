import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Text,
  Tooltip,
  IconButton,
  Center,
  ModalHeader,
  Input,
  VStack,
  useDisclosure,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';

import { ReactComponent as InfoIcon } from '@app/icons/info-icon.svg';
import { SidebarContext } from '@app/contexts/SidebarContext';
import Popup from '@app/components/Popup';
import { ReactComponent as RechargeIcon } from '@app/icons/recharge-icon.svg';
import useCurrentAccount from '@app/hooks/useCurrentAccount';

export const BalanceBlock = () => {
  const { isOpen, onClose } = useDisclosure();
  const { t } = useTranslation('modalsAndMenus');
  const { toggleSidebar } = useContext(SidebarContext);

  const { business } = useCurrentAccount();
  const balance = business?.balance ? business.balance / 100 : 0;
  const onTopUpSubmit = () => {
    onClose();
  };
  const isNotEnoughBalance = balance < 0;
  const inactiveColor = useColorModeValue('grey.300', 'secondaryGray.600');
  const activeColor = useColorModeValue('primary.green', 'white');

  return (
    <>
      {toggleSidebar ? (
        <Box
          background={isNotEnoughBalance ? '#fEEAE9' : 'gray.100'}
          padding="25px 20px"
          textAlign="center"
          w="full">
          <VStack alignItems="flex-start" spacing={5}>
            <Text fontSize={16}>
              {`${t('balance')}: `}
              <Text
                as="span"
                color={isNotEnoughBalance ? 'red' : 'black.100'}
                fontSize={16}
                fontWeight={700}>
                {`${balance.toFixed(0)} ₴`}
              </Text>
              {isNotEnoughBalance && (
                <Tooltip
                  hasArrow
                  bg="#EA7B34"
                  borderRadius="10px"
                  colorScheme="red"
                  label={
                    <Text
                      bg="#EA7B34"
                      borderRadius="10px"
                      color="white"
                      fontSize="13px"
                      fontWeight="500"
                      maxW="150px"
                      padding="10px 10px">
                      {t('notEnoughBalance')}
                    </Text>
                  }
                  padding="0"
                  placement="top">
                  <IconButton
                    _active={{}}
                    _focus={{}}
                    _hover={{ outline: 'none' }}
                    aria-label="Info Balance"
                    color="#EA7B34"
                    fontSize={14}
                    fontWeight={600}
                    margin="0"
                    padding="0 0 4px 0"
                    size="sm"
                    variant="icon">
                    <InfoIcon height={20} width={20} />
                  </IconButton>
                </Tooltip>
              )}
            </Text>

            <Tooltip
              hasArrow
              borderRadius="10px"
              label={
                <Text
                  borderRadius="10px"
                  color="white"
                  fontSize="13px"
                  fontWeight="500"
                  maxW="200px"
                  padding="10px 10px">
                  {t('willBeAvailableSoon')}
                </Text>
              }
              padding="0"
              placement="top">
              {/* Temporary doesn't have a click handler */}
              <Button size="sm" variant="primary">
                {t('topUpBalance')}
              </Button>
            </Tooltip>
          </VStack>
        </Box>
      ) : (
        <Box width="25px">
          <Tooltip
            hasArrow
            label={
              <Text borderRadius="10px" color="white" fontSize="13px" fontWeight="500" maxW="300px">
                {t('willBeAvailableSoon')}
              </Text>
            }
            marginLeft="10px"
            placement="right">
            <Icon
              _hover={{
                cursor: 'pointer',
                stroke: activeColor,
              }}
              as={RechargeIcon}
              boxSize="25px"
              stroke={inactiveColor}
              transition="stroke 0.3s ease"
            />
          </Tooltip>
        </Box>
      )}

      <Popup isOpen={isOpen} onClose={onClose}>
        <Box p="0px 40px 30px">
          <ModalHeader
            color="navy.700"
            fontSize="28px"
            fontWeight={700}
            lineHeight="40px"
            padding={['65px 20px 35px']}
            textAlign="center"
            width="100%">
            {t('enterTopUpAmount')}
          </ModalHeader>
          <Input borderRadius={10} margin="30px 0" variant="main" />
          <Center>
            <Button variant="primary" onClick={onTopUpSubmit}>
              Submit
            </Button>
          </Center>
        </Box>
      </Popup>
    </>
  );
};
