import { Box, Flex, Tooltip, VStack } from '@chakra-ui/react';
import chunk from 'lodash/chunk';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChatPreviewFragment } from '@app/api/schemas/chat.mongo.generated';
import { db } from '@app/db';
import { useActiveChatsWatch } from '@app/hooks/useActiveChatsWatch';
import { useChatWidgetContext } from '@app/providers/ChatWidgetProvider';
import { HiddenChatHeader } from '@app/views/Chat/components/chat/HiddenChatHeader';
import { Messenger } from '@app/views/Chat/components/chat/Messenger';
import { ContextMenuProvider } from '@app/views/Chat/hooks/useContextMenu';

import { ChatContactBubble } from './ChatContactBubble';
import { TooltipNamesView } from './TooltipNamesView';

const defaultShowChats = 5;

export const ChatWidget = () => {
  const navigate = useNavigate();
  const { activeChat, setActiveChat } = useChatWidgetContext();
  const { chats } = useActiveChatsWatch();

  const onCloseWidget = () => {
    setActiveChat(undefined);
  };

  const handleOpenChat = useCallback(
    (chat: ChatPreviewFragment) => {
      if (activeChat?._id === chat?._id) {
        setActiveChat(undefined);
        return;
      }
      setActiveChat(chat);
    },
    [activeChat?._id],
  );

  const removeChat = (_id: string) => {
    db.chats.delete(_id);
  };

  const chatsItems = useMemo(() => {
    const [visible, hidden] = chunk(chats, defaultShowChats);
    return {
      visible,
      hidden,
    };
  }, [chats]);

  const hiddenChatItem = useMemo(
    () => chatsItems.hidden?.[0] ?? null,
    [chatsItems.hidden],
  );

  return (
    <Flex
      alignItems="flex-end"
      bottom={0}
      flexDirection="row"
      gap="14px"
      position="fixed"
      px={2}
      right={2}
      zIndex={20}>
      {(!!activeChat?._id || activeChat?.state?.isNew) && (
        <ContextMenuProvider>
          <VStack
            alignItems="flex-start"
            background="white.0"
            borderTopLeftRadius={20}
            borderTopRightRadius={20}
            bottom={{ base: '60px', md: '0' }}
            boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)"
            flex={2}
            flexDirection="column-reverse"
            gap={0}
            h="420px"
            justifyContent="space-between"
            position={{ base: 'absolute', md: 'relative' }}
            right={0}
            w="340px"
            zIndex={2}>
            <Messenger
              key={`active-chat-${activeChat?._id}`}
              isWidget
              chat={activeChat}
              state={activeChat?.state}
              onCloseWidget={onCloseWidget}
            />
          </VStack>
        </ContextMenuProvider>
      )}
      <Box display={{ base: 'none', md: 'block' }}>
        <VStack mb={4} spacing={2}>
          {chatsItems.visible?.map((chat) => (
            <ChatContactBubble
              key={`chat-tooltip-${chat._id}`}
              chat={chat}
              isActive={activeChat?._id === chat?._id}
              onClose={removeChat}
              onOpen={handleOpenChat}
            />
          ))}
          {!!hiddenChatItem && (
            <Box position="relative">
              <Box>
                <HiddenChatHeader item={hiddenChatItem} />
              </Box>
              <Tooltip
                hasArrow
                label={<TooltipNamesView items={chatsItems.hidden} />}
                placement="left-end"
                variant="ligth"
                onClick={() => navigate(`/chat`)}>
                <Flex
                  alignItems="center"
                  bg="black.145"
                  borderRadius="50%"
                  color="white.0"
                  fontWeight={700}
                  h={10}
                  inset="3px"
                  justifyContent="center"
                  position="absolute"
                  px={1}
                  w={10}
                  onClick={() => navigate('/chat')}>
                  +{chatsItems.hidden?.length}
                </Flex>
              </Tooltip>
            </Box>
          )}
        </VStack>
      </Box>
    </Flex>
  );
};
