import { Box, Heading, HStack } from '@chakra-ui/react';
import React from 'react';
import YouTube from 'react-youtube';

import { useWindowDimensions } from '@app/hooks/useWindowDimensions';

const videoId = process.env.REACT_APP_YOUTUBE_PROMO_ID;

export const Video = () => {
  const dimension = useWindowDimensions();
  const height = (dimension.width / 1.2 / 16) * 9;

  return (
    <Box px={{ base: 4, lg: 10 }} py={10}>
      <Heading
        color="mainGray.500"
        fontSize={{ base: '28px', lg: '54px' }}
        fontWeight={600}
        lineHeight={{ base: '30px', lg: '58px' }}
        mb={10}
        textAlign="center">
        Як працює MIRA.travel
      </Heading>
      <HStack justifyContent="center">
        <YouTube
          id="about"
          opts={{ width: dimension.width / 1.2, height, autoplay: 0 }}
          videoId={videoId}
        />
      </HStack>
    </Box>
  );
};
