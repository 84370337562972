import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { contentfulClient } from '@app/api/contentful';
import { getLocale } from '@app/components/FormatNumber/utils';

import { TextRenderer } from './components/TextRenderer';
import { Layout } from './components/Layout';

interface HelpSearchProps {
  q: string;
  onSetSearch: (val: string) => void;
}
export const HelpSearch: FC<HelpSearchProps> = ({ q, onSetSearch }) => {
  const { i18n } = useTranslation();

  const [articles, setArticles] = useState([]);

  const getArticles = useCallback(async () => {
    try {
      const { items } = await contentfulClient.getEntries({
        limit: 100,
        locale: getLocale(i18n.resolvedLanguage),
        query: q,
        content_type: 'articlesFaqAgentsApp',
      });
      setArticles(items);
    } catch (error) {
      console.log(error);
    }
  }, [q]);

  useEffect(() => {
    if (!!q) {
      void getArticles();
    }
  }, [i18n.resolvedLanguage, q]);

  return (
    <Layout title={q} onSetSearch={onSetSearch}>
      <Accordion allowToggle maxW="580px" mt="50px" mx="auto">
        {articles?.map((item) => (
          <AccordionItem
            key={item?.sys?.id}
            border="0 none"
            borderBottom="1px solid"
            borderBottomColor="gray.200"
            mt="10px">
            <AccordionButton
              _hover={{
                backgroundColor: 'transparent',
              }}
              px="0">
              <HStack flex={1} justifyContent="space-between">
                <Text
                  fontSize={{ base: 16, md: 20 }}
                  fontWeight={500}
                  lineHeight="42px">
                  {item?.fields?.question}
                </Text>
                <AccordionIcon color="grey.300" h="30px" w="30px" />
              </HStack>
            </AccordionButton>
            <AccordionPanel fontSize={16} lineHeight="24px" px={0}>
              <TextRenderer>{item?.fields?.answer}</TextRenderer>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Layout>
  );
};
