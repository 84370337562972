import React, { FC } from 'react';
import { useLoadScript } from '@react-google-maps/api';

import { Navigation } from './components/Navigation';
import { ApiProvider } from './providers/ApiProvider';

import 'amplify';
import '@app/db';
import 'amplitude';

const App: FC = () => {
  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: ['maps', 'places'],
  });

  return (
    <ApiProvider>
      <Navigation />
    </ApiProvider>
  );
};

export default App;
