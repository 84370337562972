import { mixed, object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { preparingForSendingPhoneNumber } from '@app/utils/formatPhone';

export const useValidationSchema = () => {
  const { t } = useTranslation('validations');

  const validationSchema = object().shape({
    avatar: mixed().nullable(),
    firstName: string().required(t('firstNameRequired')),
    lastName: string().required(t('lastNameRequired')),
    phone: string()
      .required(t('phoneNumberRequired'))
      .test(
        'phone',
        t('phoneNumberValid'),
        (value) => preparingForSendingPhoneNumber(value).length > 12,
      ),
  });

  return yupResolver(validationSchema);
};
