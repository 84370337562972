import { AspectRatio, Box, Image } from '@chakra-ui/react';
import React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './slider.css';
interface ImageGalleryProps {
  photoUris: string[] | null;
  maxW?: string;
  isGrayScale?: boolean;
  maxH?: string;
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  photoUris = [],
  maxW,
  maxH = 'full',
  isGrayScale = false,
}) => {
  const settings: Settings = {
    dots: photoUris?.length > 1,
    infinite: false,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box
      borderRadius="20px"
      height="auto"
      maxW={maxW ? maxW : '220px'}
      position="relative">
      <Box
        background="linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 80%);"
        h="100px"
        left={0}
        position="absolute"
        top={0}
        w="full"
        zIndex={2}
      />
      <Slider {...settings} className="custom-slider">
        {photoUris.map((image, index) => (
          <Box key={index} outline="none!important">
            <AspectRatio maxH={maxH}>
              <Image
                alt={`Image ${index}`}
                filter={isGrayScale && 'grayscale(100%)'}
                maxH={maxH}
                objectFit="contain"
                outline="none!important"
                src={image}
              />
            </AspectRatio>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
