import {
  BusinessStatus,
  OfferStatus,
  OrderStatus,
} from '@app/api/gql/generated-types';

export const statusColor = (status: OfferStatus, opacity?: number) => {
  switch (status) {
    case OfferStatus.DRAFT:
      return `rgba(234, 124, 53, ${opacity ?? 1})`;
    case OfferStatus.ACTIVE:
      return `rgba(61, 152, 151, ${opacity ?? 1})`;
    case OfferStatus.ARCHIVE:
      return `rgba(72, 73, 84, ${opacity ?? 1})`;
    case OfferStatus.ENDED:
      return `rgba(72, 73, 84, ${opacity ?? 1})`;
    case OfferStatus.HOT:
      return `rgba(255, 42, 42, ${opacity ?? 1})`;
    default:
      return `rgba(72, 73, 84, ${opacity ?? 1})`;
  }
};

export const statusOrderColor = (status: OrderStatus, opacity?: number) => {
  switch (status) {
    case OrderStatus.CONFIRMED:
      return `rgba(61, 152, 151, ${opacity ?? 1})`;
    case OrderStatus.PENDING_AGENT:
      return `rgba(255, 42, 42, ${opacity ?? 1})`;
    case OrderStatus.CANCELLED:
      return `rgba(160, 160, 160, ${opacity ?? 1})`;
    case OrderStatus.PENDING_TRAVELER:
      return `rgba(234, 124, 53, ${opacity ?? 1})`;
    case OrderStatus.CANCEL_REQUEST:
      return `rgba(227, 26, 26, ${opacity ?? 1})`;
    default:
      return 'grey.200';
  }
};

export const agentsStatusColor = (status: BusinessStatus) => {
  switch (status) {
    case BusinessStatus.ACTIVE:
      return 'green.700';
    case BusinessStatus.REVIEW:
      return 'primary.red';
    default:
      return 'grey.200';
  }
};

export const requestStatusColor = (status: string, opacity?: number) => {
  switch (status) {
    case 'isRead':
      return `rgba(61, 152, 151, ${opacity ?? 1})`;
    default:
      return 'grey.200';
  }
};
