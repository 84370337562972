import { Box, Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { OfferType } from '@app/api/gql/generated-types';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';
import { Datepicker } from '@app/components/Datepicker';
import { InputNumber } from '@app/components/FormElements/InputNumber';
import { FormField } from '@app/components/formInputs/FormField';
import { PriceCurrencyField } from '@app/components/formInputs/PriceCurrencyField';

import { OfferPriceLine } from './OfferPriceLine';

interface OfferPriceSegmentProps {
  type: OfferType;
  name?: string;
  isFirst?: boolean;
  onRemove?: () => void;
}

export const OfferPriceSegment: FC<OfferPriceSegmentProps> = ({
  type,
  name = null,
  isFirst,
  onRemove,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { t } = useTranslation(['forms']);
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    clearErrors,
  } = useFormContext();
  const fieldNameAppendix = useMemo(() => (name ? `${name}.` : ''), [name]);
  const { field: { value: isSavedValue } = {} } = useController({
    name: `${fieldNameAppendix}isSaved`,
    control,
  });

  const isSavedError = get(errors, `${fieldNameAppendix}isSaved`);

  const hasErrors = useMemo(() => {
    return get(errors, name);
  }, [errors, name]);

  const handleOpen = () => {
    setValue(`${fieldNameAppendix}isSaved`, false);
    onOpen();
  };
  const handleSave = useCallback(async () => {
    const result = await trigger([
      `${fieldNameAppendix}.startDate`,
      `${fieldNameAppendix}.days`,
      `${fieldNameAppendix}.price`,
      `${fieldNameAppendix}.adults`,
      `${fieldNameAppendix}.children`,
    ]);
    if (!result) {
      return;
    }
    setValue(`${fieldNameAppendix}isSaved`, true);
    clearErrors();
    onClose();
  }, [fieldNameAppendix]);

  useEffect(() => {
    if (!isSavedValue) {
      onOpen();
    }
  }, []);

  useEffect(() => {
    if (!!hasErrors) {
      onOpen();
    }
  }, [hasErrors]);

  if (!isOpen) {
    return (
      <OfferPriceLine
        isFirst={isFirst}
        name={fieldNameAppendix}
        onEdit={handleOpen}
        onRemove={() => onRemove?.()}
      />
    );
  }

  return (
    <>
      <Box
        border="1px solid"
        borderColor={!!isSavedError ? 'red' : 'gray.100'}
        borderRadius={6}
        mt={4}
        pt={2}
        px={4}>
        <HStack
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={{ base: 1, md: 2, lg: 4 }}>
          <Datepicker
            label={t('startDate')}
            name={`${fieldNameAppendix}startDate`}
            placeholder={t('startDatePlaceholder')}
            placement="right-start"
          />
          <FormField<OfferFragment>
            Component={InputNumber}
            label={t('nights')}
            maxW="100px"
            mb="20px"
            min={1}
            name={`${fieldNameAppendix}days`}
            placeholder={t('nightsPlaceholder')}
          />
          <PriceCurrencyField name={`${fieldNameAppendix}price`} />
        </HStack>
        <HStack
          alignItems="flex-start"
          justifyContent="space-between"
          name={`${fieldNameAppendix}isSaved`}
          spacing={{ base: 1, md: 2, lg: 4 }}></HStack>

        <HStack alignItems="flex-start" spacing={4} w="full">
          {type === OfferType.TOUR && (
            <HStack
              alignItems="flex-start"
              gap={4}
              justifyContent="space-between">
              <FormField<OfferFragment>
                Component={InputNumber}
                label={t('adults')}
                maxW="100px"
                mb="20px"
                min={1}
                name={`${fieldNameAppendix}adults`}
                placeholder={t('adultsChildrenPlaceholder')}
              />
              <FormField<OfferFragment>
                Component={InputNumber}
                label={t('children')}
                maxW="100px"
                mb="20px"
                min={0}
                name={`${fieldNameAppendix}children`}
                placeholder={t('adultsChildrenPlaceholder')}
              />
            </HStack>
          )}
        </HStack>

        <HStack justifyContent="flex-end" mb={2} mt={-2} spacing={4}>
          {!isFirst && (
            <Button textDecoration="none" variant="link" onClick={onRemove}>
              <HStack>
                <Text fontSize={12}>❌</Text>
                <Text color="grey.250" fontSize={12}>
                  {t('remove')}
                </Text>
              </HStack>
            </Button>
          )}
          <Button
            _disabled={{
              opacity: 0.5,
            }}
            textDecoration="none"
            variant="link"
            onClick={handleSave}>
            <HStack>
              <Text fontSize={12}>💾</Text>
              <Text color="grey.250" fontSize={12}>
                {t('save')}
              </Text>
            </HStack>
          </Button>
        </HStack>
      </Box>
      <Box color="red" fontSize={14} textAlign="right">
        {!!isSavedError && t('saveForm')}
      </Box>
    </>
  );
};
