import React, { ReactNode, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalProps,
  ModalOverlayProps,
  AspectRatio,
  VStack,
} from '@chakra-ui/react';

interface ImagePopupProps extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: ReactNode;
  width?: string;
  size?: string;
  closeOnOverlayClick?: boolean;
  maxW?: string;
  scrollBehavior?: 'inside' | 'outside';
  modalOverplayProps?: ModalOverlayProps;
  blockScrollOnMount?: boolean;
}

const ImagePopup: React.FC<ImagePopupProps> = ({
  children,
  title,
  isOpen,
  onClose,
  closeOnOverlayClick,
  maxW,
  width,
  scrollBehavior = 'outside',
  modalOverplayProps,
  blockScrollOnMount = false,
  isCentered = true,
}) => {
  const finalRef = useRef();

  return (
    <Modal
      blockScrollOnMount={blockScrollOnMount}
      closeOnEsc={closeOnOverlayClick}
      closeOnOverlayClick={closeOnOverlayClick}
      initialFocusRef={finalRef}
      isCentered={isCentered}
      isOpen={isOpen}
      scrollBehavior={scrollBehavior}
      onClose={onClose}>
      <ModalOverlay
        backdropFilter="blur(2px)"
        bg="rgba(0, 0, 0, 0.8)"
        {...modalOverplayProps}
      />
      <ModalContent
        // background="#FDFEFE"
        boxShadow="0px 8px 44px rgba(66, 66, 66, 0.16) !important"
        // maxH="638px"
        maxW={maxW}
        // objectFit="contain"
        width={{ base: 'calc(100% - 20px)', md: width }}>
        <ModalHeader
          color="brand.900"
          fontSize="36px"
          fontWeight="bold"
          lineHeight="56px"
          position="absolute"
          textAlign="center"
          top="-65px"
          width="100%"
          zIndex="10">
          {title}
        </ModalHeader>

        <AspectRatio>
          <VStack alignItems="center" h="full">
            {children}
          </VStack>
        </AspectRatio>
      </ModalContent>
    </Modal>
  );
};
export default ImagePopup;
