import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { Offer } from '@app/api/gql/generated-types';
import { MultiSelectField } from '@app/components/MultiSelectField';
import { GroupedOption } from '@app/api/types';
import { useActivitiesData } from '@app/api/hooks/useActivitiesData';

const formatGroupLabel = (data: GroupedOption) => (
  <Text
    color="gray.200"
    fontSize={12}
    fontWeight={600}
    lineHeight="20px"
    textTransform="none">
    {data.label}
  </Text>
);

export const TravelCategory = () => {
  const { t } = useTranslation(['forms']);

  const { items, getTitle } = useActivitiesData();

  const {
    formState: { isSubmitted, errors },
    control,
  } = useFormContext<Offer>();

  const isInvalid = !!errors.activities && isSubmitted;
  const itemsLength = useMemo(() => {
    if (window.innerWidth < 768) {
      return 1;
    }
    return 3;
  }, []);

  return (
    <FormControl isInvalid={isInvalid} position="relative">
      <FormLabel color="grey.300" fontSize={14}>
        {t('activities')}
      </FormLabel>
      <Box pb="25px">
        <Controller
          control={control}
          name="activities"
          render={({ field }) => (
            <MultiSelectField
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              columns={{ base: 1, xl: 2 }}
              error={!!errors.activities?.message}
              formatGroupLabel={formatGroupLabel}
              getOptionLabel={(category) => getTitle(category?.details)}
              getOptionValue={(category) => category._id}
              hideSelectedOptions={false}
              isClearable={false}
              itemsLength={itemsLength}
              {...field}
              options={items}
              placeholder={t('tagsPlaceholder')}
            />
          )}
        />
      </Box>
      <FormErrorMessage
        bottom="6px"
        color="red.600"
        fontSize="12px"
        fontWeight={400}
        left={1}
        position="absolute">
        {errors.activities?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
