import { Box, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

import { StatusCount } from '@app/api/gql/generated-types';
import { FilterButton } from '@app/components/buttons/FilterButton';
import { useMobile } from '@app/hooks/useMobile';

interface TablesHeaderProps {
  title: string;
  children?: ReactNode;
  statusCount?: StatusCount[];
}

export const TablesHeader: FC<TablesHeaderProps> = ({ title, children }) => {
  const txtColor = useColorModeValue('brand.900', 'white');
  const { setShowFilters, showFilters } = useMobile();

  return (
    <Box px="15px">
      <HStack
        alignItems="center"
        gap={{ base: '20px', '2xl': '50px' }}
        justifyContent="flex-start"
        pb={5}
        wrap={{ base: 'wrap', '2xl': 'nowrap' }}>
        <HStack flex={{ base: 1, md: 0 }} justifyContent="space-between">
          <HStack columnGap={'40px'} justifyContent="flex-start">
            <Text
              color={txtColor}
              fontSize={{ base: 24, md: 32 }}
              fontWeight={700}
              lineHeight="24px"
              whiteSpace="nowrap">
              {title}
            </Text>

            <FilterButton
              isActive={showFilters}
              onClick={() => setShowFilters(!showFilters)}
            />
          </HStack>
        </HStack>
      </HStack>
      {showFilters && (
        <Box alignItems="center" display="flex" justifyContent="space-between">
          {children}
        </Box>
      )}
    </Box>
  );
};
