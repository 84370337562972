const phoneRegExp = new RegExp(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

export const formatPhone = (phone?: string) => {
  if (!phone) {
    return null;
  }

  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = phoneRegExp.exec(cleaned);

  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return null;
};

export const preparingForSendingPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/[()\-_\s]+/g, '');
};
