import { Box, HStack } from '@chakra-ui/react';
import React, { FC } from 'react';

interface ItemProps {
  items: string[];
}

const Item: FC<ItemProps> = ({ items }) => {
  return (
    <HStack alignItems="center" justifyContent="flex-start" py="6px">
      {items?.map((width, index) => {
        if (index === 0) {
          return (
            <Box key={index} flex="0 0 120px">
              <Box
                backgroundColor="#EEEEEE"
                borderRadius={5}
                height="21px"
                w={`${width}px`}
              />
            </Box>
          );
        }
        return (
          <Box
            key={index}
            backgroundColor="#EEEEEE"
            borderRadius={5}
            height="21px"
            w={`${width}px`}
          />
        );
      })}
    </HStack>
  );
};

export default Item;
