import { Box, Grid, Skeleton } from '@chakra-ui/react';
import React from 'react';

export const Header = () => {
  return (
    <Box background="#F0F4F9" h="50px" padding="15px 30px" width="100%">
      <Grid columnGap="30px" gridTemplateColumns="repeat(8, 1fr)" rowGap="15px">
        {Array(8)
          .fill(0)
          .map((_, i) => (
            <Skeleton
              key={`header-row-${i.toString()}`}
              endColor="#f5f5f5"
              height="14px"
              startColor="#EAEDF3"
              width="100%"
            />
          ))}
      </Grid>
    </Box>
  );
};
