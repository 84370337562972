import {
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RemoveIcon } from '@app/icons/remove-field-icon.svg';

export const MultipleInput = () => {
  const { t } = useTranslation('forms');
  const { control, register } = useFormContext();
  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'website',
  });

  const isDisabledAdd = fields?.length >= 5;

  return (
    <>
      {fields?.map((field, index) => (
        <FormControl key={field.id} mb={6} position="relative">
          <InputGroup>
            <Input
              id={field.id}
              placeholder={t('websitePlacehloder')}
              size="lg"
              variant="auth"
              {...register(`website.${index}.value`)}
            />
            {index > 0 && (
              <Tooltip hasArrow label={t('removeLink')} placement="right">
                <InputRightElement alignItems="center" h="full">
                  <IconButton
                    aria-label="view offer"
                    icon={<RemoveIcon />}
                    size="sm"
                    variant="icon"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </InputRightElement>
              </Tooltip>
            )}
          </InputGroup>
        </FormControl>
      ))}
      {!isDisabledAdd && (
        <HStack justifyContent="flex-end" mt={-3}>
          <Button
            color="grey.300"
            fontSize={12}
            textDecorationColor="grey.300"
            variant="link"
            onClick={() => insert(fields?.length, {})}>
            {t('addMoreLink')}
          </Button>
        </HStack>
      )}
    </>
  );
};
