import {
  FormControl,
  FormErrorMessage,
  Input,
  InputProps,
} from '@chakra-ui/react';
import React, { ElementType } from 'react';
import { Controller, Path, useFormContext } from 'react-hook-form';
import { FormErrorMessageProps } from '@chakra-ui/form-control/dist/form-error';
import { FormControlProps } from '@chakra-ui/form-control/dist/form-control';
import get from 'lodash/get';

import Label from '@app/components/FormElements/Label';

type DynamicInputProps<T> = InputProps & {
  width?: string;
  name: Path<T>;
  label?: string;
  required?: boolean;
  Component?: ElementType;
  isDisabled?: boolean;
  errorProps?: FormErrorMessageProps;
  formControlProps?: FormControlProps;
};
export const FormField = <T extends Record<string, unknown>>({
  name,
  label,
  width,
  Component = Input,
  isDisabled,
  errorProps,
  formControlProps,
  ...props
}: DynamicInputProps<T>) => {
  const {
    formState: { errors, isValid },
    control,
  } = useFormContext<T>();

  const error = get(errors, name);

  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={!!error && !isValid}
      position="relative"
      width={width}
      {...formControlProps}>
      {label && (
        <Label
          color="grey.300"
          fontSize={14}
          htmlFor={name}
          isRequired={props.isRequired}>
          {label}
        </Label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Component id={name} variant="main" {...props} {...field} />
        )}
      />

      <FormErrorMessage
        bottom="5px"
        color="red.600"
        fontSize="12px"
        fontWeight={400}
        left={1}
        noOfLines={1}
        position="absolute"
        {...errorProps}>
        {!!error && (error.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};
