import { cssVar } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');

export const Tooltip = {
  components: {
    Tooltip: {
      baseStyle: {
        borderRadius: '4px',
        fontWeight: 400,
        backgroundColor: 'grey.300',
        color: 'white',
        fontSize: '12px',
        lineHeight: '16px',
        padding: '4px 6px',
        [$arrowBg.variable]: 'var(--chakra-colors-grey-300)',
      },

      variants: {
        dark: () => ({
          backgroundColor: 'grey.300',
          color: 'white',
        }),
        ligth: () => ({
          backgroundColor: 'grey.100',
          color: 'grey.300',
          padding: '11px 15px',
          [$arrowBg.variable]: 'var(--chakra-colors-grey-100)',
        }),
      },
    },
  },
};
