import {
  Box,
  Flex,
  VStack,
  Image,
  Avatar,
  Text,
  HStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BatteryIcon } from '@app/icons/battery-icon.svg';
import { ReactComponent as WiFiIcon } from '@app/icons/wifi-icon.svg';
import { ReactComponent as ConnectionIcon } from '@app/icons/connection-icon.svg';
import { ReactComponent as BasketIcon } from '@app/icons/basket-icon.svg';
import { ReactComponent as FavoriteIcon } from '@app/icons/favorite-icon.svg';
import { ReactComponent as BackIcon } from '@app/icons/back-icon.svg';
import { ReactComponent as CalendarViewIcon } from '@app/icons/calendar-view-icon.svg';
import { ReactComponent as MessagesIcon } from '@app/icons/messages-black-icon.svg';
import { ReactComponent as MoonIcon } from '@app/icons/moon-icon.svg';
import { ReactComponent as MapIcon } from '@app/icons/map-pin-icon.svg';
import { formatDate } from '@app/utils/formatDate';
import { DateFormatter } from '@app/components/DateFormatter';
import useCurrentAccount from '@app/hooks/useCurrentAccount';
import { PriceField } from '@app/components/PriceField';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';

interface MobileInformationProps {
  offer?: Partial<OfferFragment>;
  photo?: string;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const MobileInformation: FC<MobileInformationProps> = ({
  offer,
  photo,
}) => {
  const { t } = useTranslation('tables');
  const { business, account } = useCurrentAccount();
  const avatar = business?.avatar?.key
    ? `${imageServer}/${business?.avatar?.key}?width=200&format=webp`
    : '';

  return (
    <Box
      backgroundColor={'grey.300'}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius={40}
      h="670px"
      overflow="hidden"
      position="relative"
      userSelect="none"
      w="315px">
      <VStack
        alignItems="stretch"
        h="full"
        position="relative"
        w="full"
        zIndex={5}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          pt={3}
          px={6}
          w="100%">
          <Box color="white.0" fontSize="14px" fontWeight="600">
            {formatDate(new Date(), 'HH:mm')}
          </Box>
          <Flex gap="5px">
            <ConnectionIcon />
            <WiFiIcon />
            <BatteryIcon />
          </Flex>
        </Flex>
        <Flex
          alignItems="flex-end"
          background="linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 60%)"
          flex="1"
          flexDirection="column"
          w="100%">
          <Flex
            alignItems="flex-end"
            color="white"
            flex="1"
            p="24px 12px 80px"
            w="100%">
            <Box flex="1">
              {!!offer.location && (
                <HStack alignItems="center" mb={2} ml="1px" space={2}>
                  <Box
                    backgroundColor="primary.green"
                    borderRadius={6}
                    py={0.5}>
                    <MapIcon />
                  </Box>
                  <Text
                    color="white"
                    fontSize={12}
                    fontWeight={400}
                    numberOfLines={1}>
                    {offer?.location?.description}
                  </Text>
                </HStack>
              )}
              <Box pb={2}>
                <Text
                  color="white"
                  fontSize={20}
                  fontWeight={300}
                  lineHeight="26px"
                  maxW="230px"
                  noOfLines={2}>
                  {offer?.title}
                </Text>
              </Box>
              <HStack alignItems="center" fontSize={11} spacing={4}>
                {!!offer?.startDate && (
                  <HStack spacing={1}>
                    <CalendarViewIcon height={14} width={14} />
                    <HStack spacing={1}>
                      <Box color="gray.300">{t('departs')}: </Box>
                      <DateFormatter date={new Date(offer?.startDate)} />{' '}
                    </HStack>
                  </HStack>
                )}
                {!!offer?.days && (
                  <HStack spacing={1}>
                    <MoonIcon height={14} width={14} />
                    <HStack spacing={1}>
                      <Box color="gray.300">{t('nights')}:</Box>
                      <Text color="white" fontSize={11}>
                        {offer?.days}
                      </Text>
                    </HStack>
                  </HStack>
                )}
              </HStack>
              <HStack alignItems="center" mt={4} spacing={1}>
                <Avatar
                  h={8}
                  me="16px"
                  mr="4px"
                  name={business?.companyName}
                  src={avatar}
                  w={8}
                />
                <Box fontSize={14} fontWeight={600}>
                  {business?.companyName}
                  <Text color="white" fontSize={10} noOfLines={1} opacity={0.6}>
                    {account?.fullName}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <VStack alignItems="flex-end" gap={4} justifyContent="flex-end">
              <VStack gap={6}>
                <BackIcon />
                <FavoriteIcon />
                <MessagesIcon />
                <BasketIcon />
              </VStack>
              {offer?.price?.value && (
                <Box fontSize="16px" fontWeight="600">
                  <PriceField
                    type={offer?.priceEstimateType}
                    value={offer?.price}
                  />
                </Box>
              )}
              <Box
                border="2px solid"
                borderColor="primary.green"
                borderRadius="full"
                fontSize={14}
                fontWeight={400}
                lineHeight="16px"
                pb={1.5}
                pt={1}
                px={2}>
                {t('details', { ns: 'forms' })}
              </Box>
            </VStack>
          </Flex>
          <Box bottom={-6} position="absolute" w="100%">
            <Image alt="nav bg" position="relative" src="/mobileNavBg.png" />
            <Box
              bg="white.0"
              borderRadius="20px"
              bottom={8}
              h="3px"
              left="calc(50% - 55px)"
              opacity={0.8}
              position="absolute"
              w="110px"
            />
          </Box>
        </Flex>
      </VStack>
      {!!photo && (
        <Image
          height="full"
          left={0}
          objectFit="cover"
          position="absolute"
          src={photo}
          top={0}
          w="full"
          zIndex={1}
        />
      )}
    </Box>
  );
};
