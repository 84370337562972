import React, { FC } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  TextareaProps,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useTranslation } from 'react-i18next';

import Label from '@app/components/FormElements/Label';
import '@ckeditor/ckeditor5-build-classic/build/translations/uk';
import './styles.css';

interface WysiwygEditorProps extends TextareaProps {
  width?: string;
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  errorProps?: FormErrorMessageProps;
  formControlProps?: FormControlProps;
}

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
};

export const WysiwygEditor: FC<WysiwygEditorProps> = ({
  name,
  label,
  placeholder,
  width,
  errorProps,
  formControlProps,
  ...props
}) => {
  const {
    formState: { errors, isValid },
    control,
  } = useFormContext();
  const { i18n } = useTranslation();

  const isInvalid = !!errors[name] && !isValid;

  return (
    <FormControl
      isInvalid={isInvalid}
      position="relative"
      width={width}
      {...formControlProps}>
      {label && (
        <Label
          color="grey.300"
          fontSize={14}
          htmlFor={name}
          isRequired={props.isRequired}>
          {label}
        </Label>
      )}
      <Box
        border="1px solid"
        borderColor={isInvalid ? 'red.600' : 'transparent'}
        borderRadius={10}>
        <Controller
          control={control}
          name={name}
          render={({ ref, ...field }) => {
            return (
              <CKEditor
                {...field}
                config={{
                  ...editorConfiguration,
                  language: i18n.resolvedLanguage,
                }}
                data={field.field.value}
                editor={ClassicEditor}
                onChange={(_, editor) => {
                  const data = editor.getData();
                  field.field.onChange(data);
                }}
              />
            );
          }}
        />
      </Box>
      <FormErrorMessage
        bottom="-20px"
        color="red.600"
        fontSize="12px"
        fontWeight={400}
        left={1}
        position="absolute"
        {...errorProps}>
        {errors[name] && (errors[name].message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};
