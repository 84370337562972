import React, { FC } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormErrorMessage, Text } from '@chakra-ui/react';
import { components } from 'react-select';

import { FieldWrapper } from '@app/components/fields/FieldWrapper';
import { SelectField } from '@app/components/SelectField';
import { ReactComponent as LocationIcon } from '@app/icons/location-pin-alt-svgrepo-com.svg';
import { OfferLocation } from '@app/api/gql/generated-types';

interface LocationWidgetProps {
  label?: string;
}

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export const DropdownIndicator = (props) => {
  const customProps = {
    ...props,
    selectProps: {
      ...props.selectProps,
      menuIsOpen: false,
    },
  };

  return (
    <components.DropdownIndicator {...customProps}>
      <Box fontSize={18}>📍</Box>
    </components.DropdownIndicator>
  );
};

export const LocationWidget: FC<LocationWidgetProps> = ({
  label = 'Location',
}) => {
  const {
    setValue,
    control,
    formState: { isValid, errors },
  } = useFormContext();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    debounce: 1000,
  });

  const { t } = useTranslation(['forms']);

  const onInputChange = (value: string) => {
    getPlacePredictions({ input: value });
  };

  const isInvalid = !!errors.location && !isValid;

  const onSelect = (value: unknown) => {
    placesService?.getDetails(
      {
        placeId: value.place_id,
      },
      (placeDetails) => {
        const data: OfferLocation = {
          place_id: placeDetails?.place_id,
          description: value?.description,
          coordinates: {
            coordinates: [
              placeDetails?.geometry?.location.lng(),
              placeDetails?.geometry?.location.lat(),
            ],
          },
        };
        setValue('location', data);
      },
    );
  };

  if (!apiKey) {
    return null;
  }

  return (
    <FormControl isInvalid={isInvalid} position="relative">
      <FieldWrapper label={t(`${label}`)} mb="0">
        <Controller
          control={control}
          name="location"
          render={({ field }) => (
            <SelectField
              {...field}
              isSearchable
              components={{ DropdownIndicator }}
              error={isInvalid}
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => option.place_id}
              isLoading={isPlacePredictionsLoading}
              loadingMessage={() => (
                <Text color="grey.250" fontSize={14}>
                  {t('locationSearchLoading')}
                </Text>
              )}
              noOptionsMessage={() => (
                <Text color="grey.250" fontSize={14}>
                  {t('locationNoOptionsText')}
                </Text>
              )}
              options={placePredictions}
              placeholder={t('locationPlaceholder')}
              onChange={onSelect}
              onInputChange={onInputChange}
            />
          )}
        />
        <FormErrorMessage
          bottom={-5}
          color="red.600"
          fontSize="12px"
          fontWeight={400}
          left={1}
          position="absolute">
          {errors.location?.message}
        </FormErrorMessage>
      </FieldWrapper>
    </FormControl>
  );
};
