import { Box, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OfferTabs } from '@app/api/types';

interface TabsProps {
  list: OfferTabs[];
  activeTab: OfferTabs;
  disabledId?: OfferTabs[];
  onChange: (v: OfferTabs) => void;
}

export const Tabs: FC<TabsProps> = ({
  activeTab,
  list,
  disabledId,
  onChange,
}) => {
  const { t } = useTranslation('tabs');
  return (
    <Flex
      alignItems="center"
      gap="25px"
      justifyContent="flex-start"
      pb="15px"
      w="100%">
      {list?.map((x) =>
        disabledId?.includes(x) ? null : (
          <Box
            key={`tab-index-${x}`}
            _hover={{
              color: 'black.100',
            }}
            borderBottom={activeTab === x ? '1px solid' : '0'}
            borderColor="primary.green"
            color={activeTab === x ? 'black.100' : 'grey.300'}
            cursor="pointer"
            fontSize="14px"
            fontWeight="500"
            opacity={disabledId?.includes(x) ? 0.5 : 1}
            onClick={() => (disabledId?.includes(x) ? null : onChange(x))}>
            {t(x)}
          </Box>
        ),
      )}
    </Flex>
  );
};
