import React, { FC, PropsWithChildren } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import Label from '@app/components/FormElements/Label';

interface InputFieldProps extends PropsWithChildren {
  label?: string;
  extra?: JSX.Element;
  placeholder?: string;
  type?: string;
  mb?: string;
  isRequired?: boolean;
  [x: string]: unknown;
}

export const FieldWrapper: FC<InputFieldProps> = ({
  label,
  extra,
  mb,
  isRequired,
  children,
}) => {
  return (
    <Flex direction="column" mb={mb ? mb : '30px'}>
      <Label color="grey.300" fontSize={14} isRequired={isRequired}>
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px">
          {extra}
        </Text>
      </Label>
      {children}
    </Flex>
  );
};
