import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
export type LanguageFragment = { __typename?: 'Language', _id: any, code: string | null, name: string | null };

export const LanguageFragmentDoc = gql`
    fragment Language on Language {
  _id
  code
  name
}
    `;