import { HStack, Image, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

import { MessageReference } from '@app/api/gql/generated-types';
import { DateFormatter } from '@app/components/DateFormatter';

interface LastMessageReferenceProps {
  item?: MessageReference;
  isActive: boolean;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const LastMessageReference: FC<LastMessageReferenceProps> = ({
  item,
  isActive = false,
}) => {
  if (!item?.offerId && !item?.orderId && !item?.requestId) {
    return null;
  }
  return (
    <HStack
      backgroundColor={isActive ? 'whiteAlpha.200' : 'grey.100'}
      borderRadius={5}
      p={1}>
      {!!item?.offerId && (
        <>
          <Image
            borderRadius={4}
            height="20px"
            objectFit="cover"
            src={`${imageServer}/${item.offerId?.photo?.[0]?.key}?width=100&format=webp`}
            width="20px"
          />
          <Text color={isActive ? 'white' : 'gray.800'} noOfLines={2}>
            {item.offerId?.title}
          </Text>
        </>
      )}
      {!!item?.requestId && (
        <Text color={isActive ? 'white' : 'gray.800'} noOfLines={2}>
          <DateFormatter
            date={new Date(item.requestId?.date)}
            format="LLL, yyyy"
          />
          , {item?.requestId?.location?.description}
        </Text>
      )}
      {!!item?.orderId && (
        <Text color={isActive ? 'white' : 'gray.800'} noOfLines={2}>
          <DateFormatter date={new Date(item.orderId.startAt)} /> -{' '}
          <DateFormatter date={new Date(item.orderId.endAt)} />
        </Text>
      )}
    </HStack>
  );
};
