import { Button, HStack, Link } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChatIcon } from '@app/icons/chat-support-icon.svg';
import { botUrls } from '@app/utils/botUrls';
import useCurrentAccount from '@app/hooks/useCurrentAccount';

export const Footer = () => {
  const { t, i18n } = useTranslation('forms');
  const { account } = useCurrentAccount();
  const url = `${botUrls?.[i18n.resolvedLanguage] ?? botUrls.en}?start=${account?._id
    }`;

  return (
    <HStack
      alignItems="center"
      borderRadius={20}
      justifyContent="space-between"
      maxW="600px"
      mt="50px"
      mx="auto"
      w="100%"
      wrap={{ base: 'wrap', md: 'nowrap' }}>
      <Button
        isExternal
        as={Link}
        flex={1}
        gap={3}
        height={10}
        href={url}
        variant="primary">
        <ChatIcon />
        {t('faq.footer.button')}
      </Button>
    </HStack>
  );
};
