export interface ProfileInformation {
  avatar: File[];
  firstName: string;
  lastName: string;
  phone: string;
}

export const initialValues: ProfileInformation = {
  avatar: [],
  firstName: '',
  lastName: '',
  phone: '',
};
