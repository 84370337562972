import { Button, HStack } from '@chakra-ui/react';
import React, { FC, useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferStatus } from '@app/api/gql/generated-types';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';
import { ReactComponent as SaveIcon } from '@app/icons/save-svgrepo-com.svg';

interface OfferActionButtonsProps {
  onSubmit: (isDraft?: boolean) => void;
  isLoading: boolean;
  isEdit?: boolean;
}

export const OfferActionButtons: FC<OfferActionButtonsProps> = ({
  onSubmit,
  isLoading,
  isEdit = false,
}) => {
  const { t } = useTranslation('forms');
  const { setValue } = useFormContext();
  const { status } = useWatch<OfferFragment>(['status']);

  const isActive = useMemo(() => status === OfferStatus.ACTIVE, [status]);

  const handleSaveDraft = useCallback(() => {
    setValue('status', OfferStatus.DRAFT);
    onSubmit(true);
  }, []);

  const handleSaveActive = useCallback(() => {
    setValue('status', OfferStatus.ACTIVE);
    onSubmit();
  }, []);

  return (
    <HStack justifyContent="flex-end" mt={4} spacing={4}>
      {!isActive && (
        <Button
          display={isActive && isLoading ? 'none' : 'flex'}
          gap={2}
          h={8}
          isDisabled={isLoading}
          isLoading={isLoading}
          variant="cancel"
          onClick={handleSaveDraft}>
          <SaveIcon height={16} width={16} />
          {t('saveDraft')}
        </Button>
      )}
      <Button
        display={!isActive && isLoading ? 'none' : 'flex'}
        fontSize={16}
        isDisabled={isLoading}
        isLoading={isLoading}
        px={10}
        variant="primary"
        w="auto"
        onClick={handleSaveActive}>
        {isEdit && isActive ? t('saveChanges') : t('saveAndSend')}
      </Button>
    </HStack>
  );
};
