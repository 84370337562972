import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { HSeparator } from '@app/components/Separator';
import { ReactComponent as Icon } from '@app/icons/telegram-svgrepo-com.svg';
import { botUrls } from '@app/utils/botUrls';

import { Form } from './Form';

export const Support = () => {
  return (
    <Box backgroundColor="#23252C" mt={10} px={{ base: 4, lg: 10 }} py={20}>
      <Heading
        color="white"
        fontSize={{ base: '28px', lg: '54px' }}
        fontWeight={600}
        lineHeight={{ base: '30px', lg: '58px' }}
        mb={10}
        textAlign="center">
        Залишились питання?
      </Heading>
      <HStack justifyContent="center">
        <Button
          as={Link}
          borderColor="white"
          color="white"
          href={botUrls.uk}
          variant="outline">
          <HStack px={6}>
            <Icon height={20} width={20} />
            <Text color="white" fontWeight={500}>
              Напиши в підтримку
            </Text>
          </HStack>
        </Button>
      </HStack>
      <Flex alignItems="center" maxW="900px" mx="auto" my={10} w="full">
        <HSeparator backgroundColor="whiteAlpha.600" />
        <Text color="white" mx="20px" textAlign="center">
          або
        </Text>
        <HSeparator backgroundColor="whiteAlpha.600" />
      </Flex>
      <Text
        color="white"
        fontSize={{ base: '22px', lg: '38px' }}
        fontWeight={600}
        lineHeight={{ base: '28px', lg: '48px' }}
        textAlign="center">
        залишай свій номер
      </Text>
      <Text color="white" mb={10} textAlign="center">
        ми передзвонимо
      </Text>
      <HStack justifyContent="center">
        <VStack justifyContent="center" maxW="400px" minW="300px">
          <Form />
        </VStack>
      </HStack>
    </Box>
  );
};
