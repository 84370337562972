interface ProcessStylesProps {
  height?: number;
  width?: number;
}

export const Process = {
  baseStyle: (props: ProcessStylesProps) => {
    return {
      transform: 'translateX(0%) translateY(0%) rotate(-90deg)',
      width: props.width,
      height: props.height,
    };
  },
};

export const SpendingsComponent = {
  components: { Process },
};
