import { Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';

export const NotificationSkeleton = () => {
  return (
    <VStack alignItems="flex-start" p={2} spacing={3} w="full">
      <Skeleton
        endColor="grey.150"
        height="18px"
        startColor="grey.100"
        width="100%"
      />
      <Skeleton
        endColor="grey.150"
        height="18px"
        startColor="grey.100"
        width="80%"
      />
      <Skeleton
        endColor="grey.150"
        height="14px"
        startColor="grey.100"
        width="50%"
      />
    </VStack>
  );
};
