import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useController, useFormContext } from 'react-hook-form';
import { FormControl, FormControlProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import * as Popper from '@popperjs/core';
import get from 'lodash/get';

import { CustomDatepickerHeader } from '@app/components/Datepicker/DatepickerHeader';
import { formatDate } from '@app/utils/formatDate';
import { ErrorMessage } from '@app/components/FormElements/ErrorMessage';
import { useLocale } from '@app/hooks/useLocale';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';

import Label from '../FormElements/Label';

import { DatepickerContainer } from './DatepickerContainer';
import { DatepickerInput } from './DatepickerInput';

interface DatepickerProps {
  name: string;
  placeholder?: string;
  formControlProps?: FormControlProps;
  showTimeSelect?: boolean;
  label?: string;
  placement?: Popper.Placement;
  minDate?: Date;
}
export const Datepicker: FC<DatepickerProps> = ({
  name,
  placeholder = 'Select date',
  formControlProps,
  showTimeSelect = false,
  label,
  placement = 'auto',
  minDate = null,
}) => {
  const locale = useLocale();
  const { control, setValue } = useFormContext();
  const {
    formState: { errors, isValid },
  } = useFormContext<OfferFragment>();
  const { t } = useTranslation(['forms']);

  const { field: { value } = {} } = useController({
    name,
    control,
  });

  const currentDate = useMemo(
    () => (value ? new Date(value) : new Date()),
    [value],
  );
  const error = get(errors, name);

  const isDateInvalid = !!error && !isValid;

  const [currentMonth, setCurrentMonth] = useState<Date>(currentDate);

  const dayClassName = (date: Date) => {
    if (date.getMonth() !== currentMonth.getMonth()) {
      return 'datepicker-day-wrapper datepicker-day-wrapper__outside-month';
    }
    return 'datepicker-day-wrapper';
  };

  useEffect(() => {
    setCurrentMonth(currentDate);
  }, [currentDate]);

  return (
    <FormControl
      flex={2}
      isInvalid={isDateInvalid}
      pb="25px"
      position="relative"
      {...formControlProps}>
      <Label color="grey.300" color="grey.300" fontSize={14} fontSize={14}>
        {label ? label : t('date')}
      </Label>
      <ReactDatePicker
        calendarContainer={DatepickerContainer}
        calendarStartDay={1}
        customInput={<DatepickerInput date={value} isInvalid={isDateInvalid} />}
        dayClassName={dayClassName}
        locale={locale}
        minDate={minDate}
        placeholderText={placeholder}
        popperPlacement={placement}
        renderCustomHeader={(props) => <CustomDatepickerHeader {...props} />}
        selected={value ? new Date(value) : undefined}
        shouldCloseOnSelect={false}
        showTimeSelect={showTimeSelect}
        onChange={(date) => {
          setValue(
            name,
            formatDate(
              date,
              showTimeSelect ? 'MM/dd/yyyy h:mm aa' : 'MM/dd/yyyy',
            ),
            { shouldValidate: true },
          );
        }}
        onMonthChange={(date) => setCurrentMonth(date)}
      />
      <ErrorMessage
        bottom="6px"
        color="red.600"
        fontSize="12px"
        fontWeight={400}
        noOfLines={1}
        position="absolute">
        {error?.message}
      </ErrorMessage>
    </FormControl>
  );
};
