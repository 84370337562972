import { useDisclosure } from '@chakra-ui/react';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import { AddOfferModal } from '@app/views/AddOffer/components/AddOfferModal';

interface AddOfferOnOpen {
  offerId?: string;
  requestId?: string;
  chatId?: string;
  orderId?: string;
}

interface AddOfferModalProps {
  data: AddOfferOnOpen;
  handleOpen: ({
    offerId,
    requestId,
    chatId,
    orderId,
  }: AddOfferOnOpen) => Promise<void>;
  handleClose: () => void;
}

export const AddOfferModalContext = createContext<AddOfferModalProps>(null);

export const useAddOfferModalContext = () => {
  const context = useContext(AddOfferModalContext);

  return context;
};

const AddOfferModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const resolveRef = useRef<(value?: unknown) => void>();
  const rejectRef = useRef<(value?: unknown) => void>();
  const [data, setData] = useState<AddOfferOnOpen>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = useCallback((payload?: AddOfferOnOpen) => {
    return new Promise((resolve, reject) => {
      setData(payload);
      onOpen();
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  }, []);

  const handleClose = useCallback(() => {
    setData({});
    onClose();
  }, []);

  return (
    <AddOfferModalContext.Provider
      value={{
        data,
        handleOpen,
        handleClose,
      }}>
      {children}
      {isOpen && (
        <AddOfferModal
          chatId={data?.chatId}
          isOpen={isOpen}
          offerId={data?.offerId}
          orderId={data?.orderId}
          requestId={data?.requestId}
          onClose={() => {
            rejectRef.current();
            onClose();
          }}
        />
      )}
    </AddOfferModalContext.Provider>
  );
};

export default AddOfferModalProvider;
