import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';

export const PageNotFoundView = () => {
  return (
    <Flex alignItems="center" h="100dvh" justifyContent="center">
      <Heading fontSize="9xl">404</Heading>
    </Flex>
  );
};
