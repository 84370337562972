import { HStack, Switch, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateOfferInput, Offer } from '@app/api/gql/generated-types';

export const OfferIsPublic = () => {
  const { t } = useTranslation(['forms']);
  const { setValue } = useFormContext<Offer | CreateOfferInput>();
  const { isPublic } = useWatch<Offer>(['isPublic']);
  return (
    <HStack
      alignItems="center"
      justifyContent="flex-end"
      mb={2}
      mt={6}
      spacing={2}>
      <Switch
        isChecked={isPublic}
        size="sm"
        onChange={() => setValue('isPublic', !isPublic)}
      />
      <Tooltip
        hasArrow
        label={t('publicOfferTooltip')}
        maxW="180px"
        placement="top">
        <Text
          color="grey.250"
          cursor="pointer"
          fontSize={14}
          fontWeight={400} // to do
          onClick={() => setValue('isPublic', !isPublic)}>
          {t('createPublicOffer')}
        </Text>
      </Tooltip>
    </HStack>
  );
};
