import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useReducer,
} from 'react';

import { MessageReference } from '@app/api/gql/generated-types';
import { MessageFragment } from '@app/api/schemas/message.mongo.generated';

import {
  ContextMenuAction,
  ContextMenuActionsTypes,
} from './contextMenuActions';

export interface WithChildren {
  children: ReactNode | JSX.Element;
}

export interface ContextMenuState {
  isOpen: boolean;
  isSpam: boolean;
  points: {
    x: number;
    y: number;
  };
  selectedMessage: MessageFragment | null;
  reference: MessageReference | null;
  replyMessage: MessageFragment | null;
  showReference: boolean;
  disableReport: boolean;
}

const initialState: ContextMenuState = {
  isOpen: false,
  isSpam: false,
  points: {
    x: 0,
    y: 0,
  },
  selectedMessage: null,
  reference: null,
  replyMessage: null,
  showReference: false,
  disableReport: false,
};

export const MenuContext = createContext<{
  state: ContextMenuState;
  dispatch: React.Dispatch<ContextMenuAction>;
}>({ state: initialState, dispatch: () => undefined });

function contextMenuReducer(
  state: ContextMenuState,
  action: ContextMenuAction,
) {
  switch (action.type) {
    case ContextMenuActionsTypes.SET_MENU_OPEN:
    case ContextMenuActionsTypes.SET_SHOW_REPLY:
    case ContextMenuActionsTypes.SET_SHOW_REFERENCE:
    case ContextMenuActionsTypes.SET_DISABLE_REPORT:
    case ContextMenuActionsTypes.SET_SPAM:
      return {
        ...state,
        ...action.payload,
      };
    case ContextMenuActionsTypes.RESET_STATE:
      return initialState;
    default: {
      return state;
    }
  }
}

const ContextMenuProvider: FC<WithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(contextMenuReducer, initialState);
  const value = { state, dispatch };

  return (
    <MenuContext.Provider value={value}> {children} </MenuContext.Provider>
  );
};

function useContextMenu() {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useDemand must be used within a DemandProvider');
  }
  return context;
}

export { ContextMenuProvider, useContextMenu };
