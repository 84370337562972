import { Avatar, Flex, useColorModeValue } from '@chakra-ui/react';
import React, { FC } from 'react';

import { ChatPreviewFragment } from '@app/api/schemas/chat.mongo.generated';

interface HiddenChatHeaderProps {
  item: ChatPreviewFragment;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const HiddenChatHeader: FC<HiddenChatHeaderProps> = ({ item }) => {
  const companion = item?.participantsExcludingSelf?.[0];

  const name =
    companion?.fullName ?? `${companion?.firstName} ${companion?.lastName}`;
  const avatar = companion?.avatar?.key
    ? `${imageServer}/${companion?.avatar?.key}?width=200&format=webp`
    : '';

  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  return (
    <Flex
      align="center"
      borderColor={borderColor}
      borderRadius="full"
      padding="3px"
      position="relative">
      <Avatar h={10} me={0} name={name} src={avatar} w={10}></Avatar>
    </Flex>
  );
};
