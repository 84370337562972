import { date, mixed, number, object, string, array, boolean } from 'yup';
import { parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { OfferType } from '@app/api/gql/generated-types';

export const useValidationSchema = () => {
  const { t } = useTranslation('validations');

  const priceSegment = {
    days: string().required(t('requiredField')).label('Days'),
    startDate: date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value;
        }
        const result = parse(originalValue, 'MM.dd.yyyy', new Date());
        return result;
      })
      .typeError(t('selectDate'))
      .required(t('selectDate')),
    price: object({
      value: string().required(t('selectPrice')),
      currency: mixed().required('currency is a required field'),
    }),
    isSaved: boolean().oneOf([true], t('saveForm')).required(),
  };

  const activeSchema = object().shape({
    title: string().trim().required(t('offerTitle')).label('Title'),
    description: string().trim().required(t('offerDesc')).label('Description'),
    type: string().required(),
    activities: array(mixed()).when('isPublic', {
      is: (val: boolean) => val,
      then: (schema) =>
        schema.min(1, t('selectCategory')).required(t('selectCategory')),
      otherwise: (schema) => schema.nullable(),
    }),
    photo: array(mixed()).min(1, 'Is required'),
    location: mixed().required(t('locationInvalid')),
    offerAddition: array()
      .when('type', {
        is: (type: OfferType) => type === OfferType.TOUR,
        then: (schema) =>
          schema.of(
            object({
              ...priceSegment,
              adults: number()
                .typeError(t('requiredField'))
                .min(1, t('requiredField'))
                .required(t('requiredField')),
            }),
          ),
        otherwise: (schema) =>
          schema.of(
            object({
              ...priceSegment,
            }),
          ),
      })
      .nullable(),
  });

  const draftSchema = object().shape({
    title: string().label('Title'),
    description: string().trim().label('Description'),
  });

  const editPriceSchema = object().shape({
    ...priceSegment,
    adults: number()
      .typeError(t('requiredField'))
      .min(1, t('requiredField'))
      .required(t('requiredField')),
  });

  return {
    offerSchema: yupResolver(activeSchema),
    draftSchema: yupResolver(draftSchema),
    editPriceSchema: yupResolver(editPriceSchema),
  };
};
