import {
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Chevron } from '@app/icons/chevron-down.svg';
import { lngs } from '@app/translations/translations';

export const SelectAppLanguage = () => {
  const { i18n } = useTranslation();
  const languages = useMemo(
    () => Object.keys(lngs).filter((lng) => lng !== i18n.resolvedLanguage),
    [i18n.resolvedLanguage],
  );

  const handleChange = async (lng: string) => {
    await i18n.changeLanguage(lng);
  };

  return (
    <Menu matchWidth offset={[0, 5]} placement="bottom-end">
      <MenuButton p="2px 8px">
        <HStack alignItems="center" color="grey.300" spacing="10px">
          <Text color="grey.300" fontSize={16} textTransform="uppercase">
            {lngs[i18n.resolvedLanguage]?.nativeName}
          </Text>
          <Chevron />
        </HStack>
      </MenuButton>

      <MenuList border="none" minW="50px" overflowY="auto">
        {languages?.map((language) => (
          <MenuItem
            key={language}
            _hover={{
              opacity: 0.8,
            }}
            alignItems="flex-start"
            bg="none"
            flexDirection="column"
            p={0}
            px="14px"
            onClick={() => handleChange(language)}>
            <Text color="grey.300" fontSize={16} textTransform="uppercase">
              {lngs[language]?.nativeName}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
